import { IDayType, IGroupPlanningDate, IPlanningDate, IUserPlanningDate } from 'interfaces/planning'
import { IFileResponse } from 'interfaces/fileResponse'
import { customAxios } from './axiosUtils'
import moment from 'moment'

/**
 * Récupère le planning des utilisateurs pour un mois donné et un critère de recherche optionnel.
 * @param {string} userSearch - Le critère de recherche optionnel pour filtrer les utilisateurs.
 * @param {number} firstDayToVizualise - le 1er jour du mois ou semaine pour lequel récupérer le planning.
 * @param {number} page l'équivalent d'une page de pagination mais pour le scroll(1 scroll = 1 nouvelle page etc...)
 * @param {number} limit nombre max d'éléments à récupérer
 * @param {boolean} isWeekMode savoir si on est en semaine ou mois
 * @param {number | null} place le lieu des collaborateurs
 * @param dayTypes
 * @param {number | null} contractType le type de contrat des collaborateurs
 * @return {Promise<IUserPlanningDate[]>} - Une promesse qui renvoie un tableau d'objets représentant les jours du mois avec leur type d'activité et les utilisateurs concernés.
 */
export const getUsersPlanning = async (
  userSearch: string,
  firstDayToVizualise: Date,
  isWeekMode: boolean,
  page: number,
  limit: number,
  place: number | null,
  dayTypes: number[],
  contractType: number | null,
  selectedDate: string | null
): Promise<IUserPlanningDate[]> => {
  try {
    const pageParam = `&currentPage=${page}`
    const searchParam = userSearch !== '' ? `&search=${userSearch}` : ''
    const dateString = moment(firstDayToVizualise).format('YYYY-MM-DD')
    const placeParam = place !== null ? `&placeId=${place}` : ''
    const isWeekModeParam = `&isWeekMode=${isWeekMode ? 1 : 0}`
    const contractParam =
      contractType !== null && contractType !== undefined ? `&contractType=${contractType}` : ''
    const selectedDateParam = `&selectedDate=${
      selectedDate ?? moment(new Date().toDateString()).format('YYYY-MM-DD')
    }`

    let dayTypeParam = dayTypes
      .map((dayType) => {
        return `dayTypes[]=${dayType}`
      })
      .join('&')

    if (dayTypeParam !== '') {
      dayTypeParam = `&${dayTypeParam}`
    }

    return await customAxios.get(
      `planning?firstDayToVizualise=${dateString}&limit=${limit}${isWeekModeParam}${searchParam}${pageParam}${placeParam}${contractParam}${dayTypeParam}${selectedDateParam}`
    )
  } catch (error) {
    return []
  }
}

/**
 * Récupérer le planning annuel de l'utilisateur connecté
 * @param userId l'id de l'utilisateur séléctionné
 * @param year l'année sélectionnée
 */
export const getUserPlanningOfTheYear = async (
  userId: number,
  year?: number
): Promise<IPlanningDate[]> => {
  try {
    const yearUpdated = year ?? moment(new Date()).year()

    return await customAxios.get(`planning/${userId}?year=${yearUpdated}`)
  } catch (error) {
    return []
  }
}

/**
 * Exporte le planning des utilisateurs pour un mois donné.
 * @param {number} firstDayToVizualise - le 1er jour du mois ou semaine pour lequel exporter le planning
 * @param {boolean} isWeekMode savoir si on est en semaine ou mois
 * @param {number | null} place le lieu des collaborateurs
 * @param {number | undefined} dayTypes pour filtrer sur le type de journée du jour
 * @return string | undefined
 */

export const exportUsersPlanning = async (
  firstDayToVizualise: string,
  place: number | null,
  isWeekMode: boolean,
  dayTypes: number[]
): Promise<IFileResponse | null> => {
  const placeParam = place !== null ? `&placeId=${place}` : ''
  const isWeekModeParam = `&isWeekMode=${isWeekMode ? 1 : 0}`
  const dayTypeParam = dayTypes
    .map((dayType) => {
      return `dayTypes[]=${dayType}`
    })
    .join('&')
  try {
    return await customAxios.get(
      `planning/export?firstDayToVizualise=${firstDayToVizualise}&${dayTypeParam}${isWeekModeParam}${placeParam}`,
      { responseType: 'arraybuffer' }
    )
  } catch (error) {
    return null
  }
}

/**
Permet d'ajouter ou mettre à jour les infos de date d'un utilisateur
@param data le body contenant les données du planning
@return IUserPlanningDate
**/
export const addOrUpdateUserPlanningDates = async (
  data: IUserPlanningDate | null
): Promise<IPlanningDate[]> => {
  try {
    return await customAxios.post('planning', data)
  } catch (error) {
    return []
  }
}

/**
 Permet de valider/refuser une journée
 @param data le body contenant les données du planning
 **/
export const validatePlanningDates = async (
  data: IUserPlanningDate | null
): Promise<IPlanningDate[]> => {
  try {
    if (data?.refusalText && data.planningDates.length > 0) {
      data.planningDates = [...data.planningDates].map((date: IPlanningDate) => ({
        ...date,
        refusalReason: data.refusalText
      }))
    }
    if (data?.planningDates?.[0].id !== undefined) {
      return await customAxios.put(`planning/${data?.planningDates?.[0].id}`, data)
    }

    return []
  } catch (error) {
    return []
  }
}

export const getDayTypes = async (): Promise<IDayType[]> => {
  try {
    return await customAxios.get('planning/types')
  } catch (error) {
    return []
  }
}

/**
 * Récupérer les TT à valider
 */
export const getTTToValidate = async (placeId: number | null): Promise<IUserPlanningDate[]> => {
  try {
    const placeParam = placeId !== null ? `?placeId=${placeId}` : ''
    return await customAxios.get(`planning/offsite${placeParam}`)
  } catch (error) {
    return []
  }
}

export const getTotalUsersPresentials = async (
  firstDayToVizualise: Date,
  placeId: number | null
): Promise<[]> => {
  try {
    const dateString = moment(firstDayToVizualise).format('YYYY-MM-DD')
    const placeParam = placeId !== null ? `&placeId=${placeId}` : ''
    return await customAxios.get(`planning/totalday?firstDayToVizualise=${dateString}${placeParam}`)
  } catch (error) {
    return []
  }
}

/**
 * Récupère le planning des groupes pour un mois donné et un critère de recherche optionnel.
 * @param {string} groupSearch - Le critère de recherche optionnel pour filtrer les groupes.
 * @param {number} firstDayToVizualise - le 1er jour du mois ou semaine pour lequel récupérer le planning.
 * @param {number} page l'équivalent d'une page de pagination mais pour le scroll(1 scroll = 1 nouvelle page etc...)
 * @param {number} limit nombre max d'éléments à récupérer
 * @param {boolean} isWeekMode savoir si on est en semaine ou mois
 * @param {number | null} place le lieu des collaborateurs
 * @param dayTypes
 * @param {number | null} contractType le type de contrat des collaborateurs
 * @return {Promise<IGroupPlanningDate[]>} - Une promesse qui renvoie un tableau d'objets représentant les jours du mois avec leur type d'activité et les utilisateurs concernés.
 */
export const getGroupsPlanning = async (
  groupSearch: string,
  firstDayToVizualise: Date,
  isWeekMode: boolean,
  page: number,
  limit: number,
  place: number | null,
  dayTypes: number[],
  contractType: number | null
): Promise<IGroupPlanningDate[]> => {
  try {
    const pageParam = `&currentPage=${page}`
    const searchParam = `&search=${groupSearch}`
    const dateString = moment(firstDayToVizualise).format('YYYY-MM-DD')
    const placeParam = place !== null ? `&placeId=${place}` : ''
    const isWeekModeParam = `&isWeekMode=${isWeekMode ? 1 : 0}`
    const contractParam =
      contractType !== null && contractType !== undefined ? `&contractType=${contractType}` : ''

    let dayTypeParam = dayTypes
      .map((dayType) => {
        return `dayTypes[]=${dayType}`
      })
      .join('&')

    if (dayTypeParam !== '') {
      dayTypeParam = `&${dayTypeParam}`
    }

    return await customAxios.get(
      `planning/groups?firstDayToVizualise=${dateString}&limit=${limit}${isWeekModeParam}${searchParam}${pageParam}${placeParam}${contractParam}${dayTypeParam}`
    )
  } catch (error) {
    return []
  }
}
