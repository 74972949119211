import { IheadCells } from 'interfaces/headCells'

export const tableCollabsColumns: IheadCells[] = [
  {
    id: 'googlePicture',
    label: '',
    priority: 4,
    isSortable: false
  },
  {
    id: 'lastname',
    label: 'Nom',
    priority: 1
  },
  {
    id: 'firstname',
    label: 'Prénom',
    priority: 1
  },
  {
    id: 'place',
    label: 'Lieu',
    priority: 4
  },
  {
    id: 'contractType',
    label: 'Contrat',
    priority: 4
  },
  {
    id: 'permanentContractStartDate',
    label: 'Date de début contrat',
    priority: 3
  },
  {
    id: 'internshipStartDate',
    label: 'Date de début stage',
    priority: 3
  },
  {
    id: 'leavingDate',
    label: 'Date de sortie',
    priority: 3
  }
]

export const tableCollabsWithHolidayColumns: IheadCells[] = [
  ...tableCollabsColumns,
  {
    id: 'annualQuotas',
    label: 'Droits annuels',
    priority: 4,
    isSortable: false
  },
  {
    id: 'remainingQuotas',
    label: 'Droits restants',
    priority: 4,
    isSortable: true
  }
]
