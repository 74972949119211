import {
  getContractTypes,
  getJobs,
  getPlaces,
  getRoles,
  getServices
} from 'services/SelectorsValuesService'
import {
  createUserExpenseSettings,
  getExpensesSubtypes,
  getExpensesTypes
} from 'services/ExpensesService'
import { IExpensesSubtype, IExpensesType, IMyExpense } from 'interfaces/expenses'
import { IListSelectorValue, IServiceAccountData } from 'interfaces/selectors'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IUsersHolidayQuota } from 'interfaces/holidays/userHolidayQuota'
import { createOrUpdateUsersHolidayQuota } from 'services/HolidayService'
import { featuresList, jobConstant, mealPlanConstant } from 'constantes'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { saveUserPicture } from 'services/ProfilePictureService'
import { isFeatureEnabled } from '../../services/SettingService'
import { IUpdateUserData } from 'interfaces/collaborateurData'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CircularProgress from '@mui/material/CircularProgress'
import CreationFormPartOne from './form/CreationFormPartOne'
import CreationFormPartTwo from './form/CreationFormPartTwo'
import { createOrUpdateCollab } from 'services/UserService'
import { FeaturesContext, YearSelectedContext } from 'App'
import { getHomonymes } from 'services/HomonymeService'
import { IErrors } from 'interfaces/formFields'
import { useNavigate } from 'react-router-dom'
import { isValidDate } from 'utils/dateUtils'
import moment from 'moment'

const AddCollab = (): ReactElement => {
  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [jobs, setJobs] = useState<IListSelectorValue[]>([])
  const [contractTypes, setContractTypes] = useState<IListSelectorValue[]>([])
  const [places, setPlaces] = useState<IListSelectorValue[]>([])
  const [roles, setRoles] = useState<IListSelectorValue[]>([])
  const [expenseTypes, setExpenseTypes] = useState<IExpensesType[]>([])
  const [expenseSubtypes, setExpenseSubtypes] = useState<IExpensesSubtype[]>([])
  const [services, setServices] = useState<IServiceAccountData[]>([])
  const [errors, setErrors] = useState<IErrors>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedImg, setSelectedImg] = useState<string | null>(null)
  const [userHolidaysQuota, setUserHolidaysQuota] = useState<IUsersHolidayQuota | null>({
    year: moment().year(),
    RTTQuota: 0,
    paidVacationQuota: 0,
    users: []
  })
  const [userExpenseSettings, setUserExpenseSettings] = useState<IMyExpense[]>([])

  const yearSelected = useContext(YearSelectedContext)
  const featuresContext = useContext(FeaturesContext)

  const [userDataToSend, setUserDataToSend] = useState<IUpdateUserData>({
    lastname: '',
    firstname: '',
    birthdayDate: null,
    phoneNumber: '',
    customPicture: '',
    job: 2,
    contractType: 1,
    tutor: null,
    rolePermissions: [2],
    place: 1,
    arrivalDate: null,
    leavingDate: null,
    internshipStartDate: null,
    permanentContractStartDate: null,
    badgeId: '',
    mealPlan: mealPlanConstant.RESTAU_TICKETS,
    initials: '',
    BIC: '',
    IBAN: '',
    personalMail: '',
    googleAccount: true,
    mattermostAccount: true,
    merakiAccount: true,
    jetbrainsAccount: true,
    gitlabAccount: true,
    bitwardenAccount: true,
    jiraAccount: true,
    updateMaterials: [],
    endTrialPeriodDate: null,
    endInternshipDate: null,
    reportingEnabled: true
  })

  // Alimentation des listes
  useEffect(() => {
    setLoading(true)
    void (async () => {
      setServices(await getServices())
      const jobs = await getJobs()
      const contractTypes = await getContractTypes()
      const places = await getPlaces()
      const roles = await getRoles()
      setJobs(jobs)
      setContractTypes(contractTypes)
      setPlaces(places)
      setRoles(roles)

      if (isFeatureEnabled(featuresContext, featuresList.NDF)) {
        const expenseTypes = await getExpensesTypes()
        const expenseSubtypes = await getExpensesSubtypes()
        setExpenseTypes(expenseTypes)
        setExpenseSubtypes(expenseSubtypes)
      }

      setLoading(false)
    })()
  }, [])

  // Initialisation des valeurs des checkbox en fonction du profil du collaborateur à ajouter
  useEffect(() => {
    preSelectServices()
  }, [userDataToSend.job])

  const preSelectServices = (): void => {
    if (jobs.length === 0) return

    const jobName = jobs.filter((job) => {
      return job.id === userDataToSend.job
    })[0].name
    if (
      jobName !== undefined &&
      jobName !== jobConstant.DEVELOPPEUR &&
      jobName !== jobConstant.DEVOPS &&
      jobName !== jobConstant.DIRECTION
    ) {
      setUserDataToSend({ ...userDataToSend, gitlabAccount: false, jetbrainsAccount: false })
    } else {
      setUserDataToSend({ ...userDataToSend, gitlabAccount: true })
    }
  }

  const controlData = async (): Promise<void> => {
    let error: boolean = false
    let lastnameErrorMessage = ''
    let firstnameErrorMessage = ''
    let arrivalDateErrorMessage = ''
    let personalMailErrorMessage = ''
    let phoneNumberErrorMessage = ''

    if (
      !isValidDate(userDataToSend.arrivalDate) &&
      !isValidDate(userDataToSend.internshipStartDate)
    ) {
      arrivalDateErrorMessage = "Veuillez renseigner la date d'arrivée ou la date de début de stage"
      error = true
    }
    if (userDataToSend.firstname === '') {
      firstnameErrorMessage = 'Veuillez renseigner le prénom'
      error = true
    }
    if (userDataToSend.lastname === '') {
      lastnameErrorMessage = 'Veuillez renseigner le nom'
      error = true
    }
    if (userDataToSend.personalMail === '') {
      personalMailErrorMessage = "Veuillez renseigner l'adresse mail personnelle du collaborateur"
      error = true
    } else if (!userDataToSend.personalMail.includes('@')) {
      personalMailErrorMessage = 'Veuillez renseigner une adresse mail valide'
      error = true
    }
    if (
      (userDataToSend.phoneNumber.length > 0 &&
        userDataToSend.phoneNumber.match(/^(\+33|0)[1-9][0-9]{8}$/) === null) ||
      userDataToSend.phoneNumber.match(/^(\+33|0)[1-9][0-9]{8}$/)?.length === 0
    ) {
      phoneNumberErrorMessage = 'Veuillez renseigner un numéro de téléphone valide'
      error = true
    }

    setErrors({
      ...errors,
      arrivalDate: { message: arrivalDateErrorMessage },
      firstname: { message: firstnameErrorMessage },
      lastname: { message: lastnameErrorMessage },
      personalMail: { message: personalMailErrorMessage },
      phoneNumber: { message: phoneNumberErrorMessage }
    })

    // si pas d'erreurs, alors on peut passer à la page suivante
    if (!error) {
      const nbHomonymes = await getHomonymes(userDataToSend.firstname, userDataToSend.lastname)
      if (nbHomonymes !== undefined && nbHomonymes > 0) {
        const result = confirm(
          `Attention, nous avons trouvé ${nbHomonymes} homonyme(s). Souhaitez-vous continuer ?`
        )
        if (result) {
          setPage(page + 1)
        } else {
          return
        }
      }
      setPage(page + 1)
    }
  }

  const handleSubmit = async (): Promise<void> => {
    if (page === 0) {
      await controlData()
    } else {
      setLoading(true)
      const user = await createOrUpdateCollab(userDataToSend, Number(yearSelected?.yearSelected))

      // Création de l'entrée renseignant les congés du collaborateur
      if (
        isFeatureEnabled(featuresContext, featuresList.HOLIDAY) &&
        userHolidaysQuota !== null &&
        user !== null
      ) {
        userHolidaysQuota.users = [user]
        const quotaResponse = await createOrUpdateUsersHolidayQuota(userHolidaysQuota)
        setUserHolidaysQuota(quotaResponse)
      }
      // Création de l'entrée renseignant les settings de note de frais du collaborateur
      if (
        isFeatureEnabled(featuresContext, featuresList.NDF) &&
        userExpenseSettings !== null &&
        user !== null
      ) {
        userExpenseSettings.forEach((userExpense) => {
          void (async () => {
            if (userExpense !== null) {
              await createUserExpenseSettings(user.id, userExpense)
            }
          })()
        })
      }
      // Sauvegarde la nouvelle photo de profil si renseignée
      if (selectedImg !== null && user !== null) {
        await saveUserPicture(Number(user.id), selectedImg)
      }
      setLoading(false)
      if (user !== null) navigate('/tableau_de_bord')
    }
  }

  return (
    <div>
      <PrimaryButton
        handleClick={(): void => {
          page === 0 ? navigate(-1) : setPage(page - 1)
        }}
        title="Retour"
        icon={<ChevronLeftIcon />}
        background={false}
        reverse={false}
        disabled={false}
      />
      <h3>
        Ajout d&apos;un nouveau <span className="accentuated-title">Collaborateur</span>{' '}
        <span className="progress-number">{page + 1}/2</span>{' '}
      </h3>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          {page === 0 ? (
            <CreationFormPartOne
              selectedImg={selectedImg}
              setSelectedImg={setSelectedImg}
              errors={errors}
              userDataToSend={userDataToSend}
              setUserDataToSend={setUserDataToSend}
              jobs={jobs}
              contractTypes={contractTypes}
              places={places}
              roles={roles}
              expenseTypes={expenseTypes}
              expenseSubtypes={expenseSubtypes}
              userHolidaysQuota={userHolidaysQuota}
              setUserHolidaysQuota={setUserHolidaysQuota}
              userExpenseSettings={userExpenseSettings}
              setUserExpenseSettings={setUserExpenseSettings}
            />
          ) : (
            <CreationFormPartTwo
              userDataToSend={userDataToSend}
              setUserDataToSend={setUserDataToSend}
              jobs={jobs}
              contractTypes={contractTypes}
              services={services}
            />
          )}
          <div className="btn-box">
            <PrimaryButton
              handleClick={() => {
                void (async () => {
                  await handleSubmit()
                })()
              }}
              title={page === 0 ? 'Etape suivante' : 'Créer compte'}
              background
              reverse={false}
              disabled={false}
            />
            <PrimaryButton
              handleClick={(): void => {
                page === 0 ? navigate(-1) : setPage(page - 1)
              }}
              title={page === 0 ? 'Annuler' : 'Etape précédente'}
              background
              reverse
              disabled={false}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default AddCollab
