import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import CustomAutoComplete from 'components/inputs/autoCompleteInput/AutoCompleteClientInput'
import CustomSelectArrows from 'components/inputs/customSelect/CustomSelectArrows'
import TwoChoicesButton from 'components/inputs/button/TwoChoicesButton'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { IProject } from 'interfaces/projects'
import { IClient } from 'interfaces/clients'
import React, { ReactElement } from 'react'

export interface AdminReportingFiltersProps {
  isBillable: boolean
  setIsBillable: React.Dispatch<React.SetStateAction<boolean>>
  selectedClient: IClient | undefined
  selectedProject: IProject | undefined
  clientOptions: string[]
  projectOptions: string[]
  projectTypes: string[]
  handleChangeClient: (value: string | null) => Promise<void>
  handleChangeProject: (value: string) => void
  handleChangeMonth: (newMonth: string) => void
  handleChangeYear: (newYear: number) => void
  monthsList: string[]
  month: number
  year: number
  years: string[]
  currentTab: number
  users: IMinimalCollaborateurData[]
  retrieveUsers: (inputValue: string) => Promise<IMinimalCollaborateurData[]>
  currentUser: IMinimalCollaborateurData | null
  setCurrentUser: (user: IMinimalCollaborateurData | null) => void
  currentContract: string
  setCurrentContract: React.Dispatch<React.SetStateAction<string>>
  currentProjectType: string
  setCurrentProjectType: React.Dispatch<React.SetStateAction<string>>
  contracts: string[]
  currentJob: string
  jobs: string[]
  setCurrentJob: React.Dispatch<React.SetStateAction<string>>
}

const AdminReportingFilters = ({
  isBillable,
  setIsBillable,
  selectedClient,
  selectedProject,
  clientOptions,
  projectOptions,
  projectTypes,
  handleChangeClient,
  handleChangeProject,
  handleChangeMonth,
  handleChangeYear,
  monthsList,
  month,
  year,
  years,
  currentTab,
  users,
  retrieveUsers,
  currentUser,
  setCurrentUser,
  currentContract,
  contracts,
  currentJob,
  jobs,
  setCurrentJob,
  setCurrentContract,
  currentProjectType,
  setCurrentProjectType
}: AdminReportingFiltersProps): ReactElement => {
  return (
    <>
      <div className="flex gap-3 wrap">
        {currentTab !== 1 && (
          <div className="pb-7">
            <CustomSelectArrows
              value={monthsList[month - 1]}
              options={monthsList}
              handleChange={(newValue: string) => {
                handleChangeMonth(newValue)
              }}
              required={false}
              width={150}
            />
          </div>
        )}

        <div className="pb-7">
          <CustomSelect
            value={year}
            options={years}
            handleSelect={(newValue) => {
              handleChangeYear(Number(newValue))
            }}
            id="year"
            width={100}
            inputLabel=""
            showDelete={false}
            placeholder="Année"
          />
        </div>

        {currentTab === 2 && (
          <div>
            <CustomSelect
              value={currentProjectType ?? null}
              options={projectTypes}
              handleSelect={(newValue) => {
                setCurrentProjectType(newValue)
              }}
              id="projectType"
              width={150}
              inputLabel=""
              showDelete={true}
              placeholder="Type"
            />
          </div>
        )}

        {currentTab === 3 && (
          <>
            <div>
              <AutoCompleteUserInput
                value={currentUser ?? null}
                onChange={(e: any, newValue: IMinimalCollaborateurData | null): void => {
                  if (newValue !== null) {
                    setCurrentUser(newValue)
                  }
                }}
                id="userAssignmentId"
                options={users}
                setOptions={(inputValue: string) => {
                  void (async () => {
                    await retrieveUsers(inputValue)
                  })()
                }}
                width={250}
                placeholder="Nom Prénom"
              />
            </div>
            <div>
              <CustomSelect
                value={currentContract ?? null}
                options={contracts}
                handleSelect={(newValue) => {
                  setCurrentContract(newValue)
                }}
                id="contract"
                width={150}
                inputLabel=""
                showDelete={true}
                placeholder="Contrat"
              />
            </div>
            <div>
              <CustomSelect
                value={currentJob ?? null}
                options={jobs}
                handleSelect={(newValue) => {
                  setCurrentJob(newValue)
                }}
                id="job"
                width={250}
                inputLabel=""
                showDelete={true}
                placeholder="Poste"
              />
            </div>
          </>
        )}

        {(currentTab === 2 || currentTab === 4) && (
          <CustomAutoComplete
            value={selectedClient?.name ?? ''}
            onChange={(e: any, newValue: string | null): void => {
              void handleChangeClient(newValue)
            }}
            id="client"
            options={clientOptions}
            setOptions={(newValue: string | null): void => {
              void handleChangeClient(newValue)
            }}
            width={200}
            placeholder="Client"
          />
        )}
        {(currentTab === 2 || currentTab === 4) && (
          <CustomSelect
            value={selectedProject?.name ?? ''}
            options={projectOptions}
            handleSelect={(newValue) => {
              handleChangeProject(newValue)
            }}
            id="project"
            width={260}
            inputLabel=""
            showDelete={false}
            placeholder="Projet"
          />
        )}
        {(currentTab === 2 || currentTab === 4) && (
          <div className="xl:pt-3">
            <TwoChoicesButton
              title1="Facturable"
              title2="Non facturable"
              onChange={setIsBillable}
              defaultValue={isBillable}
              color="violet"
            />
          </div>
        )}
      </div>
    </>
  )
}

export default AdminReportingFilters
