import { IUpdateUserData } from 'interfaces/collaborateurData'
import CheckBox from 'components/inputs/checkBox/CheckBox'
import { IServiceAccountData } from 'interfaces/selectors'
import React, { ReactElement } from 'react'

interface IListServices {
  accounts: IServiceAccountData[]
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  title: string
  mTop?: boolean
}

const ListServices = ({
  accounts,
  editMode,
  userDataToSend,
  setUserDataToSend,
  title,
  mTop
}: IListServices): ReactElement => {
  return (
    <div className={mTop !== undefined ? 'box-accounts m-top' : 'box-accounts'}>
      <p className="data-title">{title}</p>
      <div className="list-accounts">
        {accounts?.map((account, index) => {
          const property = `${account.name.toLowerCase().split(' (à venir)')[0]}Account`
          return !editMode ? (
            <div key={index} className="box-account">
              <img src={`../${account.logoPath}`} alt="[icon service]" />
              <p className={account.name.includes('venir') ? 'will-be-created' : ''}>
                {account.name}
              </p>
            </div>
          ) : (
            <CheckBox
              key={index}
              userDataToSend={userDataToSend}
              setUserDataToSend={setUserDataToSend}
              id={account.name.toLowerCase()}
              title={account.name}
              icon={`../${account.logoPath}`}
              propertyToUpdate={property}
              disabled={false}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ListServices
