import { IMinimalCollaborateurData, IUpdateUserData } from 'interfaces/collaborateurData'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import ImageUploader from 'components/inputs/uploader/ImageUploader'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement, useContext, useEffect } from 'react'
import { saveUserPicture } from 'services/ProfilePictureService'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { hasManagerRole, hasRHRole } from 'utils/otherUtils'
import { CurrentUserContext } from 'App'
import { toast } from 'react-toastify'

interface IProfilHeader {
  editMode: boolean
  user: IMinimalCollaborateurData | null | undefined
  selectedImg: string | null
  setSelectedImg: React.Dispatch<React.SetStateAction<string | null>>
  lastnameError: string | null
  firstnameError: string | null
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  handleClick: () => void
  croppedImg: string | null
  setCroppedImg: React.Dispatch<React.SetStateAction<string | null>>
}

const ProfilHeader = ({
  user,
  handleClick,
  editMode,
  selectedImg,
  setSelectedImg,
  lastnameError,
  firstnameError,
  userDataToSend,
  setUserDataToSend,
  croppedImg,
  setCroppedImg
}: IProfilHeader): ReactElement => {
  const handleCopy = (): void => {
    void navigator.clipboard.writeText(user?.email !== undefined ? user?.email : '')
    toast.success('Email copié !')
  }
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  useEffect(() => {
    void (async () => {
      if (selectedImg !== null && user !== null && user !== undefined) {
        await saveUserPicture(Number(user.id), croppedImg ?? selectedImg)
      }
    })()
  }, [selectedImg, croppedImg])

  return (
    <div className="py-4 px-6">
      <div className="md:flex md:justify-between md:items-center">
        <div className="flex items-center">
          <div className="mr-6 mb-3">
            <ImageUploader
              selectedImg={selectedImg}
              setSelectedImg={setSelectedImg}
              entity={user}
              croppedImg={croppedImg}
              setCroppedImg={setCroppedImg}
            />
          </div>
          <div>
            {editMode ? (
              <div className="text-field">
                <CustomTextField
                  onChange={(value) => {
                    setUserDataToSend({ ...userDataToSend, firstname: value })
                  }}
                  id="firstname"
                  value={userDataToSend?.firstname ?? ''}
                  errorMessage={firstnameError ?? ''}
                  required
                  label="Prénom"
                />
                <CustomTextField
                  onChange={(value) => {
                    setUserDataToSend({ ...userDataToSend, lastname: value })
                  }}
                  id="lastname"
                  value={userDataToSend?.lastname ?? ''}
                  errorMessage={lastnameError ?? ''}
                  required
                  label="Nom"
                />
              </div>
            ) : (
              <div className="text-xl md:text-4xl">
                {user?.displayFirstname} {user?.displayLastname}
              </div>
            )}
            {user?.email !== null && user?.email !== undefined && (
              <div className="flex items-center mt-2">
                <p className="mr-2">{user?.email}</p>
                <ContentCopyIcon className="copy-email" onClick={handleCopy} />
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 md:mt-0">
          {currentUser?.rolePermissions !== undefined &&
            (hasManagerRole(currentUser?.rolePermissions) ||
              hasRHRole(currentUser?.rolePermissions)) &&
            !editMode && (
              <PrimaryButton
                handleClick={handleClick}
                title="Modifier"
                background
                reverse={false}
                disabled={false}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default ProfilHeader
