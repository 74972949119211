import React, { ReactElement } from 'react'

interface ISubFormHeader {
  title: string
}

const SubFormHeader = ({ title }: ISubFormHeader): ReactElement => {
  return (
    <div>
      <p className="subForm-title">{title}</p>
      <div className="dash"></div>
    </div>
  )
}

export default SubFormHeader
