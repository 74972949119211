import {
  deleteJustificationFile,
  getJustificationFile,
  saveJustificationFile
} from 'services/JustificationPdfService'
import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import DocumentUploader from 'components/inputs/uploader/DocumentUploader'
import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import React, { ReactElement, useEffect, useState } from 'react'
import { Delete, FileDownload } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { PdfFile } from 'interfaces/pfdFile'
import fileDownload from 'js-file-download'
import './HolidaysRequestPdf.scss'

interface IHolidaysRequestPdf {
  request: IDetailHolidayRequest
  handlePdfUpdate: (response: IDetailHolidayRequest, file: PdfFile | null) => void
}

const HolidaysRequestPdf = ({ request, handlePdfUpdate }: IHolidaysRequestPdf): ReactElement => {
  const [selectedPdf, setSelectedPdf] = useState<PdfFile | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [openDeleteJustifPopUp, setOpenDeleteJustifPopUp] = useState<boolean>(false)

  const handleConfirm = async (file: PdfFile): Promise<void> => {
    const response = await saveJustificationFile(request?.id, file)
    if (response !== null) {
      handlePdfUpdate(response, file)
    }
  }

  const handleDelete = async (): Promise<void> => {
    setLoading(true)
    const response = await deleteJustificationFile(request?.id)
    if (response !== null) {
      setSelectedPdf(null)
      handlePdfUpdate(response, null)
    }
    setOpenDeleteJustifPopUp(false)
    setLoading(false)
  }

  const handleDownload = async (): Promise<void> => {
    const response = await getJustificationFile(request.id)
    if (response !== null) {
      fileDownload(response.content, response.filename)
    }
  }

  useEffect(() => {
    void (async () => {
      if (selectedPdf !== null) {
        await handleConfirm(selectedPdf)
      }
    })()
  }, [selectedPdf])

  return (
    <div className="holidays-requests-document">
      {request.justificationFilename !== undefined ? (
        <div className="pdf-data-display">
          <p>{request.justificationFilename}</p>
          {request.justificationFilename !== '-' && (
            <div className="pdf-icon-box">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  void (async () => {
                    await handleDownload()
                  })()
                }}
              >
                <FileDownload id="download-file-icon" />
              </IconButton>
              <DocumentUploader
                selectedFile={selectedPdf}
                uploadFile={(pdfBase64) => {
                  setSelectedPdf(pdfBase64)
                }}
                minimalDesign
                edit={true}
              />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenDeleteJustifPopUp(true)
                }}
              >
                <Delete id="delete-file-icon" />
              </IconButton>
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <DocumentUploader
              selectedFile={selectedPdf}
              uploadFile={(pdfBase64) => {
                setSelectedPdf(pdfBase64)
              }}
              minimalDesign
              edit={false}
            />
          </div>
        </>
      )}

      <ConfirmationPopUp
        dialogTitle="Voulez-vous vraiment supprimer le justificatif de congé ?"
        dialogContentText=""
        open={openDeleteJustifPopUp}
        setOpen={setOpenDeleteJustifPopUp}
        handleSave={handleDelete}
        loading={loading}
      />
    </div>
  )
}

export default HolidaysRequestPdf
