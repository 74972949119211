import { InputLabel, TextField } from '@mui/material'
import React, { ReactElement } from 'react'

interface ICustomNumberTextField {
  onChange: (value: string) => void
  id: string
  value: string
  errorMessage?: string
  required: boolean
  label?: string
  disabled?: boolean
  float?: boolean
  text?: string
}

const CustomNumberTextField = ({
  onChange,
  id,
  value,
  errorMessage = '',
  required,
  label = '',
  disabled = false,
  text = ''
}: ICustomNumberTextField): ReactElement => {
  return (
    <div>
      <InputLabel id={id} required={required}>
        {label}
      </InputLabel>
      <TextField
        id={id}
        variant="outlined"
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={value}
        disabled={disabled}
        helperText={text}
        type="number"
        inputProps={{
          step: 0.5
        }}
      />
      {errorMessage !== undefined && errorMessage !== '' && (
        <p className="form-error">{errorMessage}</p>
      )}
    </div>
  )
}

export default CustomNumberTextField
