import { IClient, IUpdateClient } from 'interfaces/clients'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

/**
 * Récupération des clients.
 */
export const getClients = async (search?: string, place?: number): Promise<IClient[]> => {
  try {
    const placeParam = place && place !== null ? place : 1
    const searchParam = search && search !== '' ? `&search=${search}` : ''
    return await customAxios.get(`clients?${searchParam}&placeId=${placeParam}`)
  } catch (error) {
    return []
  }
}

/**
 Permet d'ajouter un client
 @param data le body contenant les données du client
 **/
export const addClient = async (data: IUpdateClient): Promise<IClient[]> => {
  try {
    const client = await customAxios.post(`clients`, data)
    toast.success('Client ajouté avec succès')
    return client as unknown as IClient[]
  } catch (error) {
    return []
  }
}

/**
 Permet de modifier un client
 @param data le body contenant les données du client
 **/
export const updateClient = async (data: IUpdateClient): Promise<IClient[]> => {
  try {
    if (data.id !== undefined) {
      const client = await customAxios.put(`clients/${data.id}`, data)
      toast.success('Client modifié avec succès')
      return client as unknown as IClient[]
    }
    return []
  } catch (error) {
    return []
  }
}

/**
 Permet de supprimer un client
 @param data le body contenant les données du client
 **/
export const deleteClient = async (data: IClient): Promise<IClient[]> => {
  try {
    if (data.id !== undefined) {
      const client = await customAxios.delete(`clients/${data.id}`)
      toast.success('Client supprimé avec succès')
      return client as unknown as IClient[]
    }
    return []
  } catch (error) {
    return []
  }
}
