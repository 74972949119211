import { IInterviewAnswer, IInterviewQuestion } from '../../../interfaces/career'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Dialog, DialogActions, IconButton } from '@mui/material'
import React, { ReactElement, useMemo, useState } from 'react'
import PrimaryButton from '../../inputs/button/PrimaryButton'

interface IReviewReaderPopUp {
  open: boolean
  close: () => void
  questions: IInterviewQuestion[]
  answers: IInterviewAnswer[]
  isAdmin: boolean
}

const ReviewReaderPopUp = ({
  open,
  close,
  questions,
  answers,
  isAdmin
}: IReviewReaderPopUp): ReactElement => {
  // Regroupe les réponses par utilisateur
  const answersByUser = useMemo(() => {
    const users = [...new Set(answers.map((answer) => answer.user.id))]
    return users.map((userId) => {
      const userAnswers = answers.filter((answer) => answer.user.id === userId)
      return {
        user: userAnswers[0].user,
        answers: userAnswers
      }
    })
  }, [answers])

  const [currentUserIndex, setCurrentUserIndex] = useState(0)

  // Fonctions pour naviguer entre les utilisateurs
  const handlePrevUser = (): void => {
    setCurrentUserIndex((prev) => Math.max(0, prev - 1))
  }

  const handleNextUser = (): void => {
    setCurrentUserIndex((prev) => Math.min(answersByUser.length - 1, prev + 1))
  }

  const currentUser = answersByUser[currentUserIndex]

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="review-popup"
    >
      <DialogActions>
        <div className="w-full max-w-3xl mx-auto p-4">
          {answersByUser.length > 0 && (
            <div className="flex items-center justify-between mb-4 border-b pb-2">
              <span className="text-lg font-semibold">
                Réponses de {currentUser.user.displayFirstname} {currentUser.user.displayLastname}
              </span>
              {answersByUser.length > 1 && isAdmin && (
                <div className="flex items-center gap-2">
                  <IconButton
                    onClick={handlePrevUser}
                    disabled={currentUserIndex === 0}
                    size="small"
                  >
                    <ArrowBackIos
                      fontSize="small"
                      color={currentUserIndex === 0 ? 'disabled' : 'action'}
                    />
                  </IconButton>
                  <span className="text-sm text-gray-500">
                    {currentUserIndex + 1} / {answersByUser.length}
                  </span>
                  <IconButton
                    onClick={handleNextUser}
                    disabled={currentUserIndex === answersByUser.length - 1}
                    size="small"
                  >
                    <ArrowForwardIos
                      fontSize="small"
                      color={currentUserIndex === answersByUser.length - 1 ? 'disabled' : 'action'}
                    />
                  </IconButton>
                </div>
              )}
            </div>
          )}

          {questions.map((question, index) => {
            const currentAnswer = currentUser?.answers.find((a) => a.question.id === question.id)

            return (
              <div key={index} className="p-4 border-b border-gray-200 last:border-b-0">
                <p className="font-bold underline mb-2">{question.title}</p>
                <p className="italic mb-3 text-blue-600">{question.question}</p>
                <p className="mb-3">{currentAnswer?.answer ?? '(Pas de réponse fournie)'}</p>
              </div>
            )
          })}

          <div className="mt-4 flex justify-end">
            <PrimaryButton
              handleClick={close}
              title="Fermer"
              background
              reverse={false}
              disabled={false}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ReviewReaderPopUp
