import {
  OSList,
  ROWS_PER_PAGE,
  YesOrNotConstant,
  YesOrNotList,
  materialTypeConstant,
  materialTypeList,
  screenSizes
} from 'constantes'
import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import { getMaterialsWithPagination } from 'services/DataWithPaginationService'
import FilteredListItems from 'components/filteredListItems/FilteredListItems'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { IMaterialWithPagination } from 'interfaces/dataWithPagination'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { useRetrieveUsers } from 'customHooks/retrieveMatchingUsers'
import { tableMaterialsColumns } from './data/tableMaterialsColumns'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { IGetMinimalMaterialData } from 'interfaces/materialData'
import SearchBox from 'components/inputs/searchBox/SearchBox'
import ListMaterialsBodyTable from './ListMaterialsBodyTable'
import { CircularProgress, InputLabel } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import useScreenSize from 'customHooks/useScreenSize'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { PlaceSelectedContext } from 'App'
import { Order } from 'types/order'

const ListMaterials = (): ReactElement => {
  const location = useLocation()
  const navigate = useNavigate()
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const queryParams = new URLSearchParams(location.search)
  const [users, retrieveUsers] = useRetrieveUsers()
  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)

  const [onlyShowFilter, setOnlyShowFilter] = useState<boolean>(false)
  const [os, setOS] = useState<string>('')
  const [page, setPage] = useState(1)
  const [materials, setMaterials] = useState<IGetMinimalMaterialData[]>([])
  const [nbPages, setNbPages] = useState<number>(1)
  const [order, setOrder] = useState<Order>('desc')
  const [loading, setLoading] = useState<boolean>(true)
  const orderByParam = queryParams.get('tri')
  const [orderBy, setOrderBy] = useState<string>(orderByParam !== null ? orderByParam : 'updatedAt')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isActive, setIsActive] = useState<string | null>(YesOrNotConstant.yes)
  const [isAssigned, setIsAssigned] = useState<string | null>(null)
  const [materialType, setMaterialType] = useState<string | null>(materialTypeConstant.PORTABLE)
  const [userAssignmentObject, setUserAssignmentObject] =
    useState<IMinimalCollaborateurData | null>(null)

  // Récupération des matériels + nb de pages à utiliser lorsqu'un order by, un filtre ou un changement de page est sélectionné
  useEffect(() => {
    setLoading(true)
    void (async () => {
      const userAssignmentId = userAssignmentObject?.id
      const response = (await getMaterialsWithPagination(
        page,
        ROWS_PER_PAGE,
        orderBy,
        order,
        searchTerm,
        os,
        place,
        materialType,
        isActive !== null ? (isActive === YesOrNotConstant.yes ? 1 : 0) : null,
        isAssigned !== null ? (isAssigned === YesOrNotConstant.yes ? 1 : 0) : null,
        userAssignmentId
      )) as IMaterialWithPagination
      setNbPages(response.pages)
      setMaterials(response.data)
      setLoading(false)
    })()
  }, [
    os,
    page,
    orderBy,
    order,
    searchTerm,
    isActive,
    userAssignmentObject,
    isAssigned,
    place,
    materialType
  ])

  // Revenir à la première page à chaque changement de filtre
  useEffect(() => {
    setPage(1)
  }, [os, orderBy, order, searchTerm, materialType])

  const resetFilters = (): void => {
    setOS('')
    setUserAssignmentObject(null)
    setIsActive(null)
    setIsAssigned(null)
    setOnlyShowFilter(false)
    setMaterialType(null)
  }

  return (
    <div className="data list-material">
      <h3>
        {onlyShowFilter ? (
          <div className="mobile-filter-header">
            <span className="accentuated-title">Filtres</span>
            <CloseIcon onClick={resetFilters} />
          </div>
        ) : (
          <>
            Liste du <span className="accentuated-title">matériel</span>
          </>
        )}
      </h3>
      <div className="data__header">
        {(!isSmallScreen || !onlyShowFilter) && (
          <div className="data__header__search">
            <SearchBox setSearchTerm={setSearchTerm} placeholder="marque, modèle, n°série/inv" />
            <PrimaryButton
              handleClick={(): void => {
                navigate('/ajout_materiel')
              }}
              title="Nouveau Matériel"
              icon={<AddIcon />}
              background
              reverse={false}
              disabled={false}
            />
          </div>
        )}
        {((isSmallScreen && onlyShowFilter) || !isSmallScreen) && (
          <div className="data__header__filters">
            <CustomSelect
              handleSelect={(value) => {
                setOS(value !== null ? (value as string) : '')
              }}
              options={OSList}
              id="os"
              value={os}
              inputLabel="Liste des OS"
              width={225}
            />
            <div className="autocomplete">
              <InputLabel id="userAssignmentId">Attribution</InputLabel>
              <AutoCompleteUserInput
                value={userAssignmentObject}
                onChange={(e, newValue): void => {
                  setUserAssignmentObject(newValue)
                }}
                id="userAssignmentId"
                options={users}
                setOptions={(inputValue) => {
                  void (async () => {
                    await retrieveUsers(inputValue)
                  })()
                }}
              />
            </div>
            <CustomSelect
              handleSelect={(value) => {
                setIsActive(value as string)
              }}
              options={YesOrNotList}
              id="isActive"
              value={isActive}
              inputLabel="Matériel Actif"
              width={190}
            />
            <CustomSelect
              handleSelect={(value) => {
                setIsAssigned(value as string)
              }}
              options={YesOrNotList}
              id="isAssigned"
              value={isAssigned}
              inputLabel="Matériel attribué"
              width={190}
            />
            <CustomSelect
              handleSelect={(value) => {
                setMaterialType(value as string)
              }}
              options={materialTypeList}
              id="materialType"
              value={materialType}
              inputLabel="Type de Matériel"
              width={190}
            />
          </div>
        )}
      </div>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <FilteredListItems
          nbPages={nbPages}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headCells={tableMaterialsColumns}
          tableBody={<ListMaterialsBodyTable materials={materials} />}
          nbItems={materials.length}
          setOnlyShowFilter={setOnlyShowFilter}
          onlyShowMobileFilters={isSmallScreen && onlyShowFilter}
          showHeaderAndPagination
        />
      )}
    </div>
  )
}

export default ListMaterials
