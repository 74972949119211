import { IGetMinimalMaterialData } from 'interfaces/materialData'
import { TableBody, TableCell, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import React, { ReactElement } from 'react'
import moment from 'moment'

interface IListMaterialsBodyTable {
  materials: IGetMinimalMaterialData[]
}

const ListMaterialsBodyTable = ({ materials }: IListMaterialsBodyTable): ReactElement => {
  const navigate = useNavigate()

  return (
    <TableBody>
      {materials.map((material: IGetMinimalMaterialData) => {
        return (
          <TableRow
            tabIndex={-1}
            key={material.serialNumber}
            hover
            onClick={() => {
              material?.id !== null && navigate(`/material/${Number(material.id)}`)
            }}
          >
            <TableCell className="sx-mini-delete">
              {material.brand !== '' ? material.brand : '-'}
            </TableCell>
            <TableCell>{material.model !== '' ? material.model : '-'}</TableCell>
            <TableCell className="md-delete">{material.os !== '' ? material.os : '-'}</TableCell>
            <TableCell className="sx-delete">
              {material.serialNumber !== '' ? material.serialNumber : '-'}
            </TableCell>
            <TableCell className="md-delete">
              {material.type !== '' ? material.type : '-'}
            </TableCell>
            <TableCell className="sx-delete">
              {material.purchaseDate !== null
                ? moment(material.purchaseDate).format('DD/MM/yyyy')
                : '-'}
            </TableCell>
            <TableCell>
              {material.userAssignment?.displayFirstname !== undefined &&
              material.userAssignment?.displayLastname !== undefined
                ? `${material.userAssignment?.displayFirstname} ${material.userAssignment?.displayLastname}`
                : '-'}
            </TableCell>
            <TableCell className="sx-mini-delete">
              {material.inventoryNumber !== null ? material.inventoryNumber : '-'}
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default ListMaterialsBodyTable
