import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import { IDetailMaterialData, IUpdateMaterialData } from 'interfaces/materialData'
import { useMaterialDataValidator } from 'customHooks/useMaterialDataValidator'
import { getMaterialById, updateMaterial } from 'services/MaterialService'
import ListItemsFooter from 'components/listItemsFooter/ListItemsFooter'
import MaterialInfoHeader from './materialInfoHeader/MaterialInfoHeader'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement, useEffect, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { getPlaces } from 'services/SelectorsValuesService'
import { IListSelectorValue } from 'interfaces/selectors'
import { useNavigate, useParams } from 'react-router-dom'
import PracticleInfos from './dataInfos/PracticleInfos'
import GeneralInfos from './dataInfos/GeneralInfos'
import { CircularProgress } from '@mui/material'
import OtherInfos from './dataInfos/OtherInfos'

const MaterialInfo = (): ReactElement => {
  const defaultMaterialValues: IUpdateMaterialData = {
    brand: '',
    model: '',
    serialNumber: '',
    type: '',
    inventoryNumber: 0,
    purchaseDate: new Date(),
    leavingDate: null,
    os: '',
    description: '',
    comment: '',
    descriptiveLink: '',
    userAssignment: null,
    isActive: true,
    place: 1
  }

  const { materialId } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [materialDataToSend, setMaterialDataToSend] =
    useState<IUpdateMaterialData>(defaultMaterialValues)
  const [openUpdateMaterialPopUp, setOpenUpdateMaterialPopUp] = useState(false)
  const [places, setPlaces] = useState<IListSelectorValue[]>([])
  const [material, setMaterial] = useState<IDetailMaterialData | null>(null)

  const { errors, validateData } = useMaterialDataValidator(materialDataToSend)

  useEffect(() => {
    void (async () => {
      setPlaces(await getPlaces())
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const material = await getMaterialById(Number(materialId))
      if (material !== null) {
        setMaterial(material)
        loadMaterialdata(material)
      }
      setLoading(false)
    })()
  }, [materialId, editMode])

  const openUpdatePopUp = (): void => {
    validateData() && setOpenUpdateMaterialPopUp(true)
  }

  const loadMaterialdata = (material: IDetailMaterialData): void => {
    setMaterialDataToSend({
      leavingDate: material.leavingDate !== undefined ? new Date(material.leavingDate ?? '') : null,
      description: material.description ?? '',
      comment: material.comment ?? '',
      descriptiveLink: material.descriptiveLink ?? '',
      place: material.place !== undefined ? material.place.id : 1,
      brand: material.brand ?? '',
      model: material.model ?? '',
      serialNumber: material.serialNumber ?? '',
      type: material.type ?? '',
      inventoryNumber: material.inventoryNumber ?? '',
      purchaseDate: material.purchaseDate ?? '',
      os: material.os ?? '',
      userAssignment: material.userAssignment ?? null,
      isActive: material.isActive ?? false
    })
  }

  const saveMaterial = async (): Promise<void> => {
    setLoading(true)
    const response = await updateMaterial(materialDataToSend, Number(materialId))
    setOpenUpdateMaterialPopUp(false)
    if (response !== null) setEditMode(false)
  }

  return (
    <div className="material">
      {loading ? (
        <div className="loader">
          <CircularProgress />{' '}
        </div>
      ) : (
        <>
          <PrimaryButton
            handleClick={(): void => {
              navigate(-1)
            }}
            title="Retour"
            icon={<ChevronLeftIcon />}
            background={false}
            reverse={false}
            disabled={false}
          />
          <MaterialInfoHeader
            editMode={editMode}
            brand={material?.brand}
            model={materialDataToSend.model}
            materialDataToSend={materialDataToSend}
            setMaterialDataToSend={setMaterialDataToSend}
            handleClick={(): void => {
              setEditMode(!editMode)
            }}
          />
          <GeneralInfos
            material={material}
            editMode={editMode}
            materialDataToSend={materialDataToSend}
            setMaterialDataToSend={setMaterialDataToSend}
            errors={errors}
          />
          <PracticleInfos
            material={material}
            editMode={editMode}
            materialDataToSend={materialDataToSend}
            setMaterialDataToSend={setMaterialDataToSend}
            places={places}
            errors={errors}
          />
          <OtherInfos
            editMode={editMode}
            materialDataToSend={materialDataToSend}
            setMaterialDataToSend={setMaterialDataToSend}
            errors={errors}
          />
          {editMode && (
            <ListItemsFooter
              handleCancel={(): void => {
                setEditMode(false)
              }}
              handleSave={(): void => {
                openUpdatePopUp()
              }}
            />
          )}
        </>
      )}
      <ConfirmationPopUp
        dialogTitle="Voulez-vous sauvegarder vos changements ?"
        dialogContentText="Les données du matériel seront mises à jour !"
        open={openUpdateMaterialPopUp}
        setOpen={setOpenUpdateMaterialPopUp}
        handleSave={saveMaterial}
        loading={loading}
      />
    </div>
  )
}

export default MaterialInfo
