import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IUsersHolidayQuota } from 'interfaces/holidays/userHolidayQuota'
import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import { getSelfUserData, getUserById } from 'services/UserService'
import { CurrentUserContext } from '../../../App'
import { CircularProgress } from '@mui/material'

interface IMyBalance {
  userId: number
  year: number
  reloadData: boolean
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>
}

const MyBalance = ({ userId, year, reloadData, setReloadData }: IMyBalance): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  const user = userContext?.currentUser

  const [myUserInfos, setMyUserInfos] = useState<IDetailCollaborateurData | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [userHolidaysQuota, setUserHolidaysQuota] = useState<IUsersHolidayQuota | null>(null)

  useEffect(() => {
    void (async () => {
      if (userId !== undefined && reloadData) {
        await handleFetchUserHolidaysQuota()
        setReloadData(false)
      }
    })()
  }, [reloadData])

  useEffect(() => {
    void (async () => {
      await handleFetchUserHolidaysQuota()
    })()
  }, [userId, year])

  const handleFetchUserHolidaysQuota = async (): Promise<void> => {
    setLoading(true)
    let myUserInfos

    if (user?.id === userId) {
      myUserInfos = await getSelfUserData(year)
    } else {
      myUserInfos = await getUserById(userId, year)
    }

    setMyUserInfos(myUserInfos)

    const userHolidaysQuota = myUserInfos?.holidaysQuota?.find((holiday) => holiday.year === year)
    setUserHolidaysQuota(userHolidaysQuota ?? null)
    setLoading(false)
  }

  return (
    <div className="box-container-myholidays">
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="my-holiday-header">
            <h3 className="box-title-myholidays">Mon solde</h3>
            <div className="progress-number">
              {myUserInfos?.remainingQuotas?.remainingQuota ?? 0} jour(s) restant(s)
            </div>
          </div>
          <div className="box-content-myholidays myBalance-data">
            <div className="box-subcontent-myholidays">
              <p className="box-subtitle-myholidays">Année</p>
              <p>{year}</p>
            </div>
            <div className="box-subcontent-myholidays">
              <p className="box-subtitle-myholidays">Congés payés</p>
              <p>{userHolidaysQuota?.paidVacationQuota ?? 0} jours</p>
            </div>
            <div className="box-subcontent-myholidays">
              <p className="box-subtitle-myholidays">RTT</p>
              <p>{userHolidaysQuota?.RTTQuota ?? 0} jours</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MyBalance
