import { IMyNotif } from 'interfaces/notifications'
import { customAxios } from './axiosUtils'

/**
    Récupérer tous les settings de notifs associés au user
**/
export const getMyNotifs = async (userId: number): Promise<IMyNotif[]> => {
  try {
    return await customAxios.get(`/users/${userId}/notification-settings`)
  } catch (error) {
    return []
  }
}

/**
    Maj un setting de notification du collaborateur 
**/
export const updateUserNotificationSettingFor = async (
  userId: number,
  idNotif: number,
  statusEnabled: boolean
): Promise<IMyNotif | null> => {
  try {
    const statusParam = `enabled=${statusEnabled ? 1 : 0}`
    return await customAxios.put(
      `/users/${userId}/notification-settings/${idNotif}/status?${statusParam}`
    )
  } catch (error) {
    return null
  }
}
