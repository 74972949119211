import CustomNumberTextField from 'components/inputs/customNumberTextField/CustomNumberTextField'
import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { useRetrieveUsers } from 'customHooks/retrieveMatchingUsers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { IUpdateMaterialData } from 'interfaces/materialData'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { IErrors } from 'interfaces/formFields'
import { useNavigate } from 'react-router-dom'
import React, { ReactElement } from 'react'
import { InputLabel } from '@mui/material'

interface IOtherInfos {
  editMode: boolean
  materialDataToSend: IUpdateMaterialData
  setMaterialDataToSend: React.Dispatch<React.SetStateAction<IUpdateMaterialData>>
  errors: IErrors
}

const OtherInfos = ({
  editMode,
  materialDataToSend,
  setMaterialDataToSend,
  errors
}: IOtherInfos): ReactElement => {
  const [users, retrieveUsers] = useRetrieveUsers()
  const navigate = useNavigate()

  return (
    <div className="box-data">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <p className="category-title">Informations sur le poste</p>
        <div className="box-inline-elts">
          <div className="box-column">
            <div>
              {editMode ? (
                <CustomNumberTextField
                  onChange={(value) => {
                    setMaterialDataToSend({ ...materialDataToSend, inventoryNumber: Number(value) })
                  }}
                  id="inventoryNumber"
                  value={materialDataToSend.inventoryNumber.toString()}
                  errorMessage={errors.inventoryNumber?.message}
                  required
                  label="N° d'inventaire"
                />
              ) : (
                <>
                  <p className="data-title">Numéro d&apos;inventaire</p>
                  <p className="data-value">{materialDataToSend?.inventoryNumber}</p>
                </>
              )}
            </div>
          </div>
          <div className="box-column">
            <div>
              {editMode ? (
                <div className="autocomplete">
                  <InputLabel id="userAssignmentId">Attribution</InputLabel>
                  <AutoCompleteUserInput
                    value={materialDataToSend.userAssignment}
                    onChange={(e: any, newValue: IMinimalCollaborateurData | null): void => {
                      setMaterialDataToSend({
                        ...materialDataToSend,
                        userAssignment: newValue
                      })
                    }}
                    id="userAssignmentId"
                    options={users}
                    setOptions={(inputValue: string) => {
                      void (async () => {
                        await retrieveUsers(inputValue)
                      })()
                    }}
                  />
                </div>
              ) : (
                <>
                  <p className="data-title">Attribution</p>
                  <div className="attribution-box">
                    <p
                      className="data-value link"
                      onClick={() => {
                        materialDataToSend.userAssignment?.id !== undefined &&
                          navigate(`/profil/${materialDataToSend.userAssignment?.id}`)
                      }}
                    >
                      {materialDataToSend.userAssignment?.displayFirstname !== undefined &&
                        materialDataToSend.userAssignment?.displayLastname !== undefined &&
                        `${materialDataToSend.userAssignment?.displayFirstname} ${materialDataToSend.userAssignment?.displayLastname}`}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </div>
  )
}

export default OtherInfos
