import { tableMyDocumentsColumns, tableMyDocumentsPaySlipColumns } from './data/MyDocumentsColumns'
import { downloadDocumentsFile, uploadFileToDrive } from '../../services/MyDocumentsService'
import DocumentUploader from '../../components/inputs/uploader/DocumentUploader'
import FilteredListItems from 'components/filteredListItems/FilteredListItems'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement, useContext, useState } from 'react'
import { PlaceSelectedContext, YearSelectedContext } from 'App'
import useScreenSize from '../../customHooks/useScreenSize'
import { IMyDocumentsDrive } from 'interfaces/myDocuments'
import MyDocumentsBodyTab from './MyDocumentsBodyTable'
import { PdfFile } from '../../interfaces/pfdFile'
import { CircularProgress } from '@mui/material'
import { screenSizes } from '../../constantes'
import fileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import { Order } from 'types/order'
import moment from 'moment'

interface IMyDocumentsTab {
  currentTab: number
  documentDrive: IMyDocumentsDrive[]
  selectedType: string
  nbPages: number
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  orderBy: string
  setOrderBy: React.Dispatch<React.SetStateAction<string>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  needReload: boolean
  setNeedReload: React.Dispatch<React.SetStateAction<boolean>>
  showHeaderAndPagination: boolean
  userId: number | undefined
  canEdit: boolean
}

const MyDocumentsTab = ({
  documentDrive,
  selectedType,
  nbPages,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  currentTab,
  showHeaderAndPagination,
  userId,
  canEdit
}: IMyDocumentsTab): ReactElement => {
  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)
  const [selectedPdf, setSelectedPdf] = useState<PdfFile | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const year = useContext(YearSelectedContext)
  const place = useContext(PlaceSelectedContext)

  const importFile = async (base64File: PdfFile | null): Promise<void> => {
    setLoading(true)
    if (base64File) {
      setSelectedPdf(base64File)
    }

    if (userId && base64File) {
      await uploadFileToDrive(userId, base64File)
      toast.success('Le document a bien été enregistré.')
    }

    setLoading(false)
    setSelectedPdf(null)
  }

  // Téléchargement des documents.
  const handleDownloadAll = async (): Promise<void> => {
    if (!documentDrive || documentDrive.length === 0) {
      return
    }

    setLoading(true)
    await Promise.all(
      documentDrive.map(async (document: IMyDocumentsDrive) => {
        const response = await downloadDocumentsFile(
          selectedType,
          document.id,
          year?.yearSelected ?? moment().year(),
          place?.placeSelected ?? 1
        )
        if (response !== null) {
          fileDownload(response.content, response.filename)
        }
      })
    )
    setLoading(false)
  }

  const handleAllClick = (): void => {
    void handleDownloadAll()
  }

  return (
    <React.Fragment>
      <div>
        {canEdit && (
          <div className="mb-2">
            <DocumentUploader
              accept={{
                'application/pdf': [],
                'image/jpeg': [],
                'image/png': []
              }}
              selectedFile={selectedPdf}
              uploadFile={(pdfBase64) => {
                void (async () => {
                  await importFile(pdfBase64)
                })()
              }}
              minimalDesign={false}
              edit={false}
              loading={loading}
              title="Importer votre document"
            />
          </div>
        )}
        {documentDrive?.length > 0 && (
          <div className="flex justify-end mb-3 mr-12">
            {!loading ? (
              <PrimaryButton
                handleClick={handleAllClick}
                title="Tout télécharger"
                background
                reverse={false}
                disabled={false}
                className="cta"
              />
            ) : (
              <div className="px-5 text-center">
                <span className="mr-5">Téléchargement de tous les documents en cours...</span>
                <CircularProgress size={25} color="secondary" />
              </div>
            )}
          </div>
        )}

        <div>
          <FilteredListItems
            nbPages={nbPages}
            page={page}
            setPage={setPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={
              currentTab === 0 && !isSmallScreen
                ? tableMyDocumentsPaySlipColumns()
                : tableMyDocumentsColumns()
            }
            tableBody={
              <MyDocumentsBodyTab
                currentTab={currentTab}
                documents={documentDrive}
                selectedType={selectedType}
                year={year}
                place={place}
              />
            }
            nbItems={documentDrive.length}
            showHeaderAndPagination={showHeaderAndPagination}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MyDocumentsTab
