import CustomDatePicker from 'components/inputs/customDatePicker/CustomDatePicker'
import { getLocalStorageWithExpiration, hasRHRole } from 'utils/otherUtils'
import DocumentUploader from 'components/inputs/uploader/DocumentUploader'
import { saveJustificationFile } from 'services/JustificationPdfService'
import useValidityHolidays from 'customHooks/useValidityHolidayControl'
import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import { IHolidayRequest } from 'interfaces/holidays/holidayRequest'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement, useContext, useState } from 'react'
import { IHolidayQuota } from 'interfaces/holidays/holidayQuota'
import { getDatesBetween, setValidDate } from 'utils/dateUtils'
import { createHolidayRequest } from 'services/HolidayService'
import HolidaysManager from 'components/HolidaysManager'
import { holidayTypesEnum } from 'constantes'
import { PdfFile } from 'interfaces/pfdFile'
import { CurrentUserContext } from 'App'
import { Dayjs } from 'dayjs'
import moment from 'moment'

interface IAskForm {
  handleClose: () => void
  showOneDatePicker: boolean
  duration: string
  userId?: number
  year: number
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>
  holidayDates: string[]
}

const AskForm = ({
  handleClose,
  showOneDatePicker,
  duration,
  userId,
  year,
  setReloadData,
  holidayDates
}: IAskForm): ReactElement => {
  const userSelf = getLocalStorageWithExpiration('user')
  const isAdmin = userSelf !== null ? hasRHRole(JSON.parse(userSelf).rolePermissions) : false

  const [daysInterval, setDaysInterval] = useState<number>(0)
  const [holidayQuota, setHolidayQuota] = useState<number | null>(null)
  const userContext = useContext(CurrentUserContext)
  const isValidHolidays = useValidityHolidays(daysInterval, holidayQuota)

  const [holidayRequest, setHolidayRequest] = useState<IHolidayRequest>({
    firstDay: new Date(),
    lastDay: new Date(),
    year,
    holidayType: { id: 16, holidayType: holidayTypesEnum.CONGE_PAYE },
    duration,
    user: userContext as unknown as IDetailCollaborateurData
  })

  const [selectedPdf, setSelectedPdf] = useState<PdfFile | null>(null)

  const handleChangeDay = (newDate: Dayjs | null, isLastDay: boolean): void => {
    if (newDate !== null) {
      const validDate = setValidDate(newDate)

      // permet d'automatiquement mettre à jour la date de fin pour ne pas être inférieur à celle de départ
      if (!isLastDay && holidayRequest.lastDay < validDate) {
        setHolidayRequest({
          ...holidayRequest,
          firstDay: validDate,
          lastDay: validDate
        })
      } else {
        setHolidayRequest({
          ...holidayRequest,
          [isLastDay ? 'lastDay' : 'firstDay']: validDate
        })
      }

      if (!showOneDatePicker) {
        const startDate = isLastDay ? holidayRequest.firstDay : newDate.toDate()
        const lastDate = !isLastDay ? holidayRequest.lastDay : newDate.toDate()
        const dates = getDatesBetween(startDate, lastDate, holidayDates)

        setDaysInterval(dates.length)
      }
    }
  }

  const updateHolidaySelected = (holidaySelected: IHolidayQuota): void => {
    setHolidayRequest({ ...holidayRequest, holidayType: holidaySelected })
  }

  const holidayRequestCreated = async (): Promise<void> => {
    if (userId !== undefined) {
      if (showOneDatePicker) {
        holidayRequest.lastDay = holidayRequest.firstDay
      }
      holidayRequest.duration = duration
      holidayRequest.year = year
      const response = await createHolidayRequest(holidayRequest, userId)

      if (response?.newHolidayRequest.id !== undefined) {
        // Enregistre le justificatif sur le serveur s'il y en a un
        if (selectedPdf !== null) {
          await saveJustificationFile(response.newHolidayRequest.id, selectedPdf)
        }
        handleClose()
      }

      // permet de refresh les autres composants comme mon solde et mes demandes
      if (response !== null) {
        setReloadData(true)
      }
    }
  }

  return (
    <div className="ask-form">
      <div>
        <div className="align-datePickers">
          <CustomDatePicker
            handleChangeDate={(e) => {
              handleChangeDay(e, false)
            }}
            id="firstDay"
            value={holidayRequest.firstDay}
            errorMessage=""
            required={false}
            label={showOneDatePicker ? 'Demi-journée' : 'Du'}
            minDate={isAdmin ? moment(`${year}-01-01`).toDate() : new Date()}
            maxDate={moment(`${year}-12-31`).toDate()}
            disableWeeksAndHolidays
            holidayDates={holidayDates}
          />
          {!showOneDatePicker && (
            <CustomDatePicker
              handleChangeDate={(e) => {
                handleChangeDay(e, true)
              }}
              id="lastDay"
              value={holidayRequest.lastDay}
              errorMessage=""
              required={false}
              label="Au"
              minDate={holidayRequest.firstDay}
              maxDate={moment(`${year}-12-31`).toDate()}
              disableWeeksAndHolidays
              holidayDates={holidayDates}
            />
          )}
        </div>
        {!isValidHolidays && (
          <div className="holiday-error-box text-error">
            <p>La période est plus longue que la durée autorisée.</p>
            <p>Veuillez réduire la période ou contacter un administrateur.</p>
          </div>
        )}
      </div>

      <HolidaysManager
        setHolidayQuota={setHolidayQuota}
        showPaidVacation
        updateHolidaySelected={updateHolidaySelected}
      />

      {holidayRequest.holidayType.holidayType === holidayTypesEnum.CONGE_PAYE ? (
        <></>
      ) : (
        <DocumentUploader
          selectedFile={selectedPdf}
          uploadFile={(pdfBase64) => {
            setSelectedPdf(pdfBase64)
          }}
          minimalDesign={false}
          edit={false}
        />
      )}

      <div className="align-btn">
        <PrimaryButton
          handleClick={handleClose}
          title="Annuler"
          background
          reverse
          disabled={false}
        />
        <PrimaryButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handleClick={holidayRequestCreated}
          title="Valider"
          background
          reverse={false}
          disabled={!isValidHolidays}
        />
      </div>
    </div>
  )
}

export default AskForm
