import {
  IDetailHolidayQuotaRequest,
  IDetailHolidayRequest,
  ITotalHolidayQuotaRequest
} from 'interfaces/holidays/holidayRequest'
import FilteredListItems from 'components/filteredListItems/FilteredListItems'
import { tableSynthesisColumns } from './data/tableSynthesisColumns'
import HolidaysSynthesisBodyTab from './HolidaysSynthesisBodyTable'
import React, { ReactElement } from 'react'
import { Order } from 'types/order'

interface IHolidaysRequestsSynthesisTab {
  currentTab: number
  requests: IDetailHolidayQuotaRequest[]
  totalRequests: ITotalHolidayQuotaRequest | undefined
  nbPages: number
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  orderBy: string
  setOrderBy: React.Dispatch<React.SetStateAction<string>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  needReload: boolean
  setNeedReload: React.Dispatch<React.SetStateAction<boolean>>
  showHeaderAndPagination: boolean
  handleRequestUpdate: (response: IDetailHolidayRequest) => void
  handleRequestApprobationUpdate: (response: IDetailHolidayRequest) => void
  pageName: string
}
const HolidaysRequestsSynthesisTab = ({
  requests,
  totalRequests,
  nbPages,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  currentTab,
  showHeaderAndPagination,
  handleRequestUpdate,
  handleRequestApprobationUpdate,
  pageName
}: IHolidaysRequestsSynthesisTab): ReactElement => {
  return (
    <React.Fragment>
      <div>
        {
          <div>
            <FilteredListItems
              nbPages={nbPages}
              page={page}
              setPage={setPage}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              headCells={tableSynthesisColumns({ currentTab, page: pageName })}
              tableBody={
                <HolidaysSynthesisBodyTab
                  currentTab={currentTab}
                  requests={requests}
                  totalRequests={totalRequests}
                  handleRequestUpdate={handleRequestUpdate}
                  handleRequestApprobationUpdate={handleRequestApprobationUpdate}
                />
              }
              nbItems={requests.length}
              showHeaderAndPagination={showHeaderAndPagination}
            />
          </div>
        }
      </div>
    </React.Fragment>
  )
}

export default HolidaysRequestsSynthesisTab
