import { addProject, getProjects, updateProject } from 'services/ProjectsService'
import FilteredListItems from 'components/filteredListItems/FilteredListItems'
import { addClient, getClients, updateClient } from 'services/ClientsService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { saveClientPicture } from 'services/ClientPictureService'
import SearchBox from 'components/inputs/searchBox/SearchBox'
import CircularProgress from '@mui/material/CircularProgress'
import { IClient, IUpdateClient } from 'interfaces/clients'
import ListClientsBodyTable from './ListClientsBodyTable'
import { IProject } from 'interfaces/projects'
import { PlaceSelectedContext } from 'App'
import ProjectPopUp from './ProjectPopUp'
import ClientPopUp from './ClientPopUp'
import { Order } from 'types/order'
import './ListClients.scss'

const ListClients = (): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const [page, setPage] = useState(1)
  const [clients, setClients] = useState<IClient[]>([])
  const [nbPages, setNbPages] = useState<number>(1)
  const [order, setOrder] = useState<Order>('desc')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [status] = useState<string>('')
  const [selectedServices] = useState<string[]>([])
  const [orderBy, setOrderBy] = useState<string>('updatedAt')
  const [clientToEdit, setClientToEdit] = useState<IClient | undefined>()
  const [projectToEdit, setProjectToEdit] = useState<IProject | undefined>()
  const [openClient, setOpenClient] = useState<boolean>(false)
  const [openProject, setOpenProject] = useState<boolean>(false)
  const [showFinishedProjects, setShowFinishedProjects] = useState<boolean>(false)
  const [selectedImg, setSelectedImg] = useState<string | null>(null)
  const [croppedImg, setCroppedImg] = useState<string | null>(null)

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const response = await getClients(searchTerm, place ?? 1)
      setNbPages(1)
      setClients(response)
      setLoading(false)
    })()
  }, [place, page, orderBy, order, searchTerm, status, selectedServices])

  const handleProjectSave = async (project: IProject): Promise<void> => {
    setLoading(true)
    const clientId = project.client?.id
    let updatedProjects: IProject[]

    if (projectToEdit?.id !== undefined) {
      await updateProject(project)
      updatedProjects = await getProjects(clientId, place ?? 1)
    } else {
      await addProject(project)
      updatedProjects = await getProjects(clientId, place ?? 1)
    }

    setClients((prevClients) => {
      return prevClients.map((client) => {
        if (client.id === clientId) {
          return { ...client, projects: updatedProjects }
        }
        return client
      })
    })

    setOpenProject(false)
    setLoading(false)
  }

  const handleClientSave = async (client: IUpdateClient): Promise<void> => {
    setLoading(true)
    if (clientToEdit?.id !== undefined) {
      await updateClient(client)
      const updatedClients = await getClients(searchTerm, place ?? 1)
      setClients(updatedClients)
      if (selectedImg !== null && client !== null) {
        const updatedClients = await saveClientPicture(Number(client.id), selectedImg)
        setClients(updatedClients)
      }
    } else {
      await addClient(client)
      const updatedClients = await getClients(searchTerm, place ?? 1)
      setClients(updatedClients)
    }
    setClientToEdit(undefined)
    setOpenClient(false)
    setSelectedImg(null)
    setLoading(false)
  }

  const handleAddClientClick = (): void => {
    setClientToEdit(undefined)
    setOpenClient(true)
  }

  return (
    <div className="client">
      <div>
        <div className="md:flex md:justify-between md:px-2 pb-5">
          <div className="md:flex md:space-x-5 pl-5 md:pl-0 space-y-3">
            <div>
              <SearchBox
                setSearchTerm={setSearchTerm}
                placeholder="Rechercher un client ou un projet"
              />
            </div>
            <div className="flex space-x-1 pb-2">
              <input
                className="checkbox"
                type="checkbox"
                onChange={(event) => {
                  setShowFinishedProjects(event.target.checked)
                }}
                id="checkbox"
                checked={showFinishedProjects}
              />
              <div className="font-bold">Projets terminés</div>
            </div>
          </div>
          <div className="pt-8 md:pt-0 flex text-center justify-center">
            <PrimaryButton
              handleClick={handleAddClientClick}
              title="Ajouter un client"
              background
              reverse={false}
              disabled={false}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="clients-list mx-2">
          <FilteredListItems
            nbPages={nbPages}
            page={page}
            setPage={setPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={[]}
            tableBody={
              <ListClientsBodyTable
                clients={clients}
                setClients={setClients}
                setOpenClient={setOpenClient}
                setOpenProject={setOpenProject}
                setClientToEdit={setClientToEdit}
                setProjectToEdit={setProjectToEdit}
                showFinishedProjects={showFinishedProjects}
              />
            }
            nbItems={clients.length}
            onlyShowMobileFilters={undefined}
          />
        </div>
      )}
      <ClientPopUp
        open={openClient}
        setOpen={setOpenClient}
        clientToEdit={clientToEdit}
        selectedImg={selectedImg}
        croppedImg={croppedImg}
        setSelectedImg={setSelectedImg}
        setCroppedImg={setCroppedImg}
        handleClientSave={handleClientSave}
      />
      <ProjectPopUp
        open={openProject}
        setOpen={setOpenProject}
        clientToEdit={clientToEdit}
        projectToEdit={projectToEdit}
        setProjectToEdit={setProjectToEdit}
        handleSave={handleProjectSave}
      />
    </div>
  )
}

export default ListClients
