import { IPlaceSelectedContext, IYearSelectedContext } from 'interfaces/context'
import { CircularProgress, TableBody, TableCell, TableRow } from '@mui/material'
import { downloadDocumentsFile } from 'services/MyDocumentsService'
import useScreenSize from '../../customHooks/useScreenSize'
import { IMyDocumentsDrive } from 'interfaces/myDocuments'
import { Download, OpenInNew } from '@mui/icons-material'
import React, { ReactElement, useState } from 'react'
import { getMonthName } from '../../utils/dateUtils'
import { screenSizes } from '../../constantes'
import fileDownload from 'js-file-download'
import './MyDocumentsBodyTable.scss'
import moment from 'moment'
import 'animate.css'

interface IMyDocumentsTab {
  currentTab: number
  documents: IMyDocumentsDrive[]
  selectedType: string
  year: IYearSelectedContext | null
  place: IPlaceSelectedContext | null
}

const MyDocumentsBodyTab = ({
  currentTab,
  documents,
  selectedType,
  year,
  place
}: IMyDocumentsTab): ReactElement => {
  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)
  const [loading, setLoading] = useState<number | null>()

  // Téléchargement du document.
  const handleDownload = async (document: IMyDocumentsDrive, index: number): Promise<void> => {
    setLoading(index)
    const response = await downloadDocumentsFile(
      selectedType,
      document.id,
      year?.yearSelected ?? moment().year(),
      place?.placeSelected ?? 1
    )
    if (response !== null) {
      fileDownload(response.content, response.filename)
    }
    setLoading(null)
  }

  // Affichage du document dans un nouvel onglet.
  const handlePreview = async (document: IMyDocumentsDrive, index: number): Promise<void> => {
    setLoading(index)
    const response = await downloadDocumentsFile(
      selectedType,
      document.id,
      year?.yearSelected ?? moment().year(),
      place?.placeSelected ?? 1
    )

    if (response !== null) {
      const blob = new Blob([response.content], { type: getContentType(response.filename) })
      const blobUrl = URL.createObjectURL(blob)

      const newTab = window.open()
      if (newTab !== null) {
        newTab.location.href = blobUrl
      }
    }
    setLoading(null)
  }

  const getContentType = (filename: string): string => {
    const extension = filename.split('.').pop()
    switch (extension) {
      case 'pdf':
        return 'application/pdf'
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      default:
        return 'application/octet-stream'
    }
  }

  return (
    <>
      <TableBody className="documents-body">
        {documents.map((document, index) => {
          return (
            <TableRow key={document.name}>
              <TableCell>{document.name}</TableCell>
              {currentTab === 0 && !isSmallScreen && (
                <TableCell className="capitalize">
                  {getMonthName(document.name.match(/\d{4}_(\d{2})_/)?.[1])}
                </TableCell>
              )}
              <TableCell>
                {loading === index ? (
                  <div className="px-5 w-10 h-[35px]">
                    <CircularProgress size={25} color="secondary" />
                  </div>
                ) : (
                  <div className="documents-requests">
                    <Download
                      onClick={(e) => {
                        e.stopPropagation()
                        void (async () => {
                          await handleDownload(document, index)
                        })()
                      }}
                      id="download-file-icon"
                      fontSize="large"
                    />
                    <OpenInNew
                      onClick={(e) => {
                        e.stopPropagation()
                        void (async () => {
                          await handlePreview(document, index)
                        })()
                      }}
                      id="preview-file-icon"
                      fontSize="large"
                    />
                  </div>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}

export default MyDocumentsBodyTab
