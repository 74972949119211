import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CustomTextField from '../customTextFIeld/CustomTextField'
import PrimaryButton from '../button/PrimaryButton'
import React, { ReactElement } from 'react'
import './HolidayRequestConfPopUp.scss'
import { statusEnum } from 'constantes'

interface IHolidayRequestConfPopUp {
  action: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleAction: () => Promise<void>
  isTT: boolean
  refusalText: string | undefined
  setRefusalText: React.Dispatch<React.SetStateAction<string | undefined>>
}

const HolidayRequestConfPopUp = ({
  action,
  open,
  setOpen,
  handleAction,
  isTT,
  refusalText,
  setRefusalText
}: IHolidayRequestConfPopUp): ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={(): void => {
        setOpen(false)
      }}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="popUp"
    >
      <DialogTitle id="save-dialog-title">
        {action === statusEnum.ACCEPTED
          ? `Voulez-vous valider cette demande de ${isTT ? 'télétravail' : 'congé'} ?`
          : `Voulez-vous refuser cette demande de ${isTT ? 'télétravail' : 'congé'} ?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {action === statusEnum.ACCEPTED
            ? `La demande de ${
                isTT ? 'télétravail' : 'congé'
              } sera acceptée et un mail d'information sera envoyé au collaborateur concerné.`
            : `La demande de ${
                isTT ? 'télétravail' : 'congé'
              } sera refusée et un mail d'information sera envoyé au collaborateur concerné.`}
        </DialogContentText>
        <div className="refusal-holiday mt-5">
          {action === statusEnum.REFUSED ? (
            <>
              <CustomTextField
                onChange={(value) => {
                  setRefusalText(value)
                }}
                id="description"
                value={refusalText ?? ''}
                errorMessage=""
                required={false}
                label="Commentaire"
                multiline
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          handleClick={() => {
            setOpen(false)
          }}
          title="Annuler"
          background
          reverse
          disabled={false}
        />
        <PrimaryButton
          handleClick={() => {
            void (async () => {
              await handleAction()
            })()
          }}
          title="Confirmer"
          background
          reverse={false}
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  )
}

export default HolidayRequestConfPopUp
