import TabNavigation from 'components/tabNavigation/TabNavigation'
import OnBoardingForm from '../onboardingForm/onBoardingForm'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IColorSettings } from 'interfaces/colorSettings'
import { InputLabel, TextField } from '@mui/material'
import React, { ReactElement, useState } from 'react'
import { settingCategoryConstant } from 'constantes'
import ThemeForm from '../themeForm/themeForm'
import { ISetting } from 'interfaces/setting'
import { toast } from 'react-toastify'

interface IServiceTokenForm {
  settings: ISetting[]
  setSettings: React.Dispatch<React.SetStateAction<ISetting[]>>
  initalColors: IColorSettings | null
}

const SettingsForm = ({ settings, setSettings, initalColors }: IServiceTokenForm): ReactElement => {
  const [currentTab, setCurrentTab] = useState(0)
  const handleTab = (e: any, tab: number): void => {
    setCurrentTab(tab)
  }

  const handleChange = (e: any, setting: ISetting): void => {
    const updatedSettings = settings.map((mapSetting) => {
      if (setting.key === mapSetting.key) {
        mapSetting.value = e.target.value
      }
      return mapSetting
    })
    setSettings(updatedSettings)
  }

  // Récupérer toutes les valeurs distinctes de la propriété "category" des settings
  const categoryList = [...new Set(settings.map((setting) => setting.category).sort())]

  const handleCopy = (fieldContent: string | null): void => {
    void navigator.clipboard.writeText(fieldContent !== null ? fieldContent : '')
    toast.success('Champ copié !')
  }

  return (
    <div>
      <TabNavigation
        currentTab={currentTab}
        handleTab={handleTab}
        navItems={categoryList.map((item) => ({ label: item }))}
      ></TabNavigation>
      <div className="form">
        <div>
          {categoryList[currentTab] === settingCategoryConstant.THEME ? (
            <ThemeForm settings={settings} setSettings={setSettings} initalColors={initalColors} />
          ) : categoryList[currentTab] === settingCategoryConstant.ON_BOARDING ? (
            <OnBoardingForm
              settings={settings}
              setSettings={setSettings}
              initalColors={initalColors}
            />
          ) : (
            settings
              .filter((setting) => setting.category === categoryList[currentTab])
              .map((setting) => (
                <div className="setting-input" key={setting.key}>
                  {setting.category !== settingCategoryConstant.THEME && (
                    <div>
                      <InputLabel className={'data-title'} id={setting.key}>
                        {setting.label}
                      </InputLabel>
                      <div className="setting-field-copy">
                        <TextField
                          id={setting.key}
                          onChange={(e) => {
                            handleChange(e, setting)
                          }}
                          value={setting.value}
                          type="text"
                        />
                        <ContentCopyIcon
                          id="copy-icon"
                          onClick={() => {
                            handleCopy(setting.value)
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  )
}

export default SettingsForm
