import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { IconButton, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Dialog } from '@material-ui/core'
import React from 'react'

interface IProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleSave: () => void
  selectedAmount: number
  setSelectedAmount: React.Dispatch<React.SetStateAction<number>>
}

const ReportingEditModal: React.FC<IProps> = ({
  open,
  setOpen,
  handleSave,
  selectedAmount,
  setSelectedAmount
}) => {
  const handlePopoverClose = (): void => {
    setOpen(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedAmount(Number(event.target.value))
  }

  const handleSaveClick = (): void => {
    setOpen(false)
    handleSave()
  }

  return (
    <Dialog
      open={open}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      className="backdrop-blur-sm"
    >
      <IconButton onClick={handlePopoverClose} id="closeIcon">
        <Close />
      </IconButton>
      <div className="popover">
        <h3>
          Modifier votre<span className="accentuated-title"> activité</span>
        </h3>
        <div className="my-6 flex justify-center">
          <TextField
            type="number"
            value={selectedAmount}
            onChange={handleChange}
            InputProps={{
              inputProps: { step: 0.25, min: 0, max: 2 }
            }}
          />
        </div>
        <div>
          <PrimaryButton
            handleClick={handleSaveClick}
            title="Confirmer"
            background
            reverse={false}
            disabled={false}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ReportingEditModal
