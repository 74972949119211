import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import { contractTypeConstant, roleConstant } from 'constantes'
import { IListSelectorValue } from 'interfaces/selectors'
import { ILocalStorage } from 'interfaces/localStorage'

// Vérifie qu'un utilisateur à un compte admin ou non
const hasSuperAdminRole = (rolePermissions: IListSelectorValue[]): boolean => {
  return rolePermissions.some((rolePermission) => rolePermission.name === roleConstant.ROLE_ADMIN)
}

const hasAccountantRole = (rolePermissions: IListSelectorValue[]): boolean => {
  return rolePermissions.some(
    (rolePermission) =>
      rolePermission.name === roleConstant.ROLE_ADMIN_COMPTABLE ||
      rolePermission.name === roleConstant.ROLE_ADMIN
  )
}

const hasRHRole = (rolePermissions: IListSelectorValue[]): boolean => {
  return rolePermissions.some(
    (rolePermission) =>
      rolePermission.name === roleConstant.ROLE_ADMIN_RH ||
      rolePermission.name === roleConstant.ROLE_ADMIN
  )
}

const hasManagerRole = (rolePermissions: IListSelectorValue[]): boolean => {
  return rolePermissions.some(
    (rolePermission) =>
      rolePermission.name === roleConstant.ROLE_MANAGER ||
      rolePermission.name === roleConstant.ROLE_ADMIN
  )
}

const hasPMRole = (rolePermissions: IListSelectorValue[]): boolean => {
  return rolePermissions.some(
    (rolePermission) =>
      rolePermission.name === roleConstant.ROLE_PROJECT_MANAGER ||
      rolePermission.name === roleConstant.ROLE_ADMIN
  )
}

const hasRole = (rolePermissions: IListSelectorValue[], roles: string[]): boolean => {
  return rolePermissions.some(
    (rolePermission) => rolePermission.name !== undefined && roles.includes(rolePermission.name)
  )
}

const hasOnlyUserRole = (rolePermissions: IListSelectorValue[]): boolean => {
  return rolePermissions.length === 1 && rolePermissions[0].name === roleConstant.ROLE_USER
}

// Vérifie qu'un utilisateur peut participer aux steamdej ou non
const isAllowedForSteamDej = (user: IDetailCollaborateurData): boolean => {
  return (
    user.googleAccount?.isActive &&
    user.contractType?.name !== undefined &&
    [
      contractTypeConstant.ALTERNANT,
      contractTypeConstant.STAGIAIRE,
      contractTypeConstant.CDI,
      contractTypeConstant.CDD
    ].includes(user.contractType?.name)
  )
}

/**
 * Lit un fichier en tant que base64.
 *
 * @param {File} file - Le fichier à lire.
 * @return {Promise<string>} La promesse résolue avec le contenu du fichier en base64.
 */
const readFileAsBase64 = async (file: File): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
  })
}

/** Met à jour la couleur primaire de l'application */
const changeThemeColor = (colorVar: string, color: string): void => {
  document.documentElement.style.setProperty(colorVar, color)
}

const removeAccents = (inputString: string): string => {
  const replacements: Record<string, string> = {
    á: 'a',
    à: 'a',
    â: 'a',
    ä: 'a',
    é: 'e',
    è: 'e',
    ê: 'e',
    ë: 'e',
    î: 'i',
    ï: 'i'
    // Ici Ajoutez toutes les correspondances si nécessaires
  }

  // Pattern regex pour rechercher les lettres avec accents
  const pattern = /[\u00C0-\u017F]/g // Cette plage unicode couvre toutes les lettres avec accents

  // Fonction de remplacement pour les correspondances trouvées
  return inputString.replace(pattern, (match: string) =>
    replacements[match] !== undefined ? replacements[match] : match
  )
}

// Fonction pour stocker les données avec un timestamp d'expiration
const setLocalStorageWithExpiration = (
  key: string,
  value: any,
  expirationMinutes: number
): void => {
  const expirationMS = expirationMinutes * 60 * 1000 // Convertir les minutes en millisecondes
  const expirationTime = new Date().getTime() + expirationMS // Calculer le timestamp d'expiration

  const item: ILocalStorage = {
    value,
    expirationTime
  }

  localStorage.setItem(key, JSON.stringify(item))
}

// Fonction pour obtenir les données du localStorage avec gestion de l'expiration
const getLocalStorageWithExpiration = (key: string): any => {
  const item = localStorage.getItem(key)

  if (item !== null) {
    const parsedItem = JSON.parse(item) as ILocalStorage
    if (new Date().getTime() < parsedItem.expirationTime) {
      return parsedItem.value
    }
  }
  localStorage.removeItem(key)
  return null
}

// Ajoute un espace devant le millier sur un nombre
const formatNumber = (number: number): string => {
  return new Intl.NumberFormat('en-US', { useGrouping: true }).format(number).replace(/,/g, ' ')
}

export {
  hasSuperAdminRole,
  hasRHRole,
  hasAccountantRole,
  hasManagerRole,
  hasPMRole,
  hasRole,
  hasOnlyUserRole,
  readFileAsBase64,
  changeThemeColor,
  removeAccents,
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
  isAllowedForSteamDej,
  formatNumber
}
