import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { exportUsersPlanning } from 'services/PlanningService'
import fileDownload from 'js-file-download'
import React, { ReactElement } from 'react'
import moment from 'moment'

interface IPlanningMonthOrWeekChanger {
  changeWeekOrMonth: (addMonth: boolean) => void
  currentDate: Date
  showExportButton: boolean
  place: number | null
  dayTypesFilter: number[]
  isSmallScreen: boolean
}

const PlanningMonthOrWeekChanger = ({
  changeWeekOrMonth,
  currentDate,
  showExportButton,
  place,
  dayTypesFilter,
  isSmallScreen
}: IPlanningMonthOrWeekChanger): ReactElement => {
  return (
    <div>
      <div className="month-displayer">
        <ArrowBackIosNewIcon
          className="icon"
          onClick={() => {
            changeWeekOrMonth(false)
          }}
        />
        <div className="month-year">
          <p className="bold month-title">
            {new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(currentDate)}
          </p>
          <p className="light">{moment(currentDate).format('YYYY')}</p>
        </div>

        <ArrowForwardIosIcon
          className="icon"
          onClick={() => {
            changeWeekOrMonth(true)
          }}
        />

        <div className="button-export">
          {showExportButton && currentDate !== null ? (
            <PrimaryButton
              handleClick={() => {
                void (async () => {
                  const response = await exportUsersPlanning(
                    moment(currentDate).format('YYYY-MM-DD'),
                    place,
                    isSmallScreen,
                    dayTypesFilter
                  )
                  if (response !== null) {
                    fileDownload(response.content, response.filename)
                  }
                })()
              }}
              title="Exporter"
              background
              reverse={false}
              disabled={false}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PlanningMonthOrWeekChanger
