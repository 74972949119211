import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { isHolidayOrWeekendDay } from 'utils/dateUtils'
import { InputLabel, TextField } from '@mui/material'
import React, { ReactElement } from 'react'
import { dateFormat } from 'constantes'
import frLocale from 'dayjs/locale/fr'
import { Dayjs } from 'dayjs'
import moment from 'moment'

interface ICustomDatePicker {
  handleChangeDate: (e: Dayjs | null, propertyToUpdate: string) => void
  id: string
  value: Date | null
  errorMessage?: string | undefined
  required: boolean
  label: string
  className?: string
  minDate?: Date | null
  maxDate?: Date | null
  disableWeeksAndHolidays?: boolean
  holidayDates?: string[]
  includeTime?: boolean
}

const CustomDatePicker = ({
  handleChangeDate,
  id,
  value,
  errorMessage,
  required,
  label,
  className = '',
  minDate,
  maxDate,
  disableWeeksAndHolidays = false,
  holidayDates = [],
  includeTime = false
}: ICustomDatePicker): ReactElement => {
  const shouldDisableDate = (date: any): boolean => {
    if (disableWeeksAndHolidays && moment(date.toDate()) !== null) {
      const dayOfWeek = moment(date.toDate()).format('YYYY-MM-DD')
      return isHolidayOrWeekendDay(dayOfWeek, holidayDates) // Désactiver les dimanches (0) et samedis (6)
    }
    return false
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={frLocale}>
      <div>
        <InputLabel id={id} required={required} className="mb-1">
          {label}
        </InputLabel>
        {includeTime ? (
          <DateTimePicker
            className={className}
            onChange={(event): void => {
              handleChangeDate(event !== undefined ? (event as unknown as Dayjs) : null, id)
            }}
            value={value !== undefined ? value : null}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: dateFormat.DATEHOUR
                }}
              />
            )}
            inputFormat={dateFormat.DATEHOUR}
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={(date) => shouldDisableDate(date)}
          />
        ) : (
          <DatePicker
            className={className}
            onChange={(event): void => {
              handleChangeDate(event !== undefined ? (event as unknown as Dayjs) : null, id)
            }}
            value={value !== undefined ? value : null}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: dateFormat.DATE
                }}
              />
            )}
            inputFormat={dateFormat.DATE}
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={(date) => {
              return shouldDisableDate(date)
            }}
          />
        )}

        {errorMessage !== undefined && <p className="form-error">{errorMessage}</p>}
      </div>
    </LocalizationProvider>
  )
}

export default CustomDatePicker
