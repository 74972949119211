import {
  getDayBackgroundAndDayType,
  getDayButtonClass,
  isDayInValidation
} from '../../../utils/planningUtils'
import { IPlanningDate } from '../../../interfaces/planning'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { isDateBeforeToday } from 'utils/dateUtils'
import React, { ReactElement } from 'react'
import moment from 'moment'

interface IPlanningMonthDisplayer {
  listDates: string[]
  holidayDates: string[]
  month: string
  currentUserPlanning: IPlanningDate[]
  hasRightSpace?: boolean
  isAdmin: boolean
  handleDayClick: (event: React.MouseEvent<HTMLButtonElement>, day: string) => void
}

const PlanningMonthDisplayer = ({
  listDates,
  holidayDates,
  month,
  currentUserPlanning,
  hasRightSpace = false,
  isAdmin,
  handleDayClick
}: IPlanningMonthDisplayer): ReactElement => {
  const days = ['L', 'M', 'M', 'J', 'V', 'S', 'D']
  const defaultDayClass = ['bold', 'day-displayer', 'day-box']

  /**
   * Transformer le tableau des jours du mois
   * en tableaux des jours par semaine
   */
  const getDayListByWeek = (): string[][] => {
    const weekDates: string[][] = []
    const listDatesCopy: string[] = [...listDates]

    // On ajoute des - si le premier jour n'est pas un dimanche
    const firstDay: number = moment(listDates?.[0])?.isoWeekday()
    if (firstDay !== 1) {
      for (let index = 0; index < firstDay - 1; index += 1) {
        listDatesCopy.unshift('-')
      }
    }

    // On ajoute des tirets pour compléter les semaines du mois
    while (listDatesCopy.length % 7 !== 0) {
      listDatesCopy.push('-')
    }

    // On transforme le tableau à une dimension en deux dimensions
    // Pour avoir une semaine par tableau
    for (let index = 0; index < listDatesCopy.length; index += 7) {
      weekDates.push(listDatesCopy.slice(index, index + 7))
    }

    return weekDates
  }

  return (
    <div className={`month-calendar ${hasRightSpace ? 'space' : ''}`}>
      <div className="month-group">
        <div className="month-year">
          <p className="bold month-displayer-title">{month}</p>
        </div>
      </div>
      <div className="w-100">
        <div className="week-displayer">
          {days.map((day, index) => {
            return (
              <div key={index} className="day-displayer">
                <div>{day}</div>
              </div>
            )
          })}
        </div>
        <div className="month-calendar-days">
          {getDayListByWeek().map((week, index) => {
            return (
              <div className="week-displayer week-border" key={index}>
                {week.map((day, index) => {
                  const isEditable: boolean = isAdmin || !isDateBeforeToday(day)
                  return (
                    <div
                      className={getDayButtonClass(
                        day,
                        holidayDates,
                        isEditable,
                        currentUserPlanning,
                        defaultDayClass
                      )}
                      key={index}
                      style={getDayBackgroundAndDayType(day, currentUserPlanning)}
                    >
                      <button
                        className="day"
                        key={index}
                        onClick={(e) => {
                          isEditable && handleDayClick(e, day)
                        }}
                      >
                        {day !== '-' ? parseInt(moment(day).format('DD'), 10) : ''}
                      </button>
                      {isDayInValidation(day, currentUserPlanning) && (
                        <span className="validation-icon">
                          <WatchLaterIcon />
                        </span>
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PlanningMonthDisplayer
