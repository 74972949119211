import {
  IBirthdayPopUpContext,
  ICurrentUserContext,
  IFeatureContext,
  IMessageContext,
  INewArrivalPopUpContext,
  IPlaceSelectedContext,
  ISelectedItemContext,
  IYearSelectedContext
} from 'interfaces/context'
import { colorVars, featuresList, menuItemConstant, roleConstant } from 'constantes'
import React, { ReactElement, createContext, useEffect, useState } from 'react'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import HolidaysRequests from 'pages/holidaysRequests/HolidaysRequests'
import { getFeatures, getThemeColors } from 'services/SettingService'
import AdminReporting from 'pages/adminReporting/AdminReporting'
import { getLocalStorageWithExpiration } from 'utils/otherUtils'
import ListMaterials from 'pages/listMaterials/ListMaterials'
import Trombinoscope from 'pages/trombinoscope/Trombinoscope'
import { Route, Routes, useLocation } from 'react-router-dom'
import Error404Page from 'pages/error404Page/Error404Page'
import MaterialInfo from 'pages/materialInfo/MaterialInfo'
import CareerPersonal from './pages/career/CareerPersonal'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ProtectedRoute from './components/ProtectedRoute'
import ListCollabs from 'pages/listCollabs/ListCollabs'
import AddMaterial from 'pages/addMaterial/AddMaterial'
import MyDocuments from 'pages/myDocuments/MyDocuments'
import ListClients from 'pages/listClients/ListClients'
import OnBoarding from './pages/onboarding/OnBoarding'
import LogoutCallback from 'components/LogoutCallack'
import { IFeature, ITheme } from 'interfaces/setting'
import MyExpenses from 'pages/myExpenses/MyExpenses'
import MyHolidays from 'pages/myHolidays/MyHolidays'
import NewPopUp from 'components/popup/PopUp/PopUp'
import Dashboard from './pages/dashboard/Dashboard'
import { useGoogleAuth } from 'hooks/useGoogleAuth'
import AddCollab from 'pages/addCollab/AddCollab'
import Reporting from 'pages/reporting/Reporting'
import { IMessage } from './interfaces/message'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Expenses from 'pages/expenses/Expenses'
import Planning from 'pages/planning/Planning'
import SteamDej from 'pages/steamDej/SteamDej'
import Setting from 'pages/setting/Setting'
import Profil from 'pages/profil/Profil'
import Group from 'pages/group/Group'
import moment from 'moment'

// Transmettre et modifier les infos de l'utilisateur n'importe où dans l'application
export const CurrentUserContext = createContext<ICurrentUserContext | null>(null)

export const PlaceSelectedContext = createContext<IPlaceSelectedContext | null>(null)

export const YearSelectedContext = createContext<IYearSelectedContext | null>(null)

// lien du logo
export const LogoAppContext = createContext<string | null>(null)

// Pouvoir conserver et modifier l'élément du menu sélectionné n'importe où dans l'app
export const SelectedItemContext = createContext<ISelectedItemContext | null>(null)

export const BirthdayPopUpContext = createContext<IBirthdayPopUpContext | null>(null)

export const NewArrivalPopUpContext = createContext<INewArrivalPopUpContext | null>(null)
export const MessagesContext = createContext<IMessageContext | null>(null)

export const FeaturesContext = createContext<IFeatureContext | null>(null)

// Créez l'objet de mappage pour les chemins de page et les valeurs de selectedItem
const menuItemMappings: Record<string, string | null> = {
  '/tableau_de_bord': menuItemConstant.TABLEAU_BORD,
  '/liste_collaborateurs': menuItemConstant.LISTE_COLLABS,
  '/trombinoscope': menuItemConstant.TROMBINOSCOPE,
  '/profil/:userId': null,
  '/liste_materiels': menuItemConstant.LISTE_MATERIELS,
  '/suivi_absences': menuItemConstant.SUIVI_ABSENCES,
  '/setting': menuItemConstant.PARAMETRAGE,
  '/mes_conges': menuItemConstant.MES_CONGES,
  '/carriere': menuItemConstant.CARRIERE,
  '/notes_de_frais': menuItemConstant.NOTES_FRAIS,
  '/mes_notes_de_frais': menuItemConstant.MES_NOTES_FRAIS,
  '/planning': menuItemConstant.PLANNING,
  '/steamdej': menuItemConstant.STEAMDEJ,
  '/mes_documents': menuItemConstant.MES_DOCUMENTS,
  '/groupes': menuItemConstant.GROUP,
  '/liste_clients': menuItemConstant.LISTE_CLIENTS,
  '/reporting_admin': menuItemConstant.ADMIN_REPORTING,
  '/reporting': menuItemConstant.REPORTING,
  '/': menuItemConstant.TABLEAU_BORD,
  '*': '' // Définir la valeur par défaut pour toutes les autres routes
}

const App = (): ReactElement => {
  // Instancie le currentUSer avec l'user stocké dans le cookie de session si trouvé
  const user = getLocalStorageWithExpiration('user')

  const [logo, setLogo] = useState<string>('/images/default-logo.png')
  const [currentUser, setCurrentUser] = useState<IMinimalCollaborateurData | null>(
    JSON.parse(user) ?? null
  )
  const [selectedItem, setSelectedItem] = useState<string>('')
  const [placeSelected, setPlaceSelected] = useState<number | null>(null)
  const [yearSelected, setYearSelected] = useState<number>(moment().year())
  const [birthdayPopUp, setBirthdayPopUp] = useState<IMinimalCollaborateurData[] | null>(null)
  const [messages, setMessages] = useState<IMessage[] | null>(null)
  const [newArrivalPopUp, setNewArrivalPopUp] = useState<IMinimalCollaborateurData[] | null>(null)
  const [features, setFeatures] = useState<IFeature[] | null>([])

  useEffect(() => {
    setPlaceSelected(currentUser?.place?.id ?? null)

    if (currentUser !== null) {
      void (async () => {
        const result = await getFeatures()
        setFeatures(result)
      })()
    }
  }, [currentUser])

  const location = useLocation()

  useEffect(() => {
    const currentPath = window.location.pathname
    const item = Object.keys(menuItemMappings).find((item: string) => currentPath.startsWith(item))
    if (item) {
      setSelectedItem(menuItemMappings[item] ?? '')
    }
  }, [location.pathname])

  const fetchColors = async (): Promise<void> => {
    const response: ITheme = await getThemeColors(placeSelected)
    document.documentElement.style.setProperty(
      colorVars.PRIMARY_COLOR,
      response.primaryColor ?? '#1653F0'
    )
    document.documentElement.style.setProperty(
      colorVars.SECONDARY_COLOR,
      response.secondaryColor ?? '#0700E0'
    )
    document.documentElement.style.setProperty(
      colorVars.TERTIARY_COLOR,
      response.tertiaryColor ?? '#7B01D4'
    )
    setLogo(response.logoLink)
  }

  useEffect(() => {
    void fetchColors()
  }, [placeSelected])

  const isCheckEnded = useGoogleAuth({
    setCurrentUser,
    setBirthdayPopUp,
    setNewArrivalPopUp,
    setMessages
  })

  if (!isCheckEnded || (currentUser !== null && (features === null || features.length === 0))) {
    return <></>
  }

  return (
    <LogoAppContext.Provider value={logo}>
      <PlaceSelectedContext.Provider value={{ placeSelected, setPlaceSelected }}>
        <YearSelectedContext.Provider value={{ yearSelected, setYearSelected }}>
          <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
            <SelectedItemContext.Provider value={{ selectedItem, setSelectedItem }}>
              <BirthdayPopUpContext.Provider value={{ birthdayPopUp, setBirthdayPopUp }}>
                <NewArrivalPopUpContext.Provider value={{ newArrivalPopUp, setNewArrivalPopUp }}>
                  <FeaturesContext.Provider value={{ features, setFeatures }}>
                    <MessagesContext.Provider value={{ messages, setMessages }}>
                      <ToastContainer position="top-right" autoClose={2000} />
                      <GoogleOAuthProvider
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
                      >
                        <Routes>
                          <Route
                            path="onboarding"
                            element={
                              <ProtectedRoute
                                element={<OnBoarding />}
                                needRole={[]}
                                feature={featuresList.DOCUMENTS}
                              />
                            }
                          />
                          <Route
                            path="/"
                            element={
                              <ProtectedRoute
                                element={<Dashboard />}
                                needRole={[
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_MANAGER,
                                  roleConstant.ROLE_ADMIN_RH
                                ]}
                              />
                            }
                          />
                          <Route path="deconnexion" element={<LogoutCallback />} />
                          <Route
                            path="tableau_de_bord"
                            element={
                              <ProtectedRoute
                                element={<Dashboard />}
                                needRole={[
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_MANAGER,
                                  roleConstant.ROLE_ADMIN_RH
                                ]}
                                feature={featuresList.COLLABS}
                              />
                            }
                          />
                          <Route
                            path="creation"
                            element={
                              <ProtectedRoute
                                element={<AddCollab />}
                                needRole={[
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_MANAGER,
                                  roleConstant.ROLE_ADMIN_RH
                                ]}
                                feature={featuresList.COLLABS}
                              />
                            }
                          />
                          <Route
                            path="liste_collaborateurs/*"
                            element={
                              <ProtectedRoute
                                element={<ListCollabs />}
                                needRole={[
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_MANAGER,
                                  roleConstant.ROLE_ADMIN_RH
                                ]}
                                feature={featuresList.COLLABS}
                              />
                            }
                          />
                          <Route
                            path="trombinoscope"
                            element={
                              <ProtectedRoute
                                element={<Trombinoscope />}
                                needRole={[]}
                                feature={featuresList.TROMBI}
                              />
                            }
                          />
                          <Route
                            path="profil/:userId"
                            element={
                              <ProtectedRoute
                                element={<Profil />}
                                needRole={[
                                  roleConstant.ROLE_USER,
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_MANAGER,
                                  roleConstant.ROLE_ADMIN_RH,
                                  roleConstant.ROLE_ADMIN_COMPTABLE
                                ]}
                                feature={featuresList.COLLABS}
                              />
                            }
                          />
                          <Route
                            path="ajout_materiel"
                            element={
                              <ProtectedRoute
                                element={<AddMaterial />}
                                needRole={[roleConstant.ROLE_ADMIN, roleConstant.ROLE_MANAGER]}
                                feature={featuresList.MATERIAL}
                              />
                            }
                          />
                          <Route
                            path="liste_materiels/*"
                            element={
                              <ProtectedRoute
                                element={<ListMaterials />}
                                needRole={[roleConstant.ROLE_ADMIN, roleConstant.ROLE_MANAGER]}
                                feature={featuresList.MATERIAL}
                              />
                            }
                          />
                          <Route
                            path="material/:materialId"
                            element={
                              <ProtectedRoute
                                element={<MaterialInfo />}
                                needRole={[roleConstant.ROLE_ADMIN, roleConstant.ROLE_MANAGER]}
                                feature={featuresList.MATERIAL}
                              />
                            }
                          />
                          <Route
                            path="suivi_absences"
                            element={
                              <ProtectedRoute
                                element={<HolidaysRequests />}
                                needRole={[roleConstant.ROLE_ADMIN, roleConstant.ROLE_ADMIN_RH]}
                                feature={featuresList.HOLIDAY}
                              />
                            }
                          />
                          <Route
                            path="email-approval"
                            element={
                              <ProtectedRoute
                                element={<HolidaysRequests />}
                                needRole={[roleConstant.ROLE_ADMIN, roleConstant.ROLE_ADMIN_RH]}
                                feature={featuresList.HOLIDAY}
                              />
                            }
                          />
                          <Route
                            path="setting"
                            element={
                              <ProtectedRoute
                                element={<Setting />}
                                needRole={[roleConstant.ROLE_ADMIN]}
                              />
                            }
                          />
                          <Route
                            path="notes_de_frais"
                            element={
                              <ProtectedRoute
                                element={<Expenses />}
                                needRole={[
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_ADMIN_COMPTABLE
                                ]}
                                feature={featuresList.NDF}
                              />
                            }
                          />
                          <Route
                            path="groupes"
                            element={
                              <ProtectedRoute
                                element={<Group />}
                                needRole={[
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_ADMIN_RH,
                                  roleConstant.ROLE_MANAGER
                                ]}
                                feature={featuresList.COLLABS}
                              />
                            }
                          />
                          <Route
                            path="mes_notes_de_frais"
                            element={
                              <ProtectedRoute
                                element={<MyExpenses />}
                                needRole={[]}
                                feature={featuresList.NDF}
                              />
                            }
                          />
                          <Route
                            path="mes_conges/:userId"
                            element={
                              <ProtectedRoute
                                element={<MyHolidays />}
                                needRole={[
                                  roleConstant.ROLE_USER,
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_MANAGER,
                                  roleConstant.ROLE_ADMIN_RH,
                                  roleConstant.ROLE_ADMIN_COMPTABLE
                                ]}
                                feature={featuresList.HOLIDAY}
                              />
                            }
                          />
                          <Route
                            path="planning"
                            element={
                              <ProtectedRoute
                                fullWidth
                                element={<Planning />}
                                needRole={[]}
                                feature={featuresList.PLANNING}
                              />
                            }
                          />
                          <Route
                            path="reporting"
                            element={
                              <ProtectedRoute
                                fullWidth
                                element={<Reporting />}
                                needRole={[]}
                                feature={featuresList.REPORTING}
                              />
                            }
                          />
                          <Route
                            path="steamdej"
                            element={
                              <ProtectedRoute
                                element={<SteamDej />}
                                needRole={[]}
                                onlySteamDej
                                feature={featuresList.DEJ}
                              />
                            }
                          />
                          <Route
                            path="mes_documents"
                            element={
                              <ProtectedRoute
                                element={<MyDocuments />}
                                needRole={[]}
                                feature={featuresList.DOCUMENTS}
                              />
                            }
                          />
                          <Route
                            path="carriere"
                            element={
                              <ProtectedRoute
                                element={<CareerPersonal />}
                                needRole={[]}
                                feature={featuresList.CAREER}
                              />
                            }
                          />
                          <Route
                            path="liste_clients"
                            element={
                              <ProtectedRoute
                                element={<ListClients />}
                                needRole={[
                                  roleConstant.ROLE_ADMIN_COMPTABLE,
                                  roleConstant.ROLE_ADMIN
                                ]}
                                feature={featuresList.REPORTING}
                              />
                            }
                          />
                          <Route
                            path="reporting_admin/"
                            element={
                              <ProtectedRoute
                                element={<AdminReporting />}
                                needRole={[
                                  roleConstant.ROLE_ADMIN,
                                  roleConstant.ROLE_ADMIN_COMPTABLE
                                ]}
                                feature={featuresList.REPORTING}
                              />
                            }
                          />
                          <Route
                            path="*"
                            element={<ProtectedRoute element={<Error404Page />} needRole={[]} />}
                          />
                        </Routes>
                        {(birthdayPopUp !== null || newArrivalPopUp !== null) && <NewPopUp />}
                      </GoogleOAuthProvider>
                    </MessagesContext.Provider>
                  </FeaturesContext.Provider>
                </NewArrivalPopUpContext.Provider>
              </BirthdayPopUpContext.Provider>
            </SelectedItemContext.Provider>
          </CurrentUserContext.Provider>
        </YearSelectedContext.Provider>
      </PlaceSelectedContext.Provider>
    </LogoAppContext.Provider>
  )
}

export default App
