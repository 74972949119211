import HolidaysRequestPdf from 'pages/holidaysRequests/HolidaysRequestPdf'
import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import { UploadedFile } from 'interfaces/uploadedFile'
import Popover from '@material-ui/core/Popover'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'

interface IProps {
  open: boolean
  anchorEl: HTMLElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
  holidayRequest: IDetailHolidayRequest
  handleFileUpdate: (response: IDetailHolidayRequest, file: UploadedFile | null) => Promise<void>
}

const FilePopover: React.FC<IProps> = ({
  open,
  anchorEl,
  setAnchorEl,
  holidayRequest,
  handleFileUpdate
}) => {
  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <IconButton onClick={handlePopoverClose} id="closeIcon">
        <Close />
      </IconButton>
      <div className="popover justificatif-confirmation">
        <div className="modal-text">Un justificatif est déjà renseigné.</div>
        <HolidaysRequestPdf
          request={holidayRequest}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handlePdfUpdate={async (response, file) => {
            handlePopoverClose()
            await handleFileUpdate(response, file)
          }}
        />
      </div>
    </Popover>
  )
}

export default FilePopover
