import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { TextField } from '@mui/material'

interface IEditableTextField {
  value: string
  onChange: React.Dispatch<React.SetStateAction<string>>
  handleSave: () => void
  width: string
}

const EditableTextField = ({
  value,
  onChange,
  handleSave,
  width
}: IEditableTextField): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(!value)
  const textFieldRef = useRef<HTMLInputElement>(null)

  useEffect((): void => {
    if (isEditing && textFieldRef.current) {
      textFieldRef.current.focus()
    }
  }, [isEditing])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault()
      textFieldRef.current?.blur()
    }
  }

  const handleBlur = (): void => {
    if (!value.trim()) {
      return
    }
    setIsEditing(false)
    handleSave()
  }

  return (
    <div className="editable-text" style={{ display: 'inline-block', width }}>
      {isEditing ? (
        <TextField
          variant="outlined"
          onChange={(e) => {
            onChange(e.target.value)
          }}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          value={value}
          inputRef={textFieldRef}
          InputProps={{
            style: {
              fontSize: '11px'
            }
          }}
        />
      ) : (
        <button
          className="w-[100%]"
          onClick={() => {
            setIsEditing(true)
          }}
        >
          <p className="text-xs text-left whitespace-nowrap overflow-hidden text-ellipsis">
            {value}
          </p>
        </button>
      )}
    </div>
  )
}

export default EditableTextField
