import {
  IDetailCollaborateurData,
  IMinimalCollaborateurData,
  IUpdateUserData
} from 'interfaces/collaborateurData'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

/**
 Récupère les données d'un collaborateur à partir de son identifiant
 @year l'année sélectionnée
 @returns Les données du collaborateur ou null en cas d'erreur
 * @param userId
 * @param year
 */
export const getUserById = async (
  userId: number,
  year: number
): Promise<IDetailCollaborateurData | null> => {
  try {
    return await customAxios.get(`users/${userId}?year=${year}`)
  } catch (error) {
    return null
  }
}

/**
 Récupère les données de l'utilisateur connecté
 @year l'année sélectionnée
 @returns Les données de l'utilisateur courant, ou null en cas d'erreur
 */
export const getSelfUserData = async (year: number): Promise<IDetailCollaborateurData | null> => {
  try {
    return await customAxios.get(`users/self?year=${year}`)
  } catch (error) {
    return null
  }
}

/**
 Récupère les données de l'utilisateur grace à un token d'autologin
 @year l'année sélectionnée
 @returns Les données de l'utilisateur courant, ou null en cas d'erreur
 */
export const getUserByAutoLoginToken = async (
  autologinToken = ''
): Promise<IDetailCollaborateurData | null> => {
  try {
    return await customAxios.get(`/autologin/?autologinToken=${autologinToken}`)
  } catch (error: any) {
    return null
  }
}

/**
 Permet de crér ou mettre  à jour un collab
 @param data le body contenant les données de l'utilisateur
 @param year
 @param id l'id du collaborateur qui doit être mis à jour s'il s'agit d'une maj
 @param desactivation boolean indiquant si'l s'agit de la désactivation d'un user
 @return Un boolean indiquant s'il y a eu une erreur
 **/
export const createOrUpdateCollab = async (
  data: IUpdateUserData,
  year: number,
  desactivation?: boolean,
  id?: number
): Promise<IDetailCollaborateurData | null> => {
  try {
    let user
    if (id !== undefined) {
      user = await customAxios.put(`users/${id}?year=${year}`, data)
      if (desactivation !== undefined && !desactivation) {
        toast.success('Collaborateur mis à jour avec succès')
      } else {
        toast.success('Collaborateur désactivé avec succès')
      }
    } else {
      user = await customAxios.post('users', data)
      toast.success('Collaborateur créé avec succès')
    }
    return user as unknown as IDetailCollaborateurData
  } catch (error) {
    return null
  }
}

export const deleteCollab = async (id: number, deletionPending: boolean): Promise<void> => {
  try {
    await customAxios.delete(`users/${id}`)
    if (deletionPending) {
      toast.success('Collaborateur supprimé avec succès')
    } else {
      toast.success('Collaborateur marqué pour suppresion avec succès')
    }
  } catch (error) {
    toast.error("Une erreur s'est produite lors de la suppression")
  }
}

/**
 *Permet de récupérer les collaborateur à afficher sur le trombinoscope et gérer la recherche
 *@param {string|null} search - La chaîne de recherche (facultatif).
 @param {number | null} place - Le lieu où se trouve les employés
 *@return {ITrombinoscopeUserData} la liste des collaborateurs par catégories à afficher
 **/
export const getTrombinoscopeUsers = async (
  search: string | null,
  place: number | null
): Promise<IMinimalCollaborateurData[]> => {
  try {
    const searchParam = search !== null && search !== '' ? `&search=${search}` : ''
    const placeParam = place !== null ? `&placeId=${place}` : ''
    return await customAxios.get(`trombinoscope/users?${searchParam}${placeParam}`)
  } catch (error) {
    return []
  }
}

export const addFavoriteUserStatus = async (
  favoriteUserId: number
): Promise<IMinimalCollaborateurData | null> => {
  try {
    return await customAxios.put(`users/self/favorites/${favoriteUserId}`)
  } catch (error) {
    return null
  }
}

export const deleteFavoriteUserStatus = async (
  favoriteUserId: number
): Promise<IMinimalCollaborateurData | null> => {
  try {
    return await customAxios.delete(`users/self/favorites/${favoriteUserId}`)
  } catch (error) {
    return null
  }
}
