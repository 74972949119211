import {
  IDetailHolidayQuotaRequest,
  IDetailHolidayRequest,
  ITotalHolidayQuotaRequest
} from 'interfaces/holidays/holidayRequest'
import { TableBody, TableCell, TableRow } from '@mui/material'
import ProfilImage from 'components/profilImage/ProfilImage'
import React, { ReactElement } from 'react'
import './HolidaysSynthesisBodyTable.scss'
import { Link } from 'react-router-dom'
import 'animate.css'

interface IHolidaysSynthesisBodyTab {
  currentTab: number
  requests: IDetailHolidayQuotaRequest[]
  totalRequests: ITotalHolidayQuotaRequest | undefined
  handleRequestUpdate: (response: IDetailHolidayRequest) => void
  handleRequestApprobationUpdate: (response: IDetailHolidayRequest) => void
}

// Fonction qui retourne l'url de la page de planning en fonction de l'id de l'utilisateur
const createPlanningUrl = (userId: string | number | undefined): string => {
  const safeUserId = String(userId ?? '')
  return `/planning?view=annual&userId=${safeUserId}`
}

const HolidaysSynthesisBodyTab = ({
  requests = [],
  totalRequests
}: IHolidaysSynthesisBodyTab): ReactElement => {
  return (
    <>
      <TableBody>
        {totalRequests !== undefined && totalRequests !== null ? (
          <TableRow key={totalRequests?.annualQuotaTotal} className="total-requests">
            <TableCell className="md-delete"></TableCell>
            <TableCell className="total-cell">TOTAL</TableCell>
            <TableCell className="total-cell md-delete">
              {!Number.isNaN(totalRequests.annualQuotaTotal) ? totalRequests.annualQuotaTotal : '-'}
            </TableCell>
            <TableCell className="total-cell">
              {!Number.isNaN(totalRequests.takenQuotaTotal) ? totalRequests.takenQuotaTotal : '-'}
            </TableCell>
            <TableCell className="total-cell">
              {!Number.isNaN(totalRequests.remainingQuotaTotal)
                ? totalRequests.remainingQuotaTotal
                : '-'}
            </TableCell>
            <TableCell className="total-cell">
              {!Number.isNaN(totalRequests.remainderYTDTotal)
                ? totalRequests.remainderYTDTotal
                : '-'}
            </TableCell>
            <TableCell className="total-cell">
              {!Number.isNaN(totalRequests.holidaysTakenThisMonthTotal)
                ? totalRequests.holidaysTakenThisMonthTotal
                : '-'}
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
        {requests.map((request) => {
          if (!request?.user) return null

          const userId = request.user.id ?? `user-${Math.random()}`
          const planningUrl = createPlanningUrl(userId)

          return (
            <TableRow hover={false} key={userId}>
              <TableCell className="profil-image-column md-delete">
                <Link to={planningUrl}>
                  <ProfilImage user={request.user} />
                </Link>
              </TableCell>
              <TableCell className="name-column">
                {request.user.displayFirstname !== undefined
                  ? String(request.user.displayFirstname) +
                    ' ' +
                    String(request.user.displayLastname)
                  : ''}
              </TableCell>
              <TableCell className="md-delete">
                {!Number.isNaN(request.holidayQuota.annualQuotaTotal)
                  ? request.holidayQuota.annualQuotaTotal
                  : '-'}
              </TableCell>
              <TableCell>
                {!Number.isNaN(request.holidayQuota.totalTaken)
                  ? request.holidayQuota.totalTaken
                  : '-'}
              </TableCell>
              <TableCell>
                {!Number.isNaN(request.holidayQuota.remainingQuotaTotal)
                  ? request.holidayQuota.remainingQuotaTotal
                  : '-'}
              </TableCell>
              <TableCell>
                {!Number.isNaN(request.holidayQuota.remainderYTDTotal)
                  ? request.holidayQuota.remainderYTDTotal
                  : '-'}
              </TableCell>
              <TableCell>
                {!Number.isNaN(request.holidayQuota.holidaysTakenThisMonth)
                  ? request.holidayQuota.holidaysTakenThisMonth
                  : '-'}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}

export default HolidaysSynthesisBodyTab
