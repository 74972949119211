import { IAnnualSummary, IReporting, ISummary } from 'interfaces/reporting'
import { isHolidayOrWeekendDay, isToday } from 'utils/dateUtils'
import React, { ReactElement, useEffect, useState } from 'react'
import { contractTypeConstant } from 'constantes'
import { formatNumber } from 'utils/otherUtils'
import moment from 'moment'
import dayjs from 'dayjs'

export interface AdminReportingProjectTableProps {
  isBillable: boolean
  listDates: string[]
  holidayDates: string[]
  summaries: ISummary[]
  annualSummaries: ISummary[]
  shortMonthsList: string[]
  month: number
  reporting: IReporting[]
  projectTotal: number
  goToReporting: (name: string) => void
}

const AdminReportingProjectTable = ({
  isBillable,
  listDates,
  holidayDates,
  summaries,
  annualSummaries,
  shortMonthsList,
  month,
  reporting,
  projectTotal,
  goToReporting
}: AdminReportingProjectTableProps): ReactElement => {
  const [annualSummary, setAnnualSummary] = useState<IAnnualSummary>()

  useEffect(() => {
    const filteredSummaries = annualSummaries.find((summary) => summary.billable === isBillable)
    const months: number[] = Array(12).fill(0)
    annualSummaries.forEach((summary) => {
      if (summary.billable === isBillable) {
        const monthIndex = summary.month - 1
        months[monthIndex] += summary.amountWorked / 100
      }
    })

    setAnnualSummary({ ...filteredSummaries, months })
  }, [annualSummaries, isBillable])

  const getAmountWorked = (userId: number, day: string, isBillable: boolean): number => {
    const userReporting = reporting.find(
      (rep) => rep.user?.id === userId && rep.isBillable === isBillable
    )
    if (!userReporting) return 0

    return userReporting.workEntries
      .filter((entry) => moment(entry.date).isSame(day, 'day'))
      .reduce((sum, entry) => sum + entry.amountWorked / 100, 0)
  }

  const getTotalMonth = (summary: ISummary): number | undefined => {
    return reporting
      .find((report) => report.user?.id === summary.user.id && report.isBillable === isBillable)
      ?.workEntries.filter((entry) => moment(entry.date).month() + 1 === month)
      .reduce((sum, entry) => sum + entry.amountWorked / 100, 0)
  }

  const getUserTotalYear = (userId: number, billable: boolean): number => {
    return annualSummaries
      .filter((summary) => summary.user.id === userId && summary.billable === billable)
      .reduce((sum, summary) => sum + summary.amountWorked / 100, 0)
  }

  const calculateSousTotal = (contractType: string, type: 'month' | 'year'): number => {
    let filteredSummaries: ISummary[]

    if (contractType === 'Non Prestataire') {
      filteredSummaries = summaries.filter(
        (summary) =>
          summary.contractType !== contractTypeConstant.PRESTATAIRE &&
          summary.billable === isBillable
      )
    } else {
      filteredSummaries = summaries.filter(
        (summary) => summary.contractType === contractType && summary.billable === isBillable
      )
    }
    return filteredSummaries.reduce((total, summary) => {
      if (type === 'month') {
        return total + (getTotalMonth(summary) ?? 0)
      } else {
        return total + getUserTotalYear(summary.user.id, isBillable)
      }
    }, 0)
  }

  const sousTotalMonthNonPrestataire = calculateSousTotal('Non Prestataire', 'month')
  const sousTotalYearNonPrestataire = calculateSousTotal('Non Prestataire', 'year')
  const sousTotalMonthPrestataire = calculateSousTotal(contractTypeConstant.PRESTATAIRE, 'month')
  const sousTotalYearPrestataire = calculateSousTotal(contractTypeConstant.PRESTATAIRE, 'year')

  const totalMonth = sousTotalMonthNonPrestataire + sousTotalMonthPrestataire
  const totalYear = sousTotalYearNonPrestataire + sousTotalYearPrestataire

  const formatDate = (dateString: string): string => {
    return dayjs(dateString).format('YYYY-MM-DD')
  }

  const calculateWeeklyTotal = (index: number, limit: number, isPrestataire: boolean): number => {
    let totalWeekly = 0
    let count = 0
    while (count < limit) {
      for (const project of reporting) {
        for (const work of project.workEntries) {
          if (isPrestataire && project.contractType === contractTypeConstant.PRESTATAIRE) {
            if (formatDate(work.date.toString()) === listDates[index] && project.isBillable) {
              totalWeekly += work.amountWorked
            }
          } else {
            if (
              formatDate(work.date.toString()) === listDates[index] &&
              project.isBillable &&
              !isPrestataire &&
              project.contractType !== contractTypeConstant.PRESTATAIRE
            ) {
              totalWeekly += work.amountWorked
            }
          }
        }
      }
      index += 1
      count++
    }
    return totalWeekly / 100
  }

  const createSousTotalCell = (isPrestataire: boolean): JSX.Element[] => {
    const tdContainer: JSX.Element[] = []
    let count = 0

    while (count < listDates.length) {
      if (isHolidayOrWeekendDay(listDates[count], [])) {
        const temp = count
        while (isHolidayOrWeekendDay(listDates[count], [])) {
          count++
        }
        tdContainer.push(
          <td
            colSpan={count - temp}
            className="border-r-2 border-l-2 border-gray-100 bg-grey-100"
          ></td>
        )
      }
      if (count + 5 >= listDates.length) {
        if (count !== listDates.length) {
          tdContainer.push(
            <td
              colSpan={listDates.length - count}
              className="border-r-2 border-l-2 border-gray-100 bg-violet-200"
            >
              {calculateWeeklyTotal(count, listDates.length - count, isPrestataire)}
            </td>
          )
        }
        return tdContainer
      } else {
        let tempRegular = count
        while (!isHolidayOrWeekendDay(listDates[tempRegular], [])) {
          tempRegular++
        }
        tdContainer.push(
          <td
            colSpan={tempRegular - count}
            className="border-r-2 border-l-2 border-gray-100 bg-violet-200"
          >
            {calculateWeeklyTotal(count, tempRegular - count, isPrestataire)}
          </td>
        )
        count += tempRegular - count
      }
    }

    return tdContainer
  }

  return (
    <>
      <div className="bg-white rounded-xl overflow-hidden p-1 mt-2">
        <table className="w-full shadow-md text-center text-sm">
          <thead>
            <tr className="border-b-2 border-gray-300">
              <th className="bold text-left pl-1">Nom</th>
              <th className="bold text-left">Prénom</th>
              <th className="bold">Type</th>
              {listDates.map((day, index) => (
                <th
                  key={index}
                  className={`bold text-sm ${
                    isHolidayOrWeekendDay(day, holidayDates) ? 'light-greyText' : ''
                  } ${isToday(day) ? 'today-date' : ''}`}
                >
                  <div className="flex flex-col day">
                    <div>{parseInt(moment(day).format('DD'), 10)}</div>
                  </div>
                </th>
              ))}

              <th className="bold">Mois</th>
              <th className="bold">Année</th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={`${
                isBillable ? 'bg-orange-100' : 'bg-violet-200'
              } border-b-2 border-gray-300 bold`}
            >
              <td colSpan={36}>{isBillable ? 'PRODUCTION FACTUREE' : 'PRODUCTION NON FACTUREE'}</td>
            </tr>
            {summaries
              .filter((item: ISummary) => item.billable === isBillable)
              .map(
                (summary, index) =>
                  summary.contractType !== contractTypeConstant.PRESTATAIRE && (
                    <tr key={index} className="border-b-2 border-gray-400">
                      <td
                        className="text-left pl-1 cursor-pointer"
                        onClick={() => {
                          goToReporting(summary.user.lastname)
                        }}
                      >
                        {summary.user.displayLastname}
                      </td>
                      <td
                        className="text-left cursor-pointer"
                        onClick={() => {
                          goToReporting(summary.user.lastname)
                        }}
                      >
                        {summary.user.displayFirstname}
                      </td>
                      <td>{summary.contractType}</td>
                      {listDates.map((day, dayIndex) => (
                        <td
                          key={dayIndex}
                          className={`bold w-[27px] text-xs ${
                            isHolidayOrWeekendDay(day, holidayDates) ? 'bg-gray-100' : ''
                          }`}
                        >
                          {getAmountWorked(summary.user.id, day, isBillable) !== 0
                            ? getAmountWorked(summary.user.id, day, isBillable)
                            : '-'}
                        </td>
                      ))}
                      <td className="bold bg-gray-300 border-r-2 border-gray-100">
                        {summary.billable === isBillable ? getTotalMonth(summary) : 0}
                      </td>
                      <td className="bold bg-gray-300">
                        {formatNumber(getUserTotalYear(summary.user.id, isBillable))}
                      </td>
                    </tr>
                  )
              )}
            {isBillable && (
              <>
                <tr className="bg-gray-200 bold border-b-2 border-gray-300">
                  <td className="text-left pl-1">Sous-total</td>
                  <td colSpan={2}></td>
                  {createSousTotalCell(false)}
                  <td className="bg-violet-200 border-r-2 border-gray-100">
                    {formatNumber(sousTotalMonthNonPrestataire)}
                  </td>
                  <td className="bg-violet-200">{formatNumber(sousTotalYearNonPrestataire)}</td>
                </tr>

                <tr className="bg-orange-100 border-b-2 border-gray-300 bold">
                  <td colSpan={36}>PRODUCTION SOUS TRAITANCE</td>
                </tr>
                {summaries
                  .filter((item: ISummary) => item.billable === isBillable)
                  .map(
                    (summary, index) =>
                      summary.contractType === contractTypeConstant.PRESTATAIRE && (
                        <tr key={index} className="border-b-2 border-gray-400">
                          <td
                            className="text-left pl-1 cursor-pointer"
                            onClick={() => {
                              goToReporting(summary.user.lastname)
                            }}
                          >
                            {summary.user.displayLastname}
                          </td>
                          <td
                            className="text-left cursor-pointer"
                            onClick={() => {
                              goToReporting(summary.user.lastname)
                            }}
                          >
                            {summary.user.displayFirstname}
                          </td>
                          <td>{summary.contractType}</td>
                          {listDates.map((day, dayIndex) => (
                            <td
                              key={dayIndex}
                              className={`bold w-[27px] text-xs ${
                                isHolidayOrWeekendDay(day, holidayDates) ? 'bg-gray-100' : ''
                              }`}
                            >
                              {getAmountWorked(summary.user.id, day, isBillable) !== 0
                                ? getAmountWorked(summary.user.id, day, isBillable)
                                : '-'}
                            </td>
                          ))}
                          <td className="bold bg-gray-300 border-r-2 border-gray-100">
                            {getTotalMonth(summary)}
                          </td>
                          <td className="bold bg-gray-300">
                            {formatNumber(getUserTotalYear(summary.user.id, isBillable))}
                          </td>
                        </tr>
                      )
                  )}
                <tr className="bg-gray-200 bold border-b-2 border-gray-300">
                  <td className="text-left pl-1">Sous-total</td>
                  <td colSpan={2}></td>
                  {createSousTotalCell(true)}
                  <td className="bg-violet-200 border-r-2 border-gray-100">
                    {formatNumber(sousTotalMonthPrestataire)}
                  </td>
                  <td className="bg-violet-200">{formatNumber(sousTotalYearPrestataire)}</td>
                </tr>
              </>
            )}

            <tr
              className={`${
                isBillable ? 'bg-orange-100' : 'bg-violet-200'
              } border-b-2 border-gray-300 bold`}
            >
              <td className="text-left pl-1" colSpan={1}>
                TOTAL
              </td>
              <td colSpan={2 + listDates.length}></td>
              <td colSpan={1}>{formatNumber(totalMonth)}</td>
              <td colSpan={1}>{formatNumber(totalYear)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Table de report */}
      <div className="bg-white rounded-xl overflow-hidden mt-2 border-2">
        <table className="w-full shadow-md text-center text-sm">
          <thead>
            <tr className="border-b-2 border-gray-400">
              {shortMonthsList.map((monthIt, index) => (
                <th key={index} className={month === index + 1 ? `bg-green-100` : `p-1`}>
                  {monthIt}
                </th>
              ))}
              <th className={isBillable ? 'bg-orange-100' : 'bg-violet-200'}>Total Annuel</th>
              <th
                className={isBillable ? 'bg-orange-100' : 'bg-violet-200'}
                style={{ width: '175px' }}
              >
                {isBillable ? 'Total facturé' : 'Total non facturé'}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {shortMonthsList.map((monthIt, index) => (
                <td key={index} className={month === index + 1 ? `bg-green-100` : `p-1`}>
                  {formatNumber(annualSummary?.months[index] ?? 0)}
                </td>
              ))}
              <td className={isBillable ? 'bg-orange-100' : 'bg-violet-200'}>
                {formatNumber(
                  annualSummary?.months.reduce((sum: number, production) => sum + production, 0) ??
                    0
                )}
              </td>
              <td className={isBillable ? 'bg-orange-100' : 'bg-violet-200'}>
                {formatNumber(projectTotal / 100)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AdminReportingProjectTable
