import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import React, { ReactElement, useCallback, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import debounce from 'lodash/debounce'

interface IAutoCompleteUserInput {
  value: IMinimalCollaborateurData | null
  onChange: (_e: any, newValue: IMinimalCollaborateurData | null) => void
  id: string
  options: IMinimalCollaborateurData[]
  setOptions: (inputValue: string) => void
  clearInputAfterSelect?: boolean
  width?: number
  placeholder?: string
}

const AutoCompleteUserInput = ({
  value,
  onChange,
  id,
  options,
  setOptions,
  clearInputAfterSelect = false,
  width = 200,
  placeholder
}: IAutoCompleteUserInput): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('')

  const debouncedLoadData = useCallback(
    // Utilisation de la fonction debounce pour limiter le nombre d'appels à setOptions
    debounce((inputValue: string, setOptions: any) => {
      setOptions(inputValue)
    }, 500),
    // Utilisation de useCallback pour éviter de recréer la fonction à chaque rendu du composant
    []
  )

  const handleInputChange = (_e: any, v: string): void => {
    setInputValue(v)
    debouncedLoadData(v, setOptions)
  }

  const handleOptionLabelChange = (option: IMinimalCollaborateurData): string => {
    return option.displayFirstname + ' ' + option.displayLastname
  }

  return (
    <div>
      <Autocomplete
        value={value}
        inputValue={inputValue}
        getOptionLabel={handleOptionLabelChange}
        onChange={(_e, newValue: IMinimalCollaborateurData | null) => {
          onChange(_e, newValue)
          if (clearInputAfterSelect) {
            setInputValue('')
          }
        }}
        onInputChange={handleInputChange}
        disablePortal
        id={id}
        noOptionsText="Entrer un nom/prénom"
        options={options}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} className="auto-complete-placeholder" />
        )}
        style={{ width }}
      />
    </div>
  )
}
export default AutoCompleteUserInput
