import { Add, ArrowDropDown, ArrowRight, FileDownload } from '@mui/icons-material'
import { getConcatenatedBillsFile } from 'services/ExpensesService'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { expenseStatusContant, screenSizes } from 'constantes'
import useScreenSize from '../../customHooks/useScreenSize'
import { IExpense } from 'interfaces/expenses'
import fileDownload from 'js-file-download'
import React, { ReactElement } from 'react'

interface IExpenseRow {
  showTicket: boolean
  handleRowClick: (e: any) => void
  expense: IExpense
  isAdmin: boolean
  month?: string | undefined
}

const ExpenseRow = ({
  showTicket,
  handleRowClick,
  expense,
  isAdmin,
  month
}: IExpenseRow): ReactElement => {
  const isSmallScreen = useScreenSize(screenSizes.TABLET_SIZE)

  const handleDownload = async (expenseId: number): Promise<void> => {
    const response = await getConcatenatedBillsFile(expenseId)
    if (response !== null) {
      fileDownload(response.content, response.filename)
    }
  }

  return (
    <div className="myexpense-content">
      <TableRow hover key={expense.id} onClick={handleRowClick}>
        <TableCell className={`user-name ${showTicket ? 'current-month' : ''}`}>
          {isAdmin ? (
            <div className="d-flex">
              <div>
                {expense.user?.displayFirstname} {expense.user?.displayLastname}
              </div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  void (async () => {
                    if (expense.id !== undefined) {
                      await handleDownload(expense.id)
                    }
                  })()
                }}
              >
                <FileDownload id="download-file-icon" />
              </IconButton>
            </div>
          ) : (
            <>{month}</>
          )}
        </TableCell>
        <TableCell className="total-amount">{expense.totalAmount}€</TableCell>
        <TableCell className="status-badge">
          {!isAdmin &&
          expense.status === expenseStatusContant.A_SIGNER &&
          expense.totalAmount === 0 ? (
            <span className={`myexpense-status ${expenseStatusContant.A_SAISIR.replace(' ', '')}`}>
              {!isAdmin && <Add />}
              {expenseStatusContant.A_SAISIR}
            </span>
          ) : (
            <span className={`myexpense-status ${expense.status.replace(' ', '')}`}>
              {!isAdmin && expense.status === expenseStatusContant.A_SAISIR && <Add />}
              {expense.status}
            </span>
          )}
        </TableCell>
        {!isSmallScreen && (
          <TableCell className="arrow-end-icon">
            {expense.status !== expenseStatusContant.NON_RENSEIGNE ? (
              showTicket ? (
                <ArrowRight id="arrow-right" />
              ) : (
                <ArrowDropDown id="arrow-down" />
              )
            ) : (
              <div className="empty-icon"></div>
            )}
          </TableCell>
        )}
      </TableRow>
    </div>
  )
}

export default ExpenseRow
