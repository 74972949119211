import { IUpdateUserData } from 'interfaces/collaborateurData'
import { IServiceAccountData } from 'interfaces/selectors'
import React, { ReactElement } from 'react'
import ListServices from './ListServices'

interface IDataInfos {
  categoryTitle: string
  activeAccounts: IServiceAccountData[]
  inactiveAccounts: IServiceAccountData[]
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
}

const Services = ({
  categoryTitle,
  activeAccounts,
  inactiveAccounts,
  editMode,
  userDataToSend,
  setUserDataToSend
}: IDataInfos): ReactElement => {
  return (
    <div className="box-data">
      <p className="category-title">{categoryTitle}</p>
      <div>
        <ListServices
          accounts={activeAccounts}
          editMode={editMode}
          userDataToSend={userDataToSend}
          setUserDataToSend={setUserDataToSend}
          title="Actifs"
        />
        <ListServices
          accounts={inactiveAccounts}
          editMode={editMode}
          userDataToSend={userDataToSend}
          setUserDataToSend={setUserDataToSend}
          title="Inactifs"
          mTop
        />
      </div>
    </div>
  )
}

export default Services
