import { Tooltip } from '@mui/material'
import React from 'react'

interface TooltipProps {
  disabled: boolean
  text: string
  children: React.ReactElement
  top?: boolean
}

const Tooltips: React.FC<TooltipProps> = ({ text, children, disabled, top }) => {
  return (
    <>
      {!disabled ? (
        <Tooltip title={text} placement={top ? 'top' : 'bottom'}>
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default Tooltips
