import {
  getBills,
  getExpenses,
  getMonthTotal,
  getMonthlyExpensesOverview,
  sendReminderNDF,
  updateExpenseStatus
} from 'services/ExpensesService'
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { expenseStatusContant, monthsList, screenSizes } from 'constantes'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { CircularProgress, Dialog, TableBody } from '@mui/material'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import YearFilter from 'components/navBar/yearFilter/YearFilter'
import MyExpensesTicket from 'pages/myExpenses/MyExpensesTicket'
import { PlaceSelectedContext, YearSelectedContext } from 'App'
import useScreenSize from '../../customHooks/useScreenSize'
import { IBill, IExpense } from 'interfaces/expenses'
import fileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import ExpenseRow from './ExpenseRow'
import moment from 'moment'

const Expenses = (): ReactElement => {
  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [month, setMonth] = useState<number>(moment().month())
  const [expenses, setExpenses] = useState<IExpense[]>([])
  const [bills, setBills] = useState<IBill[]>([])
  const [reloadData, setReloadData] = useState<boolean>(false)
  const [expense, setExpense] = useState<IExpense | null>(null)
  const [showTicketList, setShowTicketList] = useState<boolean[]>([])
  const [ticketPosition, setTicketPosition] = useState<number>(0)
  const tableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const response = await getExpenses(month, year, place)
      if (response !== null) {
        setExpenses(response)
        setShowTicketList(new Array(response.length).fill(false))
      }
      const result = await getBills(month, year, place)
      if (result !== null) {
        setBills(result)
      }
      const totalMonth = await getMonthTotal(year, month, place)
      if (totalMonth !== null) {
        setTotal(totalMonth)
      }
    })()
    setLoading(false)
  }, [month, year, place, reloadData])

  const handleDownloadZip = async (): Promise<void> => {
    const response = await getMonthlyExpensesOverview(month, year, place)
    if (response !== null) {
      fileDownload(response.content, response.filename)
    }
    setReloadData(!reloadData)
  }

  const handleChange = (expenseToUpdate: IExpense): void => {
    const updatedExpenses = [...expenses]
    const indexToUpdate = updatedExpenses.findIndex(
      (item: IExpense) => item.id === expenseToUpdate.id
    )

    if (indexToUpdate !== -1) {
      updatedExpenses[indexToUpdate] = expenseToUpdate
    } else {
      updatedExpenses.push(expenseToUpdate)
    }
    setExpenses((expenses) => updatedExpenses)
    setExpense(expenseToUpdate)
  }

  const validateAllRequests = async (): Promise<void> => {
    expenses
      .filter(
        (expense) => expense.month === month && expense.status === expenseStatusContant.A_VALIDER
      )
      .map(async (expense) => {
        setLoading(true)
        let response
        const status = expenseStatusContant.A_REMBOURSER
        if (expense.user?.id !== undefined && expense !== null && expense.id !== undefined) {
          response = await updateExpenseStatus(expense.id, {
            status,
            refusalReason: null
          })
        }
        setLoading(false)
        if (response !== null && response !== undefined && expense !== null) {
          handleChange({
            ...expense,
            status: response.status,
            refusalReason: response.refusalReason
          })
        }
      })
  }

  const handleRowClick = (expense: IExpense, index: number, event: React.MouseEvent): void => {
    const rowElement = event.currentTarget as HTMLElement
    const tableTop = tableRef.current?.getBoundingClientRect().top ?? 0
    const rowTop = rowElement.getBoundingClientRect().top
    const relativePosition = rowTop - tableTop

    setExpense(expense)
    setShowTicketList(showTicketList.map((ticket, value) => (value === index ? !ticket : false)))
    setTicketPosition(Math.max(0, relativePosition))
  }

  return (
    <div className="expenses">
      <div className="expenses-title">
        <h3>
          Vérification des <span className="accentuated-title">notes de frais</span>
        </h3>
        <div className="myexpenses-total">
          Total mensuel : <span>{total} €</span>
        </div>
      </div>
      <div className="expenses-header">
        <div className="expenses-filters">
          <YearFilter navOpen />
          <div className="year-filter">
            <CustomSelect
              value={monthsList[month - 1]}
              options={monthsList}
              handleSelect={(newValue) => {
                setMonth(
                  Number(monthsList.indexOf(typeof newValue === 'string' ? newValue : '')) + 1
                )
              }}
              id="month"
              width={250}
              inputLabel="Mois"
              required
            />
          </div>
        </div>
        <div className="flex gap-4">
          <PrimaryButton
            handleClick={() => {
              void (async () => {
                await validateAllRequests()
                toast.success('Toutes les demandes ont bien étaient validées.')
              })()
            }}
            title="Tout valider"
            background
            reverse={true}
            disabled={false}
          />
          <PrimaryButton
            handleClick={() => {
              void (async () => {
                await sendReminderNDF(year, month, place)
                toast.success('Les mails de relance ont bien été envoyés.')
              })()
            }}
            title="Relancer les collaborateurs"
            background
            reverse={false}
            disabled={false}
          />
        </div>
        {expenses.length !== 0 && (
          <PrimaryButton
            handleClick={() => {
              void (async () => {
                await handleDownloadZip()
              })()
            }}
            title="Générer le fichier .zip"
            background
            reverse={false}
            disabled={false}
          />
        )}
      </div>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="myexpenses-body">
          <div className="myexpenses-table" ref={tableRef}>
            <TableBody>
              {expenses
                .filter((expense) => expense.month === month)
                .map((expense, index) => (
                  <ExpenseRow
                    key={index}
                    showTicket={showTicketList[expenses.findIndex((row) => row.id === expense.id)]}
                    expense={expense}
                    isAdmin
                    handleRowClick={(e) => {
                      handleRowClick(expense, index, e)
                    }}
                  />
                ))}
            </TableBody>
          </div>
          {!isSmallScreen &&
          expense !== null &&
          showTicketList[expenses.findIndex((row) => row.id === expense.id)] ? (
            <div
              className="ticket-container"
              style={{
                position: 'sticky',
                top: 20,
                transform: `translateY(${ticketPosition}px)`,
                transition: 'transform 0.2s ease-out'
              }}
            >
              <MyExpensesTicket
                myBills={bills}
                setMyBills={setBills}
                myExpense={expense}
                setMyExpense={handleChange}
                expensesSubtypes={[]}
                userId={expense.user?.id}
                isAdmin={true}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      <Dialog
        open={
          isSmallScreen &&
          expense !== null &&
          showTicketList[expenses.findIndex((row) => row.id === expense.id)]
        }
        onClose={(): void => {
          if (expense != null) {
            const index = expenses.findIndex((row) => row.id === expense.id)
            setShowTicketList(
              showTicketList.map((ticket, value) => {
                return value === index ? !ticket : false
              })
            )
          }
        }}
        aria-labelledby="save-dialog"
        aria-describedby="save-dialog-description"
        className="popUp popUp-expenses myexpenses-body"
      >
        <MyExpensesTicket
          myBills={bills}
          setMyBills={setBills}
          myExpense={expense}
          setMyExpense={handleChange}
          expensesSubtypes={[]}
          userId={expense?.user?.id}
          isAdmin={true}
          handleClose={(): void => {
            if (expense != null) {
              const index = expenses.findIndex((row) => row.id === expense.id)
              setShowTicketList(
                showTicketList.map((ticket, value) => {
                  return value === index ? !ticket : false
                })
              )
            }
          }}
        />
      </Dialog>
    </div>
  )
}

export default Expenses
