import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { isHolidayOrWeekendDay, isToday } from 'utils/dateUtils'
import React, { ReactElement } from 'react'
import moment from 'moment'

interface IPlanningDaysDisplayer {
  isSmallScreen: boolean
  listDates: string[]
  holidayDates: string[]
  updateInitalDate: () => void
  totalUsersPresentials: string[]
  setSelectedDate: React.Dispatch<React.SetStateAction<string | null>>
  selectedDate: string | null
  hoveredDay: string | null
}

const PlanningDaysDisplayer = ({
  isSmallScreen,
  listDates,
  holidayDates,
  updateInitalDate,
  totalUsersPresentials,
  setSelectedDate,
  selectedDate,
  hoveredDay
}: IPlanningDaysDisplayer): ReactElement => {
  const days = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
  return (
    <div className="row">
      <div className="profil-section">
        <div className="button-today">
          <PrimaryButton
            handleClick={updateInitalDate}
            title={isSmallScreen ? 'Auj' : "Aujourd'hui"}
            background
            reverse
            disabled={false}
          />
        </div>
      </div>
      <div className="w-100 days-displayer">
        {listDates.map((day, index) => {
          const totalUsersPresential = totalUsersPresentials[index]
          return (
            <div
              className={`day-box bold cursor-pointer hover:bg-blue-100 rounded-md ${
                isHolidayOrWeekendDay(day, holidayDates) ? 'light-greyText' : ''
              } ${isToday(day) ? 'today-date' : ''} ${selectedDate === day ? 'bg-blue-200' : ''} ${
                hoveredDay === day ? 'bg-blue-100' : ''
              }`}
              key={index}
              onClick={() => {
                if (selectedDate === day) {
                  setSelectedDate(null)
                } else {
                  setSelectedDate(day)
                }
              }}
            >
              <div className="flex-column day">
                <div>{days[moment(day).day()]}</div>
                <div>{parseInt(moment(day).format('DD'), 10)}</div>
              </div>
              <div className="day-count">{totalUsersPresential}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PlanningDaysDisplayer
