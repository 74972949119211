import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { IUpdateMaterialData } from 'interfaces/materialData'
import { FormControlLabel, Switch } from '@mui/material'
import React, { ReactElement, useContext } from 'react'
import { hasManagerRole } from 'utils/otherUtils'
import { CurrentUserContext } from '../../../App'

interface IMaterialInfoHeader {
  editMode: boolean
  brand: string | null | undefined
  materialDataToSend: IUpdateMaterialData
  setMaterialDataToSend: React.Dispatch<React.SetStateAction<IUpdateMaterialData>>
  model: string
  handleClick: () => void
}

const MaterialInfoHeader = ({
  brand,
  handleClick,
  materialDataToSend,
  setMaterialDataToSend,
  editMode,
  model
}: IMaterialInfoHeader): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  return (
    <div className="profil-header">
      <div className="profil-header__left">
        <div className="profil-header__left__material__infos">
          <h3>
            {brand} {model}
          </h3>
          <FormControlLabel
            control={
              <Switch
                checked={materialDataToSend.isActive}
                onChange={(e): void => {
                  setMaterialDataToSend({ ...materialDataToSend, isActive: e.target.checked })
                }}
                disabled={!editMode}
              />
            }
            label="Actif"
          />
        </div>
      </div>
      <div className="profil-header__right">
        {currentUser?.rolePermissions !== undefined &&
          hasManagerRole(currentUser?.rolePermissions) &&
          !editMode && (
            <PrimaryButton
              handleClick={handleClick}
              title="Modifier"
              background
              reverse={false}
              disabled={false}
            />
          )}
      </div>
    </div>
  )
}

export default MaterialInfoHeader
