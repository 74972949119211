import { getPlaces, getProjectStatus, getProjectTypes } from 'services/SelectorsValuesService'
import CustomDatePicker from 'components/inputs/customDatePicker/CustomDatePicker'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import { Dialog, DialogTitle, IconButton, InputLabel } from '@mui/material'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement, useEffect, useState } from 'react'
import { IListSelectorValue } from 'interfaces/selectors'
import { ISharedPlace } from 'interfaces/shared-place'
import ShareWithDropdown from './ShareWithDropdown'
import { IProject } from 'interfaces/projects'
import { setValidDate } from 'utils/dateUtils'
import { IClient } from 'interfaces/clients'
import { Close } from '@mui/icons-material'
import { Dayjs } from 'dayjs'

interface IProjectPopUp {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  clientToEdit: IClient | undefined
  projectToEdit: IProject | undefined
  setProjectToEdit: React.Dispatch<React.SetStateAction<IProject | undefined>>
  handleSave: (project: IProject) => Promise<void>
}

const ProjectPopUp = ({
  open,
  setOpen,
  clientToEdit,
  projectToEdit,
  setProjectToEdit,
  handleSave
}: IProjectPopUp): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [invoice, setInvoice] = useState<boolean>(false)
  const [project, setProject] = useState<IProject>({})
  const [projectTypes, setProjectTypes] = useState<IListSelectorValue[]>([])
  const [projectStatus, setProjectStatus] = useState<IListSelectorValue[]>([])
  const [locations, setLocations] = useState<IListSelectorValue[]>([])
  const [selectedLocations, setSelectedLocations] = useState<IListSelectorValue[]>([])

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const sharedPlaces = (projectToEdit?.sharedPlaces as ISharedPlace[]) ?? []
      const selectedSharedPlaces = sharedPlaces.map((sp) => ({
        id: sp.place.id,
        name: sp.place.name
      }))

      setProject({
        ...projectToEdit,
        client: clientToEdit,
        status: projectToEdit?.status,
        type: projectToEdit?.type,
        sharedPlaces
      })
      setInvoice(projectToEdit?.invoice !== undefined ? projectToEdit.invoice : false)
      setSelectedLocations(selectedSharedPlaces)
      setProjectTypes(await getProjectTypes())
      setProjectStatus(await getProjectStatus())
      setLocations(await getPlaces())
      setLoading(false)
    })()
  }, [clientToEdit, projectToEdit])

  // Permets d'assigner une valeur par défaut au type et au status du projet
  if ((!project?.type && projectTypes.length) || (!project?.status && projectStatus.length)) {
    setProject((previous) => {
      return {
        ...previous,
        type: project?.type
          ? project?.type
          : { id: projectTypes[0]?.id ?? 1, name: projectTypes[0]?.name ?? '' },
        status: project?.status
          ? project?.status
          : { id: projectStatus[0]?.id ?? 1, name: projectStatus[0]?.name ?? '' },
        startingDate: project?.startingDate ?? new Date()
      }
    })
  }

  const handleChangeDate = (newValue: Dayjs | null, propertyToUpdate: string): void => {
    setProject({
      ...project,
      [propertyToUpdate]: newValue !== null ? setValidDate(newValue) : null
    })
  }

  const handleShareWithChange = (newSelectedLocations: IListSelectorValue[]): void => {
    setSelectedLocations(newSelectedLocations)
    setProject((prev) => ({ ...prev, sharedPlaces: newSelectedLocations }))
  }

  const currentLocationId = clientToEdit?.place?.id

  return (
    <div>
      <Dialog
        open={open}
        onClose={(): void => {
          setOpen(false)
          setProjectToEdit(undefined)
        }}
        aria-labelledby="save-dialog"
        aria-describedby="save-dialog-description"
      >
        <div className="w-[400px]">
          <IconButton
            onClick={() => {
              setOpen(false)
              setProjectToEdit(undefined)
            }}
            id="closeIcon"
          >
            <Close />
          </IconButton>
          {projectToEdit !== undefined ? (
            <DialogTitle id="save-dialog-title" className="text-center">
              Modifier un projet
            </DialogTitle>
          ) : (
            <DialogTitle id="save-dialog-title" className="text-center">
              Ajouter un projet
            </DialogTitle>
          )}
          <div className="px-11 pt-1">
            <div>
              <CustomTextField
                onChange={(value) => {
                  setProject({ ...project, name: value })
                }}
                id="name"
                value={project?.name ?? ''}
                errorMessage={''}
                label="Nom du projet"
                required
              />
            </div>
            <div>
              <CustomTextField
                onChange={(value) => {
                  setProject({ ...project, description: value })
                }}
                id="description"
                value={project?.description ?? ''}
                errorMessage={''}
                label="Description"
                required={false}
                multiline={true}
              />
            </div>
            <div>
              <CustomTextField
                onChange={(value) => {
                  setProject({ ...project, identifier: value })
                }}
                id="identifier"
                value={project?.identifier ?? ''}
                errorMessage={''}
                label="TB"
                required={false}
              />
            </div>
            <div className="pb-2">
              <CustomSelect
                value={project.type?.id ?? 1}
                options={projectTypes}
                handleSelect={(newValue) => {
                  const selectedProjectType = projectTypes.find((type) => type.id === newValue)
                  if (selectedProjectType?.name !== undefined) {
                    setProject({
                      ...project,
                      type: {
                        id: newValue,
                        name: selectedProjectType.name
                      }
                    })
                  }
                }}
                id="projectType"
                inputLabel="Type"
                required={true}
              />
            </div>
            <div className="pb-2">
              <CustomSelect
                value={project.status?.id ?? 1}
                options={projectStatus}
                handleSelect={(newValue) => {
                  const selectedProjectStatus = projectStatus.find(
                    (status) => status.id === newValue
                  )
                  if (selectedProjectStatus?.name !== undefined) {
                    setProject({
                      ...project,
                      status: {
                        id: newValue,
                        name: selectedProjectStatus.name
                      }
                    })
                  }
                }}
                id="projectStatus"
                inputLabel="Statut"
                required={true}
              />
            </div>
            <div className="pt-2">
              <CustomDatePicker
                handleChangeDate={(e, property) => {
                  handleChangeDate(e, property)
                }}
                id="startingDate"
                value={project.startingDate !== undefined ? project.startingDate : new Date()}
                errorMessage=""
                required={true}
                label={'Date de début du projet'}
              />
            </div>
            <div className="pb-2">
              <CustomDatePicker
                handleChangeDate={(e, property) => {
                  handleChangeDate(e, property)
                }}
                id="endingDate"
                value={project.endingDate !== undefined ? project.endingDate : null}
                errorMessage=""
                required={false}
                label={'Date de fin du projet'}
              />
            </div>
            <div className="pb-2">
              {currentLocationId && (
                <ShareWithDropdown
                  locations={locations}
                  selectedLocations={selectedLocations}
                  onChange={handleShareWithChange}
                  currentLocationId={currentLocationId}
                />
              )}
            </div>
            <div className="pb-2 flex space-x-2">
              <input
                className="checkbox"
                type="checkbox"
                onChange={(event) => {
                  const value = event.target.checked
                  setInvoice((prevInvoice) => !prevInvoice)
                  setProject({ ...project, invoice: value })
                }}
                id="invoice"
                name="Facturé"
                checked={invoice}
              />
              <InputLabel id="checkbox" required={false}>
                {'Facturé'}
              </InputLabel>
            </div>
            <div className="buttons-quotas py-8">
              <PrimaryButton
                handleClick={(): void => {
                  setOpen(false)
                }}
                title="Annuler"
                background
                reverse
                disabled={loading}
              />
              <PrimaryButton
                handleClick={() => {
                  void (async () => {
                    await handleSave(project)
                  })()
                }}
                title="Enregistrer"
                background
                reverse={false}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ProjectPopUp
