import { customAxios } from './axiosUtils'
import { IGroup } from 'interfaces/group'
import { toast } from 'react-toastify'

/**
 * Récupération des groupes.
 */
export const getGroups = async (placeId: number | string | null): Promise<IGroup[]> => {
  try {
    const placeParam = placeId !== null ? `?placeId=${placeId}` : ''
    return await customAxios.get(`groups${placeParam}`)
  } catch (error) {
    return []
  }
}

/**
 Permet d'ajouter un groupe
 @param data le body contenant les données du groupe
 **/
export const addGroup = async (data: IGroup): Promise<IGroup[]> => {
  try {
    const group = await customAxios.post(`groups`, data)
    toast.success('Groupe ajouté avec succès')
    return group as unknown as IGroup[]
  } catch (error) {
    return []
  }
}

/**
 Permet de modifier un groupe
 @param data le body contenant les données du groupe
 **/
export const updateGroup = async (data: IGroup): Promise<IGroup[]> => {
  try {
    if (data.id !== undefined) {
      const group = await customAxios.put(`groups/${data.id}`, data)
      toast.success('Groupe modifié avec succès')
      return group as unknown as IGroup[]
    }
    return []
  } catch (error) {
    return []
  }
}

/**
 Permet de supprimer un groupe
 @param data le body contenant les données du groupe
 **/
export const deleteGroup = async (data: IGroup): Promise<IGroup[]> => {
  try {
    if (data.id !== undefined) {
      const group = await customAxios.delete(`groups/${data.id}`)
      toast.success('Groupe supprimé avec succès')
      return group as unknown as IGroup[]
    }
    return []
  } catch (error) {
    return []
  }
}
