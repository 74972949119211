import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import React, { ReactElement, useCallback, useState } from 'react'
import ProfilImage from 'components/profilImage/ProfilImage'
import ClientImage from 'components/clientImage/ClientImage'
import { getCroppedImg } from 'services/canvasUtils'
import CancelIcon from '@mui/icons-material/Cancel'
import { Area, Point } from 'react-easy-crop/types'
import { readFileAsBase64 } from 'utils/otherUtils'
import { IUpdateClient } from 'interfaces/clients'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import { useDropzone } from 'react-dropzone'
import Cropper from 'react-easy-crop'

interface IImageUploader {
  selectedImg: string | null
  setSelectedImg: React.Dispatch<React.SetStateAction<string | null>>
  entity?: IMinimalCollaborateurData | IUpdateClient | null | undefined
  onClick?: (param: string) => void
  croppedImg?: string | null
  setCroppedImg?: React.Dispatch<React.SetStateAction<string | null>>
}

const ImageUploader = ({
  selectedImg,
  setSelectedImg,
  entity,
  onClick,
  croppedImg,
  setCroppedImg
}: IImageUploader): ReactElement => {
  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 1,
    // eslint-disable-next-line
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const base64file = await readFileAsBase64(acceptedFiles[0])
        setSelectedImg(base64file)
        if (onClick !== undefined && onClick !== null) onClick(base64file)
      }
    }
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.name}>
      {errors.map((e) => (
        <p key={e.code}>Seules les images au format png et jpg sont autorisées</p>
      ))}
    </div>
  ))

  const handleCancel = (): void => {
    setSelectedImg(null)
  }

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      if (setCroppedImg !== undefined && setCroppedImg !== null && selectedImg !== null) {
        void (async () => {
          setCroppedImg(await getCroppedImg(selectedImg, croppedAreaPixels))
        })()
      }
    },
    [crop, zoom]
  )

  return (
    <div className="uploader">
      <div {...getRootProps()} className={`dropzone dropzone-img ${isDragActive ? 'drag' : ''}`}>
        <input {...getInputProps()} />
        <div className={`img-zone ${entity?.id !== undefined ? 'overlay' : ''}`}>
          {entity?.id !== undefined ? (
            <>
              {selectedImg !== undefined && selectedImg !== null ? (
                <img src={selectedImg} alt="preview image upload" />
              ) : 'clientPictureFilename' in entity ? (
                <ClientImage client={entity} />
              ) : (
                <ProfilImage user={entity as IMinimalCollaborateurData} />
              )}
              <EditIcon className="icon" />
            </>
          ) : (
            <div className="add-doc">
              {selectedImg !== undefined && selectedImg !== null ? (
                <img src={selectedImg} alt="preview image upload" />
              ) : (
                <AddIcon className="icon" />
              )}
            </div>
          )}
          <Cropper
            image={selectedImg ?? undefined}
            crop={crop}
            zoom={zoom}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
          />
          {selectedImg !== null && (
            <IconButton className="cancel-upload" onClick={handleCancel}>
              <CancelIcon />
            </IconButton>
          )}
        </div>
      </div>
      <ul>{fileRejectionItems}</ul>
    </div>
  )
}

export default ImageUploader
