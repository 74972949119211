import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import { IDetailCollaborateurData, IUpdateUserData } from 'interfaces/collaborateurData'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { useRetrieveUsers } from 'customHooks/retrieveMatchingUsers'
import { IListSelectorValue } from 'interfaces/selectors'
import React, { ReactElement } from 'react'
import { InputLabel } from '@mui/material'

interface IProInfos {
  user: IDetailCollaborateurData | null
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  contractTypes: IListSelectorValue[]
  jobs: IListSelectorValue[]
}

const ProInfos = ({
  user,
  editMode,
  userDataToSend,
  setUserDataToSend,
  contractTypes,
  jobs
}: IProInfos): ReactElement => {
  const [tutors, retrieveTutors] = useRetrieveUsers()

  const handleSelect = (newValue: number | string | null, propertyToUpdate: string): void => {
    if (propertyToUpdate === 'contractType' && newValue === 3) {
      setUserDataToSend({
        ...userDataToSend,
        leavingDate: null,
        [propertyToUpdate]: newValue
      })
    } else {
      setUserDataToSend({
        ...userDataToSend,
        [propertyToUpdate]: newValue
      })
    }
  }

  return (
    <div className="box-data">
      <p className="category-title">Informations professionnelles</p>
      <div className="box-inline-elts">
        <div className="box-column">
          {editMode ? (
            <CustomSelect
              value={userDataToSend.contractType}
              options={contractTypes}
              handleSelect={(newValue) => {
                handleSelect(newValue, 'contractType')
              }}
              id="contractType"
              width={300}
              inputLabel="Contrat"
              required
            />
          ) : (
            <div>
              <p className="data-title">Contrat</p>
              <p className="data-value">{user?.contractType?.name}</p>
            </div>
          )}
        </div>
        <div className="box-column">
          {editMode ? (
            <CustomSelect
              value={userDataToSend.job}
              options={jobs}
              handleSelect={(newValue) => {
                handleSelect(newValue, 'job')
              }}
              id="job"
              width={300}
              inputLabel="Poste"
              required
            />
          ) : (
            <div>
              <p className="data-title">Poste</p>
              <p className="data-value">{user?.job?.name}</p>
            </div>
          )}
        </div>
        <div className="box-column">
          {editMode ? (
            <div className="pb-15">
              <InputLabel className="t-black" id="tutor">
                Tuteur
              </InputLabel>
              <AutoCompleteUserInput
                value={userDataToSend.tutor}
                onChange={(e, newValue): void => {
                  setUserDataToSend({
                    ...userDataToSend,
                    tutor: newValue
                  })
                }}
                id="tutor"
                options={tutors}
                setOptions={(inputValue) => {
                  void (async () => {
                    await retrieveTutors(inputValue)
                  })()
                }}
              />
            </div>
          ) : (
            <div>
              <p className="data-title">Tuteur</p>
              <p className="data-value">
                {user?.tutor !== undefined
                  ? `${user.tutor.displayFirstname}  ${user.tutor.displayLastname}`
                  : ''}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProInfos
