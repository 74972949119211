import { IDayType, IPlanningDate, IUserPlanningDate } from '../../../interfaces/planning'
import PlanningMonthDisplayer from '../planningMonthDisplayer/PlanningMonthDisplayer'
import { IDetailCollaborateurData } from '../../../interfaces/collaborateurData'
import PlanningPopover from '../planningModal/PlanningModal'
import { enumerateDaysBetweenDates } from 'utils/dateUtils'
import { dayDurationList } from '../../../constantes'
import React, { ReactElement, useState } from 'react'
import moment from 'moment'

interface IPlanningYearDisplayer {
  holidayDates: string[]
  currentDate: Date
  currentUserPlanning: IPlanningDate[]
  isSmallScreen: boolean
  isAdmin: boolean
  dayTypes: IDayType[]
  handleChangePlanning: (
    updatedPlanning: IUserPlanningDate,
    firstDateUpdated: Date,
    lastDayUpdated: Date
  ) => void
  user: IDetailCollaborateurData | null
}

const PlanningYearDisplayer = ({
  holidayDates,
  currentDate,
  currentUserPlanning,
  isSmallScreen,
  isAdmin,
  dayTypes,
  handleChangePlanning,
  user
}: IPlanningYearDisplayer): ReactElement => {
  const months = [
    [
      { code: '01', label: 'Janvier' },
      { code: '02', label: 'Février' },
      { code: '03', label: 'Mars' },
      { code: '04', label: 'Avril' }
    ],
    [
      { code: '05', label: 'Mai' },
      { code: '06', label: 'Juin' },
      { code: '07', label: 'Juillet' },
      { code: '08', label: 'Août' }
    ],
    [
      { code: '09', label: 'Septembre' },
      { code: '10', label: 'Octobre' },
      { code: '11', label: 'Novembre' },
      { code: '12', label: 'Décembre' }
    ]
  ]

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [firstDaySelected, setFirstDaySelected] = useState<string>('')
  const open = Boolean(anchorEl)
  /**
   * Récupérer les jours du mois passé en paramètre
   * @param date
   */
  const getDayListByMonth = (date: string): string[] => {
    // Ajoute 1 à la journée pour être certain de récupérer la semaine courant ou mois courant de la date passée
    const year: string = currentDate.getFullYear().toString()
    const validDateToGetInterval = moment(date + ' ' + year, 'MM YYYY').add(1, 'day')
    // Charger la liste des jours du mois
    return enumerateDaysBetweenDates(
      validDateToGetInterval.startOf('month').toDate(),
      validDateToGetInterval.endOf('month').toDate()
    )
  }

  // maj l'état du jour cliqué et ouvre le Popover
  const handleDayClick = (event: React.MouseEvent<HTMLButtonElement>, day: string): void => {
    setFirstDaySelected(day)
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <div className="year-title-displayer">
        <div className="year">
          <p className="light year-title">
            Année <span className="bold">{moment(currentDate).format('YYYY')}</span>
            &nbsp;de&nbsp;
            {user !== null && (
              <span className="accentuated-title">
                {user.displayFirstname} {user.displayLastname}
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="w-100 year-displayer">
        {months.map((monthPart, index) => {
          return (
            <>
              {!isSmallScreen && (
                <div key={index} className="month-part">
                  {monthPart.map((month, index) => {
                    return (
                      <PlanningMonthDisplayer
                        key={index}
                        listDates={getDayListByMonth(month.code)}
                        holidayDates={holidayDates}
                        month={month.label}
                        currentUserPlanning={currentUserPlanning}
                        isAdmin={isAdmin}
                        handleDayClick={handleDayClick}
                        hasRightSpace={index < 3}
                      />
                    )
                  })}
                </div>
              )}
              {isSmallScreen && (
                <>
                  {monthPart.map((month, index) => {
                    return (
                      <PlanningMonthDisplayer
                        key={index}
                        listDates={getDayListByMonth(month.code)}
                        holidayDates={holidayDates}
                        month={month.label}
                        currentUserPlanning={currentUserPlanning}
                        hasRightSpace
                        isAdmin={isAdmin}
                        handleDayClick={handleDayClick}
                      />
                    )
                  })}
                </>
              )}
            </>
          )
        })}
      </div>
      <PlanningPopover
        open={open}
        anchorEl={anchorEl}
        dayTypes={dayTypes}
        setAnchorEl={setAnchorEl}
        dayDurationList={dayDurationList}
        planningUser={user}
        firstDaySelected={moment(firstDaySelected, 'YYYY-MM-DD').toDate()}
        handleChangePlanning={handleChangePlanning}
        isUserRestricted={!isAdmin}
        holidayDates={holidayDates}
        planningDates={currentUserPlanning}
      />
    </div>
  )
}

export default PlanningYearDisplayer
