import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement } from 'react'

interface IListItemsFooter {
  handleCancel: () => void
  handleSave: () => void
}

// Composant Footer utilisé dans les différentes listes de données (Matériels, Collaborateurs)
const ListItemsFooter = ({ handleCancel, handleSave }: IListItemsFooter): ReactElement => {
  return (
    <div className="data-visualization-footer">
      <div className="box-buttons">
        <PrimaryButton
          handleClick={handleSave}
          title="Sauvegarder"
          background
          reverse={false}
          disabled={false}
        />
        <PrimaryButton
          handleClick={handleCancel}
          title="Annuler"
          background
          reverse
          disabled={false}
        />
      </div>
    </div>
  )
}

export default ListItemsFooter
