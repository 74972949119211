import {
  getDayBackgroundAndDayType,
  getDayButtonClass,
  isDayInValidation
} from '../../../utils/planningUtils'
import { IDetailCollaborateurData, IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import { IDayType, IPlanningDate, IUserPlanningDate } from 'interfaces/planning'
import ProfilImage from 'components/profilImage/ProfilImage'
import PlanningPopover from '../planningModal/PlanningModal'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { DayEnum, dayDurationList } from 'constantes'
import React, { ReactElement, useState } from 'react'
import Tooltips from 'components/molecules/Tooltips'
import { isDateBeforeToday } from 'utils/dateUtils'
import StarIcon from '@mui/icons-material/Star'
import './UserPlanningRow.scss'
import moment from 'moment'
import 'moment/locale/fr'

interface IUserPlanningRow {
  user: IDetailCollaborateurData | null
  planningDates: IPlanningDate[]
  listDates: string[]
  isAdmin: boolean
  dayTypes: IDayType[]
  handleChangePlanning: (
    updatedPlanning: IUserPlanningDate,
    firstDateUpdated: Date,
    lastDayUpdated: Date
  ) => void
  isSelfUser: boolean
  holidayDates: string[]
  updateUserFavorite: (
    user: IDetailCollaborateurData | null,
    isNewFavorite: boolean
  ) => Promise<void>
  favorites: IMinimalCollaborateurData[]
  setIdUserYearPlanning: (value: number) => void
  onDayHover: (day: string | null) => void
}

const UserPlanningRow = ({
  user,
  planningDates,
  listDates,
  isAdmin,
  dayTypes,
  handleChangePlanning,
  holidayDates,
  isSelfUser,
  updateUserFavorite,
  favorites,
  setIdUserYearPlanning,
  onDayHover
}: IUserPlanningRow): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [firstDaySelected, setFirstDaySelected] = useState<string>('')
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [pendingDayClick, setPendingDayClick] = useState<{
    event: React.MouseEvent<HTMLButtonElement>
    day: string
  } | null>(null)
  const open = Boolean(anchorEl)

  const defaultDayClass = ['day-box']

  const handleDayMouseEnter = (day: string): void => {
    onDayHover(day)
  }

  const handleDayMouseLeave = (): void => {
    onDayHover(null)
  }

  /**
   * Gère le click sur un jour
   *
   * @param event
   * @param day
   * @returns
   */
  const handleDayClick = (event: React.MouseEvent<HTMLButtonElement>, day: string): void => {
    const date = moment(day)
    const isWeekend = date.day() === DayEnum.Saturday || date.day() === DayEnum.Sunday
    const isHoliday = holidayDates.includes(day)

    if (isWeekend || isHoliday) {
      setPendingDayClick({ event, day })
      setShowConfirmation(true)
      return
    }

    executeDayClick(event, day)
  }

  /**
   * Execute le click sur un jour
   *
   * @param event
   * @param day
   */
  const executeDayClick = (event: React.MouseEvent<HTMLButtonElement>, day: string): void => {
    setFirstDaySelected(day)
    setAnchorEl(event.currentTarget)
  }

  /**
   * Gère le click sur un jour confirmé
   *
   * @returns
   */
  const handleConfirmedDayClick = async (): Promise<void> => {
    if (!pendingDayClick) return

    executeDayClick(pendingDayClick.event, pendingDayClick.day)
    setShowConfirmation(false)
    setPendingDayClick(null)
  }

  const isFavoriteUser = (): boolean => {
    if (user?.id === undefined) return false
    return favorites.map((favoriteUser) => favoriteUser.id).includes(user.id)
  }

  const getPlanningDateFromDay = (day: string): IPlanningDate | undefined => {
    return planningDates.find((planningDate) => planningDate.dayDate === day)
  }

  return (
    <div className="row">
      <div className="profil-section">
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {isFavoriteUser() || isSelfUser ? (
          <StarIcon
            className={isSelfUser ? 'no-star-cursor' : ''}
            id="favorite-icon-active"
            onClick={() => {
              if (!isSelfUser) {
                void updateUserFavorite(user, false)
              }
            }}
          />
        ) : (
          <StarBorderIcon
            id="favorite-icon-inactive"
            onClick={() => {
              void updateUserFavorite(user, true)
            }}
          />
        )}

        <ProfilImage user={user}></ProfilImage>

        <div className="text-profile">
          <p
            className="m0 mt-1"
            style={isAdmin ? { cursor: 'pointer' } : { cursor: 'selection' }}
            onClick={
              isAdmin && user !== null
                ? () => {
                    setIdUserYearPlanning(user.id)
                  }
                : () => {}
            }
          >
            {user?.displayFirstname} {user?.displayLastname[0]}
          </p>
        </div>
      </div>

      <div className="w-100 days-displayer">
        {listDates.map((day, index) => {
          const { background, dayType } = getDayBackgroundAndDayType(day, planningDates)
          const isEditable: boolean = isAdmin || (isSelfUser && !isDateBeforeToday(day))
          const planningDate = getPlanningDateFromDay(day)
          return (
            <Tooltips
              key={index}
              text={planningDate?.holydayType ?? ''}
              disabled={!(dayType === 'A' && isAdmin)}
            >
              <div
                className={getDayButtonClass(
                  day,
                  holidayDates,
                  isEditable,
                  planningDates,
                  defaultDayClass
                )}
                key={index}
                style={{ background }}
                onMouseEnter={() => {
                  handleDayMouseEnter(day)
                }}
                onMouseLeave={() => {
                  handleDayMouseLeave()
                }}
              >
                <button
                  className="day"
                  key={index}
                  onClick={(e) => {
                    isEditable && handleDayClick(e, day)
                  }}
                >
                  {dayType}
                </button>
                {isDayInValidation(day, planningDates) && (
                  <span className="validation-icon">
                    <WatchLaterIcon />
                  </span>
                )}
              </div>
            </Tooltips>
          )
        })}
      </div>
      <PlanningPopover
        open={open}
        anchorEl={anchorEl}
        dayTypes={dayTypes}
        setAnchorEl={setAnchorEl}
        dayDurationList={dayDurationList}
        planningUser={user}
        firstDaySelected={moment(firstDaySelected, 'YYYY-MM-DD').toDate()}
        handleChangePlanning={handleChangePlanning}
        isUserRestricted={!isAdmin}
        holidayDates={holidayDates}
        planningDates={planningDates}
      />
      <ConfirmationPopUp
        open={showConfirmation}
        setOpen={setShowConfirmation}
        dialogTitle="Confirmation requise"
        dialogContentText={
          pendingDayClick
            ? holidayDates.includes(pendingDayClick.day)
              ? 'Vous souhaitez pointer un jour férié. Êtes-vous sûr(e) de vouloir continuer ?'
              : `Vous souhaitez pointer un ${moment(pendingDayClick.day)
                  .locale('fr')
                  .format('dddd')}. Êtes-vous sûr(e) de vouloir continuer ?`
            : ''
        }
        handleSave={handleConfirmedDayClick}
        loading={false}
      />
    </div>
  )
}

export default UserPlanningRow
