import { useEffect, useState } from 'react'

const useScreenSize = (breakpoint: number): boolean => {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= breakpoint)

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth <= breakpoint)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoint])

  return isSmallScreen
}

export default useScreenSize
