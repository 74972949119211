import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { CircularProgress, IconButton } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { UploadedFile } from 'interfaces/uploadedFile'
import { Edit, PostAdd } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'
import React, { ReactElement } from 'react'

interface IDocumentUploader {
  accept?: Record<string, string[]>
  selectedFile: UploadedFile | null
  uploadFile: (file: UploadedFile | null) => void
  minimalDesign: boolean
  edit: boolean
  loading?: boolean | undefined
  title?: string
}

const DocumentUploader = ({
  accept = {
    'application/pdf': [],
    'image/png': [],
    'image/jpeg': []
  },
  selectedFile,
  uploadFile,
  minimalDesign,
  edit,
  loading,
  title = 'Importer votre justificatif'
}: IDocumentUploader): ReactElement => {
  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles: 1,
    // eslint-disable-next-line
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        const blobFile = new Blob([file], { type: file.type })
        uploadFile({ content: blobFile, filename: file.name })
      }
    }
  })

  const acceptedFormat = Object.keys(accept)
    .join(', ')
    .replaceAll('application/', '')
    .replaceAll('image/', '')

  const fileRejectionItems = fileRejections.map(
    ({ file, errors }): ReactElement => (
      <div key={file.name}>
        {errors.map((e) => (
          <p key={e.code}>Seuls les fichiers {acceptedFormat} sont autorisés</p>
        ))}
      </div>
    )
  )

  return (
    <div className={`uploader ${minimalDesign ? 'minimal-design-flex' : ''}`}>
      <div
        {...getRootProps()}
        className={`${
          !minimalDesign
            ? isDragActive
              ? 'dropzone dropzone-pdf drag'
              : 'dropzone dropzone-pdf'
            : ''
        }`}
      >
        <input {...getInputProps()} />
        <div className="pdf-zone">
          {selectedFile !== null ? (
            !minimalDesign && (
              <div className="upload-box">
                {loading && (
                  <div className="loader-small">
                    <CircularProgress />
                  </div>
                )}
                <PictureAsPdfIcon id="pdf-icon" />
                <p>{selectedFile.filename}</p>
              </div>
            )
          ) : !minimalDesign ? (
            <div className="upload-box">
              <FileUploadIcon />
              <p>
                {title} ({acceptedFormat})
              </p>
            </div>
          ) : edit ? (
            <IconButton>
              <Edit id="edit-pdf-icon" />
            </IconButton>
          ) : (
            <PostAdd id="add-pdf-icon" />
          )}
        </div>
      </div>
      <ul>{fileRejectionItems}</ul>
    </div>
  )
}

export default DocumentUploader
