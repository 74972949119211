import {
  ICollabsWithPagination,
  IHolidayRequestsQuotaWithPagination,
  IMaterialWithPagination
} from 'interfaces/dataWithPagination'
import { customAxios } from './axiosUtils'
import { ROWS_PER_PAGE } from 'constantes'
import { isArray } from 'lodash'
/**
Récupérer les users et le nb de pages nécessaires pour afficher les users selon les paramètres
 * @param {number} currentPage - La page courante.
 * @param {number} limit - Le nombre de résultats à afficher.
 * @param {number|null} placeId - L'ID du lieu (facultatif).
 * @param {number[]|number|string|null} contractTypeId - L'ID du type de contrat (facultatif).
 * @param {string|null} search - La chaîne de recherche (facultatif).
 * @param {string|null} orderBy - Le critère de tri (facultatif).
 * @param {string|null} sortDirection - L'ordre de tri (facultatif).
 * @param {string[]} selectedServices - Les services sélectionnés.
 * @param {string|null|number} status - Le statut des services
 * @returns {Promise<ICollabsWithPagination|null>} - Le nombre de pages + les infos des users trouvés
 */
export const getUsersWithPagination = async (
  currentPage: number,
  limit: number,
  placeId: number | string | null,
  contractTypeId: number[] | string | number | null,
  search: string | null,
  orderBy: string | null,
  sortDirection: string | null,
  selectedServices: string[],
  status: number | string | null,
  year: number
): Promise<ICollabsWithPagination | null> => {
  try {
    const placeParam = placeId !== null ? `&placeId=${placeId}` : ''
    let contractTypeParam = ''
    if (contractTypeId !== null) {
      if (isArray(contractTypeId)) {
        contractTypeId.forEach((contract) => {
          contractTypeParam += `&contractTypeIdList[]=${contract}`
        })
      } else {
        contractTypeParam = contractTypeId !== null ? `&contractTypeIdList[]=${contractTypeId}` : ''
      }
    }
    const searchParam = search !== null && search !== '' ? `&search=${search}` : ''
    let selectedServicesParam = ''
    selectedServices.forEach((service) => {
      selectedServicesParam += `&services[]=${service}`
    })
    const statusParam = status ? `&status=${status}` : ''
    const orderByParam = orderBy !== null && orderBy !== 'active' ? `&orderBy=${orderBy}` : ''
    const sortDirectionParam = sortDirection !== null ? `&sortDirection=${sortDirection}` : ''

    const url = `users?limit=${limit}&year=${year}&currentPage=${currentPage}${placeParam}${contractTypeParam}${searchParam}${orderByParam}${sortDirectionParam}${selectedServicesParam}${statusParam}`
    return await customAxios.get(url)
  } catch (error) {
    return null
  }
}

/**
Récupérer les users et le nb de pages nécessaires pour afficher les users selon les paramètres
 * @param {number} currentPage - La page courante.
 * @param {number} limit - Le nombre de résultats à afficher.
 * @param {string|null} search - La chaîne de recherche (facultatif).
 * @param {string} orderBy - Le critère de tri (facultatif).
 * @param {string} sortDirection - L'ordre de tri (facultatif).
 * @param {string} os - L'os utilisé sur l'ordinateur'
 * @param {number|null} placeId - L'ID du lieu (facultatif).
 * @param {number|null} userAssignmentId - l'utilisateur à qui a été assigné le matériel
 * @param {boolean|null} isActive - le statut du matériel
 * @param {boolean|null} isAssigned - si le matériel est assigné à quelqu'un ou non
 * @param {string|null} materialType - le type de matériel
 * @returns {Promise<IMaterialWithPagination|null>} - Le nombre de pages + les infos des matériels trouvés
 */
export const getMaterialsWithPagination = async (
  currentPage: number = 1,
  limit: number = ROWS_PER_PAGE,
  orderBy: string = 'updatedAt',
  sortDirection: string = 'desc',
  search: string | null = '',
  os: string = '',
  placeId: number | string | null = null,
  materialType: string | null = null,
  isActive?: number | null,
  isAssigned?: number | null,
  userAssignmentId?: number | null
): Promise<IMaterialWithPagination | null> => {
  try {
    const OSParam = os !== '' ? `&os=${os}` : ''
    const searchParam = search !== null && search !== '' ? `&search=${search}` : ''
    const userAssignmentIdParam =
      userAssignmentId !== undefined && userAssignmentId !== null
        ? `&userAssignmentId=${userAssignmentId}`
        : ''
    const orderByParam = orderBy !== null && orderBy !== 'active' ? `&orderBy=${orderBy}` : ''
    const sortDirectionParam = sortDirection !== null ? `&sortDirection=${sortDirection}` : ''

    const isActiveParam = isActive !== null && isActive !== undefined ? `&isActive=${isActive}` : ''
    const isAssignedParam =
      isAssigned !== null && isAssigned !== undefined ? `&isAssigned=${isAssigned}` : ''

    const materialTypeParam = materialType !== null ? `&materialType=${materialType}` : ''
    const placeParam = placeId !== null ? `&placeId=${placeId}` : ''

    const url = `materials?limit=${limit}&currentPage=${currentPage}${searchParam}${orderByParam}${sortDirectionParam}${placeParam}${OSParam}${userAssignmentIdParam}${isActiveParam}${isAssignedParam}${materialTypeParam}`
    return await customAxios.get(url)
  } catch (error) {
    return null
  }
}
/**
 Récupérer les users holydays et le nb de pages nécessaires pour afficher les users selon les paramètres
 * @param {number} currentPage - La page courante.
 * @param {number} limit - Le nombre de résultats à afficher.
 * @param {number|null} placeId - L'ID du lieu (facultatif).
 * @param {string|null} search - La chaîne de recherche (facultatif).
 * @param {string|null|number} status - Le statut des services
 * @param year
 * @returns {Promise<ICollabsWithPagination|null>} - Le nombre de pages + les infos des users trouvés
 */
export const getUsersHolydaysWithPagination = async (
  currentPage: number,
  limit: number,
  placeId: number | string | null,
  search: string | null,
  status: number | string | null,
  year: number,
  month?: number | null
): Promise<IHolidayRequestsQuotaWithPagination | null> => {
  try {
    const placeParam = placeId !== null ? `&placeId=${placeId}` : ''
    const contractTypeParam = ''
    const searchParam = search !== null && search !== '' ? `&search=${search}` : ''
    const selectedServicesParam = ''
    const statusParam = status ? `&status=${status}` : ''
    const monthParam = month !== null && month !== undefined ? `&month=${month}` : ''
    const orderByParam = '&orderBy=lastname'
    const sortDirectionParam = '&sortDirection=asc'

    const url = `users-holidays?limit=${limit}&year=${year}&currentPage=${currentPage}${placeParam}${contractTypeParam}${searchParam}${orderByParam}${sortDirectionParam}${selectedServicesParam}${statusParam}${monthParam}`
    return await customAxios.get(url)
  } catch (error) {
    return null
  }
}
