import React, { ReactElement, useState } from 'react'
import { IDayType } from 'interfaces/planning'

interface IPlanningLegend {
  dayTypes: IDayType[]
  dayTypesFilter: number[]
  setDayTypesFilter: React.Dispatch<React.SetStateAction<number[]>>
  yearView: boolean
}

const PlanningLegend = ({
  dayTypes,
  dayTypesFilter,
  setDayTypesFilter,
  yearView
}: IPlanningLegend): ReactElement => {
  const [activeButtons, setActiveButtons] = useState<boolean[]>(Array(dayTypes.length).fill(false))
  const handleLegendClick = (index: number, dayType: IDayType): void => {
    // active ou désactive le bon bouton + ajoute ou retire le type de journée aux filtres

    const newActiveButtons = activeButtons
    const isActive = !newActiveButtons[index]
    newActiveButtons[index] = isActive
    setActiveButtons(newActiveButtons)

    if (isActive) {
      setDayTypesFilter([...dayTypesFilter, dayType.id])
    } else {
      const dayTypesFiltered = dayTypesFilter.filter((dayTypeFilter) => {
        return dayTypeFilter !== dayType.id
      })
      setDayTypesFilter(dayTypesFiltered)
    }
  }

  return (
    <div className="color-guide legend-box-mobile">
      {dayTypes.map((dayType, index) => (
        <button
          key={index}
          className={`color ${!yearView ? (activeButtons[index] ? 'active' : '') : 'not-btn'}`}
          onClick={() => {
            if (!yearView) {
              handleLegendClick(index, dayType)
            }
          }}
        >
          <div className="legend" style={{ background: dayType.colorCode }}></div>
          <p>{dayType.label}</p>
          {yearView && dayType.totalDayType !== undefined ? (
            <p className="legend-count" style={{ background: dayType.colorCode }}>
              {dayType.totalDayType}
            </p>
          ) : (
            <></>
          )}
        </button>
      ))}
    </div>
  )
}

export default PlanningLegend
