import { IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import { IListSelectorValue } from 'interfaces/selectors'
import ClearIcon from '@mui/icons-material/Clear'
import React, { ReactElement } from 'react'

interface ICustomSelect {
  handleSelect: (value: any) => void
  options: IListSelectorValue[] | string[]
  id: string
  value: number | string | null | number[]
  inputLabel: string | boolean
  width?: number | null
  required?: boolean
  showDelete?: boolean
  className?: string
  multiple?: boolean
  placeholder?: string
  useValue?: boolean
}

// Sélecteur qui permet d'afficher le texte de l'objet pour chaque option mais qui l'id de l'objet comme valeur pour être ensuite envoyé au back
const CustomSelect = ({
  handleSelect,
  options,
  id,
  value,
  inputLabel,
  width = null,
  required = false,
  showDelete = true,
  className,
  multiple = false,
  placeholder,
  useValue = false
}: ICustomSelect): ReactElement => {
  const renderCustomValue = (selected: any): ReactElement => {
    /**
     * Fonction utilisée pour personnaliser l'affichage de la valeur sélectionnée dans le composant CustomSelect.
     * Si les options sont des objets avec une propriété "name", alors la fonction va chercher le "name" correspondant à l'id de l'option sélectionnée.
     * Elle ajoute également un bouton "Clear" si le champ n'est pas requis et qu'une option est sélectionnée.
     * @param selected - La valeur sélectionnée dans le CustomSelect
     * @returns Un élément React contenant l'affichage personnalisé de la valeur sélectionnée.
     */
    if ((selected === '' || selected === null) && placeholder) {
      return <span className="select-placeholder">{placeholder}</span>
    }

    if (typeof options[0] !== 'string') {
      if (Array.isArray(selected)) {
        selected = selected
          .map((item) => {
            const customSelected = (options as IListSelectorValue[]).find((option) =>
              useValue ? option.value === item : option.id === item
            )
            const selectedValue =
              customSelected?.label !== undefined ? customSelected.label : customSelected?.name

            return selectedValue
          })
          .join(', ')
      } else {
        const customSelected = (options as IListSelectorValue[]).find((option) =>
          useValue ? option.value === selected : option.id === selected
        )
        const selectedValue =
          customSelected?.label !== undefined ? customSelected.label : customSelected?.name

        if (selectedValue !== undefined) {
          selected = selectedValue
        }
      }
    }
    return (
      <div className="customSelectClear">
        {selected}
        {!required && showDelete && selected !== undefined && (
          <IconButton
            size="small"
            onClick={() => {
              handleSelect(null)
            }}
            onMouseDown={(e) => {
              e.stopPropagation()
            }}
          >
            <ClearIcon />
          </IconButton>
        )}
      </div>
    )
  }

  return (
    <div className={`${className ?? 'select'}`}>
      <InputLabel required={typeof inputLabel === 'boolean' ? inputLabel : required}>
        {typeof inputLabel === 'string' ? inputLabel : ''}
      </InputLabel>
      <Select
        sx={{ width: { width } }}
        value={value !== null ? value : ''}
        id={id}
        onChange={(event) => {
          handleSelect(event.target.value)
        }}
        renderValue={(selected) => renderCustomValue(selected)}
        multiple={multiple}
        displayEmpty={!!placeholder}
      >
        {placeholder && !value && (
          <MenuItem value="" disabled>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options?.map((option, index) =>
          typeof option === 'string' ? (
            <MenuItem value={option} key={index + 1}>
              {option}
            </MenuItem>
          ) : (
            <MenuItem value={useValue ? option.value : option.id} key={index + 1}>
              {option.label !== undefined ? option.label : option.name}
            </MenuItem>
          )
        )}
      </Select>
    </div>
  )
}

export default CustomSelect
