import PlanningFilterModal from '../planningFilterModal/PlanningFilterModal'
import { planningViewEnum, planningViewList } from '../../../constantes'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { getContractTypes } from 'services/SelectorsValuesService'
import SwitchButton from 'components/inputs/button/SwitchButton'
import React, { ReactElement, useEffect, useState } from 'react'
import SearchBox from 'components/inputs/searchBox/SearchBox'
import PlanningLegend from '../planningLegend/PlanningLegend'
import { IListSelectorValue } from 'interfaces/selectors'
import { IDayType } from 'interfaces/planning'

interface IPlanningHeader {
  dayTypes: IDayType[]
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  isSmallScreen: boolean
  setContractSearch: React.Dispatch<React.SetStateAction<number | null>>
  dayTypesFilter: number[]
  setDayTypesFilter: React.Dispatch<React.SetStateAction<number[]>>
  planningViewSelected: string
  setPlanningViewSelected: (value: string) => Promise<void>
}

const PlanningHeader = ({
  dayTypes,
  setSearchTerm,
  isSmallScreen,
  setContractSearch,
  dayTypesFilter,
  setDayTypesFilter,
  planningViewSelected,
  setPlanningViewSelected
}: IPlanningHeader): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const openInfoLegend = Boolean(anchorEl)
  const [contract, setContract] = useState<string | number | null>('')
  const [contractTypes, setContractTypes] = useState<IListSelectorValue[]>([])

  // retour la légende pour le type de journée 'Vide'
  const dayTypesWithoutVide = dayTypes.filter((dayType) => {
    return dayType.label !== 'Vide'
  })

  const switchView = (): void => {
    void setPlanningViewSelected(
      planningViewSelected === planningViewEnum.GROUP
        ? planningViewEnum.MONTH
        : planningViewEnum.GROUP
    )
  }

  const handleSelect = (value: string): void => {
    void setPlanningViewSelected(value)
    setSearchTerm('')
  }

  useEffect(() => {
    void (async () => {
      setContractTypes(await getContractTypes())
    })()
  }, [])

  return (
    <div className="header-planning">
      <div className="data">
        <h3>
          Planning des<span className="accentuated-title"> collaborateurs </span>
        </h3>
      </div>
      <div className="sub-header-planning pt-2">
        <div className="filters">
          {planningViewSelected === planningViewEnum.GROUP ? (
            <SearchBox setSearchTerm={setSearchTerm} placeholder="Rechercher un groupe" />
          ) : (
            <SearchBox setSearchTerm={setSearchTerm} placeholder="Rechercher un collaborateur" />
          )}
          <CustomSelect
            handleSelect={(newValue) => {
              setContract(newValue)
              if (typeof newValue !== 'string') {
                setContractSearch(newValue)
              }
            }}
            options={contractTypes}
            id="contract"
            value={contract}
            inputLabel="Liste des contrats"
            width={230}
          />
          <SwitchButton title="Vue par groupe" onChange={switchView} />
        </div>
        <div
          className={`year-planning ${
            planningViewSelected === planningViewEnum.MONTH ? 'month-view' : 'year-view'
          }`}
        >
          <>
            {isSmallScreen ? (
              <>
                <PlanningFilterModal
                  open={openInfoLegend}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  dayTypes={dayTypes}
                  dayTypesFilter={dayTypesFilter}
                  setDayTypesFilter={setDayTypesFilter}
                  yearView={planningViewSelected === planningViewEnum.YEAR}
                />
              </>
            ) : (
              <PlanningLegend
                dayTypes={dayTypesWithoutVide}
                dayTypesFilter={dayTypesFilter}
                setDayTypesFilter={setDayTypesFilter}
                yearView={planningViewSelected === planningViewEnum.YEAR}
              />
            )}
          </>
          <CustomSelect
            handleSelect={(value) => {
              handleSelect(value)
            }}
            options={planningViewList}
            className="display-select"
            id="planningView"
            value={
              planningViewSelected === planningViewEnum.GROUP
                ? planningViewEnum.MONTH
                : planningViewSelected
            }
            inputLabel=""
            width={isSmallScreen ? 100 : 225}
            showDelete={false}
          />
        </div>
      </div>
    </div>
  )
}

export default PlanningHeader
