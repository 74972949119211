import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import { IUpdateUserData } from 'interfaces/collaborateurData'
import React, { ReactElement, useState } from 'react'

interface IBankingInfos {
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
}

const validateIBAN = (iban: string): boolean => {
  const ibanRegex =
    /^(?:FR[0-9]{2}(?:[ -]?[0-9A-Z]{4}){5}[ -]?[0-9A-Z]{3}|(?!FR)([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?)$/
  return iban === '' || ibanRegex.test(iban)
}

const BankingInfos = ({
  editMode,
  userDataToSend,
  setUserDataToSend
}: IBankingInfos): ReactElement => {
  const [ibanError, setIbanError] = useState<string>('')

  const handleIBANChange = (value: string): void => {
    setUserDataToSend({ ...userDataToSend, IBAN: value })
    setIbanError(validateIBAN(value) ? '' : 'Format IBAN invalide')
  }
  return (
    <div className="box-data">
      <div className="category-title">Informations bancaires</div>
      <div className="box-inline-elts">
        <div className="bank-details">
          <div className="box-column">
            <div>
              {editMode ? (
                <CustomTextField
                  onChange={(value) => {
                    setUserDataToSend({ ...userDataToSend, initials: value })
                  }}
                  id="initials"
                  value={userDataToSend.initials}
                  errorMessage=""
                  required={false}
                  label="Initiales"
                />
              ) : (
                <>
                  <p className="data-title">Initiales</p>
                  <p className="data-value">{userDataToSend.initials}</p>
                </>
              )}
            </div>
          </div>
          <div className="box-column">
            <div>
              {editMode ? (
                <CustomTextField
                  onChange={(value) => {
                    setUserDataToSend({ ...userDataToSend, BIC: value })
                  }}
                  id="BIC"
                  value={userDataToSend.BIC}
                  errorMessage={undefined}
                  required={false}
                  label="BIC"
                />
              ) : (
                <>
                  <p className="data-title">BIC</p>
                  <p className="data-value">{userDataToSend.BIC}</p>
                </>
              )}
            </div>
          </div>
          <div className="box-column">
            <div>
              {editMode ? (
                <CustomTextField
                  onChange={handleIBANChange}
                  id="IBAN"
                  value={userDataToSend.IBAN}
                  errorMessage={ibanError}
                  required={false}
                  label="IBAN"
                />
              ) : (
                <>
                  <p className="data-title">IBAN</p>
                  <p className="data-value">{userDataToSend.IBAN}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankingInfos
