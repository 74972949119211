import { IHolidayQuota } from 'interfaces/holidays/holidayQuota'
import React, { ReactElement, useEffect, useState } from 'react'
import CustomSelect from './inputs/customSelect/CustomSelect'
import { IListSelectorValue } from 'interfaces/selectors'
import { getHolidays } from 'services/HolidayService'
import { holidayTypesEnum } from 'constantes'

interface IHolidaysManager {
  setHolidayQuota: React.Dispatch<React.SetStateAction<number | null>>
  updateHolidaySelected: (holidaySelected: IHolidayQuota) => void
  showPaidVacation: boolean
}

const HolidaysManager = ({
  setHolidayQuota,
  showPaidVacation,
  updateHolidaySelected
}: IHolidaysManager): ReactElement => {
  const [otherHolidays, setOtherHolidays] = useState<IHolidayQuota[]>([])
  const [holidaySelected, setHolidaySelected] = useState<IHolidayQuota>({
    id: 16,
    holidayType: holidayTypesEnum.CONGE_PAYE
  })

  // Récupère les congés en retirant ou non congé payé selon le besoin + initialise le congé sélectionné
  useEffect(() => {
    void (async () => {
      let holidays = await getHolidays()
      let initialHolidaySelected = null
      if (!showPaidVacation) {
        holidays = holidays.filter((holiday) => holiday.holidayType !== holidayTypesEnum.CONGE_PAYE)
        initialHolidaySelected = holidays.find(
          (holiday) => holiday.holidayType === holidayTypesEnum.CONGE_MALADIE
        )
      } else {
        initialHolidaySelected = holidays.find(
          (holiday) => holiday.holidayType === holidayTypesEnum.CONGE_PAYE
        )
      }
      setOtherHolidays(holidays)
      setHolidaySelected(initialHolidaySelected ?? { id: 1 })
      updateHolidaySelected(initialHolidaySelected ?? { id: 1 })
    })()
  }, [])

  const handleOtherHolidaySelect = (selectedItem: string | number | null): void => {
    const otherHolidaySelected = otherHolidays.find((holiday) => holiday.id === selectedItem)

    const newQuota = otherHolidaySelected?.quota ?? null
    setHolidayQuota(newQuota)
    if (otherHolidaySelected !== undefined) {
      setHolidaySelected(otherHolidaySelected)
      updateHolidaySelected(otherHolidaySelected)
    }
  }

  // Permet de transformer IHolidayQuota[] en IListSelectorValue[] pour s'en servir d'un un custom select
  const IHolidaysQuotaToIListSelectorValues = (): IListSelectorValue[] => {
    const holidayFormated = otherHolidays.map((holiday) => {
      return {
        id: holiday.id,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        label: `${holiday.holidayType} ${holiday.quota !== undefined ? `- ${holiday.quota}J` : ''}`
      } satisfies IListSelectorValue
    })

    return holidayFormated
  }
  return (
    <CustomSelect
      handleSelect={(newValue) => {
        handleOtherHolidaySelect(newValue)
      }}
      options={IHolidaysQuotaToIListSelectorValues()}
      id="holidayType"
      value={holidaySelected.holidayType !== undefined ? holidaySelected.holidayType : ''}
      inputLabel="Type de congés"
      required
    />
  )
}

export default HolidaysManager
