import { IUpdateUserData } from 'interfaces/collaborateurData'
import React, { ReactElement, useState } from 'react'

interface ICheckBox {
  title: string
  icon: string
  id: string
  disabled: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  propertyToUpdate: string
}

const CheckBox = ({
  title,
  icon,
  id,
  disabled,
  userDataToSend,
  setUserDataToSend,
  propertyToUpdate
}: ICheckBox): ReactElement => {
  const [check, setCheck] = useState<boolean>(userDataToSend[propertyToUpdate])
  const handleCheck = (): void => {
    setUserDataToSend({ ...userDataToSend, [propertyToUpdate]: !check })
    setCheck(!check)
  }

  return (
    <div className="checkBox-container">
      <div className="check-icon">
        <input
          className="checkbox"
          type="checkbox"
          disabled={disabled}
          onChange={handleCheck}
          id={id}
          name={id}
          checked={check}
        />
        <img src={icon} alt={`Icon ${title}`} />
      </div>
      <p>{title}</p>
    </div>
  )
}

export default CheckBox
