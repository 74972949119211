import { hasAccountantRole, hasRHRole, hasSuperAdminRole } from 'utils/otherUtils'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { isHoliday } from '../../services/ReportingService'
import { Block, Delete, Save } from '@mui/icons-material'
import { IReporting } from 'interfaces/reporting'
import React from 'react'

interface ActionCellProps {
  item: IReporting
  handleSaveLine: (item: IReporting) => void
  handleDelete: (id: number) => void
  modifiedReportings: IReporting[]
  connectedUser: IMinimalCollaborateurData | null
}

/**
 * Vérifie si l'utilisateur peut modifier les congés
 *
 * @param user
 * @returns
 */
const canModifyHolidays = (user: IMinimalCollaborateurData | null): boolean => {
  if (user?.rolePermissions !== undefined) {
    const isHR = hasRHRole(user.rolePermissions)
    const isAdmin = hasSuperAdminRole(user.rolePermissions)
    const isAccountant = hasAccountantRole(user.rolePermissions)
    return isHR || isAdmin || isAccountant
  }
  return false
}

/* Choix du bouton sauvegarder ou supprimer selon l'état de la ligne */
const ReportingActionCell = ({
  item,
  handleSaveLine,
  handleDelete,
  modifiedReportings,
  connectedUser
}: ActionCellProps): JSX.Element => {
  return (
    <td className="border border-gray-200 bg-white text-xs text-center">
      {modifiedReportings.some((modifiedItem) => modifiedItem.id === item.id) ? (
        <button
          onClick={(): void => {
            handleSaveLine(item)
          }}
        >
          <Save fontSize="small" style={{ color: 'green' }} />
        </button>
      ) : isHoliday(item.project) ? (
        <button
          onClick={(): void => {
            handleDelete(item.id)
          }}
        >
          {canModifyHolidays(connectedUser) ? (
            <Delete fontSize="small" style={{ color: 'gray' }} />
          ) : (
            <Block fontSize="small" style={{ color: 'gray' }} />
          )}
        </button>
      ) : (
        <button
          onClick={(): void => {
            handleDelete(item.id)
          }}
        >
          <Delete fontSize="small" style={{ color: 'gray' }} />
        </button>
      )}
    </td>
  )
}

export default ReportingActionCell
