import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import { IListSelectorValue } from 'interfaces/selectors'
import React from 'react'

interface ShareWithDropdownProps {
  locations: IListSelectorValue[]
  selectedLocations: IListSelectorValue[]
  onChange: (selectedLocations: IListSelectorValue[]) => void
  currentLocationId: number
}

const ShareWithDropdown: React.FC<ShareWithDropdownProps> = ({
  locations,
  selectedLocations,
  onChange,
  currentLocationId
}) => {
  const handleChange = (event: SelectChangeEvent<number[]>): void => {
    const selectedIds = event.target.value as number[]
    const selectedPlaces = locations.filter((location) => selectedIds.includes(location.id))
    onChange(selectedPlaces)
  }

  const availableLocations = locations.filter((location) => location.id !== currentLocationId)

  return (
    <FormControl fullWidth>
      <InputLabel id="share-with-label">Partager avec</InputLabel>
      <Select
        labelId="share-with-label"
        id="share-with"
        multiple
        value={selectedLocations.map((location) => location.id)}
        onChange={handleChange}
        renderValue={(selected) => selectedLocations.map((location) => location.name).join(', ')}
      >
        {availableLocations.map((location) => (
          <MenuItem key={location.id} value={location.id}>
            <Checkbox checked={selectedLocations.some((sel) => sel.id === location.id)} />
            <ListItemText primary={location.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ShareWithDropdown
