import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { customAxios } from './axiosUtils'

/**
 Récupère les anniversaires du jour
*/
export const getBirthdaysOfTheDay = async (): Promise<IMinimalCollaborateurData[]> => {
  try {
    return await customAxios.get(`birthdays`)
  } catch (error) {
    return []
  }
}
