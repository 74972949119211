import React, { ReactElement, useContext, useEffect, useState } from 'react'
import SearchBox from 'components/inputs/searchBox/SearchBox'
import { getSteamDejGroups } from 'services/SteamDejService'
import { ISteamDejGroup } from 'interfaces/steamDejGroup'
import { PlaceSelectedContext } from 'App'
import SteamDejCard from './SteamDejCard'

const SteamDej = (): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [steamdejGroups, setSteamdejGroups] = useState<ISteamDejGroup[]>([])
  const [statusModified, setStatusModified] = useState<boolean>(false)

  useEffect(() => {
    void (async () => {
      const response = await getSteamDejGroups(searchTerm, place)
      if (response !== null) {
        setSteamdejGroups(response)
      }
    })()
  }, [searchTerm, statusModified, place])

  const handleChangeGroupStatus = (updatedGroup: ISteamDejGroup): void => {
    const updatedSteamdejStatus = steamdejGroups.map((group) => {
      if (group.id === updatedGroup.id) {
        return { ...group, status: updatedGroup.status }
      }
      return group
    })
    setSteamdejGroups(updatedSteamdejStatus)
  }

  return (
    <div className="steamdej">
      <div className="steamdej-title">
        <h3>
          Les <span className="accentuated-title">SteamDej&apos;</span>
        </h3>
        <SearchBox setSearchTerm={setSearchTerm} placeholder="Rechercher un collaborateur" />
      </div>

      <SteamDejCard
        steamdejGroups={steamdejGroups}
        statusModified={statusModified}
        setStatusModified={setStatusModified}
        handleChangeGroupStatus={handleChangeGroupStatus}
      />
    </div>
  )
}

export default SteamDej
