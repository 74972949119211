import { IAnnualSummary, ISummary, IUserProduction } from 'interfaces/reporting'
import { getReportingSummaryOfYear } from 'services/ReportingService'
import { contractTypeConstant, shortMonthsList } from 'constantes'
import React, { ReactElement, useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { formatNumber } from 'utils/otherUtils'
import { round } from 'lodash'

export interface AdminReportingAnnualProps {
  year: number
  userProduction: IUserProduction[]
  place: number
}

const AdminReportingAnnual = ({
  year,
  userProduction,
  place
}: AdminReportingAnnualProps): ReactElement => {
  const [annualSummary, setAnnualSummary] = useState<IAnnualSummary[]>([])
  const contractTypes = [...new Set(userProduction.map((production) => production.contractType))]
  const [loader, setLoader] = useState<boolean>(false)

  useEffect(() => {
    setLoader(true)
    void getReportingSummaryOfYear(year, place).then((response) => {
      if (response) {
        const summarizedData = summarizeByContractType(response)
        setAnnualSummary(summarizedData)
      }
      setLoader(false)
    })
  }, [year, place])

  const summarizeByContractType = (summaries: ISummary[]): IAnnualSummary[] => {
    const summaryMap: Record<string, number[]> = {}

    summaries?.forEach((summary) => {
      const { contractType, month, amountWorked } = summary
      if (!summaryMap[contractType]) {
        summaryMap[contractType] = new Array(12).fill(0)
      }
      if (summary.billable) {
        summaryMap[contractType][month - 1] += amountWorked / 100 // fais la somme si facturé
      }
    })

    const annualSummary: IAnnualSummary[] = Object.entries(summaryMap).map(([type, months]) => ({
      type,
      months
    }))

    return annualSummary
  }

  const findTotalOfList = (list: number[]): number => {
    return list.reduce((acc, value) => acc + value, 0)
  }

  const sumByContractTypeAndMonth = (
    data: IUserProduction[],
    month: number,
    contractType: string
  ): number => {
    return round(
      data
        .filter(
          (production) => production.month === month && production.contractType === contractType
        )
        .reduce((sum: number, production) => sum + Number(production.sa), 0) / 100,
      2
    )
  }

  const sumByContractType = (data: IUserProduction[], contractType: string): number => {
    return round(
      data
        .filter((production) => production.contractType === contractType)
        .reduce((sum: number, production) => sum + Number(production.sa), 0) / 100,
      2
    )
  }

  const sumGlobalByMonth = (data: IUserProduction[], month: number): number => {
    return round(
      data
        .filter((production) => production.month === month)
        .reduce((sum: number, production) => sum + Number(production.sa), 0) / 100,
      2
    )
  }

  const sumGlobal = (data: IUserProduction[]): number => {
    return round(data.reduce((sum: number, production) => sum + Number(production.sa), 0) / 100, 2)
  }

  return (
    <>
      {loader && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
      {!loader && annualSummary?.length > 0 && (
        <div className="bg-white text-center rounded-xl overflow-hidden">
          <table className="w-full shadow-md text-sm !text-nowrap">
            <thead className="border-b-2 border-gray-300">
              <tr>
                <th className="bold text-left p-1">Type</th>
                {shortMonthsList.map((month, index) => (
                  <th key={index} className="p-1">
                    {month}
                  </th>
                ))}
                <th className="bold">TOTAL</th>
              </tr>
            </thead>

            <tbody>
              {annualSummary.map((summary, index) => (
                <tr key={index} className="border-b-2 border-gray-300">
                  <td className="text-left p-1">{summary.type}</td>
                  {summary.months.map((month, index) => (
                    <td key={index} className="p-1">
                      {month}
                    </td>
                  ))}
                  <td className="bold">{formatNumber(findTotalOfList(summary.months))}</td>
                </tr>
              ))}
              <tr className="border-b-2 border-gray-300 bg-violet-100 rounded-lg">
                <td className="text-left bold p-1">Total Direct</td>
                {shortMonthsList.map((month: string, monthIndex: number) => (
                  <td key={monthIndex}>
                    {formatNumber(
                      annualSummary
                        .filter((summary) => summary.type !== contractTypeConstant.PRESTATAIRE)
                        .reduce(
                          (acc: number, summary) => acc + Number(summary.months[monthIndex]),
                          0
                        )
                    )}
                  </td>
                ))}
                <td className="bold">
                  {formatNumber(
                    annualSummary
                      .filter((summary) => summary.type !== contractTypeConstant.PRESTATAIRE)
                      .reduce((acc: number, summary) => acc + findTotalOfList(summary.months), 0)
                  )}
                </td>
              </tr>
              <tr className="border-b-2 border-gray-300 bg-violet-100">
                <td className="text-left bold p-1">Total</td>
                {shortMonthsList.map((month, monthIndex) => (
                  <td key={monthIndex} className="p-1">
                    {formatNumber(
                      annualSummary.reduce(
                        (acc: number, summary) => acc + Number(summary.months[monthIndex]),
                        0
                      )
                    )}
                  </td>
                ))}
                <td className="bold">
                  {formatNumber(
                    annualSummary.reduce(
                      (acc: number, summary) => acc + findTotalOfList(summary.months),
                      0
                    )
                  )}{' '}
                </td>
              </tr>

              <tr className="flex text-center justify-center">
                <td colSpan={14} className="flex justify-center text-center text-white">
                  ----------------------------
                </td>
              </tr>

              {contractTypes.map((contractType, index) => (
                <tr key={index} className="border-b-2 border-gray-300 bg-violet-100">
                  <td className="text-left p-1">SA {contractType}</td>
                  {shortMonthsList.map((month, monthIndex) => (
                    <td key={monthIndex} className="p-1">
                      {formatNumber(
                        sumByContractTypeAndMonth(userProduction, monthIndex + 1, contractType)
                      )}
                    </td>
                  ))}
                  <td className="bold">
                    {formatNumber(sumByContractType(userProduction, contractType))}
                  </td>
                </tr>
              ))}
              <tr className="border-b-2 border-gray-300 bg-violet-100">
                <td className="text-left bold p-1">SA Global</td>
                {shortMonthsList.map((month, monthIndex) => (
                  <td key={monthIndex} className="p-1">
                    {formatNumber(sumGlobalByMonth(userProduction, monthIndex + 1))}
                  </td>
                ))}
                <td className="bold">{formatNumber(sumGlobal(userProduction))}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default AdminReportingAnnual
