import { openJustificationFile } from 'services/JustificationPdfExpenseService'
import { Close, FileDownload } from '@mui/icons-material'
import { IBill, IExpense } from 'interfaces/expenses'
import LogoSteamulo from 'assets/Logo_Steamulo2.png'
import fileDownload from 'js-file-download'
import React, { ReactElement } from 'react'
import { IconButton } from '@mui/material'
import { monthsList } from 'constantes'

interface IExpenseReceipt {
  myBills: IBill[]
  myExpense: IExpense
  handleClose?: (() => void) | undefined
}

const ExpenseReceipt = ({ myBills, myExpense, handleClose }: IExpenseReceipt): ReactElement => {
  const handleOpen = async (billId: number): Promise<void> => {
    const response = await openJustificationFile(billId)
    if (response !== null) {
      fileDownload(response.content, response.filename)
    }
  }

  return (
    <div className="box-myexpense-receipt">
      <div className="myexpense-title">
        <div>
          <img src={LogoSteamulo} alt="Steamulo Logo"></img>
          {handleClose !== undefined && <Close className="icon-close" onClick={handleClose} />}
        </div>
        <div>Informations sur votre note de frais</div>
      </div>
      <div className="box-myexpense-content">
        <div className="myexpense-detail">
          Mois : <span className="detail">{monthsList[myExpense.month - 1]}</span>
        </div>
        <div className="myexpense-detail">
          Année : <span className="detail">{myExpense.year}</span>
        </div>
        <div className="myexpense-detail status-detail">
          Etat :{' '}
          <span className={`detail ${myExpense.status.replace(' ', '')}`}>
            {myExpense.status}&nbsp;
          </span>
          {myExpense.refusalReason !== undefined && <span>({myExpense.refusalReason})</span>}
        </div>
      </div>
      <div className="mybills">
        {myBills.length !== 0 &&
          myBills
            .filter((myBill) => myBill.expense.id === myExpense.id)
            .map((myBill) => {
              return (
                <div className="mybill-content" key={myBill.id}>
                  <div
                    className={`myexpense-detail ${myBill.description ? 'with-description' : ''}`}
                  >
                    <div className="expense-type">
                      {myBill.expenseSubtype?.label ? (
                        <div>{myBill.expenseSubtype?.label}</div>
                      ) : (
                        <div>{myBill.expenseType.label}</div>
                      )}
                    </div>
                    {myBill.description && (
                      <div className="expense-description">{myBill.description}</div>
                    )}
                  </div>
                  <div>
                    <span>{myBill.amount}€</span>
                    {myBill.document !== undefined ? (
                      <IconButton
                        onClick={() => {
                          void (async () => {
                            if (myBill.id !== undefined) {
                              await handleOpen(myBill.id)
                            }
                          })()
                        }}
                      >
                        <FileDownload id="download-file-icon" />
                      </IconButton>
                    ) : (
                      <IconButton disabled onClick={() => {}} />
                    )}
                  </div>
                </div>
              )
            })}
      </div>
      <div className="mybills-total">
        <div className="myexpense-detail">Total</div>
        <span>{myExpense.totalAmount}€</span>
      </div>
    </div>
  )
}

export default ExpenseReceipt
