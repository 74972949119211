import { CurrentUserContext, PlaceSelectedContext, YearSelectedContext } from 'App'
import HolidaysRequestsMetrics from './userBoxMetric/HolidaysRequestsMetrics'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { getMetrics, getRecentUsers } from 'services/MetricService'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { hasManagerRole, hasRHRole } from '../../utils/otherUtils'
import ArrivalsAndLeaves from './userBoxMetric/ArrivalsAndLeaves'
import Permission from '../../components/permission/Permission'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import MessageAddIcon from '@mui/icons-material/Message'
import Messages from './userBoxMetric/Messages'
import { featuresList } from '../../constantes'
import { useNavigate } from 'react-router-dom'
import BoxMetric from './boxMetric/BoxMetric'
import { IMetric } from 'interfaces/metrics'
import moment from 'moment'

const Dashboard = (): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  const [metrics, setMetrics] = useState<IMetric[]>([])
  const [arrivalUsers, setArrivalUsers] = useState<IMinimalCollaborateurData[]>([])
  const [leavingUsers, setLeavingUsers] = useState<IMinimalCollaborateurData[]>([])
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(true)
  const [createNewMessage, setCreateNewMessage] = useState<boolean>(false)

  const navigate = useNavigate()
  const MONTHS: number = 12

  // Chargement des metrics
  useEffect(() => {
    setLoadingMetrics(true)
    void (async () => {
      const metrics = await getMetrics(place, MONTHS)
      setMetrics(metrics)
      const arrivingUserCount = getUsersCount(metrics, 'arrivalUsersCount')
      if (arrivingUserCount !== null && arrivingUserCount !== 0) {
        const arrivalUsers = (await getRecentUsers(false, place, year, arrivingUserCount))?.data
        arrivalUsers !== undefined && setArrivalUsers(arrivalUsers)
      }

      const leavingUserCount = getUsersCount(metrics, 'leavingUsersCount')
      if (leavingUserCount !== null && leavingUserCount !== 0) {
        const leavingUsers = (await getRecentUsers(true, place, year, leavingUserCount))?.data
        leavingUsers !== undefined && setLeavingUsers(leavingUsers)
      }

      setLoadingMetrics(false)
    })()
  }, [place])

  const getUsersCount = (metrics: IMetric[], property: string): number | null => {
    return metrics.find((item) => item.metric === property)?.count ?? null
  }

  /**
  Récupérer la/les valeurs associées à la metric renseignée en paramètre
  @param property la valeur de la metric qu'on veut récupérer
  @return le nombre associé à la metric recherchée
  **/
  const getMetricValues = (property: string): number => {
    const data = metrics.filter((metric: IMetric) => {
      return metric.metric === property
    })

    return data[0].count
  }

  return (
    <div className="dashboard">
      <div className="header">
        <h3>
          <span className="accentuated-title big-title">Collabs</span> de Steamulo
        </h3>
        <p>Vous trouverez ici toutes les données sur les entrées/sorties de votre entreprise.</p>
      </div>
      <div className="inputs">
        <PrimaryButton
          handleClick={(): void => {
            navigate('/creation')
          }}
          title="Nouveau Collab"
          icon={<PersonAddIcon />}
          background
          reverse={false}
          disabled={loadingMetrics}
        />
        {currentUser?.rolePermissions !== undefined && hasRHRole(currentUser.rolePermissions) && (
          <PrimaryButton
            handleClick={(): void => {
              setCreateNewMessage(true)
            }}
            title="Nouveau message"
            icon={<MessageAddIcon />}
            background
            reverse={false}
            disabled={loadingMetrics}
          />
        )}
      </div>

      <div className="metrics">
        <BoxMetric
          title="Collaborateurs actifs"
          number={metrics.length > 0 ? getMetricValues('activeUsersCount') : 0}
          loading={loadingMetrics}
        />
        <BoxMetric
          title={`Entrées/Sorties ${new Date().getFullYear()}`}
          number={metrics.length > 0 ? getMetricValues('arrivalLessleavingUsersCount') : 0}
          loading={loadingMetrics}
        />
        <BoxMetric
          title={`Entrées depuis ${MONTHS} mois`}
          number={metrics.length > 0 ? getMetricValues('arrivalUsersCount') : 0}
          loading={loadingMetrics}
        />
        <BoxMetric
          title={`Sorties depuis ${MONTHS} mois`}
          number={metrics.length > 0 ? getMetricValues('leavingUsersCount') : 0}
          loading={loadingMetrics}
        />
      </div>
      <div className="leaves-outs">
        <ArrivalsAndLeaves leave={false} users={arrivalUsers} loading={loadingMetrics} />
        <ArrivalsAndLeaves leave users={leavingUsers} loading={loadingMetrics} />
      </div>
      {currentUser?.rolePermissions !== undefined && hasRHRole(currentUser?.rolePermissions) && (
        <Messages createNewMessage={createNewMessage} setCreateNewMessage={setCreateNewMessage} />
      )}
      {currentUser?.rolePermissions !== undefined &&
        (hasRHRole(currentUser?.rolePermissions) ||
          hasManagerRole(currentUser?.rolePermissions)) && (
          <>
            {hasRHRole(currentUser?.rolePermissions) && (
              <Permission name={featuresList.HOLIDAY}>
                <HolidaysRequestsMetrics />
              </Permission>
            )}
          </>
        )}
    </div>
  )
}
export default Dashboard
