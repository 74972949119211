import ConfirmationPopUp from '../../components/inputs/confirmationPopUp/ConfirmationPopUp'
import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import { updateHolidayRequests } from 'services/HolidayService'
import React, { ReactElement, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { Tooltip } from '@mui/material'
import { statusEnum } from 'constantes'

interface ICancellationButtons {
  request: IDetailHolidayRequest
  handleOldRequestCancellation: (response: IDetailHolidayRequest) => void
}

const OldHolidayRequestCancellation = ({
  request,
  handleOldRequestCancellation
}: ICancellationButtons): ReactElement => {
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [requestId, setRequestId] = useState<number>()
  const [holidayRequest, setHolidayRequest] = useState<IDetailHolidayRequest>()

  const handleCancellationClick = (): void => {
    setOpenPopUp(true)
    setRequestId(request.id)
    setHolidayRequest({
      ...request,
      status: statusEnum.CANCELED
    })
  }

  const handleCancelRequest = async (): Promise<void> => {
    if (holidayRequest !== undefined && requestId !== undefined) {
      setLoading(true)
      holidayRequest.status = statusEnum.CANCELED
      const response = await updateHolidayRequests(holidayRequest)
      if (response !== null) {
        handleOldRequestCancellation(response)
      }
      setLoading(false)
      setOpenPopUp(false)
    }
  }

  return (
    <>
      <div className="icons-with-trash">
        <div className={request.status}>{request.status}</div>
        {request.status === statusEnum.ACCEPTED && (
          <Tooltip title="Annuler">
            <CancelIcon id="canceled-icon" onClick={handleCancellationClick} />
          </Tooltip>
        )}
      </div>
      <ConfirmationPopUp
        dialogTitle="Voulez-vous annuler ces anciens congés ?"
        dialogContentText="Ces congés seront annulés et le planning sera automatiquement mis à jour"
        open={openPopUp}
        setOpen={setOpenPopUp}
        handleSave={handleCancelRequest}
        loading={loading}
      />
    </>
  )
}

export default OldHolidayRequestCancellation
