import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import { Switch } from '@mui/material'
import './ReportingSwitch.scss'

interface ReportingSwitchProps {
  user: IDetailCollaborateurData | null
  checked: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const ReportingSwitch: React.FC<ReportingSwitchProps> = ({
  user,
  checked,
  handleChange,
  disabled = false
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    void (async () => {
      if (user?.id !== undefined) {
        setLoading(false)
      }
    })()
  }, [user?.id])

  return (
    <div className="box-data reporting-switch">
      <div className="category-title">Reporting</div>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="box-inline-elts">
          <div className="reporting-switch-toggle-box">
            <Switch checked={checked} onChange={handleChange} disabled={disabled} />
            <p className="data-title">Inclure le reporting de l&apos;utilisateur</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportingSwitch
