import { IDetailMaterialData, IUpdateMaterialData } from 'interfaces/materialData'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { IErrors } from 'interfaces/formFields'
import React, { ReactElement } from 'react'
import { OSList } from 'constantes'

interface IGeneralInfos {
  material: IDetailMaterialData | null
  editMode: boolean
  materialDataToSend: IUpdateMaterialData
  setMaterialDataToSend: React.Dispatch<React.SetStateAction<IUpdateMaterialData>>
  errors: IErrors
}

const GeneralInfos = ({
  material,
  editMode,
  materialDataToSend,
  setMaterialDataToSend,
  errors
}: IGeneralInfos): ReactElement => {
  return (
    <div className="box-data">
      <p className="category-title">Informations matériel</p>
      <div className="box-inline-elts">
        {editMode && (
          <>
            <div className="box-column">
              <div>
                <CustomTextField
                  onChange={(value) => {
                    setMaterialDataToSend({ ...materialDataToSend, brand: value })
                  }}
                  id="brand"
                  value={materialDataToSend.brand}
                  errorMessage={errors.brand?.message}
                  required
                  label="Marque"
                />
              </div>
            </div>
            <div className="box-column">
              <div>
                <CustomTextField
                  onChange={(value) => {
                    setMaterialDataToSend({ ...materialDataToSend, model: value })
                  }}
                  id="model"
                  value={materialDataToSend.model}
                  errorMessage={errors.model?.message}
                  required
                  label="Modèle"
                />
              </div>
            </div>
          </>
        )}
        <div className="box-column">
          <div>
            {editMode ? (
              <CustomTextField
                onChange={(value) => {
                  setMaterialDataToSend({ ...materialDataToSend, serialNumber: value })
                }}
                id="serialNumber"
                value={materialDataToSend.serialNumber}
                errorMessage={errors.serialNumber?.message}
                required
                label="N° de série"
              />
            ) : (
              <>
                <p className="data-title">N° de série</p>
                <p className="data-value">{material?.serialNumber}</p>
              </>
            )}
          </div>
        </div>
        <div className="box-column">
          <div>
            {editMode ? (
              <CustomTextField
                onChange={(value) => {
                  setMaterialDataToSend({ ...materialDataToSend, descriptiveLink: value })
                }}
                id="descriptiveLink"
                value={materialDataToSend.descriptiveLink}
                errorMessage=""
                required={false}
                label="Lien descriptif"
              />
            ) : (
              <>
                <p className="data-title">Lien descriptif</p>
                <a
                  href={materialDataToSend.descriptiveLink}
                  target="_blank"
                  className="data-value link"
                  rel="noreferrer"
                >
                  {material?.descriptiveLink}
                </a>
              </>
            )}
          </div>
        </div>
        <div className="box-column">
          {editMode ? (
            <div className="os-select">
              <CustomSelect
                handleSelect={(value) => {
                  setMaterialDataToSend({ ...materialDataToSend, os: value as string })
                }}
                options={OSList}
                id="os"
                value={materialDataToSend.os}
                inputLabel="Liste des os"
              />
              <p className="form-error"></p>
            </div>
          ) : (
            <div>
              <p className="data-title">Liste des OS</p>
              <p className="data-value">{material?.os}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GeneralInfos
