import {
  exportHolidaySynthesis,
  getHolidayRequests,
  getTotalHolidayQuotaRequests,
  getUnvalidatedHolidayRequests,
  updateHolidayRequests
} from 'services/HolidayService'
import {
  IDetailHolidayQuotaRequest,
  IDetailHolidayRequest,
  ITotalHolidayQuotaRequest
} from 'interfaces/holidays/holidayRequest'
import {
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_MAX,
  holidayRequestList,
  holidayTypesEnum,
  monthsList,
  statusEnum
} from 'constantes'
import {
  IHolidayRequestsQuotaWithPagination,
  IHolidayRequestsWithPagination
} from 'interfaces/dataWithPagination'
import TabNavigation, { INavItem } from '../../components/tabNavigation/TabNavigation'
import { getUsersHolydaysWithPagination } from 'services/DataWithPaginationService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import HolidaysRequestsSynthesisTab from './HolidaysRequestsSynthesisTab'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { PlaceSelectedContext, YearSelectedContext } from 'App'
import SearchBox from 'components/inputs/searchBox/SearchBox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { getTTToValidate } from 'services/PlanningService'
import { IPlanningDate } from '../../interfaces/planning'
import HolidaysRequestsTab from './HolidaysRequestsTab'
import OffsiteRequestsTab from './OffsiteRequestsTab'
import { useSearchParams } from 'react-router-dom'
import ErrorIcon from '@mui/icons-material/Error'
import { CircularProgress } from '@mui/material'
import fileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import { Order } from 'types/order'
import './HolidaysRequests.scss'
import moment from 'moment'

interface IHolidaysRequests {
  showHeaderAndPagination?: boolean
  nbRows?: number
  pageName?: string
}

export interface NavItemsUpdateParamters {
  tabIndex: number
  reqNumber?: number
}

const HolidaysRequests = ({
  showHeaderAndPagination = true,
  nbRows = 20,
  pageName = ''
}: IHolidaysRequests): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()

  const [searchParams] = useSearchParams()
  const [emailApprovalStatus, setEmailApprovalStatus] = useState<{
    loading: boolean
    message: string | null
  }>({ loading: false, message: null })

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [requests, setRequests] = useState<IDetailHolidayRequest[]>([])
  const [requestsSynthesis, setRequestsSynthesis] = useState<IDetailHolidayQuotaRequest[]>([])
  const [totalRequestsSynthesis, setTotalRequestsSynthesis] = useState<ITotalHolidayQuotaRequest>()
  const [loading, setLoading] = useState<boolean>(true)
  const [order, setOrder] = useState<Order>('asc')
  const [page, setPage] = useState(1)
  const queryParams = new URLSearchParams(location.search)
  const orderByParam = queryParams.get('tri')
  const [orderBy, setOrderBy] = useState<string>(orderByParam !== null ? orderByParam : 'createdAt')
  const [nbPages, setNbPages] = useState<number>(1)
  const [needReload, setNeedReload] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [month, setMonth] = useState<number>(moment().month() + 1)
  const [navItems, setNavItems] = useState<INavItem[]>(
    holidayRequestList.map((item) => ({ label: item }))
  )

  // Gère la mise à jour d'un élément de la liste des demandes par email
  useEffect(() => {
    const handleEmailApproval = async (): Promise<void> => {
      const requestId = searchParams.get('requestId')
      const action = searchParams.get('action')
      const token = searchParams.get('token')

      if (requestId && token && (action === 'accept' || action === 'refuse')) {
        setEmailApprovalStatus({ loading: true, message: null })
        try {
          // Réception de la demande
          const response = (await getHolidayRequests(
            1,
            ROWS_PER_PAGE_MAX,
            'createdAt',
            'asc',
            '',
            place,
            [statusEnum.TO_VALIDATE],
            year,
            null
          )) as IHolidayRequestsWithPagination

          const request = response?.data.find((r) => r.id === parseInt(requestId))

          if (!request) {
            throw new Error('Request not found')
          }

          // Mets à jour la demande
          const updatedRequest = {
            ...request,
            status: action === 'accept' ? statusEnum.ACCEPTED : statusEnum.REFUSED,
            user: request.user,
            requestingUser: request.requestingUser,
            token
          }

          const updateResponse = await updateHolidayRequests(updatedRequest)
          if (updateResponse) {
            handleRequestApprobationUpdate(updateResponse)
            setEmailApprovalStatus({
              loading: false,
              message: `Demande de congés ${
                action === 'accept' ? 'acceptée' : 'refusée'
              } avec succès`
            })
            // Raffraichit la liste des demandes après l'approbation/le refus
            setNeedReload((prev) => !prev)
          }
        } catch (error) {
          setEmailApprovalStatus({
            loading: false,
            message:
              'Il y a eu une erreur lors de la validation de la demande. Réessayez depuis le tableau des demandes.'
          })
        }
      }
    }

    void handleEmailApproval()
  }, [searchParams])

  useEffect(() => {
    setLoading(true)
    let totalRequests: number | null
    let totalTTRequests: number | null
    void (async () => {
      try {
        if (currentTab === 1) {
          setLoading(false)
          totalRequests = await getUnvalidatedHolidayRequests(statusEnum.TO_VALIDATE, place).then(
            (response) => response?.data.length ?? 0
          )
          modifyNavItems([{ tabIndex: 0, reqNumber: totalRequests ?? 0 }])
        } else if (currentTab === 4) {
          const response = (await getUsersHolydaysWithPagination(
            page,
            ROWS_PER_PAGE_MAX,
            place,
            searchTerm,
            status,
            Number(yearSelectedContext?.yearSelected),
            month
          )) as IHolidayRequestsQuotaWithPagination
          if (response !== null) {
            setNbPages(response.pages)
            setRequestsSynthesis(response.data)
          }

          const totalResponse = (await getTotalHolidayQuotaRequests(
            month
          )) as ITotalHolidayQuotaRequest
          setTotalRequestsSynthesis(totalResponse)
          setCurrentTab(currentTab)
          setLoading(false)
        } else {
          const response = (await getHolidayRequests(
            page,
            nbRows === null ? ROWS_PER_PAGE : nbRows,
            orderBy,
            currentTab === 2 ? 'DESC' : order,
            searchTerm,
            place,
            currentTab === 0
              ? [statusEnum.TO_VALIDATE]
              : currentTab === 2
              ? [statusEnum.ACCEPTED, statusEnum.REFUSED]
              : [statusEnum.ACCEPTED, statusEnum.REFUSED, statusEnum.CANCELED],
            year,
            currentTab === 2 ? holidayTypesEnum.CONGE_MALADIE : null
          )) as IHolidayRequestsWithPagination
          if (currentTab === 0) {
            totalRequests = response.data.length
            totalTTRequests = await getTTToValidate(place).then((response) => response.length)

            modifyNavItems([
              { tabIndex: 0, reqNumber: totalRequests },
              { tabIndex: 1, reqNumber: totalTTRequests ?? 0 },
              {
                tabIndex: 2,
                reqNumber:
                  (
                    (await getHolidayRequests(
                      page,
                      nbRows === null ? ROWS_PER_PAGE : nbRows,
                      orderBy,
                      'DESC',
                      searchTerm,
                      place,
                      [statusEnum.ACCEPTED, statusEnum.REFUSED],
                      year,
                      holidayTypesEnum.CONGE_MALADIE
                    )) as IHolidayRequestsWithPagination
                  ).data.filter((data) => !data.justificationFilename).length ?? 0
              }
            ])
          }
          setNbPages(response.pages)
          setRequests(response.data)
          setLoading(false)
        }
      } catch (error) {
        toast.error('Erreur lors de la récupération des données')
        setLoading(false)
      }
    })()
  }, [searchTerm, place, needReload, currentTab, page, orderBy, order, year, month])

  // Revenir à la première page à chaque changement de tab
  useEffect(() => {
    setOrderBy(orderByParam !== null ? orderByParam : 'createdAt')
    setPage(1)
  }, [currentTab])

  const handleRequestUpdate = (holidayResponse: IDetailHolidayRequest): void => {
    setRequests((prev) =>
      prev.map((request) => {
        if (request.id === holidayResponse.id) {
          return holidayResponse // Remplace l'élément par la nouvelle requête modifiée
        }
        return request // Garde les autres éléments inchangés
      })
    )
  }

  const handleRequestApprobationUpdate = (
    approbationResponse: IDetailHolidayRequest | IPlanningDate[]
  ): void => {
    setRequests((prev) =>
      prev.filter((request) => {
        return request.id !== (approbationResponse as IDetailHolidayRequest).id
      })
    )
  }

  const handleTab = (e: any, tab: number): void => {
    setRequests([])
    setRequestsSynthesis([])
    setCurrentTab(tab)
  }

  // Fonction pour mettre à jour les données du tableau NavItems
  const updateNavItems = (index: number, nouvelleQuantite: number | undefined): void => {
    setNavItems((navItems) => {
      const nouvellesDonnees = [...navItems]
      nouvellesDonnees[index].count = nouvelleQuantite === 0 ? undefined : nouvelleQuantite
      return nouvellesDonnees
    })
  }

  // Fonction pour modifier les données de NavItems à partir d'un tableau d'objets
  const modifyNavItems = (items: NavItemsUpdateParamters[]): void => {
    items.forEach((item) => {
      updateNavItems(item.tabIndex, item.reqNumber)
    })
  }

  /**
   * Rendu du message de validation/refus par email
   *
   * @returns
   */
  const renderEmailApprovalStatus = (): ReactElement | null => {
    if (!emailApprovalStatus.message) return null

    const isSuccess = emailApprovalStatus.message.includes('succès')

    return (
      <div
        className={`flex items-center p-4 mb-4 rounded ${
          isSuccess ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
        }`}
      >
        {isSuccess ? (
          <CheckCircleIcon className="mr-2 text-green-500" />
        ) : (
          <ErrorIcon className="mr-2 text-red-500" />
        )}
        <span>{emailApprovalStatus.message}</span>
      </div>
    )
  }

  return (
    <div className="holidays-requests">
      {emailApprovalStatus.message && renderEmailApprovalStatus()}

      {showHeaderAndPagination && (
        <div className="holidays-requests-header">
          <h3>
            Suivi des <span className="accentuated-title">absences</span>
          </h3>
          <SearchBox setSearchTerm={setSearchTerm} placeholder="Rechercher un collaborateur" />
        </div>
      )}

      {showHeaderAndPagination && (
        <TabNavigation
          currentTab={currentTab}
          handleTab={handleTab}
          navItems={navItems}
        ></TabNavigation>
      )}

      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : currentTab === 4 ? (
        <>
          <div className="button-synthesis">
            <CustomSelect
              value={monthsList[month - 1]}
              options={monthsList}
              handleSelect={(newValue) => {
                setMonth(
                  Number(monthsList.indexOf(typeof newValue === 'string' ? newValue : '')) + 1
                )
              }}
              id="month"
              width={250}
              inputLabel="Mois"
              showDelete={false}
            />
            <div className="button-synthesis-export">
              <PrimaryButton
                handleClick={() => {
                  void (async () => {
                    const response = await exportHolidaySynthesis(place, year, month)
                    if (response !== null) {
                      fileDownload(response.content, response.filename)
                    }
                  })()
                }}
                title="Exporter"
                background
                reverse={false}
                disabled={false}
              />
            </div>
          </div>
          <HolidaysRequestsSynthesisTab
            requests={requestsSynthesis}
            totalRequests={totalRequestsSynthesis}
            nbPages={showHeaderAndPagination ? nbPages : 1}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            page={page}
            setPage={setPage}
            needReload={needReload}
            setNeedReload={setNeedReload}
            currentTab={currentTab}
            showHeaderAndPagination={showHeaderAndPagination}
            handleRequestUpdate={handleRequestUpdate}
            handleRequestApprobationUpdate={handleRequestApprobationUpdate}
            pageName={pageName}
          />
        </>
      ) : currentTab === 1 ? (
        <OffsiteRequestsTab updateNavItems={modifyNavItems} />
      ) : (
        <HolidaysRequestsTab
          requests={requests}
          nbPages={showHeaderAndPagination ? nbPages : 1}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          page={page}
          setPage={setPage}
          needReload={needReload}
          setNeedReload={setNeedReload}
          currentTab={currentTab}
          showHeaderAndPagination={showHeaderAndPagination}
          handleRequestUpdate={handleRequestUpdate}
          handleRequestApprobationUpdate={handleRequestApprobationUpdate}
          pageName={pageName}
        />
      )}
    </div>
  )
}

export default HolidaysRequests
