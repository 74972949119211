import { IProject } from 'interfaces/projects'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

/**
 * Récupération des projets.
 */
export const getProjects = async (clientId?: string, placeId?: number): Promise<IProject[]> => {
  const placeParam = placeId && placeId !== null ? placeId : 1
  try {
    if (clientId) {
      return await customAxios.get(`projects?search=${clientId}&placeId=${placeParam}`)
    } else {
      return await customAxios.get(`projects?placeId=${placeParam}`)
    }
  } catch (error) {
    return []
  }
}

/**
 Permet d'ajouter un project
 @param data le body contenant les données du projet
 **/
export const addProject = async (data: IProject): Promise<IProject[]> => {
  try {
    const project = await customAxios.post(`projects`, data)
    toast.success('Projet ajouté avec succès')
    return project as unknown as IProject[]
  } catch (error) {
    return []
  }
}

/**
 Permet de modifier un project
 @param data le body contenant les données du projet
 **/
export const updateProject = async (data: IProject): Promise<IProject[]> => {
  try {
    if (data.id !== undefined) {
      const project = await customAxios.put(`projects/${data.id}`, data)
      toast.success('Projet modifié avec succès')
      return project as unknown as IProject[]
    }
    return []
  } catch (error) {
    return []
  }
}

/**
 Permet de supprimer un projet
 @param data le body contenant les données du projet
 **/
export const deleteProject = async (data: IProject): Promise<IProject[]> => {
  try {
    if (data.id !== undefined) {
      const project = await customAxios.delete(`projects/${data.id}`)
      toast.success('Projet supprimé avec succès')
      return project as unknown as IProject[]
    }
    return []
  } catch (error) {
    return []
  }
}
