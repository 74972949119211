import { ONE_DAY_DURATION_IN_MINUTES, myDocumentRequestConstant } from 'constantes'
import { CurrentUserContext, PlaceSelectedContext, YearSelectedContext } from 'App'
import { IDetailCollaborateurData } from '../../interfaces/collaborateurData'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { setLocalStorageWithExpiration } from '../../utils/otherUtils'
import TabNavigation from 'components/tabNavigation/TabNavigation'
import { getDocumentsFiles } from 'services/MyDocumentsService'
import { getSelfUserData } from '../../services/UserService'
import { IMyDocumentsDrive } from 'interfaces/myDocuments'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MyDocumentsTab from './MyDocumentsTab'
import { toast } from 'react-toastify'
import { Order } from 'types/order'
import './MyDocuments.scss'
import moment from 'moment'

const MyDocuments = (): ReactElement => {
  const yearSelectedContext = useContext(YearSelectedContext)
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const [currentUser, setCurrentUser] = useState<IDetailCollaborateurData | null>(null)
  const [documents, setDocuments] = useState<IMyDocumentsDrive[]>([])
  const [selectedType, setSelectedType] = useState<string>('')
  const [needReload, setNeedReload] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [nbPages] = useState<number>(1)
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [order, setOrder] = useState<Order>('asc')
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const userContext = useContext(CurrentUserContext)
  const [abortController, setAbortController] = useState<AbortController | null>(null)

  const handleTab = (e: any, tab: number): void => {
    setCurrentTab(tab)
  }

  useEffect(() => {
    getSelfUserData(yearSelectedContext?.yearSelected ?? moment().year())
      .then((user) => {
        setCurrentUser(user)
        userContext?.setCurrentUser(user)
        setLocalStorageWithExpiration('user', JSON.stringify(user), ONE_DAY_DURATION_IN_MINUTES)
      })
      .catch(() => {
        toast.error("L'utilisateur n'a pas pu être récupéré")
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const abortController = new AbortController()
      setAbortController(abortController)

      if (currentUser === null || currentUser === undefined) {
        return
      }

      setSelectedType(myDocumentRequestConstant[currentTab].type)

      void getDocumentsFiles(
        myDocumentRequestConstant[currentTab].type,
        yearSelectedContext?.yearSelected ?? moment().year(),
        placeSelectedContext?.placeSelected ?? 1,
        abortController.signal
      )
        .then((response) => {
          setDocuments(response)
        })
        .catch(() => {
          toast.error('Une erreur est survenue lors du chargement des documents')
        })

      setAbortController(null)
      setLoading(false)
    })()
  }, [
    currentUser,
    currentTab,
    yearSelectedContext?.yearSelected,
    placeSelectedContext?.placeSelected
  ])

  // Annule l'appel API si le tab change et que l'appel est en cours
  useEffect(() => {
    if (abortController) {
      abortController.abort()
    }
  }, [currentTab])

  return (
    <div className="mes-documents-title">
      <div className="d-flex flex-wrap justify-between">
        <h3>
          Mes <span className="accentuated-title">documents</span>
        </h3>
        {currentUser?.onBoardingFiles != null &&
        currentUser.onBoardingFiles.filter((file) => file.provided).length < 4 ? (
          <div className="warning-container bg-white p-1 rounded-md border-l-8 border-2 border-red-700">
            <p className="text-sm">
              Documents manquants, cliquez{' '}
              <span
                className="accentuated-btn"
                onClick={() => {
                  navigate('/onboarding')
                }}
              >
                ici
              </span>{' '}
              pour compléter votre profil
            </p>
          </div>
        ) : null}
      </div>

      <TabNavigation
        currentTab={currentTab}
        handleTab={handleTab}
        navItems={myDocumentRequestConstant.map((item) => ({ label: item.title }))}
      />
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <MyDocumentsTab
            documentDrive={documents}
            nbPages={nbPages}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            page={page}
            setPage={setPage}
            needReload={needReload}
            setNeedReload={setNeedReload}
            currentTab={currentTab}
            selectedType={selectedType}
            showHeaderAndPagination={true}
            userId={currentUser?.id}
            canEdit={myDocumentRequestConstant[currentTab].type === 'DOCUMENTSPERSONNELS'}
          />
        </div>
      )}
    </div>
  )
}

export default MyDocuments
