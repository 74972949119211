import { ICollabsWithPagination } from 'interfaces/dataWithPagination'
import { IActivity } from 'interfaces/activity'
import { IMetric } from 'interfaces/metrics'
import { customAxios } from './axiosUtils'

/**
Récupérer les informations des $limit derniers utilisateurs arrivés ou sorties de chez Steamulo
@param leaving Boolean indiquant si on veut les données des arrivants ou des sortants
@param limit le nombre de résultats à récupérer
@param placeId la ville où se trouve la société Steamulo
@return les doonnées des utilisateurs
**/
export const getRecentUsers = async (
  leaving: boolean,
  placeId: number | string | null,
  year: number,
  limit?: number
): Promise<ICollabsWithPagination | null> => {
  const limitParam = limit !== undefined ? `&limit=${limit}` : ''
  const placeParam = placeId !== null ? `&placeId=${placeId}` : ''
  const leavingParam = leaving ? 1 : 0
  const url = `users?year=${year}&leaving=${leavingParam}${limitParam}${placeParam}`
  try {
    return await customAxios.get(url)
  } catch (error) {
    return null
  }
}

/**
 *Récupérer les informations des utilisateurs actifs sur la plateforme
 *@param placeId la ville où se trouve la société Steamulo
 *@param months Le nombre de mois à partir duquel on doit récupérer les arrivants et sortants
 *@return les doonnées des utilisateurs actifs
 **/
export const getMetrics = async (
  placeId: number | string | null,
  months: number
): Promise<IMetric[]> => {
  const url =
    placeId !== null ? `metrics?months=${months}&placeId=${placeId}` : `metrics?months=${months}`
  try {
    return await customAxios.get(url)
  } catch (error) {
    return []
  }
}
/**
 * Récupérer les dernière activités, actions réalisées sur les collaborateurs
 *@param limit Le nombre d'activités à récupérer
 **/
export const getActivities = async (limit: number): Promise<IActivity[]> => {
  try {
    return await customAxios.get(`activities?limit=${limit}`)
  } catch (error) {
    return []
  }
}
