import { IListSelectorValue, IServiceAccountData } from 'interfaces/selectors'
import { IUpdateUserData } from 'interfaces/collaborateurData'
import CheckBox from 'components/inputs/checkBox/CheckBox'
import React, { ReactElement } from 'react'

interface ICreationFormPartTwo {
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  jobs: IListSelectorValue[] | undefined
  contractTypes: IListSelectorValue[] | undefined
  services: IServiceAccountData[] | undefined
}

const CreationFormPartTwo = ({
  userDataToSend,
  setUserDataToSend,
  jobs,
  contractTypes,
  services
}: ICreationFormPartTwo): ReactElement => {
  const contratName = contractTypes?.filter((contractType) => {
    return contractType.id === userDataToSend.contractType
  })[0].name
  const jobName = jobs?.filter((job) => {
    return job.id === userDataToSend.job
  })[0].name

  return (
    <div>
      <div className="info-box">
        <p className="info-text">
          <span className="important">
            {userDataToSend.firstname} {userDataToSend.lastname}
          </span>{' '}
          est un collaborateur <span className="important">{contratName}</span> ayant pour poste{' '}
          <span className="important">{jobName}</span>. Les comptes à créer pour ce type de
          collaborateur ont été pré-sélectionnés. Vous pouvez ici en ajouter ou retirer.
        </p>
        <p className="info-text">
          {userDataToSend.arrivalDate !== undefined &&
          userDataToSend.arrivalDate !== null &&
          userDataToSend.arrivalDate < new Date()
            ? 'Les comptes vont être créés dès la fin de l’inscription étant donné que la date d’entrée du collaborateur est dépassée.'
            : "Une fois la date d'arrivée dépassée, les comptes seront automatiquement créés."}
        </p>
      </div>
      <div className="checkBox-box">
        {services?.map((service, index) => {
          const property = `${service?.name.toLowerCase()}Account`
          const disable = service.name === 'Google'
          return (
            <CheckBox
              key={index}
              userDataToSend={userDataToSend}
              setUserDataToSend={setUserDataToSend}
              id={service.name.toLowerCase()}
              title={service.name}
              icon={service.logoPath}
              propertyToUpdate={property}
              disabled={disable}
            />
          )
        })}
      </div>
    </div>
  )
}

export default CreationFormPartTwo
