import CustomNumberTextField from 'components/inputs/customNumberTextField/CustomNumberTextField'
import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import CustomGroupRadios from 'components/inputs/customGroupRadios/CustomGroupRadios'
import CustomDatePicker from 'components/inputs/customDatePicker/CustomDatePicker'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { useRetrieveUsers } from 'customHooks/retrieveMatchingUsers'
import SubFormHeader from 'components/subFormHeader/SubFormHeader'
import { IUpdateMaterialData } from 'interfaces/materialData'
import { IListSelectorValue } from 'interfaces/selectors'
import { OSList, materialTypeConstant } from 'constantes'
import { IErrors } from 'interfaces/formFields'
import { setValidDate } from 'utils/dateUtils'
import React, { ReactElement } from 'react'
import { InputLabel } from '@mui/material'
import { Dayjs } from 'dayjs'

interface IMaterialForm {
  newMaterial: IUpdateMaterialData
  setNewMaterial: React.Dispatch<React.SetStateAction<IUpdateMaterialData>>
  errors: IErrors
  places: IListSelectorValue[]
}

const MaterialForm = ({
  newMaterial,
  setNewMaterial,
  errors,
  places
}: IMaterialForm): ReactElement => {
  const [users, retrieveUsers] = useRetrieveUsers()

  const handleChangeDate = (newValue: Dayjs | null, propertyToUpdate: string): void => {
    setNewMaterial({
      ...newMaterial,
      [propertyToUpdate]: newValue !== null ? setValidDate(newValue) : null
    })
  }

  return (
    <div className="form">
      <div className="left-content">
        <SubFormHeader title="Informations matériel" />
        <div className="sub-form">
          <CustomTextField
            onChange={(value) => {
              setNewMaterial({ ...newMaterial, brand: value })
            }}
            id="brand"
            value={newMaterial.brand}
            errorMessage={errors.brand?.message}
            required
            label="Marque"
          />

          <CustomTextField
            onChange={(value) => {
              setNewMaterial({ ...newMaterial, model: value })
            }}
            id="model"
            value={newMaterial.model}
            errorMessage={errors.model?.message}
            required
            label="Modèle"
          />

          <CustomTextField
            onChange={(value) => {
              setNewMaterial({ ...newMaterial, serialNumber: value })
            }}
            id="serialNumber"
            value={newMaterial.serialNumber}
            errorMessage={errors.serialNumber?.message}
            required
            label="N° de série"
          />

          <CustomTextField
            onChange={(value) => {
              setNewMaterial({ ...newMaterial, descriptiveLink: value })
            }}
            id="descriptiveLink"
            value={newMaterial.descriptiveLink}
            errorMessage=""
            required={false}
            label="Lien descriptif"
          />

          <CustomSelect
            handleSelect={(value) => {
              setNewMaterial({ ...newMaterial, os: value as string })
            }}
            options={OSList}
            id="os"
            value={newMaterial.os}
            inputLabel="Liste des OS"
          />
          <p className="form-error"></p>
        </div>

        <div className="margin-top"></div>

        <SubFormHeader title="Informations sur le poste" />
        <div className="inline-sub-form">
          <div>
            <CustomNumberTextField
              onChange={() => {}}
              id="inventoryNumber"
              value={newMaterial.inventoryNumber.toString()}
              errorMessage={errors.inventoryNumber?.message}
              required={false}
              label="N° d'inventaire"
              disabled={true}
            />
          </div>
          <div>
            <InputLabel id="userAssignmentId">Attribution</InputLabel>
            <AutoCompleteUserInput
              value={newMaterial.userAssignment}
              onChange={(e, newValue): void => {
                setNewMaterial({
                  ...newMaterial,
                  userAssignment: newValue
                })
              }}
              id="userAssignmentId"
              options={users}
              setOptions={(inputValue) => {
                void (async () => {
                  await retrieveUsers(inputValue)
                })()
              }}
            />
          </div>
        </div>
      </div>

      <div className="right-content">
        <SubFormHeader title="Informations pratiques" />

        <CustomDatePicker
          handleChangeDate={(e, property) => {
            handleChangeDate(e, property)
          }}
          id="purchaseDate"
          value={newMaterial.purchaseDate}
          errorMessage={errors.purchaseDate?.message}
          required
          label="Date d'achat"
        />

        <CustomDatePicker
          handleChangeDate={(e, property) => {
            handleChangeDate(e, property)
          }}
          id="leavingDate"
          value={newMaterial.leavingDate}
          errorMessage=""
          required={false}
          label="Date de sortie"
        />

        <CustomSelect
          value={newMaterial.place}
          options={places}
          handleSelect={(newValue) => {
            setNewMaterial({ ...newMaterial, place: newValue as number })
          }}
          id="place"
          inputLabel="Lieu"
          required
        />

        <CustomTextField
          onChange={(value) => {
            setNewMaterial({ ...newMaterial, description: value })
          }}
          id="description"
          value={newMaterial.description}
          errorMessage=""
          required={false}
          label="Description"
          multiline
        />

        <CustomTextField
          onChange={(value) => {
            setNewMaterial({ ...newMaterial, comment: value })
          }}
          id="comment"
          value={newMaterial.comment}
          errorMessage=""
          required={false}
          label="Commentaire"
          multiline
        />

        <CustomGroupRadios
          onChange={(value) => {
            setNewMaterial({ ...newMaterial, type: value })
          }}
          id="type"
          gpRadiovalue={newMaterial.type}
          required={false}
          radiosLabelValue={[materialTypeConstant.FIX, materialTypeConstant.PORTABLE]}
        />
      </div>
    </div>
  )
}

export default MaterialForm
