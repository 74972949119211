import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { customAxios } from './axiosUtils'

/**
 Retourner les infos de l'utilisateur ou une erreur si informations non trouvées
 @param credential
 @return les données de l'utilisateur (nom, prénom, photo de profil, rôle...)
 **/
export const connexionGoogle = async (
  credential: string
): Promise<IMinimalCollaborateurData | null> => {
  const data = { credential }
  try {
    return await customAxios.post('login/callback', data)
  } catch (error) {
    return null
  }
}
/**
 Permet de déconnecter l'utilisateur en appelant l'API pour une suppression du cookie de connexion
 **/
export const logout = async (): Promise<any | null> => {
  try {
    return await customAxios.post('logout')
  } catch (error) {
    return null
  }
}

/**
 * Permet de rafraichir le token d'accès
 *
 * @param refreshToken
 * @returns
 */
export const refreshToken = async (
  refreshToken: string
): Promise<{ access_token: string; expires_in: number } | null> => {
  try {
    return await customAxios.post('login/refresh', { refresh_token: refreshToken })
  } catch (error) {
    return null
  }
}
