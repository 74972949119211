import {
  EventBusy,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  MoreVert
} from '@mui/icons-material'
import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import { convertDateTimeToDateString, formatDateToDay } from 'utils/dateUtils'
import { deleteProject, updateProject } from 'services/ProjectsService'
import { TableBody, TableCell, TableRow } from '@mui/material'
import ClientImage from 'components/clientImage/ClientImage'
import MorePopOver from 'components/popup/PopUp/MorePopOver'
import { deleteClient } from 'services/ClientsService'
import React, { ReactElement, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionPopUp from './DescriptionPopUp'
import EditIcon from '@mui/icons-material/Edit'
import { IProject } from 'interfaces/projects'
import { IClient } from 'interfaces/clients'
import { projectStatus } from 'constantes'
import Euro from 'assets/euro.svg'

interface IListClientsBodyTable {
  clients: IClient[]
  setClients: React.Dispatch<React.SetStateAction<IClient[]>>
  setOpenClient: React.Dispatch<React.SetStateAction<boolean>>
  setOpenProject: React.Dispatch<React.SetStateAction<boolean>>
  showFinishedProjects: boolean
  setClientToEdit: React.Dispatch<React.SetStateAction<IClient | undefined>>
  setProjectToEdit: React.Dispatch<React.SetStateAction<IProject | undefined>>
}

const ListClientsBodyTable = ({
  clients,
  setClients,
  setOpenClient,
  setOpenProject,
  showFinishedProjects,
  setClientToEdit,
  setProjectToEdit
}: IListClientsBodyTable): ReactElement => {
  const [selectedClientId, setSelectedClientId] = useState<string | undefined>('')
  const [openDeleteClientPopUp, setOpenDeleteClientPopUp] = useState<boolean>(false)
  const [openDeleteProjectPopUp, setOpenDeleteProjectPopUp] = useState<boolean>(false)
  const [openEndProject, setOpenEndProject] = useState<boolean>(false)
  const [openDescriptionPopUp, setOpenDescriptionPopUp] = useState<boolean>(false)
  const [clientToDelete, setClientToDelete] = useState<IClient>()
  const [projectToDelete, setProjectToDelete] = useState<IProject>()
  const [projectToEnd, setProjectToEnd] = useState<IProject>()
  const [description, setDescription] = useState<string | undefined>('')
  const [showEditClient, setShowEditClient] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | SVGSVGElement | null>(null)

  /**
   * Permet de supprimer un client
   *
   * @returns {Promise<void>}
   */
  const handleClientDelete = async (): Promise<void> => {
    if (clientToDelete !== undefined) {
      const updatedClients = await deleteClient(clientToDelete)
      setClients(updatedClients)
      setOpenDeleteClientPopUp(false)
    }
  }

  /**
   * Permet de supprimer un projet
   *
   * @returns {Promise<void>}
   */
  const handleProjectDelete = async (): Promise<void> => {
    if (projectToDelete !== undefined) {
      const clientId = projectToDelete.client?.id
      const updatedProjects = await deleteProject(projectToDelete)

      setClients((prevClients) => {
        return prevClients.map((client) => {
          if (client.id === clientId) {
            return { ...client, projects: updatedProjects }
          }
          return client
        })
      })
      setOpenDeleteProjectPopUp(false)
    }
  }

  /**
   * Permet de clôturer un projet
   *
   * @returns {Promise<void>}
   */
  const handleProjectEnd = async (): Promise<void> => {
    if (projectToEnd !== undefined) {
      const clientId = projectToEnd.client?.id
      const project: IProject = {
        ...projectToEnd,
        status: {
          id: 2,
          name: projectStatus.CLOSED
        }
      }
      const updatedProjects = await updateProject(project)

      setClients((prevClients) => {
        return prevClients.map((client) => {
          if (client.id === clientId) {
            return { ...client, projects: updatedProjects }
          }
          return client
        })
      })
      setOpenEndProject(false)
    }
  }

  /**
   * Permet d'afficher plus d'options pour un client
   *
   * @param e
   * @param client
   */
  const handleMoreVertClick = (e: React.MouseEvent<SVGSVGElement>, client: IClient): void => {
    e.stopPropagation()
    handleDayClick(e)
    setSelectedClientId(client.id)
    setShowEditClient(!showEditClient)
  }

  /**
   * Permet d'afficher la description d'un projet
   *
   * @param projectDescription
   */
  const handleInfoClick = (projectDescription: string | undefined): void => {
    setDescription(projectDescription)
    setOpenDescriptionPopUp(true)
  }

  /**
   * Permet d'ouvrir la fenêtre de modification d'un projet
   *
   * @param client
   * @param project
   */
  const handleEditProjectClick = (client: IClient, project: IProject): void => {
    setClientToEdit(client)
    setProjectToEdit(project)
    setOpenProject(true)
  }

  /**
   * Permet d'ouvrir la fenêtre de suppression d'un projet
   *
   * @param project
   * @param client
   */
  const handleDeleteProjectClick = (project: IProject, client: IClient): void => {
    setProjectToDelete({ ...project, client })
    setOpenDeleteProjectPopUp(true)
  }

  /**
   * Permet d'ouvrir la fenêtre de clôture d'un projet
   *
   * @param project
   * @param client
   */
  const handleEndProjectClick = (project: IProject, client: IClient): void => {
    setProjectToEnd({ ...project, client })
    setOpenEndProject(true)
  }

  /**
   * Permet d'ouvrir la fenêtre de modification d'un client
   *
   * @param client Le client à modifier
   * @returns {void}
   */
  const handleEditClientClick = (client: IClient): void => {
    setOpenClient(true)
    setClientToEdit(client)
  }

  /**
   * Permet d'ouvrir la fenêtre de suppression d'un client
   *
   * @param client Le client à supprimer
   * @returns {void}
   */
  const handleDeleteClientClick = (client: IClient): void => {
    setClientToDelete(client)
    setOpenDeleteClientPopUp(true)
  }

  /**
   * Permet d'ouvrir la fenêtre de création d'un projet
   *
   * @param client Le client pour lequel créer un projet
   * @returns {void}
   */
  const handleCreateProjectClick = (client: IClient): void => {
    setOpenProject(true)
    setProjectToEdit(undefined)
    setClientToEdit(client)
  }

  /**
   * Permet de gérer le click sur un jour
   *
   * @param event
   */
  const handleDayClick = (event: React.MouseEvent<SVGSVGElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Permet de gérer le click sur un client
   *
   * @param id
   */
  const handleClientClick = (id: string | undefined): void => {
    if (!id || selectedClientId === id) {
      setSelectedClientId(undefined)
    } else {
      setSelectedClientId(id)
    }
  }

  const getProjectCount = (client: IClient): number => {
    if (!client.projects) {
      return 0
    }

    return showFinishedProjects
      ? client.projects?.length
      : client.projects?.filter(
          (project: IProject) => project.status?.name !== projectStatus.CLOSED
        ).length
  }

  return (
    <TableBody>
      {(!showFinishedProjects
        ? clients.filter(
            (client: IClient) =>
              client.projects === undefined ||
              client.projects.length === 0 ||
              client.projects?.some((project: IProject) => project.status?.name !== 'Clôturé')
          )
        : clients
      ).map((client: IClient, index: number) => (
        <React.Fragment key={client.id}>
          <TableRow className={`${index === 0 ? '!border-t-0' : ''} cursor-pointer`} tabIndex={1}>
            <TableCell>
              <div className="flex align-middle">
                <ClientImage client={client} />
                <div className="pt-2 pl-2">{client.name !== '' ? client.name : '-'}</div>
              </div>
            </TableCell>
            <TableCell className="sx-delete"></TableCell>
            <TableCell>
              <div className="pt-2 pl-2 text-gray-500">Client STEAMULO {client.place?.name}</div>
            </TableCell>
            <TableCell></TableCell>
            <TableCell className="hidden md:block"></TableCell>
            <TableCell className="sx-delete">
              {client.projects?.length !== undefined && client.projects.length !== 0
                ? `${getProjectCount(client)} projet${getProjectCount(client) > 1 ? 's' : ''}`
                : 'Aucun projet'}
            </TableCell>
            <TableCell className="space-x-2">
              <>
                <MoreVert
                  id="settings-icon"
                  onClick={(e) => {
                    handleMoreVertClick(e, client)
                  }}
                />
                {selectedClientId === client.id && (
                  <MorePopOver
                    open={showEditClient}
                    setOpen={setShowEditClient}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    buttonConfigs={[
                      {
                        label: 'Modifier le client',
                        onClick: () => {
                          handleEditClientClick(client)
                        },
                        show: true
                      },
                      {
                        label: 'Supprimer le client',
                        onClick: () => {
                          handleDeleteClientClick(client)
                        },
                        show: client.projects?.length === 0
                      },
                      {
                        label: 'Créer un projet',
                        onClick: () => {
                          handleCreateProjectClick(client)
                        },
                        show: true
                      }
                    ]}
                  />
                )}
              </>
              {client.projects?.length !== 0 &&
                (selectedClientId === client.id ? (
                  <KeyboardArrowDown
                    id="arrow"
                    onClick={() => {
                      handleClientClick(client.id)
                    }}
                  />
                ) : (
                  <KeyboardArrowRight
                    id="arrow"
                    onClick={() => {
                      handleClientClick(client.id)
                    }}
                  />
                ))}
            </TableCell>
          </TableRow>
          {selectedClientId === client.id &&
            client.projects
              ?.filter((project: IProject) => showFinishedProjects || project.status?.id !== 2)
              .map((project: IProject) => (
                <TableRow className="bg-gray-100" tabIndex={-1} key={project.id}>
                  <TableCell>
                    <div className="flex">
                      <div className="pr-2">
                        {project.name}
                        {project.identifier !== undefined &&
                          project.identifier !== '' &&
                          ` (${project.identifier})`}
                      </div>
                      {project.description !== undefined && project.description !== '' && (
                        <Info
                          id="info-icon"
                          onClick={() => {
                            handleInfoClick(project.description)
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{project.type?.name}</TableCell>
                  <TableCell className="sx-delete">
                    {project.startingDate &&
                      formatDateToDay(convertDateTimeToDateString(project.startingDate))}
                    {project.endingDate && (
                      <>
                        {' - '}
                        {formatDateToDay(convertDateTimeToDateString(project.endingDate))}
                      </>
                    )}
                  </TableCell>
                  <TableCell className="sx-delete">
                    {project.invoice === true && <img src={Euro} width={25} />}
                  </TableCell>
                  <TableCell>
                    <div
                      className="text-center pt-1 lg:h-[30px] lg:w-[70%] rounded-md"
                      style={
                        project.status?.name === projectStatus.INPROGRESS
                          ? { color: '#73a689', backgroundColor: '#d9e9e0' }
                          : { color: '#ee9e56', backgroundColor: '#fae2d1' }
                      }
                    >
                      {project.status?.name}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="buttons-container">
                      <EditIcon
                        id="edit-icon"
                        onClick={() => {
                          handleEditProjectClick(client, project)
                        }}
                      />
                      <DeleteIcon
                        id="delete-icon"
                        onClick={() => {
                          handleDeleteProjectClick(project, client)
                        }}
                      />
                      {project.status?.name === projectStatus.INPROGRESS && (
                        <EventBusy
                          id="end-icon"
                          onClick={() => {
                            handleEndProjectClick(project, client)
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
        </React.Fragment>
      ))}
      <ConfirmationPopUp
        dialogTitle="Voulez-vous supprimer ce client ?"
        dialogContentText="Les données de ce client seront supprimées"
        open={openDeleteClientPopUp}
        setOpen={setOpenDeleteClientPopUp}
        handleSave={handleClientDelete}
        loading={false}
      />
      <ConfirmationPopUp
        dialogTitle="Voulez-vous supprimer ce projet ?"
        dialogContentText="Les données de ce projet ainsi que tous les pointages associés seront supprimés"
        open={openDeleteProjectPopUp}
        setOpen={setOpenDeleteProjectPopUp}
        handleSave={handleProjectDelete}
        loading={false}
      />
      <ConfirmationPopUp
        dialogTitle="Voulez-vous clôturer ce projet ?"
        dialogContentText="Le projet ne sera plus accessible au pointage dans le reporting"
        open={openEndProject}
        setOpen={setOpenEndProject}
        handleSave={handleProjectEnd}
        loading={false}
      />
      <DescriptionPopUp
        descriptionContent={description}
        open={openDescriptionPopUp}
        setOpen={setOpenDescriptionPopUp}
      />
    </TableBody>
  )
}

export default ListClientsBodyTable
