import { Chip, InputLabel, ListItem } from '@mui/material'

import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import React, { ReactElement, useState } from 'react'

interface IInterviewPopupUserInput {
  handleDelete: (chipToDelete: IMinimalCollaborateurData) => () => void
  retrieveUsers: (inputValue: string) => Promise<IMinimalCollaborateurData[]>
  users: IMinimalCollaborateurData[]
  selectedUsers: IMinimalCollaborateurData[] | undefined
  title: string
  handleChange: (newValue: IMinimalCollaborateurData) => void
}

const InterviewPopupUserInput = ({
  handleDelete,
  retrieveUsers,
  users,
  selectedUsers,
  title,
  handleChange
}: IInterviewPopupUserInput): ReactElement => {
  const [requestedUser] = useState<IMinimalCollaborateurData | null>(null)

  return (
    <>
      <div>
        <InputLabel className="mb-1" id={title}>
          {title}
        </InputLabel>
        <AutoCompleteUserInput
          width={320}
          value={requestedUser}
          onChange={(e: any, newValue: IMinimalCollaborateurData | null): void => {
            if (newValue !== null) {
              handleChange(newValue)
            }
          }}
          id={title}
          options={users}
          setOptions={(inputValue: string) => {
            void (async () => {
              await retrieveUsers(inputValue)
            })()
          }}
          clearInputAfterSelect
        />
      </div>
      <div className="chips">
        {selectedUsers?.map((data: IMinimalCollaborateurData) => {
          return (
            <ListItem key={data.id}>
              <Chip
                label={`${data.displayFirstname} ${data.displayLastname}`}
                onDelete={handleDelete(data)}
              />
            </ListItem>
          )
        })}
      </div>
    </>
  )
}

export default InterviewPopupUserInput
