import { IheadCells } from 'interfaces/headCells'

export const tableMaterialsColumns: IheadCells[] = [
  {
    id: 'brand',
    label: 'Marque',
    priority: 2
  },
  {
    id: 'model',
    label: 'Modèle',
    priority: 1
  },
  {
    id: 'os',
    label: 'OS',
    priority: 4
  },
  {
    id: 'serialNumber',
    label: 'N° série',
    priority: 3
  },
  {
    id: 'type',
    label: 'Type',
    priority: 4
  },
  {
    id: 'purchaseDate',
    label: 'Achat',
    priority: 3
  },
  {
    id: 'userAssignment',
    label: 'Attribution',
    priority: 1
  },
  {
    id: 'inventoryNumber',
    label: 'N° inventaire',
    priority: 2
  }
]
