import {
  IBill,
  IExpense,
  IExpenseBillRequest,
  IExpensesSubtype,
  IExpensesType,
  IMyExpense,
  IUpdateExpense,
  IUpdateResponseBill
} from 'interfaces/expenses'
import { PdfFile } from 'interfaces/pfdFile'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

/**
 * Permet d'obtenir les sous-types de notes de frais
 */
export const getExpensesSubtypes = async (): Promise<IExpensesSubtype[]> => {
  try {
    return await customAxios.get('expenses-subtypes')
  } catch (error) {
    return []
  }
}

export const getExpensesTypes = async (): Promise<IExpensesType[]> => {
  try {
    return await customAxios.get('expenses-types')
  } catch (error) {
    return []
  }
}

/**
 * Crée les settings de note de frais du collaborateur
 */
export const createUserExpenseSettings = async (
  userId: number,
  data: IMyExpense
): Promise<IMyExpense[]> => {
  try {
    return await customAxios.post(`/users/${userId}/expense-settings`, data)
  } catch (error) {
    return []
  }
}

/**
 * Maj un setting de note de frais du collaborateur
 */
export const updateUserExpenseSetting = async (
  userId: number,
  data: IMyExpense
): Promise<IMyExpense | null> => {
  try {
    if (data.id !== null) {
      return await customAxios.put(`/users/${userId}/expense-settings/${data.id}`, data)
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir les notes de frais d'un collaborateur
 */
export const getMyExpenses = async (year: number, userId: number): Promise<IExpense[] | null> => {
  try {
    const yearParam = `year=${year}`
    return await customAxios.get(`users/${userId}/expenses?${yearParam}`)
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir le statut des notes de frais
 */
export const getStatusExpenses = async (year: number, month: number): Promise<string> => {
  try {
    const yearParam = `year=${year}`
    const monthParam = `&month=${month}`
    return await customAxios.get(`expenses/status?${yearParam}${monthParam}`)
  } catch (error) {
    return ''
  }
}

/**
    Récupérer tous les settings de notifs associés au user
**/
export const getMyExpensesSettings = async (userId: number): Promise<IMyExpense[]> => {
  try {
    return await customAxios.get(`/users/${userId}/expense-settings`)
  } catch (error) {
    return []
  }
}

/**
 * Permet d'obtenir les factures des notes de frais d'un collaborateur
 */
export const getMyBills = async (userId: number, year: number): Promise<IBill[] | null> => {
  try {
    return await customAxios.get(`users/${userId}/expenses-bills?year=${year}`)
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir les factures des notes de frais des collaborateurs pour un mois donné
 */
export const getBills = async (
  month: number,
  year: number,
  place: number | null
): Promise<IBill[] | null> => {
  try {
    const placeParam = place !== null ? `&placeId=${place}` : ''
    return await customAxios.get(`expenses-bills?year=${year}&month=${month}${placeParam}`)
  } catch (error) {
    return null
  }
}

/**
 * Permet d'ajouter une nouvelle facture de note de frais
 */
export const createorUpdateExpenseBill = async (
  data: IExpenseBillRequest,
  userId: number
): Promise<IBill | null> => {
  try {
    const result: IUpdateResponseBill = await customAxios.post(
      `users/${userId}/expenses-bill`,
      data
    )
    toast.success('Votre note de frais a bien été ajoutée')
    // eslint-disable-next-line
    if (result.recalcul) {
      toast.warning('Le montant remboursé de votre note de frais a été recalculé')
    }
    return result.data
  } catch (error) {
    return null
  }
}

/**
 * Mettre à jour le statut d'une note de frais
 */
export const updateExpenseStatus = async (
  expenseId: number,
  data: IUpdateExpense
): Promise<IExpense | null> => {
  try {
    let result
    if (expenseId !== undefined) {
      result = await customAxios.put(`expenses/${expenseId}`, data)
    }
    return result as unknown as IExpense
  } catch (error) {
    return null
  }
}

/**
 * Supprimer une facture de note de frais
 */
export const deleteBill = async (billId: number): Promise<IBill | null> => {
  try {
    return await customAxios.delete(`expenses-bill/${billId}`)
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir toutes les notes de frais de tous les collaborateurs pour un mois donné
 */
export const getExpenses = async (
  month: number | null,
  year: number | null,
  place: number | null
): Promise<IExpense[] | null> => {
  try {
    const monthParam = month !== null ? `&month=${month}` : ''
    const yearParam = year !== null ? `&year=${year}` : ''
    const placeParam = place !== null ? `&placeId=${place}` : ''
    return await customAxios.get(`expenses?${monthParam}${yearParam}${placeParam}`)
  } catch (error) {
    return null
  }
}

/**
 * Permet de générer le fichier zip avec le récapitulatif des notes de frais du mois
 */
export const getMonthlyExpensesOverview = async (
  month: number,
  year: number,
  place: number | null
): Promise<PdfFile | null> => {
  try {
    const placeParam = place !== null ? `&placeId=${place}` : ''
    return await customAxios.get(`expenses/overview?month=${month}&year=${year}${placeParam}`, {
      responseType: 'arraybuffer'
    })
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir un pdf concaténant les fichiers des factures d'un collaborateur pour un mois
 */
export const getConcatenatedBillsFile = async (expenseId: number): Promise<PdfFile | null> => {
  try {
    return await customAxios.get(`expenses/${expenseId}/bills-file`, {
      responseType: 'arraybuffer'
    })
  } catch (error) {
    toast.warning('Aucun document renseigné pour ce collaborateur')
    return null
  }
}

/**
 * Permet d'obtenir le total annuel des notes de frais
 */
export const getMonthTotal = async (
  year: number,
  month: number,
  place: number | null
): Promise<number | null> => {
  try {
    const placeParam = place !== null ? `&placeId=${place}` : ''
    return await customAxios.get(`expenses-total?year=${year}&month=${month}${placeParam}`)
  } catch (error) {
    return null
  }
}

/**
 * Permet d'envoyer un mail de rappel aux personnes n'ayant pas saisi ou signer leur note de frais
 */
export const sendReminderNDF = async (
  year: number,
  month: number,
  place: number | null
): Promise<number | null> => {
  try {
    const placeParam = place !== null ? `&placeId=${place}` : ''
    return await customAxios.post(`expenses-reminder?year=${year}&month=${month}${placeParam}`)
  } catch (error) {
    return null
  }
}
