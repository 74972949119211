import CustomGroupRadios from 'components/inputs/customGroupRadios/CustomGroupRadios'
import { IUpdateUserData } from 'interfaces/collaborateurData'
import { mealPlanConstant } from 'constantes'
import React, { ReactElement } from 'react'

interface IMealPlanInfos {
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
}

const MealPlanInfos = ({
  editMode,
  userDataToSend,
  setUserDataToSend
}: IMealPlanInfos): ReactElement => {
  return (
    <div className="box-data">
      <div className="category-title">Autres avantages</div>
      <div className="box-inline-elts">
        <div className="box-column">
          <div>
            {editMode ? (
              <CustomGroupRadios
                onChange={(value) => {
                  if (value === 'Aucun') {
                    setUserDataToSend({ ...userDataToSend, mealPlan: null })
                  } else {
                    setUserDataToSend({ ...userDataToSend, mealPlan: value })
                  }
                }}
                id="mealPlan"
                gpRadiovalue={userDataToSend.mealPlan}
                required={false}
                radiosLabelValue={[mealPlanConstant.RESTAU_TICKETS, mealPlanConstant.IFR, 'Aucun']}
              />
            ) : (
              <>
                <p className="data-title">Indemnité repas</p>
                <p className="data-value">{userDataToSend.mealPlan ?? 'Aucun'}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MealPlanInfos
