import {
  getDayTypes,
  getGroupsPlanning,
  getTotalUsersPresentials,
  getUserPlanningOfTheYear,
  getUsersPlanning
} from 'services/PlanningService'
import {
  getLocalStorageWithExpiration,
  hasAccountantRole,
  hasRHRole,
  setLocalStorageWithExpiration
} from 'utils/otherUtils'
import { IDayType, IGroupPlanningDate, IPlanningDate, IUserPlanningDate } from 'interfaces/planning'
import { CurrentUserContext, MessagesContext, PlaceSelectedContext, YearSelectedContext } from 'App'
import { addFavoriteUserStatus, deleteFavoriteUserStatus, getUserById } from 'services/UserService'
import { IDetailCollaborateurData, IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import PlanningMonthOrWeekChanger from './planningMonthOrWeekChanger/PlanningMonthOrWeekChanger'
import { enumerateDaysBetweenDates, getHolidayDatesWithoutPentecote } from 'utils/dateUtils'
import { ONE_DAY_DURATION_IN_MINUTES, planningViewEnum, screenSizes } from 'constantes'
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import PlanningDaysDisplayer from './planningDaysDisplayer/PlanningDaysDisplayer'
import PlanningYearDisplayer from './planningYearDisplayer/PlanningYearDisplayer'
import { getUsersWithPagination } from 'services/DataWithPaginationService'
import { ICollabsWithPagination } from 'interfaces/dataWithPagination'
import UserPlanningRow from './userPlanningRow/UserPlanningRow'
import CircularProgress from '@mui/material/CircularProgress'
import InfiniteScroll from 'react-infinite-scroll-component'
import PlanningHeader from './planningHeader/PlanningHeader'
import { getMessages } from '../../services/MessageService'
import useScreenSize from 'customHooks/useScreenSize'
import Holidays from 'date-holidays'
import moment from 'moment'
import './Planning.scss'

const Planning = (): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null

  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser
  const messagesContext = useContext(MessagesContext)

  const LIMIT_USERS_PLANNING = 20

  const isSmallScreen = useScreenSize(screenSizes.TABLET_SIZE)

  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()

  /**
   * Récupérer la date suivant celle sélectionnée
   */
  const getCurrentDateByYearContext = (isSmallScreen: boolean): Date => {
    if (year === undefined || new Date().getFullYear() === year) {
      let newFirstDay = new Date()
      if (isSmallScreen) {
        // quand on passe en semaine, on veut revenir sur la semaine du jour
        newFirstDay = moment(newFirstDay).startOf('isoWeek').toDate()
      } else {
        newFirstDay = moment(newFirstDay).startOf('month').toDate()
      }
      return newFirstDay
    }
    return new Date(year, 0, 1)
  }

  const queryParameters = new URLSearchParams(window.location.search)

  const [usersPlanning, setUsersPlanning] = useState<IUserPlanningDate[]>([])
  const [groupsPlanning, setGroupsPlanning] = useState<IGroupPlanningDate[]>([])
  const [currentUserPlanning, setCurrentUserPlanning] = useState<IPlanningDate[]>([])
  const [currentDate, setCurrentDate] = useState<Date>(getCurrentDateByYearContext(isSmallScreen))
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [contractSearch, setContractSearch] = useState<number | null>(null)
  const [listDates, setListDates] = useState<string[]>([])
  const [dayTypes, setDayTypes] = useState<IDayType[]>([])
  const [scrollPage, setScrollPage] = useState<number>(0)
  const [more, setMore] = useState<boolean>(true)
  const [sliderDirectionClass, setSliderDirectionClass] = useState<string>('')
  const [dayTypesFilter, setDayTypesFilter] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [planningViewSelected, setPlanningViewSelected] = useState<string>(
    queryParameters.get('view') === 'annual' ? planningViewEnum.YEAR : planningViewEnum.MONTH
  )
  const [idUserYearPlanning, setIdUserYearPlanning] = useState<number>(
    Number(queryParameters.get('userId'))
  )
  const [userYearPlanning, setUserYearPlanning] = useState<IDetailCollaborateurData | null>(null)
  const [totalUsersPresentials, setTotalUsersPresentials] = useState<[]>([])

  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [hoveredDay, setHoveredDay] = useState<string | null>(null)

  const hd = new Holidays('FR')
  // Obtenez les dates des jours fériés pour une plage de dates donnée en retirant le jour de la pentcote qui n'est pas férié chez Steamulo
  const holidayDates = useMemo(() => {
    return getHolidayDatesWithoutPentecote(hd, currentDate.getFullYear())
  }, [currentDate.getFullYear()])

  const user = getLocalStorageWithExpiration('user')
  const isAdmin = user !== null ? hasRHRole(JSON.parse(user).rolePermissions) : false
  const canExport =
    user !== null
      ? hasRHRole(JSON.parse(user).rolePermissions) ||
        hasAccountantRole(JSON.parse(user).rolePermissions)
      : false

  useEffect(() => {
    void (async () => {
      setDayTypes(await getDayTypes())
      const messages = await getMessages(true, user.place?.id ?? 1)
      messagesContext?.setMessages(messages)
    })()
  }, [])

  useEffect(() => {
    updateListMonth()
  }, [isSmallScreen, year])

  const updateListMonth = (): void => {
    const newCurrentDate = getCurrentDateByYearContext(isSmallScreen)
    setCurrentDate(newCurrentDate)
    setDayListOnCurrentMonthOrWeek(newCurrentDate)
    setSelectedDate(null)
  }

  // chargement des nouveaux plannings quand on change de mois ou semaine sans loader
  useEffect(() => {
    void (async () => {
      if (planningViewSelected === planningViewEnum.MONTH) {
        await loadUsersPlanningDate()
      } else if (planningViewSelected === planningViewEnum.GROUP) {
        await loadGroupsPlanningDate()
      }
      setTotalUsersPresentials(await getTotalUsersPresentials(currentDate, place))
    })()
  }, [currentDate])

  useEffect(() => {
    void (async () => {
      await loadCurrentUserPlanningDatesForOneYear(idUserYearPlanning)
    })()
  }, [idUserYearPlanning, year])

  useEffect(() => {
    void (async () => {
      if (planningViewSelected === planningViewEnum.MONTH) {
        await loadUsersPlanningWithLoading()
      }
      if (planningViewSelected === planningViewEnum.GROUP) {
        await loadGroupsPlanningDate()
      }
    })()
  }, [searchTerm, place, dayTypesFilter, contractSearch])

  useEffect(() => {
    void (async () => {
      if (planningViewSelected === planningViewEnum.YEAR && isAdmin) {
        setLoading(true)
        const response = (await getUsersWithPagination(
          1,
          20,
          place,
          null,
          searchTerm,
          null,
          null,
          [],
          null,
          Number(yearSelectedContext?.yearSelected)
        )) as ICollabsWithPagination
        if (response.data.length === 1) {
          setIdUserYearPlanning(response.data[0].id)
        }
        setLoading(false)
      }
    })()
  }, [searchTerm])

  const loadUsersPlanningWithLoading = async (): Promise<void> => {
    setLoading(true)
    setSliderDirectionClass('')
    await loadUsersPlanningDate()
    setLoading(false)
  }

  const setDayListOnCurrentMonthOrWeek = (date: Date): void => {
    // Ajoute 1 à la journée pour être certain de récupérer la semaine courant ou mois courant de la date passée
    const validDateToGetInterval = moment(date).add(1, 'day')
    let dates = ['']
    // Récupérer que la semaine si petit écran
    if (isSmallScreen) {
      dates = enumerateDaysBetweenDates(
        validDateToGetInterval.startOf('isoWeek').toDate(),
        validDateToGetInterval.endOf('isoWeek').toDate()
      )
    } else {
      // Charger la liste des jours du mois
      dates = enumerateDaysBetweenDates(
        validDateToGetInterval.startOf('month').toDate(),
        validDateToGetInterval.endOf('month').toDate()
      )
    }

    setListDates(dates)
  }

  const handleChangePlanning = (
    updatedPlanning: IUserPlanningDate,
    firstDateUpdated: Date,
    lastDateUpdated: Date
  ): void => {
    const updatedUsersPlanning = usersPlanning.map((userPlanning) => {
      if (userPlanning.planningUser?.id === updatedPlanning?.planningUser?.id) {
        if (updatedPlanning?.planningDates !== undefined) {
          return { ...userPlanning, planningDates: updatedPlanning.planningDates }
        }
      }
      return userPlanning
    })
    // Si l'utilisateur a modifié son planning, on met à jour la vue annuelle
    if (updatedPlanning?.planningDates !== undefined) {
      // On garde les mois non modifiés (car updatedPlanning contient seulement les modifications des mois modifiés)
      const updatedCurrentUserPlanning = currentUserPlanning?.filter(
        (planningDate) =>
          moment(planningDate?.dayDate).month() !== moment(firstDateUpdated).month() &&
          moment(planningDate?.dayDate).month() !== moment(lastDateUpdated).month()
      )
      // On ajoute les mois modifiés
      setCurrentUserPlanning([...updatedCurrentUserPlanning, ...updatedPlanning?.planningDates])
    }
    setUsersPlanning(updatedUsersPlanning)
  }

  const checkWeeklyIndex = (addMonth: boolean): void => {
    let newDate
    if (addMonth) {
      newDate = moment(currentDate).add(1, 'week').toDate()
    } else {
      newDate = moment(currentDate).subtract(1, 'week').toDate()
    }
    setCurrentDate(newDate)
    setDayListOnCurrentMonthOrWeek(newDate)
  }

  const changeWeekOrMonth = (addMonth: boolean): void => {
    setSliderDirectionClass('')
    if (isSmallScreen) {
      checkWeeklyIndex(addMonth) // Fonction permettant de modifier le starting day index
    } else {
      let newDate
      if (addMonth) {
        newDate = moment(currentDate).add(1, 'month')
      } else {
        newDate = moment(currentDate).subtract(1, 'month')
      }
      setCurrentDate(newDate.startOf('month').toDate())
      setDayListOnCurrentMonthOrWeek(newDate.startOf('month').toDate())
    }

    setTimeout(() => {
      setSliderDirectionClass(`month-slider ${!addMonth ? 'reverse' : ''} `)
    }, 50) // délai de 500ms avant de changer la classe
  }

  // Permet de récupérer les premiers utilisateurs
  const loadUsersPlanningDate = async (): Promise<void> => {
    setScrollPage(1)
    const planning = await getUsersPlanning(
      searchTerm,
      currentDate,
      isSmallScreen,
      1,
      LIMIT_USERS_PLANNING,
      place,
      dayTypesFilter,
      contractSearch,
      selectedDate
    )
    setUsersPlanning(planning)
    // Détermine s'il est possible de charger plus d'éléments
    if (planning.length === LIMIT_USERS_PLANNING) {
      setMore(true)
    } else {
      setMore(false)
    }
  }

  // Permet de récupérer les groupes
  const loadGroupsPlanningDate = async (): Promise<void> => {
    setScrollPage(1)
    const planningGroup = await getGroupsPlanning(
      searchTerm,
      currentDate,
      isSmallScreen,
      1,
      LIMIT_USERS_PLANNING,
      place,
      dayTypesFilter,
      contractSearch
    )
    setGroupsPlanning(planningGroup)
  }

  const totalByDayType = (planning: IPlanningDate[]): void => {
    setDayTypes((prevDayTypes) => {
      const result: IDayType[] = [...prevDayTypes]
      const counts: Record<string, number> = {}

      prevDayTypes.forEach((dayType) => {
        dayType.totalDayType = undefined
      })

      planning.forEach((userPlanning: IPlanningDate) => {
        const { dayType } = userPlanning

        if (dayType !== undefined) {
          const { label } = dayType
          if (label !== undefined) {
            counts[label] = (counts[label] ?? 0) + 1
          }
        }
      })

      Object.entries(counts).forEach(([label, totalDayType]) => {
        const existingDayTypeIndex = result.findIndex((dayType) => dayType.label === label)
        result[existingDayTypeIndex] = {
          ...result[existingDayTypeIndex],
          totalDayType
        }
      })
      return result
    })
  }

  // Permet de récupérer le planning de l'utilisateur connecté
  const loadCurrentUserPlanningDatesForOneYear = async (userId: number): Promise<void> => {
    if (userId === 0 || userId === JSON.parse(user).id) {
      userId = JSON.parse(user).id
      setUserYearPlanning(JSON.parse(user))
    } else {
      setUserYearPlanning(await getUserById(userId, year))
    }

    const planning = await getUserPlanningOfTheYear(userId, year)
    totalByDayType(planning)
    setCurrentUserPlanning(planning)
  }

  // Permet de récupérer les utilisateurs suivants suite à un scroll
  const loadNextUsers = async (): Promise<void> => {
    const newScrollPage = scrollPage + 1
    setScrollPage(newScrollPage)
    const planning = await getUsersPlanning(
      searchTerm,
      currentDate,
      isSmallScreen,
      newScrollPage,
      LIMIT_USERS_PLANNING,
      place,
      dayTypesFilter,
      contractSearch,
      selectedDate
    )
    if (planning.length > 0) {
      const result = [...usersPlanning].concat(planning)
      setUsersPlanning(result) // planning de tous les utilisateurs
    } else {
      setMore(false)
    }
  }

  const updateUserFavorite = async (
    user: IDetailCollaborateurData | null,
    isNewFavorite: boolean
  ): Promise<void> => {
    if (user !== null) {
      let newUserResponse: IMinimalCollaborateurData | null
      if (isNewFavorite) {
        newUserResponse = await addFavoriteUserStatus(user.id)
      } else {
        newUserResponse = await deleteFavoriteUserStatus(user.id)
      }

      updateUserContext(newUserResponse)
      orderUsersPlanning(newUserResponse)
    }
  }

  const updateUserContext = (newUserResponse: IMinimalCollaborateurData | null): void => {
    // obligé de set aussi le local storage pour ne pas perdre les donnée du user au refresh
    setLocalStorageWithExpiration(
      'user',
      JSON.stringify(newUserResponse),
      ONE_DAY_DURATION_IN_MINUTES
    )
    userContext?.setCurrentUser(newUserResponse)
  }

  const orderUsersPlanning = (newUserResponse: IMinimalCollaborateurData | null): void => {
    setUsersPlanning((prev) => {
      const [firstElement, ...restOfElements] = prev
      const sortedRestOfElements = restOfElements.sort((a, b) => {
        const isAInFavorites: boolean =
          newUserResponse?.favorites.some((favorite) => favorite.id === a.planningUser?.id) ?? false
        const isBInFavorites: boolean =
          newUserResponse?.favorites.some((favorite) => favorite.id === b.planningUser?.id) ?? false

        // Trier d'abord en fonction de la présence dans les favoris
        if (isAInFavorites && !isBInFavorites) {
          return -1 // A avant B
        } else if (!isAInFavorites && isBInFavorites) {
          return 1 // B avant A
        }

        // Si les deux utilisateurs sont dans les favoris ou hors des favoris, tri alphabétique par nom
        const nameA = a.planningUser?.lastname?.toLowerCase() ?? ''
        const nameB = b.planningUser?.lastname?.toLowerCase() ?? ''
        return nameA.localeCompare(nameB)
      })
      return [firstElement, ...sortedRestOfElements]
    })
  }

  // Trie les groupes pour mettre ceux de l'utilisateur en premier
  const orderedGroups = groupsPlanning.sort((groupA, groupB) => {
    const containsCurrentUserA =
      groupA?.planningUsers?.some(
        (userPlanning) => userPlanning?.planningUser?.lastname === currentUser?.lastname
      ) ?? false
    const containsCurrentUserB =
      groupB?.planningUsers?.some(
        (userPlanning) => userPlanning?.planningUser?.lastname === currentUser?.lastname
      ) ?? false

    return containsCurrentUserA === containsCurrentUserB ? 0 : containsCurrentUserA ? -1 : 1
  })

  return (
    <div className="planning-container">
      <PlanningHeader
        dayTypes={dayTypes}
        setSearchTerm={setSearchTerm}
        isSmallScreen={isSmallScreen}
        setContractSearch={setContractSearch}
        dayTypesFilter={dayTypesFilter}
        setDayTypesFilter={setDayTypesFilter}
        planningViewSelected={planningViewSelected}
        setPlanningViewSelected={async (value: string) => {
          setPlanningViewSelected(value)
          setIdUserYearPlanning(JSON.parse(user).id)
          if (value === planningViewEnum.MONTH && usersPlanning.length === 0) {
            await loadUsersPlanningDate()
          }
          if (value === planningViewEnum.GROUP && groupsPlanning.length === 0) {
            await loadGroupsPlanningDate()
          }
          setTotalUsersPresentials(await getTotalUsersPresentials(currentDate, place))
        }}
      />
      {(planningViewSelected === planningViewEnum.MONTH ||
        planningViewSelected === planningViewEnum.GROUP) && (
        <>
          <PlanningMonthOrWeekChanger
            changeWeekOrMonth={changeWeekOrMonth}
            currentDate={currentDate}
            showExportButton={canExport}
            place={place}
            dayTypesFilter={dayTypesFilter}
            isSmallScreen={isSmallScreen}
          />
          <PlanningDaysDisplayer
            isSmallScreen={isSmallScreen}
            listDates={listDates}
            holidayDates={holidayDates}
            updateInitalDate={updateListMonth}
            totalUsersPresentials={totalUsersPresentials}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            hoveredDay={hoveredDay}
          />
        </>
      )}

      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          {planningViewSelected === planningViewEnum.MONTH &&
            (usersPlanning.length > 0 ? (
              <div className="scrollableDiv" id="scrollableDiv">
                <InfiniteScroll
                  style={{ overflow: 'hidden' }}
                  dataLength={usersPlanning.length}
                  next={loadNextUsers}
                  hasMore={more}
                  scrollableTarget="scrollableDiv"
                  loader=""
                >
                  <div className={sliderDirectionClass}>
                    {usersPlanning.map((userPlanning, index) => (
                      <UserPlanningRow
                        key={index}
                        user={userPlanning.planningUser}
                        planningDates={userPlanning.planningDates}
                        listDates={listDates}
                        isAdmin={isAdmin}
                        isSelfUser={userPlanning.planningUser?.email === currentUser?.email}
                        dayTypes={dayTypes}
                        handleChangePlanning={handleChangePlanning}
                        holidayDates={holidayDates}
                        updateUserFavorite={updateUserFavorite}
                        favorites={currentUser?.favorites ?? []}
                        setIdUserYearPlanning={(id) => {
                          setPlanningViewSelected(planningViewEnum.YEAR)
                          setIdUserYearPlanning(id)
                        }}
                        onDayHover={setHoveredDay}
                      ></UserPlanningRow>
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            ) : (
              <h4 className="text-center mt-[5%]">Collaborateur(s) non trouvé(s)</h4>
            ))}

          {planningViewSelected === planningViewEnum.GROUP &&
            (groupsPlanning.length > 0 ? (
              <div className="scrollableDiv" id="scrollableDiv">
                <InfiniteScroll
                  style={{ overflow: 'hidden' }}
                  dataLength={usersPlanning.length}
                  next={loadNextUsers}
                  hasMore={more}
                  scrollableTarget="scrollableDiv"
                  loader=""
                >
                  <div className={sliderDirectionClass}>
                    {orderedGroups.map((group, outerIndex) => (
                      <div key={outerIndex}>
                        <div className="planning-group-title">{group.title}</div>
                        {group.planningUsers?.map((userPlanning, innerIndex) => (
                          <UserPlanningRow
                            key={innerIndex}
                            user={userPlanning.planningUser}
                            planningDates={userPlanning.planningDates}
                            listDates={listDates}
                            isAdmin={isAdmin}
                            isSelfUser={userPlanning.planningUser?.email === currentUser?.email}
                            dayTypes={dayTypes}
                            handleChangePlanning={handleChangePlanning}
                            holidayDates={holidayDates}
                            updateUserFavorite={updateUserFavorite}
                            favorites={currentUser?.favorites ?? []}
                            setIdUserYearPlanning={(id) => {
                              setPlanningViewSelected(planningViewEnum.YEAR)
                              setIdUserYearPlanning(id)
                            }}
                            onDayHover={setHoveredDay}
                          ></UserPlanningRow>
                        ))}
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            ) : (
              <></>
            ))}

          {planningViewSelected === planningViewEnum.YEAR && (
            <PlanningYearDisplayer
              holidayDates={holidayDates}
              currentDate={currentDate}
              currentUserPlanning={currentUserPlanning}
              isSmallScreen={isSmallScreen}
              isAdmin={isAdmin}
              user={userYearPlanning}
              dayTypes={dayTypes}
              handleChangePlanning={handleChangePlanning}
            />
          )}
        </>
      )}
    </div>
  )
}

export default Planning
