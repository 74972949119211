import {
  createOrUpdateAllUsersHolidayQuota,
  createOrUpdateUsersHolidayQuota
} from 'services/HolidayService'
import { CurrentUserContext, FeaturesContext, PlaceSelectedContext, YearSelectedContext } from 'App'
import { tableCollabsColumns, tableCollabsWithHolidayColumns } from './data/tableCollabsColumns'
import { ROWS_PER_PAGE, Status, StatusEnum, featuresList, screenSizes } from 'constantes'
import MultiCustomSelect from 'components/inputs/customSelect/MultiCustomSelect'
import { getContractTypes, getServices } from 'services/SelectorsValuesService'
import FilteredListItems from 'components/filteredListItems/FilteredListItems'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { getUsersWithPagination } from 'services/DataWithPaginationService'
import { IUsersHolidayQuota } from 'interfaces/holidays/userHolidayQuota'
import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { ICollabsWithPagination } from 'interfaces/dataWithPagination'
import QuotasPopUp from 'components/popup/quotasPopUp/QuotasPopUp'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { isFeatureEnabled } from '../../services/SettingService'
import Permission from '../../components/permission/Permission'
import SearchBox from 'components/inputs/searchBox/SearchBox'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation, useNavigate } from 'react-router-dom'
import { hasAccountantRole } from '../../utils/otherUtils'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ListCollabsBodyTable from './ListCollabsBodyTable'
import { IListSelectorValue } from 'interfaces/selectors'
import useScreenSize from 'customHooks/useScreenSize'
import { toApiStatus } from 'utils/statusApiUtils'
import CloseIcon from '@mui/icons-material/Close'
import { Tooltip } from '@material-ui/core'
import { Order } from 'types/order'
import './ListCollabs.scss'
import moment from 'moment'

const ListCollabs = (): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const yearSelectedContext = useContext(YearSelectedContext)
  const featuresContext = useContext(FeaturesContext)
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)
  const [onlyShowFilter, setOnlyShowFilter] = useState<boolean>(false)
  const [contractType, setContractType] = useState<string | number | null>(null)
  const [services, setServices] = useState<string[]>([])
  const [contractTypes, setContractTypes] = useState<IListSelectorValue[]>([])
  const [page, setPage] = useState(1)
  const [collabs, setCollabs] = useState<IDetailCollaborateurData[]>([])
  const [nbPages, setNbPages] = useState<number>(1)
  const [order, setOrder] = useState<Order>('desc')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [status, setStatus] = useState<Status | null>(null)
  const [selectedServices, setSelectedServices] = useState<string[]>([])
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const orderByParam = queryParams.get('tri')
  const [orderBy, setOrderBy] = useState<string>(orderByParam !== null ? orderByParam : 'updatedAt')
  const [selectedCollabs, setSelectedCollabs] = useState<IDetailCollaborateurData[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [userHolidaysQuota, setUserHolidaysQuota] = useState<IUsersHolidayQuota>({
    year: moment().year(),
    RTTQuota: 0,
    paidVacationQuota: 0,
    users: []
  })
  const [needReload, setNeedReload] = useState<boolean>(false)
  const [filtersInitialized, setFiltersInitialized] = useState<boolean>(false)

  const navigate = useNavigate()

  // Alimentation des sélecteurs
  useEffect(() => {
    const filtersApplied = getFiltersFromURL()

    void (async () => {
      setContractTypes(await getContractTypes())
      const servicesObject = await getServices()
      setServices(servicesObject.map((service) => service.name))

      // Si aucun filtr n'est appliqué, on met à jour le booléen
      if (!filtersApplied) {
        setFiltersInitialized(true)
      }
    })()
  }, [])

  // Récupération des users lorsqu'un order by, un filtre ou un changement de page est sélectionné
  // obtenir le nombre de pages nécessaires
  useEffect(() => {
    if (!filtersInitialized) return
    setLoading(true)
    void (async () => {
      const response = (await getUsersWithPagination(
        page,
        ROWS_PER_PAGE,
        place,
        contractType,
        searchTerm,
        orderBy,
        order,
        selectedServices,
        status ? toApiStatus(status) : null,
        Number(yearSelectedContext?.yearSelected)
      )) as ICollabsWithPagination
      setNbPages(response.pages)
      setCollabs(response.data)
      setLoading(false)
    })()
  }, [
    filtersInitialized,
    place,
    page,
    orderBy,
    order,
    searchTerm,
    contractType,
    status,
    selectedServices,
    yearSelectedContext?.yearSelected,
    needReload
  ])

  // Revenir à la première page à chaque changement de filtre
  useEffect(() => {
    setPage(1)
  }, [place, orderBy, order, searchTerm, contractType, selectedServices, status])

  // Récupération des quotas de congés de l'utilisateur sélectionné
  useEffect(() => {
    const userHolidaysQuota = selectedCollabs[0]?.holidaysQuota?.find(
      (holiday) => holiday.year === Number(yearSelectedContext?.yearSelected)
    )
    if (userHolidaysQuota !== undefined) {
      setUserHolidaysQuota(userHolidaysQuota)
    } else {
      setUserHolidaysQuota({
        year:
          yearSelectedContext?.yearSelected !== null &&
          yearSelectedContext?.yearSelected !== undefined
            ? Number(yearSelectedContext?.yearSelected)
            : moment().year(),
        RTTQuota: 0,
        paidVacationQuota: 0,
        users: []
      })
    }
  }, [selectedCollabs, yearSelectedContext?.yearSelected])

  // Ajoute les filtres dans l'URL
  useEffect(() => {
    const params = new URLSearchParams()

    if (contractType) {
      params.set('contractType', contractType.toString())
    }
    if (selectedServices.length > 0) {
      params.set('selectedServices', selectedServices.join(','))
    }
    if (status) {
      const apiStatus = toApiStatus(status)
      if (apiStatus) {
        params.set('status', apiStatus)
      }
    }
    if (searchTerm) {
      params.set('searchTerm', searchTerm)
    }
    if (page) {
      params.set('page', page.toString())
    }

    navigate({ search: params.toString() })
    // Met à jour le booléen une fois que les filtres ont été initialisés
    setFiltersInitialized(true)
  }, [contractType, selectedServices, status, searchTerm, page, navigate])

  // Récupère les filtres dans l'URL
  const getFiltersFromURL = (): boolean => {
    const params = new URLSearchParams(location.search)

    const contractTypeParam: number = Number(params.get('contractType'))
    const selectedServicesParam: string | null = params.get('selectedServices')
    const statusParam: string | null = params.get('status')
    const searchTermParam: string | null = params.get('searchTerm')
    const pageParam: number = Number(params.get('page'))

    if (contractTypeParam) {
      setContractType(contractTypeParam)
    }
    if (selectedServicesParam) {
      setSelectedServices(selectedServicesParam.split(','))
    }
    if (statusParam && Object.values(StatusEnum).includes(statusParam as Status)) {
      setStatus(statusParam as Status)
    }
    if (searchTermParam) {
      setSearchTerm(searchTermParam)
    }
    if (pageParam) {
      setPage(pageParam)
    }

    // Convertit les paramètres en booléen pour savoir si des filtres sont appliqués
    return !!(
      contractTypeParam ??
      selectedServicesParam ??
      statusParam ??
      searchTermParam ??
      pageParam
    )
  }

  // Réinitialise les filtres
  const resetFilters = (): void => {
    setContractType(null)
    setSelectedServices([])
    setStatus(null)
    setOnlyShowFilter(false)
  }

  // Enregistre les quotas de congés pour un utilisateur ou pour tous les utilisateurs sélectionnés
  const handleSave = async (): Promise<void> => {
    let response
    if (!selectAll) {
      userHolidaysQuota.users = selectedCollabs
      response = await createOrUpdateUsersHolidayQuota(userHolidaysQuota)
    } else {
      response = await createOrUpdateAllUsersHolidayQuota(
        userHolidaysQuota,
        page,
        ROWS_PER_PAGE,
        place,
        contractType,
        searchTerm,
        orderBy,
        order,
        selectedServices,
        status
      )
    }
    if (response !== null) {
      setNeedReload(!needReload)
    }
    setOpen(false)
  }

  // Réinitialise les variables
  const initializeVariables = (): void => {
    setSelectedCollabs([])
    setSelectAll(false)
  }

  return (
    <div className="data">
      <h3>
        {onlyShowFilter ? (
          <div className="mobile-filter-header">
            <span className="accentuated-title">Filtres</span>
            <CloseIcon onClick={resetFilters} />
          </div>
        ) : (
          <>
            Liste des <span className="accentuated-title">collaborateurs</span>
          </>
        )}
      </h3>
      <div className="data__header">
        {(!isSmallScreen || !onlyShowFilter) && (
          <div className="data__header__search">
            <SearchBox
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              placeholder="Rechercher un collaborateur"
            />
            <PrimaryButton
              handleClick={(): void => {
                navigate('/creation')
              }}
              title="Nouveau Collab"
              icon={<PersonAddIcon />}
              background
              reverse={false}
              disabled={false}
            />
          </div>
        )}
        {((isSmallScreen && onlyShowFilter) || !isSmallScreen) && (
          <div className="data__header__filters">
            <CustomSelect
              value={contractType}
              options={contractTypes}
              handleSelect={(newValue) => {
                setContractType(newValue)
              }}
              id="contractType"
              width={230}
              inputLabel="Contrat"
            />
            <MultiCustomSelect
              status={status}
              setStatus={setStatus}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              services={services}
            />
            {currentUser?.rolePermissions !== undefined &&
              hasAccountantRole(currentUser?.rolePermissions) && (
                <div className="modify-button">
                  <Tooltip
                    title={
                      selectedCollabs.length === 0 && !selectAll
                        ? 'Veuillez sélectionner au moins un collaborateur'
                        : ''
                    }
                  >
                    <div>
                      <Permission name={featuresList.HOLIDAY}>
                        <PrimaryButton
                          handleClick={() => {
                            setOpen(true)
                          }}
                          title="Modifier les congés"
                          background
                          reverse={false}
                          disabled={selectedCollabs.length === 0 && !selectAll}
                          className="btn-modify-quota"
                        />
                      </Permission>
                    </div>
                  </Tooltip>
                </div>
              )}
          </div>
        )}
      </div>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="collabs-list">
          <FilteredListItems
            nbPages={nbPages}
            page={page}
            setPage={setPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            showHeaderAndPagination
            headCells={
              isFeatureEnabled(featuresContext, featuresList.HOLIDAY)
                ? tableCollabsWithHolidayColumns
                : tableCollabsColumns
            }
            tableBody={
              <ListCollabsBodyTable
                collabs={collabs}
                selectedCollabs={selectedCollabs}
                setSelectedCollabs={setSelectedCollabs}
                selectAll={selectAll}
              />
            }
            nbItems={collabs.length}
            setOnlyShowFilter={setOnlyShowFilter}
            onlyShowMobileFilters={isSmallScreen && onlyShowFilter}
            selectAll={selectAll}
            setSelectAll={
              isFeatureEnabled(featuresContext, featuresList.HOLIDAY) ? setSelectAll : undefined
            }
          />
        </div>
      )}
      <QuotasPopUp
        open={open}
        setOpen={setOpen}
        handleSave={handleSave}
        userHolidaysQuota={userHolidaysQuota}
        setUserHolidaysQuota={setUserHolidaysQuota}
        initializeVariables={initializeVariables}
      />
    </div>
  )
}

export default ListCollabs
