import PrimaryButton from 'components/inputs/button/PrimaryButton'
import Popover from '@material-ui/core/Popover'
import { IDayType } from 'interfaces/planning'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'

interface IProps {
  open: boolean
  anchorEl: HTMLElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
  dayTypes: IDayType[]
  dayTypesFilter: number[]
  setDayTypesFilter: React.Dispatch<React.SetStateAction<number[]>>
  yearView: boolean
}

const PlanningFilterModal: React.FC<IProps> = ({
  open,
  anchorEl,
  dayTypes,
  setAnchorEl,
  dayTypesFilter,
  setDayTypesFilter,
  yearView
}) => {
  const [activeButtons, setActiveButtons] = useState<boolean[]>(Array(dayTypes.length).fill(false))
  const [dayTypesFilterState, setDayTypesFilterState] = useState<number[]>(dayTypesFilter)

  /**
   * On enregistre les boutons cochés au fur et à mesure
   * @param index
   * @param dayType
   */
  const handleFilterClick = (index: number, dayType: IDayType): void => {
    // active ou désactive le bon bouton + ajoute ou retire le type de journée aux filtres

    const newActiveButtons = activeButtons
    const isActive = !newActiveButtons[index]
    newActiveButtons[index] = isActive

    setActiveButtons(newActiveButtons)

    if (isActive) {
      setDayTypesFilterState([...dayTypesFilterState, dayType.id])
    } else {
      const dayTypesFiltered = dayTypesFilterState.filter((dayTypeFilter) => {
        return dayTypeFilter !== dayType.id
      })
      setDayTypesFilterState(dayTypesFiltered)
    }
  }

  const saveFiltersRequest = (): void => {
    setDayTypesFilter(dayTypesFilterState)
    setAnchorEl(null)
  }

  return (
    <>
      <PrimaryButton
        handleClick={(event) => {
          setAnchorEl(event.currentTarget)
        }}
        title={
          !yearView
            ? `Filtrer${
                dayTypesFilterState.length > 0 ? `(${dayTypesFilterState.length.toString()})` : ''
              }`
            : 'Légende'
        }
        background
        reverse
        disabled={false}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <IconButton
          id="closeIcon"
          onClick={() => {
            setAnchorEl(null)
          }}
        >
          <Close />
        </IconButton>
        <div className="popover">
          <div className="align-datePickers">
            <div className="color-guide legend-box-mobile">
              {dayTypes.map((dayType, index) => (
                <button
                  key={index}
                  className={`color ${
                    !yearView ? (activeButtons[index] ? 'active' : '') : 'not-btn'
                  }`}
                  onClick={() => {
                    if (!yearView) {
                      handleFilterClick(index, dayType)
                    }
                  }}
                >
                  <div className="legend" style={{ background: dayType.colorCode }}></div>
                  <p>{dayType.label}</p>
                </button>
              ))}
            </div>
          </div>
          {!yearView ? (
            <PrimaryButton
              handleClick={saveFiltersRequest}
              title={`Filtrer${
                dayTypesFilterState.length > 0 ? `(${dayTypesFilterState.length.toString()})` : ''
              }`}
              background
              reverse
              disabled={false}
            />
          ) : (
            <></>
          )}
        </div>
      </Popover>
    </>
  )
}

export default PlanningFilterModal
