import {
  IIncome,
  IInterview,
  IInterviewAnswer,
  IInterviewAnswerUpdateDTO,
  IInterviewQuestion,
  ILevel
} from '../interfaces/career'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

/**
 * Récupération des grades existants
 */
export const getLevels = async (): Promise<ILevel[]> => {
  try {
    return await customAxios.get(`careers/levels`)
  } catch (error) {
    return []
  }
}

/**
 * Récupération des entretiens d'un utilisateur
 */
export const getInterviews = async (userId: number): Promise<IInterview[]> => {
  try {
    return await customAxios.get(`users/${userId}/interviews`)
  } catch (error) {
    return []
  }
}

/**
 * Récupération des entretiens d'un utilisateur en tant que reviewer
 */
export const getReviewerInterviews = async (userId: number): Promise<IInterview[]> => {
  try {
    return await customAxios.get(`users/${userId}/reviewer-interviews`)
  } catch (error) {
    return []
  }
}

/**
 Permet d'ajouter un entretien
 @param userId
 @param data le body contenant les données de l'entretien
 **/
export const addInterview = async (userId: number, data: IInterview): Promise<IInterview[]> => {
  try {
    const interviews = await customAxios.post(`users/${userId}/interviews`, data)
    toast.success('Entretien ajouté avec succès')
    return interviews as unknown as IInterview[]
  } catch (error) {
    return []
  }
}

/**
 Permet de modifier un entretien
 @param userId
 @param data le body contenant les données de l'entretien
 **/
export const updateInterview = async (userId: number, data: IInterview): Promise<IInterview[]> => {
  try {
    if (data.id !== undefined) {
      const interviews = await customAxios.put(`users/${userId}/interviews/${data.id}`, data)
      toast.success('Entretien modifié avec succès')
      return interviews as unknown as IInterview[]
    }
    return []
  } catch (error) {
    return []
  }
}

/**
 Permet de supprimer un entretien
 @param userId
 @param interviewId
 **/
export const deleteInterview = async (
  userId: number,
  interviewId: string
): Promise<IInterview[]> => {
  try {
    const interviews = await customAxios.delete(`users/${userId}/interviews/${interviewId}`)
    toast.success('Entretien supprimé avec succès')
    return interviews as unknown as IInterview[]
  } catch (error) {
    return []
  }
}

/**
 * Récupération des revenus d'un utilisateur
 */
export const getIncomes = async (userId: number): Promise<IIncome[]> => {
  try {
    return await customAxios.get(`users/${userId}/incomes`)
  } catch (error) {
    return []
  }
}

/**
 * Récupère les questions de l'interview dépendamment de si c'est une review ou non.
 * @param {boolean} isReview
 * @param {number} userID
 *
 * @return {Promise<IInterviewQuestion[]>}
 */
export const getInterviewQuestions = async (
  isReview: number,
  userID: number
): Promise<IInterviewQuestion[]> => {
  try {
    return await customAxios.get(`users/${userID}/interview-questions?isReview=${isReview}`)
  } catch (error) {
    return []
  }
}

/**
 * Récupère les réponses de l'interview.
 * @param {number} interviewId
 * @param {number} userID
 *
 * @return {Promise<IInterviewAnswer[]>}
 */
export const getInterviewAnswers = async (
  interviewId: number,
  userID: number
): Promise<IInterviewAnswer[]> => {
  try {
    return await customAxios.get(`users/${userID}/interview-answers?interviewId=${interviewId}`)
  } catch (error) {
    return []
  }
}

/**
 * Récupère les réponses de l'interview.
 * @param {IInterviewAnswer[]} data
 * @param {number} userID
 *
 * @return {Promise<IInterviewAnswer[]>}
 */
export const updateInterviewAnswers = async (
  data: IInterviewAnswerUpdateDTO[],
  userID: number
): Promise<IInterviewAnswer[]> => {
  try {
    return await customAxios.post(`users/${userID}/interview-answers`, data)
  } catch (error) {
    return []
  }
}
