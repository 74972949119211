import { useEffect, useState } from 'react'

const useValidityHolidays = (datesInterval: number, holidayQuota: number | null): boolean => {
  const [isValidHolidays, setIsValidHolidays] = useState(true)

  useEffect(() => {
    const updateValidityHolidays = (): void => {
      if (holidayQuota !== null && datesInterval > holidayQuota) {
        setIsValidHolidays(false)
      } else {
        setIsValidHolidays(true)
      }
    }

    updateValidityHolidays()
  }, [datesInterval, holidayQuota])

  return isValidHolidays
}

export default useValidityHolidays
