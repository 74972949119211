import ImageUploader from 'components/inputs/uploader/ImageUploader'
import { colorHexValues, colorVars, themeSetting } from 'constantes'
import React, { ReactElement, useEffect, useState } from 'react'
import { IColorSettings } from 'interfaces/colorSettings'
import { changeThemeColor } from 'utils/otherUtils'
import ColorPicker from 'material-ui-color-picker'
import { ISetting } from 'interfaces/setting'

interface IThemeForm {
  settings: ISetting[]
  setSettings: React.Dispatch<React.SetStateAction<ISetting[]>>
  initalColors: IColorSettings | null
}

const ThemeForm = ({ settings, setSettings, initalColors }: IThemeForm): ReactElement => {
  const [selectedImg, setSelectedImg] = useState<string | null>(null)
  const [primarySelectedColor, setPrimarySelectedColor] = useState(initalColors?.primaryColor)
  const [secondarySelectedColor, setSecondarySelectedColor] = useState(initalColors?.secondaryColor)
  const [tertiarySelectedColor, setTertiarySelectedColor] = useState(initalColors?.tertiaryColor)

  const updateColors = (color: string, key: string): void => {
    const result = [...settings]
    result[result.findIndex((element) => element.key === key)].value = color
    setSettings(result)
  }

  useEffect(() => {
    setPrimarySelectedColor(initalColors?.primaryColor)
    setSecondarySelectedColor(initalColors?.secondaryColor)
    setTertiarySelectedColor(initalColors?.tertiaryColor)
  }, [initalColors])

  // useEffect permettant de transformer le logo en format base64 si un logo été déjà existant
  useEffect(() => {
    void (async () => {
      const userSettings = [...settings]
      const logo = userSettings.find((element) => element.key === 'logo')
      if (logo?.value !== undefined) {
        const imageUrl = logo.value
        if (imageUrl !== null) {
          const response = await fetch(imageUrl)
          const blob = await response.blob()
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64 = reader.result as string
            setSelectedImg(base64)
            const result = [...settings]
            result[result.findIndex((element) => element.key === 'logo')].value = base64
            setSettings(result)
          }
          reader.readAsDataURL(blob)
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (selectedImg === null) {
      const result = [...settings]
      result[result.findIndex((element) => element.key === 'logo')].value = null
      setSettings(result)
    }
  }, [selectedImg])

  const updateImage = (base64File: string): void => {
    if (base64File !== null && base64File !== undefined) {
      const result = [...settings]
      result[result.findIndex((element) => element.key === 'logo')].value = base64File
      setSettings(result)
    } else {
      const result = [...settings]
      result[result.findIndex((element) => element.key === 'logo')].value = null
      setSettings(result)
    }
  }

  const resetTheme = (): void => {
    setPrimarySelectedColor(colorHexValues.PRIMARY_COLOR)
    setSecondarySelectedColor(colorHexValues.SECONDARY_COLOR)
    setTertiarySelectedColor(colorHexValues.TERTIARY_COLOR)

    updateColors(colorHexValues.PRIMARY_COLOR, themeSetting.PRIMARY_COLOR)
    updateColors(colorHexValues.SECONDARY_COLOR, themeSetting.SECONDARY_COLOR)
    updateColors(colorHexValues.TERTIARY_COLOR, themeSetting.TERTIARY_COLOR)

    changeThemeColor(colorVars.PRIMARY_COLOR, colorHexValues.PRIMARY_COLOR)
    changeThemeColor(colorVars.SECONDARY_COLOR, colorHexValues.SECONDARY_COLOR)
    changeThemeColor(colorVars.TERTIARY_COLOR, colorHexValues.TERTIARY_COLOR)
  }

  return (
    <div>
      <div className="containerTheme">
        <div>
          <ColorPicker
            className="w-75"
            value={primarySelectedColor}
            onChange={(color) => {
              if (color !== undefined) {
                updateColors(color, themeSetting.PRIMARY_COLOR)
                changeThemeColor(colorVars.PRIMARY_COLOR, color)
                setPrimarySelectedColor(color)
              }
            }}
          />
          <div className="hex-value primary-color-hex">{primarySelectedColor}</div>
        </div>
        <div>
          <ColorPicker
            className="w-75"
            value={secondarySelectedColor}
            onChange={(color) => {
              if (color !== undefined) {
                updateColors(color, themeSetting.SECONDARY_COLOR)
                changeThemeColor(colorVars.SECONDARY_COLOR, color)
                setSecondarySelectedColor(color)
              }
            }}
          />
          <div className="hex-value secondary-color-hex">{secondarySelectedColor}</div>
        </div>
        <div>
          <ColorPicker
            className="w-75"
            value={tertiarySelectedColor}
            onChange={(color) => {
              if (color !== undefined) {
                updateColors(color, themeSetting.TERTIARY_COLOR)
                changeThemeColor(colorVars.TERTIARY_COLOR, color)
                setTertiarySelectedColor(color)
              }
            }}
          />
          <div className="hex-value tertiary-color-hex">{tertiarySelectedColor}</div>
        </div>
        <div
          className="reset-theme-button"
          onClick={() => {
            resetTheme()
          }}
        >
          Réinitialiser le thème
        </div>
      </div>
      <div className="mb-2">
        <p className="title">Logo</p>
        <div>
          <ImageUploader
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
            onClick={updateImage}
          ></ImageUploader>
        </div>
      </div>
    </div>
  )
}

export default ThemeForm
