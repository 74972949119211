import {
  BeachAccess,
  Business,
  CalendarMonth,
  EditCalendar,
  Euro,
  Folder,
  KeyboardArrowDown,
  Logout,
  Menu,
  NewReleases,
  Tune
} from '@mui/icons-material'
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material'
import {
  hasAccountantRole,
  hasManagerRole,
  hasOnlyUserRole,
  hasRHRole,
  hasSuperAdminRole
} from 'utils/otherUtils'
import { contractTypeConstant, featuresList, menuItemConstant, screenSizes } from 'constantes'
import { CurrentUserContext, FeaturesContext, SelectedItemContext } from '../../App'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { isFeatureEnabled } from '../../services/SettingService'
import { CSSObject, Theme, styled } from '@mui/material/styles'
import ProfilImage from 'components/profilImage/ProfilImage'
import useScreenSize from '../../customHooks/useScreenSize'
import { ISelectedItemContext } from 'interfaces/context'
import PlaceFilter from './placeFilter/PlaceFilter'
import CloseIcon from '@mui/icons-material/Close'
import YearFilter from './yearFilter/YearFilter'
import { MenuItem } from '../../interfaces/menu'
import { useNavigate } from 'react-router-dom'
import MuiDrawer from '@mui/material/Drawer'
import './NavBar.scss'

const drawerWidth = 255

/* Animation d'ouverture du menu */
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

/* Animation de fermeture du menu */
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: '55px'
})

// Surchargement du Drawer de base de matériel UI en y incorporant notamment les animations
const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  background: 'black',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  ...(open != null &&
    open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
  ...(open != null &&
    !open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
}))

interface INavbarProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  hasPublication: boolean
}

const Navbar = ({ open, setOpen, hasPublication }: INavbarProps): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  const featuresContext = useContext(FeaturesContext)
  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)
  const navigate = useNavigate()
  const [navItems, setNavItems] = useState<MenuItem[]>([])
  const selectedItemContext = useContext<ISelectedItemContext | null>(SelectedItemContext)
  const user = userContext?.currentUser
  const [subMenuOpen, setSubMenuOpen] = useState<string | undefined>()

  useEffect(() => {
    if (isSmallScreen) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    // Ajouter les actions onClick
    const currentNavItems: MenuItem[] = []
    if (user?.rolePermissions !== undefined) {
      if (
        !hasOnlyUserRole(user.rolePermissions) &&
        (hasAccountantRole(user.rolePermissions) ||
          hasRHRole(user.rolePermissions) ||
          hasManagerRole(user.rolePermissions) ||
          hasSuperAdminRole(user.rolePermissions))
      ) {
        currentNavItems.push({ title: menuItemConstant.ADMIN, icon: <Tune />, nestedItems: [] })

        const adminItems = currentNavItems.find(
          (item) => item.title === menuItemConstant.ADMIN
        )?.nestedItems
        if (hasManagerRole(user.rolePermissions) || hasRHRole(user.rolePermissions)) {
          adminItems?.push({ title: menuItemConstant.TABLEAU_BORD, link: '/' })
          adminItems?.push({
            title: menuItemConstant.LISTE_COLLABS,
            link: '/liste_collaborateurs?tri=updatedAt'
          })
          adminItems?.push({ title: menuItemConstant.GROUP, link: '/groupes' })
        }

        if (
          hasManagerRole(user.rolePermissions) &&
          isFeatureEnabled(featuresContext, featuresList.MATERIAL)
        ) {
          adminItems?.push({
            title: menuItemConstant.LISTE_MATERIELS,
            link: '/liste_materiels?tri=updatedAt'
          })
        }

        if (
          hasRHRole(user.rolePermissions) &&
          isFeatureEnabled(featuresContext, featuresList.HOLIDAY)
        ) {
          adminItems?.push({
            title: menuItemConstant.SUIVI_ABSENCES,
            link: '/suivi_absences'
          })
        }

        if (
          hasAccountantRole(user.rolePermissions) &&
          isFeatureEnabled(featuresContext, featuresList.NDF)
        ) {
          adminItems?.push({
            title: menuItemConstant.NOTES_FRAIS,
            link: '/notes_de_frais'
          })
        }

        if (
          hasAccountantRole(user.rolePermissions) &&
          isFeatureEnabled(featuresContext, featuresList.REPORTING) &&
          !isSmallScreen
        ) {
          adminItems?.push({
            title: menuItemConstant.ADMIN_REPORTING,
            link: '/reporting_admin'
          })
        }

        if (hasSuperAdminRole(user.rolePermissions)) {
          adminItems?.push({
            title: menuItemConstant.PARAMETRAGE,
            link: '/setting'
          })
        }
      }
    }

    if (isFeatureEnabled(featuresContext, featuresList.PLANNING)) {
      currentNavItems.push({
        title: menuItemConstant.PLANNING,
        link: '/planning',
        icon: <CalendarMonth />
      })
    }
    if (isFeatureEnabled(featuresContext, featuresList.HOLIDAY)) {
      currentNavItems.push({
        title: menuItemConstant.MES_CONGES,
        link: `/mes_conges/${user?.id as number}`,
        icon: <BeachAccess />
      })
    }
    if (isFeatureEnabled(featuresContext, featuresList.NDF)) {
      currentNavItems.push({
        title: menuItemConstant.MES_NOTES_FRAIS,
        link: '/mes_notes_de_frais',
        icon: <Euro />
      })
    }
    if (isFeatureEnabled(featuresContext, featuresList.DOCUMENTS)) {
      currentNavItems.push({
        title: menuItemConstant.MES_DOCUMENTS,
        link: '/mes_documents',
        icon: <Folder />,
        warningIcon: (user?.onBoardingFiles?.filter((file) => file.provided).length ?? 0) < 4
      })
    }
    if (isFeatureEnabled(featuresContext, featuresList.REPORTING)) {
      currentNavItems.push({
        title: menuItemConstant.REPORTING,
        link: '/reporting',
        icon: <EditCalendar />
      })
    }

    if (
      (!isSmallScreen &&
        user?.contractType?.name !== undefined &&
        (user.contractType.name === contractTypeConstant.CDI ||
          user.contractType.name === contractTypeConstant.CDD ||
          user.contractType.name === contractTypeConstant.ALTERNANT ||
          user.contractType.name === contractTypeConstant.STAGIAIRE) &&
        isFeatureEnabled(featuresContext, featuresList.CAREER)) ||
      isFeatureEnabled(featuresContext, featuresList.TROMBI) ||
      isFeatureEnabled(featuresContext, featuresList.DEJ)
    ) {
      currentNavItems.push({ title: menuItemConstant.COMPANY, icon: <Business />, nestedItems: [] })

      const companyItems = currentNavItems.find(
        (item) => item.title === menuItemConstant.COMPANY
      )?.nestedItems
      if (isFeatureEnabled(featuresContext, featuresList.TROMBI)) {
        companyItems?.push({
          title: menuItemConstant.CARRIERE,
          link: '/carriere'
        })
      }
      if (isFeatureEnabled(featuresContext, featuresList.TROMBI)) {
        companyItems?.push({
          title: menuItemConstant.TROMBINOSCOPE,
          link: '/trombinoscope'
        })
      }
      if (isFeatureEnabled(featuresContext, featuresList.DEJ)) {
        companyItems?.push({
          title: menuItemConstant.STEAMDEJ,
          link: '/steamdej'
        })
      }
    }

    currentNavItems.push({ title: 'Déconnexion', link: '/deconnexion', icon: <Logout /> })

    setNavItems(currentNavItems)
  }, [user, isSmallScreen])

  const handleClick = (item: MenuItem): void => {
    if (item.link !== undefined) {
      isSmallScreen && setOpen(false)
      navigate(item.link)
    } else {
      if (item.nestedItems !== undefined) {
        // Si le menu est fermé et on clique sur un élément avec sous-menu, on ouvre le menu
        if (!open) {
          setOpen(true)
        }
      } else {
        isSmallScreen && setOpen(false)
        navigate('/')
      }
    }
  }

  const handleProfilClick = (): void => {
    isSmallScreen && setOpen(false)
    setSubMenuOpen(undefined)
    navigate(`/profil/${user?.id as number}`)
  }

  /* Permet de supprimer l'effet de hover par défaut du composant ListItemButton */
  const StyleButton = styled(ListItemButton)`
    &:hover {
      background: none;
    }
  `
  return (
    <div className="navBar">
      <div className={`${open ? 'showMenu' : ''}`}>
        <div
          className={`${
            hasPublication ? 'mobile-menu-height-with-publication' : 'mobile-menu-height'
          }`}
        >
          <div className="mobile-navbar">
            <IconButton
              onClick={(): void => {
                setOpen(!open)
              }}
            >
              <Menu />
            </IconButton>
          </div>
        </div>

        <Drawer variant="permanent" open={open}>
          <div>
            <div className="drawerHeader divider">
              <div className={open ? 'navOpen' : 'navCLose'}>
                {open && <h2>Collabs</h2>}
                <IconButton
                  id="nav-btn"
                  onClick={(): void => {
                    setOpen(!open)
                  }}
                >
                  {open ? <CloseIcon /> : <Menu />}
                </IconButton>
              </div>
            </div>
            <ListItem
              className={`listItem divider ${
                selectedItemContext?.selectedItem === menuItemConstant.PROFIL ? 'active' : ''
              }`}
            >
              <StyleButton onClick={handleProfilClick}>
                <ListItemIcon>
                  <ProfilImage user={user} />
                </ListItemIcon>
                <ListItemText
                  // permet de passer sur deux lignes les noms longs
                  className="whitespace-normal break-words max-h-10"
                  primary={`${user?.displayFirstname as string} ${user?.displayLastname as string}`}
                />
              </StyleButton>
            </ListItem>

            <List sx={{ paddingTop: 0 }}>
              {navItems.map((item) => (
                <>
                  <ListItem
                    key={item.title}
                    className={`listItem ${
                      (item.title === selectedItemContext?.selectedItem &&
                        subMenuOpen === undefined) ||
                      item.title === subMenuOpen
                        ? 'active'
                        : ''
                    }`}
                  >
                    <StyleButton
                      onClick={() => {
                        handleClick(item)
                        if (subMenuOpen === item.title || item.nestedItems === undefined) {
                          setSubMenuOpen(undefined)
                        } else {
                          setSubMenuOpen(item.title)
                        }
                      }}
                    >
                      <Tooltip title={open ? '' : item.title}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={item.title} />
                      {item.warningIcon === true ? (
                        <div className="warning-container">
                          <NewReleases />
                        </div>
                      ) : null}
                      {item.nestedItems !== undefined && item.nestedItems.length > 0 && (
                        <KeyboardArrowDown
                          className={`${subMenuOpen === item.title ? 'submenu-open' : ''}`}
                        />
                      )}
                    </StyleButton>
                  </ListItem>
                  {item.nestedItems !== undefined && (
                    <Collapse in={subMenuOpen === item.title && open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.nestedItems?.map((nestedItem) => (
                          <ListItem
                            key={nestedItem.title}
                            className={`listItem subMenuItem ${
                              nestedItem.title === selectedItemContext?.selectedItem ? 'active' : ''
                            }`}
                          >
                            <StyleButton
                              onClick={() => {
                                handleClick(nestedItem)
                              }}
                            >
                              <Tooltip title={open ? '' : nestedItem.title}>
                                <ListItemIcon>{nestedItem.icon}</ListItemIcon>
                              </Tooltip>
                              <ListItemText primary={nestedItem.title} />
                            </StyleButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </>
              ))}
            </List>
          </div>
          <div>
            <YearFilter navOpen={open} showSupport />
            <PlaceFilter navOpen={open} />
          </div>
        </Drawer>
      </div>
    </div>
  )
}

export default Navbar
