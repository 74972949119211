import ReportingAddOptions from './ReportingAddOptions'
import { IProject } from 'interfaces/projects'
import { IClient } from 'interfaces/clients'
import { Close } from '@mui/icons-material'
import { Dialog } from '@material-ui/core'
import { IconButton } from '@mui/material'
import React, { ChangeEvent } from 'react'

interface IProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  isBillable: boolean
  setIsBillable: React.Dispatch<React.SetStateAction<boolean>>
  selectedClient: IClient | undefined
  selectedProject: IProject | undefined
  clientOptions: string[]
  projectOptions: string[]
  handleAddClick: () => void
  description: string | undefined
  handleChangeClient: (value: string | null) => Promise<void>
  handleChangeProject: (value: string) => void
  handleChangeDescription: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const ReportingAddModal: React.FC<IProps> = ({
  open,
  setOpen,
  isBillable,
  setIsBillable,
  selectedClient,
  selectedProject,
  clientOptions,
  projectOptions,
  handleAddClick,
  description,
  handleChangeClient,
  handleChangeDescription,
  handleChangeProject
}) => {
  const handlePopoverClose = (): void => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handlePopoverClose}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      className="backdrop-blur-sm"
    >
      <IconButton onClick={handlePopoverClose} id="closeIcon">
        <Close />
      </IconButton>
      <div className="popover">
        <h3>
          Votre <span className="accentuated-title">nouvelle activité</span>
        </h3>
        <ReportingAddOptions
          isBillable={isBillable}
          setIsBillable={setIsBillable}
          selectedClient={selectedClient}
          selectedProject={selectedProject}
          clientOptions={clientOptions}
          projectOptions={projectOptions}
          handleAddClick={handleAddClick}
          description={description}
          handleChangeClient={handleChangeClient}
          handleChangeProject={handleChangeProject}
          handleChangeDescription={handleChangeDescription}
          isSmallScreen={true}
        />
      </div>
    </Dialog>
  )
}

export default ReportingAddModal
