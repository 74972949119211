import { InputLabel, TextField } from '@mui/material'
import React, { ReactElement } from 'react'

interface ICustomTextField {
  onChange: (value: string) => void
  id: string
  value: string
  errorMessage: string | undefined
  required: boolean
  label: string
  multiline?: boolean
  onBlur?: () => void
}

const CustomTextField = ({
  onChange,
  id,
  value,
  errorMessage,
  required,
  label,
  multiline = false,
  onBlur
}: ICustomTextField): ReactElement => {
  return (
    <>
      <InputLabel id={id} required={required}>
        {label}
      </InputLabel>
      <TextField
        id={id}
        variant="outlined"
        onChange={(e) => {
          onChange(e.target.value)
        }}
        onBlur={onBlur}
        value={value}
        multiline={multiline}
        rows={4}
      />
      <p className="form-error">{errorMessage !== undefined ? errorMessage : ''}</p>
    </>
  )
}

export default CustomTextField
