import { ICollabsWithPagination } from 'interfaces/dataWithPagination'
import { customAxios } from './axiosUtils'

/**
Permet de rechercher un homonyme et d'alerter si au moins 1 trouvé
@param firstName le body contenant les données de l'utilisateur
@param lastName si renseigné = désactivation de compte
@return Le nombre d'homonymes trouvés, 0 par défaut
**/
export const getHomonymes = async (firstName: string, lastname: string): Promise<number> => {
  try {
    const homonymeFound = await customAxios.get(`users?firstname=${firstName}&lastname=${lastname}`)
    const nbHomonymes = (homonymeFound as unknown as ICollabsWithPagination).data.length
    return nbHomonymes
  } catch (error) {
    return 0
  }
}
