import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import { PdfFile } from 'interfaces/pfdFile'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

export const getJustificationFile = async (requestId: number): Promise<PdfFile | null> => {
  try {
    return await customAxios.get(`holiday-requests/${requestId}/justification-files`, {
      responseType: 'arraybuffer'
    })
  } catch (error) {
    return null
  }
}

export const saveJustificationFile = async (
  requestId: number,
  file: PdfFile
): Promise<IDetailHolidayRequest | null> => {
  try {
    const formData = new FormData()
    formData.append('file', file.content, file.filename)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const response = await customAxios.post(
      `holiday-requests/${requestId}/justification-files`,
      formData,
      config
    )
    toast.success('Votre justificatif a bien été sauvegardé')
    return response as unknown as IDetailHolidayRequest
  } catch (error) {
    return null
  }
}

export const deleteJustificationFile = async (
  requestId: number
): Promise<IDetailHolidayRequest | null> => {
  try {
    const response = await customAxios.delete(`holiday-requests/${requestId}/justification-files`)
    toast.success('Votre justificatif a bien été supprimé')
    return response as unknown as IDetailHolidayRequest
  } catch (error) {
    return null
  }
}
