import { IUpdateMaterialData } from 'interfaces/materialData'
import { IErrors } from 'interfaces/formFields'
import { isValidDate } from 'utils/dateUtils'
import { useState } from 'react'

export interface IUseMaterialDataValidatorResponse {
  errors: IErrors
  validateData: () => boolean
}

export const useMaterialDataValidator = (
  materialData: IUpdateMaterialData
): IUseMaterialDataValidatorResponse => {
  const [errors, setErrors] = useState<IErrors>({})

  const validateData = (): boolean => {
    let valid: boolean = true
    let purchaseDateErrorMessage = ''
    let brandErrorMessage = ''
    let modelErrorMessage = ''
    let serialNumberErrorMessage = ''
    let inventoryNumberErrorMessage = ''

    if (!isValidDate(materialData?.purchaseDate)) {
      purchaseDateErrorMessage = "Veuillez renseigner la date d'achat ou une date valide"
      valid = false
    }
    if (materialData?.brand === '') {
      brandErrorMessage = 'Veuillez renseigner la marque'
      valid = false
    }
    if (materialData?.model === '') {
      modelErrorMessage = 'Veuillez renseigner le modèle'
      valid = false
    }
    if (materialData?.serialNumber === '') {
      serialNumberErrorMessage = 'Veuillez renseigner le numéro de série'
      valid = false
    }
    if (materialData?.inventoryNumber === null) {
      inventoryNumberErrorMessage = "Veuillez renseigner le numéro d'inventaire"
      valid = false
    }

    setErrors({
      purchaseDate: { message: purchaseDateErrorMessage },
      brand: { message: brandErrorMessage },
      model: { message: modelErrorMessage },
      serialNumber: { message: serialNumberErrorMessage },
      inventoryNumber: { message: inventoryNumberErrorMessage }
    })

    return valid
  }

  return {
    errors,
    validateData
  }
}
