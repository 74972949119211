import React, { ReactElement, useContext, useEffect, useState } from 'react'

import EditableTextField from '../../components/inputs/customTextFIeld/EditableTextField'
import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import { hasAccountantRole, hasRHRole, hasSuperAdminRole } from 'utils/otherUtils'
import { IReporting, ISelectedCell, IWorkEntry } from 'interfaces/reporting'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { isHolidayOrWeekendDay } from '../../utils/dateUtils'
import { isHoliday } from '../../services/ReportingService'
import ReportingActionCell from './ReportingActionCell'
import ReportingEntryCell from './ReportingEntryCell'
import ReportingEditModal from './ReportingEditModal'
import Tooltips from 'components/molecules/Tooltips'
import { AddCircle } from '@mui/icons-material'
import { projectStatus } from 'constantes'
import { CurrentUserContext } from 'App'
import { toast } from 'react-toastify'

export interface ReportingTableRowProps {
  listDates: string[]
  year: number
  month: number
  isBillable: boolean
  handleDelete: (reportingId: number) => void
  setReporting: React.Dispatch<React.SetStateAction<IReporting[]>>
  modifiedReportings: IReporting[]
  setModifiedReportings: React.Dispatch<React.SetStateAction<IReporting[]>>
  handleSaveLine: (item: IReporting) => void
  item: IReporting
  index: number
  totalForDay: number[]
  isFirstReportingOfProject: boolean
  isSmallScreen: boolean
  holidayDates: string[]
  isLocked: boolean
  hoveredDay: string | null
  setHoveredDay: (day: string | null) => void
}

const ReportingTableRow = ({
  year,
  month,
  listDates,
  isBillable,
  handleDelete,
  setReporting,
  modifiedReportings,
  setModifiedReportings,
  handleSaveLine,
  index,
  item,
  isFirstReportingOfProject,
  isSmallScreen,
  totalForDay,
  holidayDates,
  isLocked,
  hoveredDay,
  setHoveredDay
}: ReportingTableRowProps): ReactElement => {
  const [description, setDescription] = useState<string>(item.description)
  const [isTouch, setIsTouch] = useState<boolean>(false)
  const [selectedCell, setSelectedCell] = useState<ISelectedCell>()
  const [showCellModal, setShowCellModal] = useState<boolean>(false)
  const [selectedAmount, setSelectedAmount] = useState<number>(0)
  const connectedUser = useContext(CurrentUserContext)?.currentUser ?? null
  const [showWeekendConfirmation, setShowWeekendConfirmation] = useState<boolean>(false)
  const [pendingCellAction, setPendingCellAction] = useState<{
    currentDayIndex: number
    day: string
    item: IReporting
    isRightClick: boolean
  } | null>(null)

  useEffect(() => {
    if (
      selectedCell?.item.workEntries &&
      selectedCell?.item.workEntries.length &&
      selectedCell.currentDayIndex >= 0
    ) {
      setSelectedAmount(
        (selectedCell?.item?.workEntries[selectedCell?.currentDayIndex].amountWorked ?? 0) / 100
      )
    }
  }, [selectedCell])

  /**
   * Vérifie si l'utilisateur peut modifier les congés
   *
   * @param user
   * @returns
   */
  const canModifyHolidays = (user: IMinimalCollaborateurData | null): boolean => {
    if (user?.rolePermissions !== undefined) {
      const isHR = hasRHRole(user.rolePermissions)
      const isAdmin = hasSuperAdminRole(user.rolePermissions)
      const isAccountant = hasAccountantRole(user.rolePermissions)
      return isHR || isAdmin || isAccountant
    }
    return false
  }

  // Permet de rajouter un pointage sur une ligne du reporting et de le sauvegarder en local
  const handleCellClick = (
    e: React.MouseEvent<HTMLTableCellElement>,
    currentDayIndex: number,
    day: string,
    item: IReporting,
    isRightClick: boolean
  ): void => {
    e.preventDefault()

    if (isTouch) {
      return
    }

    // On verifie si le jour clické est un weekend
    const date = new Date(day)
    const isWeekend = date.getDay() === 0 || date.getDay() === 6
    const isHoliday = holidayDates.includes(day)

    // Si c'est un weekend ou un jour férié, on demande une confirmation à l'utilisateur
    if (isWeekend || isHoliday) {
      setPendingCellAction({ currentDayIndex, day, item, isRightClick })
      setShowWeekendConfirmation(true)
      return
    }

    // Si ce n'est pas un weekend, on execute directement l'action
    executeCellUpdate(currentDayIndex, day, item, isRightClick)
  }

  const executeCellUpdate = (
    currentDayIndex: number,
    day: string,
    item: IReporting,
    isRightClick: boolean
  ): void => {
    let updatedEntries = [...item.workEntries]
    const currentEntry = updatedEntries[currentDayIndex]

    // Check si le projet n'est pas clôturé
    if (item.project.status?.name === projectStatus.CLOSED) {
      toast.error('Le projet est clôturé et ne peut plus être pointé !')
      return
    }

    // Check si le jour clické contient déjà un pointage
    if (currentDayIndex === -1 || !currentEntry?.amountWorked) {
      const newEntry: IWorkEntry = {
        date: new Date(day),
        amountWorked: isRightClick ? 200 : 100
      }
      if (currentDayIndex !== -1) {
        updatedEntries.splice(currentDayIndex, 1)
      }
      updatedEntries = [...updatedEntries, newEntry]
    } else {
      currentEntry.amountWorked += isRightClick ? -25 : 25
      if (currentEntry.amountWorked <= 0 || currentEntry.amountWorked >= 200) {
        updatedEntries.splice(currentDayIndex, 1)
      }
    }

    handleLineModification({ ...item, workEntries: updatedEntries, isBillable })
  }

  // Sauvegarde la modification de la ligne en local si l'utilisateur a confirmé
  const handleConfirmedUpdate = async (): Promise<void> => {
    if (!pendingCellAction) return

    const { currentDayIndex, day, item, isRightClick } = pendingCellAction
    executeCellUpdate(currentDayIndex, day, item, isRightClick)
    setShowWeekendConfirmation(false)
    setPendingCellAction(null)
  }

  // Sauvegarde le texte lors d'un blur ou de l'appuie de la touche Entrée
  const handleSaveText = (): void => {
    // Vérifie que la valeur a été modifiée avant de changer l'état
    if (description === item.description) {
      return
    }

    const updatedItem = { ...item, description }
    handleLineModification(updatedItem)
  }

  // Sauvegarde la ligne modifiée (description ou pointage) en local
  const handleLineModification = (updatedItem: IReporting): void => {
    setReporting((prevReporting) => {
      const existingIndex = prevReporting.findIndex((element) => element.id === updatedItem.id)
      if (existingIndex !== -1) {
        prevReporting[existingIndex] = updatedItem
        return [...prevReporting]
      } else {
        return [...prevReporting, updatedItem]
      }
    })

    setModifiedReportings((prevReporting) => {
      const existingIndex = prevReporting.findIndex((element) => element.id === updatedItem.id)
      if (existingIndex !== -1) {
        prevReporting[existingIndex] = updatedItem
        return [...prevReporting]
      } else {
        return [...prevReporting, updatedItem]
      }
    })
  }

  // Ajoute une ligne de reporting sur le projet séléctionné en local
  const handleAddLineClick = async (item: IReporting): Promise<void> => {
    const newItem = { ...item }

    newItem.id = 0 - (modifiedReportings.length + 1)
    newItem.description = ''
    newItem.workEntries = [
      {
        date: new Date(year, month - 1, 3),
        amountWorked: 0
      }
    ]
    newItem.isBillable = isBillable

    setReporting((reporting) => [...reporting, newItem])
    setModifiedReportings((prevModifiedReportings) => [...prevModifiedReportings, newItem])
  }

  const handleCellSave = (): void => {
    if (!selectedCell) {
      return
    }

    let updatedEntries = [...selectedCell.item.workEntries]
    const currentDayIndex = selectedCell.currentDayIndex
    const currentEntry = updatedEntries[currentDayIndex]

    // Check si le projet n'est pas clôturé
    if (item.project.status?.name === projectStatus.CLOSED) {
      toast.error('Le projet est clôturé et ne peut plus être pointé !')
      return
    }

    // Check si le jour clické contient déjà un pointage
    if (currentDayIndex === -1 || !currentEntry?.amountWorked) {
      const newEntry: IWorkEntry = {
        date: new Date(selectedCell.day),
        amountWorked: selectedAmount * 100
      }
      if (currentDayIndex !== -1) {
        updatedEntries.splice(currentDayIndex, 1)
      }
      updatedEntries = [...updatedEntries, newEntry]
    } else {
      currentEntry.amountWorked = selectedAmount * 100
      if (currentEntry.amountWorked <= 0 || currentEntry.amountWorked >= 200) {
        updatedEntries.splice(currentDayIndex, 1)
      }
    }

    handleLineModification({ ...item, workEntries: updatedEntries, isBillable })
  }

  return (
    <>
      {isSmallScreen ? (
        // Vue ligne version mobile
        <tr
          className={
            item.project.name === 'Congé' && !canModifyHolidays(connectedUser)
              ? 'pointer-events-none'
              : ''
          }
        >
          <td colSpan={2}>
            <EditableTextField
              value={description}
              onChange={setDescription}
              handleSave={handleSaveText}
              width="170px"
            />
          </td>

          {listDates.map((day, index) => (
            <ReportingEntryCell
              key={`${index}${item?.project.name ?? ''}${item.description}`}
              day={day}
              index={`${index}${item?.project.name ?? ''}${item.description}`}
              item={item}
              handleCellClick={handleCellClick}
              isHolidayOrWeekendDay={isHolidayOrWeekendDay}
              modifiedReportings={modifiedReportings}
              totalForDay={totalForDay}
              holidayDates={holidayDates}
              isLocked={isLocked}
              setSelectedCell={setSelectedCell}
              setShowCellModal={setShowCellModal}
              setIsTouch={setIsTouch}
              hoveredDay={hoveredDay}
              setHoveredDay={setHoveredDay}
            />
          ))}

          <ReportingActionCell
            item={item}
            handleSaveLine={handleSaveLine}
            handleDelete={handleDelete}
            modifiedReportings={modifiedReportings}
            connectedUser={connectedUser}
          />

          <ReportingEditModal
            setOpen={setShowCellModal}
            open={showCellModal}
            handleSave={handleCellSave}
            selectedAmount={selectedAmount}
            setSelectedAmount={setSelectedAmount}
          />
        </tr>
      ) : (
        <tr
          className={
            'border-l-4 hover:bg-slate-50 ' +
            (isBillable ? 'border-orange-400' : 'border-blue-600') +
            (isHoliday(item.project) && !canModifyHolidays(connectedUser)
              ? ' pointer-events-none'
              : '') +
            (isFirstReportingOfProject ? ' border-t-2 border-t-gray-100' : '')
          }
          style={{ fontSize: '11px' }}
          key={`${index}`}
        >
          <td colSpan={1}>
            <p className="font-black text-xs pl-3">
              {isFirstReportingOfProject ? item.project.client?.name : ''}
            </p>
          </td>
          <td>
            <div className="flex items-center min-w-[100px]">
              {isFirstReportingOfProject && (
                <>
                  <p className="text-xs">{item.project.name}</p>
                  <p className="cursor-pointer pl-2">
                    {isFirstReportingOfProject &&
                      !isHoliday(item.project) &&
                      item.project.status?.name !== projectStatus.CLOSED &&
                      !isLocked && (
                        <AddCircle
                          color="primary"
                          fontSize="small"
                          onClick={() => {
                            void handleAddLineClick(item)
                          }}
                        ></AddCircle>
                      )}
                  </p>
                </>
              )}
            </div>
          </td>
          <td colSpan={1} className="pl-3">
            <Tooltips text={description} disabled={false} top>
              <span>
                <EditableTextField
                  value={description}
                  onChange={setDescription}
                  handleSave={handleSaveText}
                  width="200px"
                />
              </span>
            </Tooltips>
          </td>

          {listDates.map((day, index) => (
            <ReportingEntryCell
              key={`${index}${item?.project.name ?? ''}${item.description}`}
              day={day}
              index={`${index}${item?.project.name ?? ''}${item.description}`}
              item={item}
              handleCellClick={handleCellClick}
              isHolidayOrWeekendDay={isHolidayOrWeekendDay}
              modifiedReportings={modifiedReportings}
              totalForDay={totalForDay}
              holidayDates={holidayDates}
              isLocked={isLocked}
              setSelectedCell={setSelectedCell}
              setShowCellModal={setShowCellModal}
              setIsTouch={setIsTouch}
              hoveredDay={hoveredDay}
              setHoveredDay={setHoveredDay}
            />
          ))}
          <td className="border border-gray-200 bg-gray-100 w-[26px] text-xs text-center bold">
            {item.workEntries
              .filter((item, index, self) => self.filter((t) => t.date === item.date))
              .reduce((total: number, entry: IWorkEntry) => total + entry.amountWorked / 100, 0)}
          </td>

          {!isLocked && (
            <ReportingActionCell
              item={item}
              handleSaveLine={handleSaveLine}
              handleDelete={handleDelete}
              modifiedReportings={modifiedReportings}
              connectedUser={connectedUser}
            />
          )}
        </tr>
      )}
      <ConfirmationPopUp
        open={showWeekendConfirmation}
        setOpen={setShowWeekendConfirmation}
        dialogTitle="Confirmation requise"
        dialogContentText={
          pendingCellAction
            ? holidayDates.includes(pendingCellAction.day)
              ? 'Vous souhaitez reporter une activité un jour férié. Êtes-vous sûr(e) de vouloir continuer ?'
              : `Vous souhaitez reporter une activité un ${new Date(
                  pendingCellAction.day
                ).toLocaleDateString('fr-FR', {
                  weekday: 'long'
                })}. Êtes-vous sûr(e) de vouloir continuer ?`
            : ''
        }
        handleSave={handleConfirmedUpdate}
        loading={false}
      />
    </>
  )
}

export default ReportingTableRow
