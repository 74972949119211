import { Checkbox, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import { Status, StatusEnum, StatusMap } from 'constantes'
import { IListSelectorValue } from 'interfaces/selectors'
import React, { ReactElement } from 'react'
import CustomSelect from './CustomSelect'

interface IMultiCustomSelect {
  status: Status | null
  setStatus: React.Dispatch<React.SetStateAction<Status | null>>
  selectedServices: string[]
  setSelectedServices: React.Dispatch<React.SetStateAction<string[]>>
  services: string[]
}

// Liste des options pour le statut
const statusOptions: IListSelectorValue[] = [
  { id: 1, value: StatusEnum.ACTIVE, name: StatusEnum.ACTIVE, label: StatusMap[StatusEnum.ACTIVE] },
  {
    id: 2,
    value: StatusEnum.INACTIVE,
    name: StatusEnum.INACTIVE,
    label: StatusMap[StatusEnum.INACTIVE]
  }
]

const MultiCustomSelect = ({
  status,
  setStatus,
  selectedServices,
  setSelectedServices,
  services
}: IMultiCustomSelect): ReactElement => {
  const handleServiceChange = (event: any): void => {
    const selectedItems = event.target.value as string[]
    setSelectedServices(selectedItems)
  }

  /**
   * Fonction qui permet de gérer le changement de statut.
   *
   * @param newValue
   * @returns
   */
  const handleStatusChange = (newValue: string | number | null): void => {
    if (!newValue) {
      setStatus(null)
      return
    }

    // Trouve l'option statut par la valeur plutôt que par l'id
    const selectedStatus = statusOptions.find((opt) => opt.value === newValue)
    if (selectedStatus) {
      setStatus(selectedStatus.value as Status)
    } else {
      setStatus(null)
    }
  }

  return (
    <div className="select-box select">
      <div>
        <InputLabel id="services">Services</InputLabel>
        <Select
          sx={{ width: '190px' }}
          multiple
          id="services"
          value={selectedServices}
          onChange={handleServiceChange}
          renderValue={() =>
            selectedServices.length === 1
              ? selectedServices
              : String(selectedServices.length) + ' sélectionnés'
          }
        >
          {services.map((service) => (
            <MenuItem key={service} value={service}>
              <Checkbox checked={selectedServices.includes(service)} />
              <ListItemText primary={service} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <CustomSelect
          value={status}
          options={statusOptions}
          handleSelect={handleStatusChange}
          id="status"
          width={190}
          inputLabel="Statut"
          useValue={true}
        />
      </div>
    </div>
  )
}

export default MultiCustomSelect
