import React, { ReactElement } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

interface ITabNavigation {
  currentTab: number
  handleTab: (e: any, tab: number) => void
  navItems: INavItem[]
}

export interface INavItem {
  label: string
  count?: number
}

const TabNavigation = ({ currentTab, handleTab, navItems }: ITabNavigation): ReactElement => {
  return (
    <div className="tabs">
      <Tabs value={currentTab} onChange={handleTab} variant="scrollable" scrollButtons="auto">
        {navItems.map((navItem) => {
          return (
            <Tab
              key={navItem.label}
              className="relative"
              label={
                <>
                  {navItem?.label}
                  {navItem?.count && navItem?.count > 0 && (
                    <div className=" bg-red-500 font-bold text-white absolute text-[10px] w-[15px] h-[15px] leading-[15px] top-0 right-0 rounded-full flex items-center justify-center">
                      {navItem?.count}
                    </div>
                  )}
                </>
              }
            />
          )
        })}
      </Tabs>
    </div>
  )
}
export default TabNavigation
