import { IInterview, IInterviewAnswer, IInterviewQuestion } from '../../../interfaces/career'
import { Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../inputs/button/PrimaryButton'
import ReviewQuestionAndAnswer from './ReviewQuestionPopUp'
import useScreenSize from 'customHooks/useScreenSize'
import { Close } from '@mui/icons-material'
import { screenSizes } from 'constantes'
import { CurrentUserContext } from 'App'

interface IReviewPopup {
  open: boolean
  close: () => void
  interviewToEdit: IInterview | undefined
  questions: IInterviewQuestion[]
  answers: IInterviewAnswer[]
  saveAnswers: (value: IInterviewAnswer[]) => void
}

const ReviewPopUp = ({
  open,
  close,
  saveAnswers,
  interviewToEdit,
  questions,
  answers
}: IReviewPopup): ReactElement => {
  const isSmallScreen = useScreenSize(screenSizes.TABLET_SIZE)
  const [loading, setLoading] = useState<boolean>(false)
  const [updatedAnswers, setUpdatedAnswers] = useState<IInterviewAnswer[]>(answers)
  const [originalAnswers, setOriginalAnswers] = useState<IInterviewAnswer[]>(answers)
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  useEffect(() => {
    if (answers) {
      setUpdatedAnswers(answers)
      setOriginalAnswers(answers)
    }
  }, [answers])

  const handleSave = (): void => {
    setLoading(true)

    // Filtre les réponses non modifiées ou vides
    const modifiedAnswers = updatedAnswers.filter((updatedAnswer) => {
      const originalAnswer = originalAnswers.find(
        (answer) => answer.question.id === updatedAnswer.question.id
      )
      return !originalAnswer || originalAnswer.answer !== updatedAnswer.answer
    })

    saveAnswers(modifiedAnswers)
    setLoading(false)
    close()
  }

  const setAnswer = (answerText: string, question: IInterviewQuestion): void => {
    if (!currentUser) return

    const updatedAnswer: IInterviewAnswer = {
      answer: answerText,
      question,
      interview: interviewToEdit,
      user: currentUser
    }

    setUpdatedAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.question.id === question.id
      )
      if (existingAnswerIndex >= 0) {
        const newAnswers = [...prevAnswers]
        newAnswers[existingAnswerIndex] = updatedAnswer
        return newAnswers
      } else {
        return [...prevAnswers, updatedAnswer]
      }
    })
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="review-popup"
    >
      <IconButton onClick={close} id="closeIcon">
        <Close />
      </IconButton>
      {interviewToEdit && questions && (
        <>
          <DialogTitle id="save-dialog-title">
            {interviewToEdit.type?.name} de {interviewToEdit.user?.displayFirstname}{' '}
            {interviewToEdit.user?.displayLastname}
          </DialogTitle>
          <DialogActions>
            <div className="w-100">
              {questions.map((question, index) => {
                const associatedAnswer = answers.find(
                  (answer) => answer.question.id === question.id
                )
                return (
                  <div key={index}>
                    <ReviewQuestionAndAnswer
                      question={question}
                      answer={associatedAnswer ?? null}
                      setAnswer={setAnswer}
                    />
                  </div>
                )
              })}

              <div className={isSmallScreen ? 'flex gap-1' : 'buttons-quotas'}>
                <PrimaryButton
                  handleClick={close}
                  title="Annuler"
                  background
                  reverse
                  disabled={loading}
                />
                <PrimaryButton
                  handleClick={handleSave}
                  title="Enregistrer"
                  background
                  reverse={false}
                  disabled={loading}
                />
              </div>
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default ReviewPopUp
