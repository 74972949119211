import React, { ReactElement } from 'react'

interface IBoxMetricProps {
  title: string
  number: number
  loading: boolean
}

const BoxMetric = ({ title, number, loading }: IBoxMetricProps): ReactElement => {
  return (
    <div className={`box-container ${loading ? 'loading' : ''}`}>
      <p className="box-title">{title}</p>
      <p className="box-number">{number}</p>
    </div>
  )
}

export default BoxMetric
