import { IReporting, ISelectedCell } from 'interfaces/reporting'
import React from 'react'

interface TableCellProps {
  day: string
  index: string
  item: IReporting
  handleCellClick: (
    e: React.MouseEvent<HTMLTableCellElement>,
    currentDayIndex: number,
    day: string,
    item: IReporting,
    isRightClick: boolean
  ) => void
  isHolidayOrWeekendDay: (day: string, holidayDates: string[]) => boolean
  modifiedReportings: IReporting[]
  totalForDay: number[]
  holidayDates: string[]
  isLocked: boolean
  setSelectedCell: React.Dispatch<React.SetStateAction<ISelectedCell | undefined>>
  setShowCellModal: React.Dispatch<React.SetStateAction<boolean>>
  setIsTouch: React.Dispatch<React.SetStateAction<boolean>>
  hoveredDay: string | null
  setHoveredDay: (day: string | null) => void
}

const ReportingEntryCell = ({
  day,
  index,
  item,
  handleCellClick,
  isHolidayOrWeekendDay,
  modifiedReportings,
  holidayDates,
  isLocked,
  setSelectedCell,
  setShowCellModal,
  setIsTouch,
  hoveredDay,
  setHoveredDay
}: TableCellProps): JSX.Element => {
  const currentDayIndex = item.workEntries.findIndex((entry) => {
    const newDate: Date = new Date(entry.date)

    // Evite que le jour change selon le fuseau horaire
    newDate.setHours(12, 0, 0, 0)
    const dateWithoutTime = newDate.toISOString().split('T')[0]

    return dateWithoutTime === day && entry.amountWorked > 0
  })
  const handleCellTouch = (currentDayIndex: number, day: string, item: IReporting): void => {
    const selectedCell: ISelectedCell = {
      currentDayIndex,
      day,
      item
    }
    setIsTouch(true)
    setSelectedCell(selectedCell)
    setShowCellModal(true)
  }

  return (
    <td
      key={index}
      style={{ fontSize: '9px' }}
      className={`${isLocked ? '' : 'cursor-pointer'} border border-gray-200 h-[30px] text-center
        ${
          isHolidayOrWeekendDay(day, holidayDates)
            ? 'bg-gray-100'
            : modifiedReportings.some((modifiedItem) => modifiedItem.id === item.id)
            ? 'bg-green-100'
            : 'bg-white'
        } 
        ${hoveredDay === day ? 'bg-slate-50 transition-colors duration-150' : ''}
        hover:bg-slate-50
      `}
      onClick={(e) => {
        !isLocked && handleCellClick(e, currentDayIndex, day, item, false)
      }}
      onContextMenu={(e) => {
        !isLocked && handleCellClick(e, currentDayIndex, day, item, true)
      }}
      onTouchStart={() => {
        !isLocked && handleCellTouch(currentDayIndex, day, item)
      }}
      onMouseEnter={() => {
        setHoveredDay(day)
      }}
      onMouseLeave={() => {
        setHoveredDay(null)
      }}
    >
      {item.workEntries[currentDayIndex]?.amountWorked
        ? item.workEntries[currentDayIndex]?.amountWorked / 100
        : ''}
    </td>
  )
}

export default ReportingEntryCell
