import { IDayType, IPlanningDate, IPlanningModalForm, IUserPlanningDate } from 'interfaces/planning'
import CustomDatePicker from 'components/inputs/customDatePicker/CustomDatePicker'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import { DayTypes, dayDurationConstant, holidayTypesEnum } from 'constantes'
import { createHolidayRequest, getHolidays } from 'services/HolidayService'
import { getDatesBetween, isSameDay, setValidDate } from 'utils/dateUtils'
import useValidityHolidays from 'customHooks/useValidityHolidayControl'
import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import { addOrUpdateUserPlanningDates } from 'services/PlanningService'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { IHolidayRequest } from 'interfaces/holidays/holidayRequest'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { IHolidayQuota } from 'interfaces/holidays/holidayQuota'
import React, { useContext, useEffect, useState } from 'react'
import HolidaysManager from 'components/HolidaysManager'
import Popover from '@material-ui/core/Popover'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { YearSelectedContext } from 'App'
import { Link } from 'react-router-dom'
import { Dayjs } from 'dayjs'
import moment from 'moment'

interface IProps {
  open: boolean
  anchorEl: HTMLElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
  dayDurationList: string[]
  planningUser: IDetailCollaborateurData | null
  dayTypes: IDayType[]
  handleChangePlanning: (
    updatedPlanning: IUserPlanningDate,
    firstDateUpdated: Date,
    lastDayUpdated: Date
  ) => void
  firstDaySelected: Date
  isUserRestricted: boolean
  holidayDates: string[]
  planningDates: IPlanningDate[]
}

const PlanningPopover: React.FC<IProps> = ({
  open,
  anchorEl,
  dayTypes,
  setAnchorEl,
  dayDurationList,
  planningUser,
  handleChangePlanning,
  firstDaySelected,
  isUserRestricted,
  holidayDates,
  planningDates
}) => {
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()

  const [loading, setLoading] = useState<boolean>(false)

  const [daysInterval, setDaysInterval] = useState<number>(0)
  const [holidayQuota, setHolidayQuota] = useState<number | null>(null)
  const isValidHolidays = useValidityHolidays(daysInterval, holidayQuota)
  const [holidayRequest, setHolidayRequest] = useState<IHolidayRequest>({
    firstDay: new Date(),
    lastDay: new Date(),
    year,
    holidayType: { id: 1 },
    duration: dayDurationConstant.JOURNEE,
    user: planningUser
  })

  const [isOtherHolidaySelected, setIsOtherHolidaySelected] = useState<boolean>(false)
  const [durationList, setDurationList] = useState<string[]>(dayDurationList)
  const [cpHolidayType, setCpHolidayType] = useState<IHolidayQuota>()

  const initialState = {
    firstDay: new Date(),
    lastDay: new Date(),
    dayType: { id: 1 },
    duration: dayDurationConstant.JOURNEE,
    justificationText: ''
  }
  const [planningModalForm, setPlanningModalForm] = useState<IPlanningModalForm>(initialState)

  const [isEditingHolidayDay, setIsEditingHolidayDay] = useState<boolean>(false)

  // Initialise les calendriers à la date sélectionnée au départ
  useEffect((): void => {
    setPlanningModalForm({
      ...planningModalForm,
      firstDay: firstDaySelected,
      lastDay: firstDaySelected
    })
    // Vérifie qu'on n'écrase pas une demande congé
    anchorEl !== null &&
      setIsEditingHolidayDay(isEditingOneHoliday([moment(firstDaySelected).format('YYYY-MM-DD')]))
  }, [firstDaySelected, planningModalForm.duration])

  const getCpHolidayType = async (): Promise<IHolidayQuota | undefined> => {
    // si déjà chargé une fois, on ne recharge pas
    if (cpHolidayType !== undefined) {
      return cpHolidayType
    }

    const holidayTypes = await getHolidays()
    const cpType = holidayTypes.find(
      (holiday) => holiday.holidayType === holidayTypesEnum.CONGE_PAYE
    )
    setCpHolidayType(cpType)
    return cpType
  }

  const handleChangeDay = (newDate: Dayjs | null, isLastDay: boolean): void => {
    if (newDate !== null) {
      const validDate = setValidDate(newDate)
      // permet d'automatiquement mettre à jour la date de fin pour ne pas être inférieur à celle de départ
      if (!isLastDay && planningModalForm.lastDay < validDate) {
        setPlanningModalForm({
          ...planningModalForm,
          firstDay: validDate,
          lastDay: validDate
        })
      } else {
        setPlanningModalForm({
          ...planningModalForm,
          [isLastDay ? 'lastDay' : 'firstDay']: validDate
        })
      }

      // maj de l'intervalle entre deux dates
      const startDate = isLastDay ? planningModalForm.firstDay : newDate.toDate()
      const lastDate = !isLastDay ? planningModalForm.lastDay : newDate.toDate()
      const dates = getDatesBetween(startDate, lastDate, holidayDates)

      // Vérifie qu'on écrase pas une demande congé
      setIsEditingHolidayDay(isEditingOneHoliday(dates))

      setDaysInterval(dates.length)
      updateDurationList(startDate, lastDate, planningModalForm.dayType.id)
    }
  }

  // Vérifie si on touche à une demande de congé dans la modification du planning car pas possible -> obligé de l'annuler dans "Mes congés"
  const isEditingOneHoliday = (newDates: string[]): boolean => {
    return newDates.some((newDate) => {
      const existingDaytypeLabel = planningDates.find(
        (planningDate) => planningDate.dayDate === newDate
      )?.dayType.label
      const existingDayDuration = planningDates.find(
        (planningDate) => planningDate.dayDate === newDate
      )?.duration
      if (existingDayDuration === dayDurationConstant.JOURNEE) {
        return (
          existingDaytypeLabel === DayTypes.AUTRE_CONGE ||
          existingDaytypeLabel === DayTypes.CONGE_PAYE
        )
      } else {
        return (
          (existingDaytypeLabel === DayTypes.AUTRE_CONGE ||
            existingDaytypeLabel === DayTypes.CONGE_PAYE) &&
          !(
            (existingDayDuration === dayDurationConstant.MATIN &&
              planningModalForm.duration === dayDurationConstant.APRES_MIDI) ||
            (existingDayDuration === dayDurationConstant.APRES_MIDI &&
              planningModalForm.duration === dayDurationConstant.MATIN)
          )
        )
      }
    })
  }

  const updateDurationList = (startDate: Date, lastDate: Date, dayTypeId: number): void => {
    // Si TT || pls jours sélectionnés -> Journée
    if (!isSameDay(startDate, lastDate)) {
      setDurationList([dayDurationConstant.JOURNEE])
      setPlanningModalForm((prev) => {
        return { ...prev, duration: dayDurationConstant.JOURNEE }
      })
    } else {
      setDurationList(dayDurationList)
    }
  }

  /* Permet de gérer le cas où on sélectionne Autre congé pour afficher le second select pour choisir le type de congé */
  const handleDaytypesSelect = (dayTypeId: string | number | null): void => {
    setIsOtherHolidaySelected(false)
    setHolidayQuota(null)

    // Vérifie si l'élément sélectionné est "Autre congé"
    const isOtherHoliday =
      dayTypes.find((dayType) => dayType.id === dayTypeId)?.label === DayTypes.AUTRE_CONGE
    if (isOtherHoliday) {
      setIsOtherHolidaySelected(true)
    }
    setPlanningModalForm({ ...planningModalForm, dayType: { id: dayTypeId as number } })
    updateDurationList(planningModalForm.firstDay, planningModalForm.lastDay, Number(dayTypeId))
  }

  const isTTDayType = (dayTypeId: number): boolean => {
    return dayTypes.find((dayType) => dayType.id === dayTypeId)?.label === 'Télétravail'
  }

  const handleDurationSelect = (duration: string | number | null): void => {
    setPlanningModalForm({ ...planningModalForm, duration: duration as string })
  }

  const updateHolidaySelected = (holidaySelected: IHolidayQuota): void => {
    setHolidayRequest({
      ...holidayRequest,
      holidayType: holidaySelected
    })
  }

  const askHoliday = async (holidayType?: IHolidayQuota): Promise<void> => {
    if (planningUser?.id !== undefined) {
      holidayRequest.duration = planningModalForm.duration
      holidayRequest.firstDay = planningModalForm.firstDay
      holidayRequest.lastDay = planningModalForm.lastDay
      holidayRequest.year = year
      if (holidayType !== undefined) {
        holidayRequest.holidayType = holidayType
      }
      const response = await createHolidayRequest(holidayRequest, planningUser.id)
      if (response !== null) {
        handleChangePlanning(
          {
            planningUser,
            planningDates: response.newPlanningDates
          },
          planningModalForm.firstDay,
          planningModalForm.lastDay
        )
        handlePopoverClose()
      }
    }
  }

  const updatePlanning = async (): Promise<void> => {
    // Récupère les dates entre le début et la fin sous forme de string comparable à celles du back
    const dates = getDatesBetween(
      planningModalForm.firstDay,
      planningModalForm.lastDay,
      holidayDates
    )
    const newPlanningDates: IPlanningDate[] = []
    // Créer le nouveau planning
    dates.forEach((date) => {
      newPlanningDates.push({
        dayDate: date,
        dayType: planningModalForm.dayType,
        duration: planningModalForm.duration,
        justificationText: planningModalForm.justificationText
      })
    })
    const userPlanningDateToSend = { planningUser, planningDates: newPlanningDates }

    const response = await addOrUpdateUserPlanningDates({
      ...userPlanningDateToSend
    })
    if (response !== null) {
      handleChangePlanning(
        {
          planningUser,
          planningDates: response
        },
        planningModalForm.firstDay,
        planningModalForm.lastDay
      )
      handlePopoverClose()
    }
  }

  /* Sauvegarde le planning MAJ et récupère la réponse pour mettre à jour directement le planning du user modifié
   sans avoir à charger tous les autres users */
  const savePlanningOrHolidayRequest = async (): Promise<void> => {
    setLoading(true)
    if (isValidHolidays && !isEditingHolidayDay) {
      const isCPSelected =
        dayTypes.find((daytype) => daytype.id === planningModalForm.dayType.id)?.label ===
        DayTypes.CONGE_PAYE

      if (isOtherHolidaySelected || isCPSelected) {
        if (isCPSelected) {
          const cpHolidayType = await getCpHolidayType()
          if (cpHolidayType !== undefined) {
            await askHoliday(cpHolidayType)
          }
        } else {
          await askHoliday()
        }
      } else {
        await updatePlanning()
      }
    }

    setLoading(false)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
    setDurationList(dayDurationList)
    setDaysInterval(0)
    setHolidayQuota(null)
    setPlanningModalForm(initialState)
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <IconButton onClick={handlePopoverClose} id="closeIcon">
        <Close />
      </IconButton>
      <div className="popover">
        <div className="align-datePickers">
          <CustomDatePicker
            handleChangeDate={(e) => {
              handleChangeDay(e, false)
            }}
            id="firstDay"
            value={planningModalForm.firstDay}
            errorMessage=""
            required={false}
            label="Du"
            minDate={isUserRestricted ? new Date() : null}
          />
          <CustomDatePicker
            handleChangeDate={(e) => {
              handleChangeDay(e, true)
            }}
            id="lastDay"
            value={planningModalForm.lastDay}
            errorMessage=""
            required={false}
            label="Au"
            minDate={planningModalForm.firstDay}
          />
          {!isValidHolidays && (
            <div className="holiday-error-box form-error">
              <p>La période est plus longue que la durée autorisée.</p>
              <p>Veuillez réduire la période ou contacter un administrateur.</p>
            </div>
          )}
          {isEditingHolidayDay && (
            <div className="holiday-error-box form-error">
              <p>Vous ne pouvez pas modifier de congés.</p>
              <p>
                Annulez d&apos;abord la demande de congés sur{' '}
                <Link to="/mes_conges" className="my-holidays-link">
                  Mes Congés
                </Link>
                .
              </p>
            </div>
          )}
        </div>
        <CustomSelect
          handleSelect={(newValue) => {
            handleDaytypesSelect(newValue)
          }}
          options={dayTypes ?? []}
          id="dayType"
          value={planningModalForm.dayType.id}
          inputLabel="Type de journée(s)"
          required
        />
        {isOtherHolidaySelected && (
          <HolidaysManager
            setHolidayQuota={setHolidayQuota}
            showPaidVacation={false}
            updateHolidaySelected={updateHolidaySelected}
          />
        )}

        <CustomSelect
          handleSelect={handleDurationSelect}
          options={durationList}
          id="duration"
          value={planningModalForm.duration}
          inputLabel="Durée"
          required
        />

        {isTTDayType(planningModalForm.dayType.id) ? (
          <CustomTextField
            onChange={(value) => {
              setPlanningModalForm({
                ...planningModalForm,
                justificationText: value
              })
            }}
            id="description"
            value={planningModalForm.justificationText ?? ''}
            errorMessage=""
            required={false}
            label="Commentaire"
            multiline
          />
        ) : (
          <></>
        )}

        <PrimaryButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handleClick={savePlanningOrHolidayRequest}
          title="Enregistrer"
          background
          reverse={false}
          disabled={loading}
        />
      </div>
    </Popover>
  )
}

export default PlanningPopover
