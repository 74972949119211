import HolidaysRequests from 'pages/holidaysRequests/HolidaysRequests'
import { useNavigate } from 'react-router-dom'
import React, { ReactElement } from 'react'
import { Rule } from '@mui/icons-material'

const HolidaysRequestsMetrics = (): ReactElement => {
  const navigate = useNavigate()
  const handleCardClick = (): void => {
    navigate('/suivi_absences')
  }
  return (
    <div className="metric-card" onClick={handleCardClick}>
      <div className="metric-card__title holidays-requests">
        <Rule />
        <h5>Suivi des absences</h5>
      </div>
      <div className="holidays-requests-body">
        <HolidaysRequests showHeaderAndPagination={false} nbRows={10} pageName="Dashboard" />
      </div>
    </div>
  )
}

export default HolidaysRequestsMetrics
