import CustomGroupRadios from 'components/inputs/customGroupRadios/CustomGroupRadios'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { getHolidayDatesWithoutPentecote } from 'utils/dateUtils'
import React, { ReactElement, useMemo, useState } from 'react'
import { dayDurationConstant } from 'constantes'
import Holidays from 'date-holidays'
import AskForm from './AskForm'

interface IHolidayRequest {
  userId?: number
  year: number
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>
}

const HolidayRequest = ({ userId, year, setReloadData }: IHolidayRequest): ReactElement => {
  const hd = new Holidays('FR')
  // Obtenez les dates des jours fériés pour une plage de dates donnée en retirant le jour de la pentecôte qui n'est pas férié chez Steamulo
  const holidayDates = useMemo(() => {
    return getHolidayDatesWithoutPentecote(hd, year)
  }, [year])

  const [showForm, setShowForm] = useState<boolean>(false)
  const [showHalfDuration, setShowHalfDuration] = useState<boolean>(false)
  const [duration, setDuration] = useState<string>('')

  const handleDurationClick = (duration: string): void => {
    setShowForm(true)
    setDuration(duration)
  }

  const handleHalfDayClick = (): void => {
    setShowForm(false)
    setShowHalfDuration(true)
    setDuration('')
  }

  const handleClose = (): void => {
    setShowForm(false)
    setShowHalfDuration(false)
    setDuration('')
  }

  return (
    <div className="box-container-myholidays">
      <h3 className="box-title-myholidays">Demande de congés</h3>
      <div className="box-content-myholidays">
        <div>
          <PrimaryButton
            handleClick={() => {
              handleDurationClick(dayDurationConstant.JOURNEE)
              setShowHalfDuration(false)
            }}
            title="Journée(s)"
            background
            reverse
            disabled={false}
            className={duration === dayDurationConstant.JOURNEE ? 'isActive' : ''}
          />
        </div>
        <div>
          <PrimaryButton
            handleClick={handleHalfDayClick}
            title="Demi-journée"
            background
            reverse
            disabled={false}
            className={showHalfDuration ? 'isActive' : ''}
          />
        </div>
      </div>
      {showHalfDuration && (
        <CustomGroupRadios
          onChange={(value) => {
            handleDurationClick(value)
          }}
          id="mealPlan"
          gpRadiovalue={duration}
          required={false}
          radiosLabelValue={[dayDurationConstant.MATIN, dayDurationConstant.APRES_MIDI]}
        />
      )}

      {showForm && (
        <AskForm
          handleClose={handleClose}
          showOneDatePicker={duration !== dayDurationConstant.JOURNEE}
          duration={duration}
          userId={userId}
          year={year}
          setReloadData={setReloadData}
          holidayDates={holidayDates}
        />
      )}
    </div>
  )
}

export default HolidayRequest
