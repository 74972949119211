import { IMyDocumentsDrive } from 'interfaces/myDocuments'
import { PdfFile } from 'interfaces/pfdFile'
import { customAxios } from './axiosUtils'

export const submitOnBoarding = async (data: {
  identityCard: [string | Blob, string] | undefined
  RIB: [string | Blob, string] | undefined
  vitalCard: [string | Blob, string] | undefined
  transport: [string | Blob, string] | undefined
  documents: Array<{ fileId: string; url?: string; envelopId?: string; title?: string }>
}): Promise<string> => {
  const formData = new FormData()
  if (data.identityCard !== undefined) {
    formData.append('identityCard', data.identityCard[0], "Carte d'identité.pdf")
  }
  if (data.RIB !== undefined) {
    formData.append('RIB', data.RIB[0], 'RIB.pdf')
  }
  if (data.vitalCard !== undefined) {
    formData.append('vitalCard', data.vitalCard[0], 'Carte vitale.pdf')
  }
  if (data.transport !== undefined) {
    formData.append('transport', data.transport[0], 'Attestation de transport.pdf')
  }
  if (data.documents.length > 0) {
    formData.append(
      'envelops',
      data.documents
        .map((document) => {
          return `${document.envelopId ?? ''},${document.title ?? ''}.pdf`
        })
        .join(';')
    )
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return await customAxios.post(`users/documents/self/upload`, formData, config)
}

export const uploadFileToDrive = async (userId: number, file: PdfFile): Promise<string> => {
  const formData = new FormData()
  formData.append('file', file.content, file.filename)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return await customAxios.post(`users/${userId}/documents`, formData, config)
}

export const generateSignedUrlDocument = async (
  fileId: string
): Promise<{ envelopId: string; url: string }> => {
  return await customAxios.get(`docu-sign/${fileId}`)
}

export const getPersonalDocumentsFiles = async (userId: number): Promise<IMyDocumentsDrive[]> => {
  return await customAxios.get(`users/${userId}/documents`)
}

export const getDocumentsFiles = async (
  typeDocuments: string,
  year: number,
  placeId: number,
  signal: AbortSignal
): Promise<IMyDocumentsDrive[]> => {
  return await customAxios.get(
    `users/documents/self/${typeDocuments}?year=${year}&placeId=${placeId}`,
    { signal }
  )
}

export const downloadDocumentsFile = async (
  typeDocuments: string,
  fileId: string,
  year: number,
  placeId: number
): Promise<PdfFile | null> => {
  try {
    return await customAxios.get(
      `users/documents/self/${typeDocuments}/${fileId}?year=${year}&placeId=${placeId}`,
      {
        responseType: 'arraybuffer'
      }
    )
  } catch (error) {
    return null
  }
}
