import { headerConstant } from 'constantes'
import { toast } from 'react-toastify'
import axios from 'axios'

// permet de gérer les erreurs et succes d'une requête avec axios et d'ajouter le token et header automatiquement pour chaque requête
export const customAxios = axios.create({
  baseURL: '/api/',
  headers: {
    'Content-Type': 'application/json'
  }
})

const handleError = (err: any): void => {
  if (axios.isAxiosError(err)) {
    if (err.response?.data.message !== undefined && err.response?.data.message !== null) {
      toast.error(err.response.data.message)
    } else {
      toast.error(err.message)
    }
  } else {
    toast.error(err as string)
  }

  if (
    (err.response.status === 403 && err.response.data.webserviceCode !== 'wrong_group_modified') ||
    err.response.status === 401
  ) {
    localStorage.clear()
    window.location.reload()
  }
}

customAxios.interceptors.response.use(
  (resp) => {
    let response = resp.data
    // S'il y a un header avec des infos sur la pagination, alors on ajoute cette info à la réponse
    if (resp.headers[headerConstant.PAGINATION_PAGE] !== undefined) {
      response = { data: resp.data, pages: Number(resp.headers[headerConstant.PAGINATION_PAGE]) }
    }
    // S'il y a un header avec des infos sur le nom de fichier à télécharger
    if (resp.headers['content-disposition'] !== undefined) {
      response = {
        content: resp.data,
        filename: resp.headers['content-disposition'].split('filename=')[1]
      }
    }
    return response
  },
  async (err) => {
    handleError(err)
    return await Promise.reject(err)
  }
)
