import { IDetailMaterialData, IUpdateMaterialData } from 'interfaces/materialData'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

/**
Permet de récupérer les données du'n matériel en particulier
@param id id du matériel à récupérer
@return IUpdateMaterialData les données du matériel
**/
export const getMaterialById = async (id: number): Promise<IDetailMaterialData | null> => {
  try {
    const materiel = await customAxios.get(`materials/${id}`)
    return materiel as unknown as IDetailMaterialData
  } catch (error) {
    return null
  }
}

/**
Permet d'ajouter un nouveau matériel
@param data le body contenant les données du matériel
@return Un boolean indiquant s'il y a eu une erreur
**/
export const addMaterial = async (
  data: IUpdateMaterialData
): Promise<IUpdateMaterialData | null> => {
  try {
    const materiel = await customAxios.post('materials', data)
    toast.success('Matériel ajouté avec succès')
    return materiel as unknown as IUpdateMaterialData
  } catch (error) {
    return null
  }
}

/**
Permet de mettre à jour un matériel
@param data le body contenant les données du matériel
@param id l'id du matériel à Maj
@return Un boolean indiquant s'il y a eu une erreur
**/
export const updateMaterial = async (
  data: IUpdateMaterialData,
  id: number
): Promise<IUpdateMaterialData | null> => {
  try {
    const materiel = await customAxios.put(`materials/${id}`, data)
    toast.success('Matériel mis à jour avec succès')
    return materiel as unknown as IUpdateMaterialData
  } catch (error) {
    return null
  }
}
