import { getLocalStorageWithExpiration, hasManagerRole, hasRHRole } from 'utils/otherUtils'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import ProfilImage from '../../components/profilImage/ProfilImage'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNavigate } from 'react-router-dom'
import React, { ReactElement } from 'react'
import { toast } from 'react-toastify'

interface ITrombinoscopeCollab {
  users: IMinimalCollaborateurData[]
  job: string | undefined
}

const TrombinoscopeCollab = ({ users, job }: ITrombinoscopeCollab): ReactElement => {
  const user = getLocalStorageWithExpiration('user')
  const isAdmin =
    user !== null
      ? hasManagerRole(JSON.parse(user).rolePermissions) ||
        hasRHRole(JSON.parse(user).rolePermissions)
      : false
  const navigate = useNavigate()
  const usersFiltered =
    job !== 'TOUS'
      ? users.filter((user: IMinimalCollaborateurData) => user.job?.name === job)
      : users

  const handleCopy = (mail: string): void => {
    void navigator.clipboard.writeText(mail)
    toast.success('Email copié !')
  }
  return (
    <div className="trombi-data">
      <div className="box-layout-elts">
        {usersFiltered.map((collab) => {
          return (
            <div className="trombi-collab" key={collab.email}>
              <div className="trombi-collab__left">
                <ProfilImage user={collab} zoomInAllowed />
              </div>
              <div className="trombi-collab__left__user-infos">
                <p
                  className="trombi-collab-content"
                  style={isAdmin ? { cursor: 'pointer' } : { cursor: 'selection' }}
                  onClick={() => {
                    isAdmin && navigate(`/profil/${collab.id}`)
                  }}
                >
                  {collab.displayFirstname} {collab.displayLastname}
                </p>
                <div className="email-copy">
                  <p className="trombi-collab-subtext">{collab.email}</p>
                  <ContentCopyIcon
                    id="copy-icon"
                    onClick={() => {
                      handleCopy(collab.email)
                    }}
                  />
                </div>
                <p className="trombi-collab-subtext">{collab.phoneNumber}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TrombinoscopeCollab
