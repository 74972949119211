import {
  CPReportingTabList,
  chartFillColorBlue,
  chartFillColorOrange,
  chartOptionsNameShown,
  chartOptionsValueShown,
  monthsList,
  projectStatus,
  screenSizes
} from 'constantes'
import {
  createLock,
  deleteLock,
  deleteReporting,
  exportReportingByMonth,
  getLocks,
  getReportingByMonth,
  isHoliday
} from 'services/ReportingService'
import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react'
import { CurrentUserContext, PlaceSelectedContext, YearSelectedContext } from 'App'
import AdminReportingByProject from 'pages/adminReporting/AdminReportingByProject'
import { ILock, IReporting, reportingExport } from 'interfaces/reporting'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { hasAccountantRole, hasPMRole } from '../../utils/otherUtils'
import { useRetrieveUsers } from 'customHooks/retrieveMatchingUsers'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import TabNavigation from 'components/tabNavigation/TabNavigation'
import { calculateWorkableDaysInMonth } from 'utils/dateUtils'
import ReportingAddOptions from './ReportingAddOptions'
import { getProjects } from 'services/ProjectsService'
import useScreenSize from 'customHooks/useScreenSize'
import { getClients } from 'services/ClientsService'
import ReportingAddModal from './ReportingAddModal'
import StatChart from 'components/stats/StatChart'
import { CircularProgress } from '@mui/material'
import ReportingHeader from './ReportingHeader'
import { IProject } from 'interfaces/projects'
import { useNavigate } from 'react-router-dom'
import ReportingTable from './ReportingTable'
import { IClient } from 'interfaces/clients'
import { toast } from 'react-toastify'
import moment from 'moment'

const Reporting = (): ReactElement => {
  const [month, setMonth] = useState<number>(moment().month() + 1)
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingExport, setLoadingExport] = useState<boolean>(false)
  const [reporting, setReporting] = useState<IReporting[]>([])
  const [modifiedReportings, setModifiedReportings] = useState<IReporting[]>([])
  const [users, retrieveUsers] = useRetrieveUsers()
  const [currentUser, setCurrentUser] = useState<IMinimalCollaborateurData | null>(
    useContext(CurrentUserContext)?.currentUser ?? null
  )
  const [isBillable, setIsBillable] = useState<boolean>(true)
  const [description, setDescription] = useState<string>()
  const [clients, setClients] = useState<IClient[]>([])
  const [clientOptions, setClientOptions] = useState<string[]>([])
  const [selectedClient, setSelectedClient] = useState<IClient>()
  const [projects, setProjects] = useState<IProject[]>([])
  const [projectOptions, setProjectOptions] = useState<string[]>([])
  const [selectedProject, setSelectedProject] = useState<IProject>()
  const width = 220
  const [listDates, setListDates] = useState<string[]>([])

  const [billableRate, setBillableRate] = useState<number>(0)
  const [potentialWorkingDays, setPotentialWorkingDays] = useState<number>(21)
  const [totalWorkedDays, setTotalWorkedDays] = useState<number>(0)
  const [projectsNumber, setProjectsNumber] = useState<number>(0)
  const isSmallScreen = useScreenSize(screenSizes.HYBRID_SIZE)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [locks, setLocks] = useState<ILock[]>([])

  const [currentTab, setCurrentTab] = useState<number>(0)

  const chartsList = [
    {
      title: 'Taux de facturation',
      subtitle: "Le taux d'occupation annuel doit être de minimum 90%.",
      options: {
        plotOptions: chartOptionsValueShown,
        fill: chartFillColorBlue,
        stroke: {
          lineCap: 'round'
        }
      },
      series: [billableRate]
    },
    {
      title: 'Nombre de jours',
      subtitle: `Pour ce mois, vous avez un potentiel de ${potentialWorkingDays} jours.`,
      options: {
        plotOptions: chartOptionsNameShown,
        fill: chartFillColorOrange,
        stroke: {
          lineCap: 'round'
        },
        labels: [totalWorkedDays]
      },
      series: [(totalWorkedDays / potentialWorkingDays) * 100]
    },
    {
      title: 'Nombre de projets',
      subtitle: 'Le nombre de projets auxquels vous avez contribué.',
      options: {
        plotOptions: chartOptionsNameShown,
        fill: chartFillColorOrange,
        stroke: {
          lineCap: 'round'
        },
        labels: [projectsNumber]
      },
      series: [projectsNumber]
    }
  ]

  useEffect(() => {
    getClientsData()
  }, [currentTab])

  useEffect(() => {
    setLoading(true)
    void (async () => {
      getReportingData()
      setLoading(false)
      setModifiedReportings([])
    })()
  }, [currentUser, year, month])

  useEffect(() => {
    void (async () => {
      if (place) {
        const locks = await getLocks(year, place)
        setLocks(locks)
      }
    })()
  }, [year, place])

  useEffect(() => {
    setLoading(true)
    void (async () => {
      setClientActions(clients)
      setProjectActions(projects)
      setSelectedClient(undefined)
      setSelectedProject(undefined)
      setLoading(false)
    })()
  }, [isBillable, place])

  useEffect(() => {
    const calculateBillableRate = (): void => {
      let totalBillableTime = 0
      let totalTime = 0

      reporting.forEach((report) => {
        // On ne prend pas en compte les congés
        if (!isHoliday(report.project)) {
          const reportTotalTime = report.workEntries.reduce(
            (sum, entry) => sum + entry.amountWorked,
            0
          )

          // On ne prend en compte que les tâches facturables
          if (report.isBillable) {
            totalBillableTime += reportTotalTime
          }
          totalTime += reportTotalTime
        }
      })

      // Conversion en pourcentage, arrondi à l'entier le plus proche
      const rate = totalTime === 0 ? 0 : Math.round((totalBillableTime / totalTime) * 100)
      setBillableRate(rate)
    }

    calculateBillableRate()

    const totalWorkingDays = calculateWorkableDaysInMonth(month, year)
    setPotentialWorkingDays(totalWorkingDays)

    const uniqueDates = new Set()
    reporting.forEach((report) => {
      report.workEntries.forEach((entry) => {
        if (entry.amountWorked !== 0) {
          const dateString = moment(entry.date).format('YYYY-MM-DD')
          uniqueDates.add(dateString)
        }
      })
    })
    setTotalWorkedDays(uniqueDates.size)

    const uniqueProjects = new Set(
      reporting.filter((report) => !isHoliday(report.project)).map((report) => report.project.id)
    )
    setProjectsNumber(uniqueProjects.size)
  }, [reporting])

  const getReportingData = (): void => {
    void getReportingByMonth(month, year, currentUser?.id ?? 0).then((updatedReporting) => {
      setReporting(updatedReporting)
    })
  }

  const getClientsData = (): void => {
    void getClients(undefined, place ?? 1).then((clients) => {
      const clientWithProjects = clients.filter(
        (client) => client.projects && client.projects.length > 0
      )
      setClients(clientWithProjects)
      setClientActions(clientWithProjects)
    })
  }

  const getProjectsData = async (id: string): Promise<void> => {
    if (id) {
      const projects = await getProjects(id, place ?? 1)
      setProjectActions(
        projects.filter((project: IProject) => project.status?.name !== projectStatus.CLOSED)
      )
    }
  }

  const setClientActions = (clients: IClient[]): void => {
    let filteredClients = clients

    // Filtre les options que si l'onglet est 'Mon reporting'
    if (currentTab === 0) {
      filteredClients = clients.filter((client) =>
        client.projects?.some((project) => project.status?.name === 'En cours')
      )
    }

    setClientOptions(filteredClients.map((client) => client.name ?? ''))
  }

  const setProjectActions = (projects: IProject[]): void => {
    let filteredProjects = projects

    // Filtre les options que si l'onglet est 'Mon reporting'
    if (currentTab === 0) {
      filteredProjects = projects.filter(
        (project) =>
          project.status?.name !== projectStatus.CLOSED && project.name !== projectStatus.HOLIDAY
      )
    }

    setProjects(filteredProjects)

    const projectOptions = filteredProjects.map((project, index) => {
      // Séléctionne le premier projet dans le select par défaut
      if (index === 0) {
        setSelectedProject(project)
      }
      return project.name ?? ''
    })
    setProjectOptions(projectOptions)
  }

  const handleChangeClient = async (value: string | null): Promise<void> => {
    if (!value) {
      setSelectedClient(undefined)
      setSelectedProject(undefined)
      return
    }
    const selected = clients.find((client) => client.name === value)
    await getProjectsData(selected?.id ?? '')
    setSelectedClient(selected)
  }

  const handleChangeProject = (value: string): void => {
    const selected = projects.find((project) => project.name === value)
    setSelectedProject(selected)
  }

  const handleChangeDescription = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setDescription(event.target.value)
  }

  // Ajout d'une tache unique aprés validation des choix de client/projet/description
  const handleAddClick = (): void => {
    if (!selectedProject || !description || !selectedClient) {
      toast.error('Veuillez sélectionner toutes les options')
      return
    }

    const reportingData: IReporting = {
      id: 0 - (modifiedReportings.length + 1),
      user: currentUser,
      project: selectedProject,
      description,
      workEntries: [
        {
          date: new Date(year, month - 1, 3),
          amountWorked: 0
        }
      ],
      isBillable
    }

    setShowModal(false)
    setSelectedClient(undefined)
    setSelectedProject(undefined)
    setDescription('')

    setReporting((reporting) => [...reporting, reportingData])
    setModifiedReportings((prevModifiedReportings) => [...prevModifiedReportings, reportingData])
  }

  // Supprime une ligne du reporting avec son ID
  const handleDelete = (reportingId: number): void => {
    if (reportingId < 0) {
      setModifiedReportings((prevReportings) =>
        prevReportings.filter((report) => report.id !== reportingId)
      )
      setReporting((prevReportings) => prevReportings.filter((report) => report.id !== reportingId))
    } else {
      void deleteReporting(reportingId, year, month).then((updatedReporting) => {
        setReporting(updatedReporting)
      })
    }

    toast.success('Suppression effectuée')
  }

  // Duplique les activités du mois précédent en enlevant les pointages journaliers
  const handleDuplicateClick = async (): Promise<void> => {
    const lastMonthReporting = await getReportingByMonth(month - 1, year, currentUser?.id ?? 0)

    if (!lastMonthReporting?.length) {
      return
    }

    const newReports: IReporting[] = []
    const workEntries = [
      {
        date: new Date(year, month - 1, 3),
        amountWorked: 0
      }
    ]

    lastMonthReporting.forEach((report, index) => {
      if (report.project.name !== projectStatus.HOLIDAY) {
        newReports.push({ ...report, id: index - 100, workEntries })
      }
    })

    setReporting((reporting) => [...reporting, ...newReports])
    setModifiedReportings((modifiedReportings) => [...modifiedReportings, ...newReports])
  }

  // Supprime toutes les lignes non pointées
  const handleDeleteEmptyLines = (): void => {
    for (const report of reporting) {
      if (!report.workEntries || report.workEntries.length === 0) {
        handleDelete(report.id)
      }
      if (report.workEntries.length === 1 && report.workEntries[0].amountWorked === 0) {
        handleDelete(report.id)
      }
    }
  }

  // Exporte le reporting du mois actuel en PDF
  const handleExport = (): void => {
    setLoadingExport(true)
    const exportData: reportingExport = {
      dates: listDates,
      totalWorked: totalWorkedDays,
      totalWorkable: potentialWorkingDays
    }
    void exportReportingByMonth(month, year, currentUser?.id ?? 0, exportData).then(() => {
      setLoadingExport(false)
    })
  }

  const monthIsLocked = (): boolean => {
    return (
      (currentUser?.rolePermissions === undefined ||
        !hasAccountantRole(currentUser?.rolePermissions)) &&
      !!locks.find((lock: ILock) => lock.month === month && lock.year === year)
    )
  }

  const handleLock = async (): Promise<void> => {
    const lock = locks.find((lock: ILock) => lock.month === month && lock.year === year)
    if (lock) {
      const locks = await deleteLock(lock.id)
      setLocks(locks)
    } else {
      if (place) {
        const locks = await createLock(year, place, month)
        setLocks(locks)
      }
    }
  }

  const navigate = useNavigate()
  const goToReporting = (name: string): void => {
    navigate(`/reporting?name=${name}&month=${month}&year=${year}`)
  }

  return (
    <>
      {currentUser?.rolePermissions === undefined ||
        (hasPMRole(currentUser?.rolePermissions) && (
          <div className="flex justify-center">
            <TabNavigation
              currentTab={currentTab}
              handleTab={(e: any, tab: number) => {
                setCurrentTab(tab)
              }}
              navItems={CPReportingTabList}
            />
          </div>
        ))}
      <div className="flex justify-center">
        <div className="px-1 max-w-[2000px]">
          {currentTab === 0 ? (
            <h3>
              Mon <span className="accentuated-title">reporting</span>
            </h3>
          ) : (
            <h3>
              Reporting des <span className="accentuated-title">projets</span>
            </h3>
          )}

          {/* Header du reporting qui contient le sélécteur de mois/année/collaborateur */}
          <ReportingHeader
            users={users}
            retrieveUsers={retrieveUsers}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            year={year}
            yearSelectedContext={yearSelectedContext}
            month={month}
            setMonth={setMonth}
            setListDates={setListDates}
            isSmallScreen={isSmallScreen}
            handleExport={handleExport}
            loading={loadingExport}
            isLocked={!!locks.find((lock: ILock) => lock.month === month && lock.year === year)}
            handleLock={() => {
              void handleLock()
            }}
            currentTab={currentTab}
            setClient={handleChangeClient}
            setProject={handleChangeProject}
            selectedClient={selectedClient}
            selectedProject={selectedProject}
            projectOptions={projectOptions}
            clientOptions={clientOptions}
            setIsBillable={setIsBillable}
            isBillable={isBillable}
            place={place !== null ? place : 0}
          />

          {currentTab === 0 &&
            (loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              <div className="mt-6 bg-white rounded-lg p-2 flex flex-col justify-center">
                {/* Bandeau d'ajout d'activité unique */}
                {!isSmallScreen && !monthIsLocked() && (
                  <>
                    <div className="mb-1 pl-3 font-bold">Ajouter une activité</div>
                    <div className="flex flex-wrap">
                      <ReportingAddOptions
                        isBillable={isBillable}
                        setIsBillable={setIsBillable}
                        selectedClient={selectedClient}
                        selectedProject={selectedProject}
                        clientOptions={clientOptions}
                        projectOptions={projectOptions}
                        handleAddClick={handleAddClick}
                        description={description}
                        handleChangeClient={handleChangeClient}
                        handleChangeProject={handleChangeProject}
                        handleChangeDescription={handleChangeDescription}
                        isSmallScreen={false}
                      />
                    </div>

                    <div className="flex mt-1">
                      <div>
                        <button
                          className="underline text-xs md:text-sm md:ml-3 hover:text-blue-800"
                          onClick={() => {
                            void handleDuplicateClick()
                          }}
                        >
                          {"Dupliquer l'activité du mois-1"}
                        </button>
                      </div>
                      <div>
                        <button
                          className="underline text-xs md:text-sm ml-3 hover:text-blue-800"
                          onClick={handleDeleteEmptyLines}
                        >
                          Supprimer les lignes vides
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-4 flex justify-center">
                  <ReportingTable
                    listDates={listDates}
                    currentUser={currentUser}
                    reporting={reporting}
                    year={year}
                    month={month}
                    setMonth={setMonth}
                    handleDelete={handleDelete}
                    setReporting={setReporting}
                    setModifiedReportings={setModifiedReportings}
                    modifiedReportings={modifiedReportings}
                    isSmallScreen={isSmallScreen}
                    isLocked={monthIsLocked()}
                  />
                </div>

                {/* Chartes en bas du Reporting */}
                <div className="mt-8">
                  <div className="mb-8 text-lg text-center md:text-start md:pl-11">
                    Vos données du mois de{' '}
                    <span className="small-accentuated-title">
                      {monthsList[month - 1]} {year}
                    </span>{' '}
                    :
                  </div>
                  <div className="flex flex-row flex-wrap gap-3 justify-center">
                    {chartsList.map((chart, index) => (
                      <StatChart
                        key={index}
                        title={chart.title}
                        subtitle={chart.subtitle}
                        // @ts-expect-error
                        options={chart.options}
                        series={chart.series}
                        width={width}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}

          {currentTab === 1 && (
            <AdminReportingByProject
              month={month}
              year={year}
              selectedProject={selectedProject}
              place={place ?? 1}
              goToReporting={goToReporting}
            />
          )}

          {isSmallScreen && (
            <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-50">
              <PrimaryButton
                title="Ajouter une activité"
                handleClick={(e) => {
                  setShowModal(true)
                }}
                background
                reverse={false}
                disabled={false}
                className="w-[250px]"
                fontSize="17px"
              />
            </div>
          )}

          {/* Pop up ajout activité reporting en mobile */}
          <ReportingAddModal
            open={showModal}
            setOpen={setShowModal}
            isBillable={isBillable}
            setIsBillable={setIsBillable}
            selectedClient={selectedClient}
            selectedProject={selectedProject}
            clientOptions={clientOptions}
            projectOptions={projectOptions}
            handleAddClick={handleAddClick}
            description={description}
            handleChangeClient={handleChangeClient}
            handleChangeProject={handleChangeProject}
            handleChangeDescription={handleChangeDescription}
          />
        </div>
      </div>
    </>
  )
}

export default Reporting
