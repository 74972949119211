import { IHolidayRequestsWithPagination } from 'interfaces/dataWithPagination'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { getMyHolidayRequest } from 'services/HolidayService'
import InfiniteScroll from 'react-infinite-scroll-component'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import CancelIcon from '@mui/icons-material/Cancel'
import { statusEnum, statusList } from 'constantes'
import { CircularProgress } from '@mui/material'
import MyRequest from './myRequest/MyRequest'
import { YearSelectedContext } from 'App'
import { Link } from 'react-router-dom'
import moment from 'moment'

interface IMyRequests {
  reloadData: boolean
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>
  userId: number
}

const MyRequests = ({ reloadData, setReloadData, userId }: IMyRequests): ReactElement => {
  const LIMIT_REQUESTS = 10
  const ORDER_BY = 'createdAt'
  const SORT_DIRECTION = 'DESC'

  const statusIcons = [
    { label: statusEnum.ACCEPTED, icon: <CheckCircleIcon id="accepted-icon" /> },
    { label: statusEnum.CANCELED, icon: <CancelIcon id="canceled-icon" /> },
    { label: statusEnum.REFUSED, icon: <DoNotDisturbOnIcon id="refused-icon" /> },
    { label: statusEnum.TO_VALIDATE, icon: <WatchLaterIcon id="toValidate-icon" /> }
  ]

  const [loading, setLoading] = useState<boolean>(false)
  const [activeButtons, setActiveButtons] = useState<boolean[]>(
    Array(statusIcons.length).fill(false)
  )

  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()

  const [myHolidayRequests, setMyHolidayRequests] = useState<IDetailHolidayRequest[]>([])
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [scrollPage, setScrollPage] = useState<number>(1)
  const [more, setMore] = useState<boolean>(true)

  useEffect(() => {
    void (async () => {
      if (userId !== undefined && reloadData) {
        await handleFetchHolidayRequest(1, userId)
        setReloadData(false)
      }
    })()
  }, [reloadData])

  useEffect(() => {
    void (async () => {
      if (userId !== undefined) {
        await handleFetchHolidayRequest(1, userId)
      }
    })()
  }, [year, statusFilter])

  const handleFetchHolidayRequest = async (scrollPage: number, userId: number): Promise<void> => {
    setLoading(true)
    const response = await fetchHolidayRequests(scrollPage, userId)
    if (response !== null) {
      setMyHolidayRequests(response.data)
    }
    setLoading(false)
  }

  // Permet de récupérer les demandes suivantes
  const loadNextRequest = async (): Promise<void> => {
    if (userId !== undefined) {
      const requests = await fetchHolidayRequests(scrollPage + 1, userId)
      if (requests !== null && requests.data.length > 0) {
        const result = [...myHolidayRequests].concat(requests.data)
        setMyHolidayRequests(result)
      }
    }
  }

  const fetchHolidayRequests = async (
    scrollPage: number,
    userId: number
  ): Promise<IHolidayRequestsWithPagination | null> => {
    setScrollPage(scrollPage)
    const result = await getMyHolidayRequest(
      scrollPage,
      LIMIT_REQUESTS,
      ORDER_BY,
      SORT_DIRECTION,
      year,
      statusFilter.length > 0 ? statusFilter : statusList,
      userId
    )

    if (result !== null && result.data.length > 0) {
      setMore(true)
    } else {
      setMore(false)
    }

    return result
  }

  const handleFilterClick = (index: number, statusLabel: string): void => {
    // active ou désactive le bon bouton + ajoute ou retire le type de journée aux filtres

    const newActiveButtons = activeButtons
    const isActive = !newActiveButtons[index]
    newActiveButtons[index] = isActive

    setActiveButtons(newActiveButtons)

    if (statusFilter.includes(statusLabel)) {
      setStatusFilter((prev) => prev.filter((status) => status !== statusLabel))
    } else {
      setStatusFilter((prev) => [...prev, statusLabel])
    }
  }

  return (
    <div className="my-holidays-requests-container">
      <div className="box-container-myholidays">
        <div className="box-myholidays-header">
          <h3 className="box-title-myholidays">Mes demandes</h3>
          <Link to="/planning?view=annual" className="underline text-blue-700">
            Afficher la vue calendrier
          </Link>
        </div>
        <div className="color-guide">
          {statusIcons.map((statusIcon, index) => (
            <button
              key={index}
              className={`color ${activeButtons[index] ? 'active' : ''}`}
              onClick={() => {
                handleFilterClick(index, statusIcon.label)
              }}
            >
              {statusIcon.icon}
              <p>{statusIcon.label}</p>
            </button>
          ))}
        </div>
        {loading ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : myHolidayRequests.length > 0 ? (
          <div className="scrollableDiv" id="scrollableDiv">
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={myHolidayRequests.length}
              next={loadNextRequest}
              hasMore={more}
              scrollableTarget="scrollableDiv"
              loader=""
            >
              <div className="my-request-body">
                {myHolidayRequests.map((request, index) => (
                  <MyRequest
                    key={index}
                    holidayRequest={request}
                    setMyHolidayRequests={setMyHolidayRequests}
                    statusIcons={statusIcons}
                    setReloadData={setReloadData}
                  />
                ))}
              </div>
            </InfiniteScroll>
          </div>
        ) : (
          <p className="no-data">Aucune demande pour l&apos;année {year}</p>
        )}
      </div>
    </div>
  )
}

export default MyRequests
