import {
  IDetailCollaborateurData,
  IMinimalCollaborateurData
} from '../../../interfaces/collaborateurData'
import { Dialog, DialogActions, DialogTitle, IconButton, Rating, Typography } from '@mui/material'
import CustomNumberTextField from '../../inputs/customNumberTextField/CustomNumberTextField'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import CustomDatePicker from '../../inputs/customDatePicker/CustomDatePicker'
import { useRetrieveUsers } from '../../../customHooks/retrieveMatchingUsers'
import { addInterview, updateInterview } from 'services/CareerService'
import { getInterviewTypes } from 'services/SelectorsValuesService'
import CustomSelect from '../../inputs/customSelect/CustomSelect'
import { IIncome, IInterview } from '../../../interfaces/career'
import React, { ReactElement, useEffect, useState } from 'react'
import InterviewPopupSelfInput from './InterviewPopUpSelfInput'
import InterviewPopupUserInput from './InterviewPopUpUserInput'
import PrimaryButton from '../../inputs/button/PrimaryButton'
import { isDateBeforeToday } from '../../../utils/dateUtils'
import { IListSelectorValue } from 'interfaces/selectors'
import { makeStyles } from '@material-ui/core/styles'
import { Close } from '@mui/icons-material'
import { CurrentUserContext } from 'App'
import { toast } from 'react-toastify'

interface IInterviewPopup {
  user: IDetailCollaborateurData | IMinimalCollaborateurData
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  interviewToEdit: IInterview | undefined
  onInterviewsChange?: (interviews: IInterview[]) => void
  isEdit: boolean
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: theme.spacing(-2.5),
    fontSize: '0.8rem'
  }
}))

const InterviewPopup = ({
  user,
  open,
  setOpen,
  interviewToEdit,
  onInterviewsChange,
  isEdit
}: IInterviewPopup): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [users, retrieveUsers] = useRetrieveUsers()
  const [interviewTypes, setInterviewTypes] = useState<IListSelectorValue[]>([])
  const [interview, setInterview] = useState<IInterview>(
    interviewToEdit ?? {
      user,
      date: new Date(),
      type: { id: -1, name: '' },
      managers: [],
      reviewers: []
    }
  )
  const userContext = React.useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser
  const classes = useStyles()

  useEffect(() => {
    void (async () => {
      const result = await getInterviewTypes()
      const filteredResult = result.filter((interview) => interview.name !== "Rapport d'étonnement")
      setInterviewTypes(filteredResult)
      if (interviewToEdit === undefined && result.length > 0) {
        setInterview({
          user,
          date: new Date(),
          type: { id: result[0].id, name: result[0].name ?? '' },
          managers: [],
          reviewers: []
        })
      }
    })()
  }, [])

  useEffect(() => {
    if (interviewToEdit !== undefined) {
      setInterview(interviewToEdit)
    } else {
      setInterview({
        user,
        date: new Date(),
        type: { id: interviewTypes?.[0]?.id ?? -1, name: interviewTypes?.[0]?.name ?? '' },
        managers: [],
        reviewers: []
      })
    }
  }, [interviewToEdit, open])

  /**
   * Sauvegarde l'entretien avec
   * managers, évaluateurs, date, type, score, income et commentaire
   *
   * @returns
   */
  const handleSave = async (): Promise<void> => {
    if (!interview.managers?.length) {
      toast.error('Veuillez sélectionner un responsable !')
      return
    }

    if (
      interview.reviewers?.some((reviewer) =>
        interview.managers?.some((manager) => manager.id === reviewer.id)
      )
    ) {
      toast.error('Un responsable ne peut pas être un évaluateur !')
      return
    }

    try {
      let updatedInterviews
      if (interview.id !== undefined) {
        updatedInterviews = await updateInterview(user.id, interview)
      } else {
        updatedInterviews = await addInterview(user.id, interview)
      }

      onInterviewsChange?.(updatedInterviews)

      setInterview({
        user,
        date: new Date(),
        type: interviewToEdit?.type,
        managers: [],
        reviewers: []
      })
      setOpen(false)
    } catch (error) {
      toast.error('Une erreur est survenue lors de la sauvegarde')
    }
  }

  /**
   * Supprime un évaluateur de la liste des évaluateurs
   *
   * @param chipToDelete
   * @returns
   */
  const handleDeleteReviewer = (chipToDelete: IMinimalCollaborateurData) => () => {
    setInterview({
      ...interview,
      reviewers:
        interview.reviewers?.filter(
          (chip: IMinimalCollaborateurData) => chip.id !== chipToDelete.id
        ) ?? []
    })
  }

  /**
   * Supprime un responsable de la liste des responsables
   *
   * @param chipToDelete
   * @returns
   */
  const handleDeleteManager = (chipToDelete: IMinimalCollaborateurData) => () => {
    setInterview({
      ...interview,
      managers:
        interview.managers?.filter(
          (chip: IMinimalCollaborateurData) => chip.id !== chipToDelete.id
        ) ?? []
    })
  }

  /**
   * Gère le changement / ajout de responsable
   *
   * @param newValue
   */
  const handleChangeManager = (newValue: IMinimalCollaborateurData): void => {
    const managers = interview.managers ?? []
    setInterview({
      ...interview,
      managers: [...managers, newValue]
    })
  }

  /**
   * Gère le changement / ajout d'évaluateur
   *
   * @param newValue
   */
  const handleChangeReviewer = (newValue: IMinimalCollaborateurData): void => {
    const reviewers = interview.reviewers ?? []
    setInterview({
      ...interview,
      reviewers: [...reviewers, newValue]
    })
  }

  return (
    <Dialog
      open={open}
      onClose={(): void => {
        setOpen(false)
      }}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="popup interview-popup"
    >
      <IconButton
        onClick={() => {
          setOpen(false)
        }}
        id="closeIcon"
      >
        <Close />
      </IconButton>
      {!isEdit ? (
        <DialogTitle id="save-dialog-title">Ajout d&apos;un entretien</DialogTitle>
      ) : (
        <DialogTitle id="save-dialog-title">Modification d&apos;un entretien</DialogTitle>
      )}
      <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
        pour {user.displayFirstname} {user.displayLastname}
      </Typography>
      <DialogActions>
        <div className="space-y-3">
          <CustomDatePicker
            handleChangeDate={(e) => {
              e !== null && setInterview({ ...interview, date: e.toDate() })
            }}
            id="firstDay"
            value={interview.date}
            required
            label="Date"
            includeTime={true}
          />
          <CustomSelect
            width={320}
            value={interview.type?.id ?? null}
            options={interviewTypes}
            handleSelect={(newValue) => {
              const selectedInterviewType = interviewTypes.find((type) => type.id === newValue)

              if (selectedInterviewType?.name !== undefined) {
                setInterview({
                  ...interview,
                  type: {
                    id: newValue,
                    name: selectedInterviewType.name
                  }
                })
              }
            }}
            id="interviewType"
            inputLabel="Type"
            required
          />

          {currentUser && <InterviewPopupSelfInput user={currentUser} title="Créé par :" />}

          <InterviewPopupUserInput
            handleDelete={handleDeleteManager}
            retrieveUsers={retrieveUsers}
            users={users}
            selectedUsers={interview.managers}
            title="Mené par :"
            handleChange={handleChangeManager}
          />

          <InterviewPopupUserInput
            handleDelete={handleDeleteReviewer}
            retrieveUsers={retrieveUsers}
            users={users}
            selectedUsers={interview.reviewers}
            title="Évalué par :"
            handleChange={handleChangeReviewer}
          />

          {isDateBeforeToday(interview.date.toString()) && isEdit && (
            <>
              <Rating
                name="simple-controlled"
                value={interview.score}
                precision={0.5}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setInterview({ ...interview, score: newValue })
                  }
                }}
              />
              <div className="d-flex-start mt-5 mb-5">
                <CustomSelect
                  handleSelect={(newValue: string | undefined) => {
                    if (newValue !== undefined) {
                      let income: IIncome
                      if (interview.income === undefined) {
                        income = {
                          id: null,
                          amount: 0,
                          date: interview.date,
                          user: interview.user,
                          type: 'Prime'
                        }
                      } else {
                        income = { ...interview.income }
                      }
                      setInterview({ ...interview, income: { ...income, type: newValue } })
                    }
                  }}
                  options={['Prime', 'Augmentation']}
                  id="incomeType"
                  value={interview.income?.type ?? 'Prime'}
                  inputLabel="Prime / Augmentation"
                  required
                />
                <div className="ml-5">
                  <CustomNumberTextField
                    onChange={(value) => {
                      let income: IIncome
                      if (interview.income === undefined) {
                        income = {
                          id: null,
                          amount: 0,
                          date: interview.date,
                          user: interview.user,
                          type: 'Prime'
                        }
                      } else {
                        income = { ...interview.income }
                      }
                      setInterview({ ...interview, income: { ...income, amount: Number(value) } })
                    }}
                    id="prime"
                    value={interview.income?.amount.toString() ?? ''}
                    label="Montant"
                    errorMessage=""
                    required={false}
                  />
                </div>
              </div>
              <div className="comment">
                <CustomTextField
                  onChange={(value) => {
                    if (interview !== undefined) {
                      setInterview({ ...interview, comment: value })
                    }
                  }}
                  id="comment"
                  value={interview.comment ?? ''}
                  errorMessage=""
                  label="Commentaire"
                  required={false}
                  multiline={true}
                />
              </div>
            </>
          )}
          <div className="buttons-quotas mt-10">
            <PrimaryButton
              handleClick={(): void => {
                setOpen(false)
              }}
              title="Annuler"
              background
              reverse
              disabled={loading}
            />
            <PrimaryButton
              handleClick={() => {
                setLoading(true)
                void (async () => {
                  await handleSave()
                  setLoading(false)
                })()
              }}
              title="Enregistrer"
              background
              reverse={false}
              disabled={loading}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default InterviewPopup
