import { IMessage } from '../interfaces/message'
import { customAxios } from './axiosUtils'

/**
 * Récupérer les derniers messages en attente de publication
 **/
export const getMessages = async (
  isRestricted: boolean = false,
  placeId: number
): Promise<IMessage[]> => {
  try {
    return await customAxios.get(
      `publications?restricted=${isRestricted.toString()}&placeId=${placeId}`
    )
  } catch (error) {
    return []
  }
}

/**
 * Ajout d'un message
 **/
export const createMessage = async (message: IMessage): Promise<IMessage[]> => {
  try {
    return await customAxios.post(`publications`, message)
  } catch (error) {
    return []
  }
}

/**
 * Modification d'un message
 **/
export const updateMessage = async (message: IMessage): Promise<IMessage[]> => {
  try {
    if (message.id !== undefined) {
      return await customAxios.put(`publications/${message.id}`, message)
    }

    return []
  } catch (error) {
    return []
  }
}

/**
 * Suppression d'un message
 **/
export const deleteMessage = async (id: string): Promise<IMessage[]> => {
  try {
    return await customAxios.delete(`publications/${id}`)
  } catch (error) {
    return []
  }
}
