import { customAxios } from './axiosUtils'

export const getUserPicture = async (userId: number): Promise<string | undefined> => {
  return await customAxios.get(`users/${userId}/profile-pictures`)
}

export const saveUserPicture = async (
  userId: number,
  picture: string
): Promise<string | undefined> => {
  return await customAxios.post(`users/${userId}/profile-pictures`, { picture })
}
