import { isFeatureEnabled } from '../../services/SettingService'
import React, { FC, ReactElement, useContext } from 'react'
import { IFeatureContext } from '../../interfaces/context'
import { FeaturesContext } from '../../App'

interface PermissionProps {
  name: string
  children: ReactElement
}

const Permission: FC<PermissionProps> = ({ name, children }) => {
  const featuresContext = useContext<IFeatureContext | null>(FeaturesContext)

  return isFeatureEnabled(featuresContext, name) ? children : <></>
}

export default Permission
