import React, { ReactElement, useState } from 'react'

interface ISwitchButton {
  onChange: (isChecked: boolean) => void
  title: string
  defaultValue?: boolean
}

const SwitchButton = ({ onChange, title, defaultValue = false }: ISwitchButton): ReactElement => {
  const [isChecked, setIsChecked] = useState(defaultValue)

  const handleToggle = (): void => {
    const newValue = !isChecked
    setIsChecked(newValue)
    onChange(newValue)
  }

  return (
    <div className="flex space-x-2 pb-2 cursor-pointer" onClick={handleToggle}>
      <div className="text-sm mt-1">{title}</div>
      <div className="relative">
        <input
          type="checkbox"
          className="cursor-pointer accent-blue-800 hover:bg-blue-600"
          checked={isChecked}
          onChange={(e) => {
            e.stopPropagation()
          }}
        />
      </div>
    </div>
  )
}

export default SwitchButton
