import { IMinimalCollaborateurData } from '../../../interfaces/collaborateurData'
import ProfilImage from 'components/profilImage/ProfilImage'
import { CircularProgress, Tooltip } from '@mui/material'
import OutputIcon from '@mui/icons-material/Output'
import { WarningAmber } from '@mui/icons-material'
import InputIcon from '@mui/icons-material/Input'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utils/dateUtils'
import React, { ReactElement } from 'react'

interface IArrivalsAndLeaves {
  leave: boolean
  users: IMinimalCollaborateurData[]
  loading: boolean
}

const ArrivalsAndLeaves = ({ leave, users, loading }: IArrivalsAndLeaves): ReactElement => {
  const navigate = useNavigate()

  const handleItemClick = (event: React.MouseEvent<unknown>, userId: number | undefined): void => {
    event.stopPropagation() // Empêcher la propagation de l'événement au parent
    userId !== undefined && navigate(`/profil/${userId}`)
  }

  const handleCardClick = (): void => {
    const orderBy = leave ? 'leavingDate' : 'arrivalDate'
    navigate(`/liste_collaborateurs?tri=${orderBy}`)
  }

  return (
    <div className="metric-card arrival-metric" onClick={handleCardClick}>
      {leave ? (
        <div className="metric-card__title leaving">
          <OutputIcon /> <h5>Sorties</h5>
        </div>
      ) : (
        <div className="metric-card__title arrivals">
          <InputIcon /> <h5>Entrées</h5>
        </div>
      )}
      <div className="card-users-info">
        {loading ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          <>
            {users.length !== 0 ? (
              users.map((userInfo, index) => (
                <div
                  className="card-user-info"
                  key={index}
                  onClick={(event) => {
                    handleItemClick(event, userInfo.id)
                  }}
                >
                  <div className="card-left-content">
                    <div className="display-none-on-mobile">
                      <ProfilImage user={userInfo} />
                    </div>
                    <div>
                      <p className="user-name">
                        {userInfo.displayLastname !== ''
                          ? `${userInfo.displayFirstname} ${userInfo.displayLastname}`
                          : userInfo.email}
                        {userInfo.contractType === undefined || userInfo.job === undefined ? (
                          <Tooltip
                            title="Le contrat ou le poste du collaborateur est manquant."
                            placement="right-end"
                          >
                            <WarningAmber id="missing-info-icon" />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </p>
                      <div className="sub-infos">
                        <p className="first-info">
                          {userInfo.job !== undefined ? userInfo.job.name : 'non spécifié'}
                        </p>
                        <p className="second-info">
                          {userInfo.contractType !== undefined
                            ? userInfo.contractType.name
                            : 'non spécifié'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-right-content">
                    <p className="date">
                      {leave
                        ? userInfo.leavingDate !== undefined && userInfo.leavingDate !== null
                          ? formatDate(userInfo.leavingDate)
                          : ''
                        : userInfo.arrivalDate !== undefined && userInfo.arrivalDate !== null
                        ? formatDate(userInfo.arrivalDate)
                        : ''}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>Aucun collaborateur</p>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ArrivalsAndLeaves
