import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import ImageUploader from 'components/inputs/uploader/ImageUploader'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement, useEffect, useState } from 'react'
import { Dialog, DialogTitle, IconButton } from '@mui/material'
import { getPlaces } from 'services/SelectorsValuesService'
import { IClient, IUpdateClient } from 'interfaces/clients'
import { IListSelectorValue } from 'interfaces/selectors'
import { Close } from '@mui/icons-material'

interface IClientPopUp {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  clientToEdit: IClient | undefined
  selectedImg: string | null
  croppedImg: string | null
  setSelectedImg: React.Dispatch<React.SetStateAction<string | null>>
  setCroppedImg: React.Dispatch<React.SetStateAction<string | null>>
  handleClientSave: (client: IUpdateClient) => Promise<void>
}

const ClientPopUp = ({
  open,
  setOpen,
  clientToEdit,
  selectedImg,
  setSelectedImg,
  croppedImg,
  setCroppedImg,
  handleClientSave
}: IClientPopUp): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [client, setClient] = useState<IUpdateClient>({
    name: '',
    place: 1,
    projects: []
  })
  const [places, setPlaces] = useState<IListSelectorValue[]>([])

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const places = await getPlaces()
      setPlaces(places)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (clientToEdit) {
      setClient({ ...clientToEdit, place: clientToEdit.place?.id })
    } else {
      setClient({
        name: '',
        place: 1,
        projects: []
      })
    }
  }, [clientToEdit])

  return (
    <div>
      <Dialog
        open={open}
        onClose={(): void => {
          setOpen(false)
        }}
        aria-labelledby="save-dialog"
        aria-describedby="save-dialog-description"
      >
        <div className="justify-center text-center w-[400px] gap-2">
          <IconButton
            onClick={() => {
              setOpen(false)
            }}
            id="closeIcon"
          >
            <Close />
          </IconButton>
          {clientToEdit !== undefined ? (
            <DialogTitle id="save-dialog-title">Modifier un client</DialogTitle>
          ) : (
            <DialogTitle id="save-dialog-title">Ajouter un client</DialogTitle>
          )}
          <div>
            <div className="pb-3">
              <ImageUploader
                selectedImg={selectedImg}
                setSelectedImg={setSelectedImg}
                entity={client}
                croppedImg={croppedImg}
                setCroppedImg={setCroppedImg}
              />
            </div>
            <div className="pb-2">
              <CustomTextField
                onChange={(value) => {
                  setClient({ ...client, name: value })
                }}
                id="firstname"
                value={client?.name ?? ''}
                errorMessage={''}
                label="Nom du client"
                required
              />
            </div>
            <div className="pb-2">
              <CustomSelect
                value={client?.place ?? 1}
                options={places}
                handleSelect={(newValue) => {
                  setClient({ ...client, place: newValue })
                }}
                id="place"
                inputLabel="Lieu"
                required
              />
            </div>
            <div className="buttons-quotas py-8">
              <PrimaryButton
                handleClick={(): void => {
                  setOpen(false)
                }}
                title="Annuler"
                background
                reverse
                disabled={loading}
              />
              <PrimaryButton
                handleClick={() => {
                  void (async () => {
                    await handleClientSave(client)
                  })()
                }}
                title="Enregistrer"
                background
                reverse={false}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ClientPopUp
