import FilteredListItems from '../../../components/filteredListItems/FilteredListItems'
import { IDetailCollaborateurData } from '../../../interfaces/collaborateurData'
import { getCostsForUser } from '../../../services/ReportingService'
import { formatDateToMonthAndYear } from '../../../utils/dateUtils'
import React, { ReactElement, useEffect, useState } from 'react'
import { TableBody, TableCell, TableRow } from '@mui/material'
import { ICost } from '../../../interfaces/reporting'
interface ICosts {
  user: IDetailCollaborateurData
}

const Costs = ({ user }: ICosts): ReactElement => {
  const [costs, setCosts] = useState<ICost[]>([])

  useEffect(() => {
    void getCostsForUser(user.id).then(setCosts)
  }, [user.id])

  return (
    <div className="md:w-[50%]">
      <FilteredListItems
        nbPages={1}
        page={1}
        headCells={[]}
        tableBody={
          <TableBody>
            {costs.map((cost: ICost, index: number) => (
              <TableRow key={cost.id} className={index === 0 ? '!border-t-0' : ''}>
                <TableCell>{formatDateToMonthAndYear(cost.date)}</TableCell>
                <TableCell>{cost.cost}€</TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
        nbItems={costs.length}
        onlyShowMobileFilters={undefined}
        setPage={() => {}}
      />
    </div>
  )
}

export default Costs
