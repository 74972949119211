import HolidayRequestApprobation from '../../components/holidayRequestApprobation/HolidayRequestApprobation'
import { IDetailHolidayRequest } from '../../interfaces/holidays/holidayRequest'
import { CircularProgress, TableBody, TableCell, TableRow } from '@mui/material'
import FilteredListItems from 'components/filteredListItems/FilteredListItems'
import { IPlanningDate, IUserPlanningDate } from '../../interfaces/planning'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { PlaceSelectedContext, YearSelectedContext } from '../../App'
import { offsiteRequestsColumns } from './data/tableRequestsColumns'
import ProfilImage from '../../components/profilImage/ProfilImage'
import { getTTToValidate } from '../../services/PlanningService'
import { NavItemsUpdateParamters } from './HolidaysRequests'
import { Link } from 'react-router-dom'
import moment from 'moment'

interface OffsiteRequestsTabProps {
  updateNavItems: (items: NavItemsUpdateParamters[]) => void
}

const OffsiteRequestsTab = ({ updateNavItems }: OffsiteRequestsTabProps): ReactElement => {
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()
  const [offsiteDays, setOffsiteDays] = useState<IUserPlanningDate[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [approvedRowId, setApprovedRowId] = useState<number | null>(null)
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const response = await getTTToValidate(place)
      updateNavItems([
        {
          tabIndex: 1,
          reqNumber: response.length
        }
      ])
      setOffsiteDays(response)
      setLoading(false)
    })()
  }, [place])

  const handleRequestUpdate = (planningResponse: IPlanningDate[] | IDetailHolidayRequest): void => {
    setOffsiteDays((prev) =>
      prev.filter((request) => {
        return request.planningDates[0]?.id !== (planningResponse as IPlanningDate[])[0]?.id
      })
    )
  }

  return loading ? (
    <div className="loader">
      <CircularProgress />
    </div>
  ) : (
    <React.Fragment>
      <FilteredListItems
        nbPages={1}
        page={0}
        setPage={() => {}}
        headCells={offsiteRequestsColumns()}
        tableBody={
          <TableBody>
            {offsiteDays.map((request, index: number) => {
              return (
                <TableRow
                  hover={false}
                  key={index}
                  className={
                    request.planningDates[0].id === approvedRowId
                      ? 'animate__animated animate__bounceOutRight'
                      : ''
                  }
                >
                  <TableCell className="md-delete">
                    <Link
                      to={
                        request.planningUser !== null
                          ? `/planning?view=annual&userId=${request.planningUser.id}`
                          : ''
                      }
                    >
                      <ProfilImage user={request.planningUser} />
                    </Link>
                  </TableCell>
                  <TableCell>
                    {request.planningUser !== null ? (
                      <span>{`${request.planningUser.displayFirstname} ${request.planningUser.displayLastname}`}</span>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(request.planningDates[0].dayDate).format('DD/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    {moment(request.planningDates[0].updatedAt).format('DD/MM/yyyy')}
                  </TableCell>
                  <TableCell className="sx-delete">{request.planningDates[0].duration}</TableCell>
                  <TableCell className="sx-delete">
                    {request.planningDates[0].justificationText}
                  </TableCell>
                  <TableCell>
                    <div
                      className={`holidays-requests-status
                      ${
                        request.planningDates[0].status !== undefined
                          ? request.planningDates[0].status
                          : ''
                      }`}
                    >
                      <HolidayRequestApprobation
                        request={request}
                        handleRequestApprobationUpdate={handleRequestUpdate}
                        setApprovedRowId={setApprovedRowId}
                        isTT={true}
                        year={year}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        }
        nbItems={offsiteDays.length}
      />
    </React.Fragment>
  )
}

export default OffsiteRequestsTab
