import userDefaultPicture from '../../assets/404.png'
import React, { ReactElement } from 'react'

const Error404Page = (): ReactElement => {
  return (
    <div className="not-found">
      <h1>Oops! Page introuvable.</h1>
      <p>Désolé, la page que vous recherchez n&apos;existe pas.</p>
      <img src={userDefaultPicture} alt="Page non trouvée" />
    </div>
  )
}

export default Error404Page
