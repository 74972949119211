import {
  getAnnualSummaryOfProject,
  getReportingByProject,
  getReportingSummaryOfProject,
  getSummaryTotalOfProject
} from 'services/ReportingService'
import { enumerateDaysBetweenDates, getHolidayDatesWithoutPentecote } from 'utils/dateUtils'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import AdminReportingPersonalTable from './AdminReportingPersonalTable'
import AdminReportingProjectTable from './AdminReportingProjectTable'
import { IReporting, ISummary } from 'interfaces/reporting'
import { CircularProgress } from '@mui/material'
import { IProject } from 'interfaces/projects'
import { shortMonthsList } from 'constantes'
import Holidays from 'date-holidays'
import moment from 'moment'

export interface AdminReportingByProjectProps {
  month: number
  year: number
  selectedProject: IProject | undefined
  place: number
  goToReporting: (name: string) => void
}

const AdminReportingByProject = ({
  month,
  year,
  selectedProject,
  place,
  goToReporting
}: AdminReportingByProjectProps): ReactElement => {
  const [listDates, setListDates] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const hd = new Holidays('FR')
  const holidayDates = useMemo(() => {
    return getHolidayDatesWithoutPentecote(hd, year)
  }, [year])
  const [reportingSummary, setReportingSummary] = useState<ISummary[]>([])
  const [annualSummary, setAnnualSummary] = useState<ISummary[]>([])
  const [reporting, setReporting] = useState<IReporting[]>([])
  const [projectTotalBillable, setProjectTotalBillable] = useState<number>(0)
  const [projectTotalNonBillable, setProjectTotalNonBillable] = useState<number>(0)

  useEffect(() => {
    setLoading(true)
    setDayListOnCurrentMonthOrWeek(new Date(year, month - 1))

    if (selectedProject) {
      void getReportingSummaryOfProject(year, month, Number(selectedProject?.id) ?? 1, place).then(
        (response) => {
          if (response) {
            setReportingSummary(response)
          }
        }
      )
      void getReportingByProject(month, year, Number(selectedProject?.id) ?? 1, place).then(
        (response) => {
          if (response) {
            setReporting(response)
          }
        }
      )
    }
    setLoading(false)
  }, [month, year, selectedProject, place])

  useEffect(() => {
    setLoading(true)
    if (selectedProject) {
      void getAnnualSummaryOfProject(year, Number(selectedProject?.id) ?? 1, place).then(
        (response) => {
          if (response) {
            setAnnualSummary(response)
          }
        }
      )
    }
    setLoading(false)
  }, [year, selectedProject, place])

  useEffect(() => {
    setLoading(true)
    if (selectedProject?.id) {
      void getSummaryTotalOfProject(selectedProject?.id, 'true', place).then((response) => {
        setProjectTotalBillable(response ?? 0)
      })
      void getSummaryTotalOfProject(selectedProject?.id, 'false', place).then((response) => {
        setProjectTotalNonBillable(response ?? 0)
      })
    }
    setLoading(false)
  }, [selectedProject])

  const setDayListOnCurrentMonthOrWeek = (date: Date): void => {
    const validDateToGetInterval = moment(date).add(1, 'day')
    let dates = ['']

    dates = enumerateDaysBetweenDates(
      validDateToGetInterval.startOf('month').toDate(),
      validDateToGetInterval.endOf('month').toDate()
    )
    setListDates(dates)
  }

  const getMergedReportings = (): IReporting[] => {
    const mergedReportings: Record<string, IReporting> = {}

    reporting.forEach((rep) => {
      const userId = rep.user?.id
      if (userId) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const id: string = `${userId}-${rep.isBillable}`
        if (!mergedReportings[id]) {
          mergedReportings[id] = {
            ...rep,
            workEntries: []
          }
        }
        mergedReportings[id].workEntries.push(...rep.workEntries)
      }
    })

    return Object.values(mergedReportings)
  }

  const mergedReportings = getMergedReportings()

  return (
    <>
      {loading && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
      {!loading && selectedProject?.name && (
        <>
          <span className="small-accentuated-title">{selectedProject?.name}</span>

          <AdminReportingProjectTable
            summaries={reportingSummary}
            annualSummaries={annualSummary}
            isBillable={true}
            listDates={listDates}
            holidayDates={holidayDates}
            shortMonthsList={shortMonthsList}
            month={month}
            reporting={mergedReportings}
            projectTotal={projectTotalBillable}
            goToReporting={goToReporting}
          />

          <AdminReportingProjectTable
            summaries={reportingSummary}
            annualSummaries={annualSummary}
            isBillable={false}
            listDates={listDates}
            holidayDates={holidayDates}
            shortMonthsList={shortMonthsList}
            month={month}
            reporting={mergedReportings}
            projectTotal={projectTotalNonBillable}
            goToReporting={goToReporting}
          />

          <AdminReportingPersonalTable
            reporting={reporting}
            listDates={listDates}
            holidayDates={holidayDates}
            month={month}
          />
        </>
      )}
    </>
  )
}

export default AdminReportingByProject
