import FilteredListItems from 'components/filteredListItems/FilteredListItems'
import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import { updateHolidayRequests } from '../../services/HolidayService'
import HolidaysRequestsBodyTable from './HolidaysRequestsBodyTable'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { tableRequestsColumns } from './data/tableRequestsColumns'
import useScreenSize from '../../customHooks/useScreenSize'
import { screenSizes, statusEnum } from '../../constantes'
import { IPlanningDate } from '../../interfaces/planning'
import React, { ReactElement, useState } from 'react'
import { Order } from 'types/order'

interface IHolidaysRequestsTab {
  currentTab: number
  requests: IDetailHolidayRequest[]
  nbPages: number
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  orderBy: string
  setOrderBy: React.Dispatch<React.SetStateAction<string>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  needReload: boolean
  setNeedReload: React.Dispatch<React.SetStateAction<boolean>>
  showHeaderAndPagination: boolean
  handleRequestUpdate: (response: IDetailHolidayRequest) => void
  handleRequestApprobationUpdate: (response: IDetailHolidayRequest | IPlanningDate[]) => void
  pageName: string
}
const HolidaysRequestsTab = ({
  requests,
  nbPages,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  currentTab,
  showHeaderAndPagination,
  handleRequestUpdate,
  handleRequestApprobationUpdate,
  needReload,
  setNeedReload,
  pageName
}: IHolidaysRequestsTab): ReactElement => {
  const [selectedRequests, setSelectedRequests] = useState<IDetailHolidayRequest[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)

  const handleSave = async (status: string): Promise<void> => {
    const targetRequests = selectAll ? requests : selectedRequests

    for (const request of targetRequests) {
      const updatedRequest = {
        ...request,
        status,
        user: request.user,
        requestingUser: request.requestingUser
      }
      const response = await updateHolidayRequests(updatedRequest)
      handleRequestApprobationUpdate(response as IDetailHolidayRequest)
    }
  }

  return (
    <React.Fragment>
      {currentTab === 1 ? (
        <>
          {!isSmallScreen && requests.length > 0 && (
            <div className="holidays-requests-status absolute-buttons">
              <PrimaryButton
                handleClick={() => {
                  void (async () => {
                    await handleSave(statusEnum.ACCEPTED)
                  })()
                }}
                title="Valider"
                background
                reverse={false}
                disabled={selectedRequests.length === 0 && !selectAll}
                className="btn-request-accept"
              />
              <PrimaryButton
                handleClick={() => {
                  void (async () => {
                    await handleSave(statusEnum.REFUSED)
                  })()
                }}
                title="Refuser"
                background
                reverse
                disabled={selectedRequests.length === 0 && !selectAll}
                className="btn-request-refuse"
              />
            </div>
          )}
          <FilteredListItems
            nbPages={nbPages}
            page={page}
            setPage={setPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={tableRequestsColumns({ currentTab, page: pageName })}
            tableBody={
              <HolidaysRequestsBodyTable
                currentTab={currentTab}
                requests={requests}
                selectedRequests={selectedRequests}
                setSelectedRequests={setSelectedRequests}
                handleRequestUpdate={handleRequestUpdate}
                handleRequestApprobationUpdate={handleRequestApprobationUpdate}
                selectAll={selectAll}
                setNeedReload={setNeedReload}
                needReload={needReload}
              />
            }
            nbItems={requests.length}
            showHeaderAndPagination={showHeaderAndPagination}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        </>
      ) : (
        <FilteredListItems
          nbPages={nbPages}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headCells={
            currentTab === 2
              ? tableRequestsColumns({ currentTab, page: pageName }).slice(
                  0,
                  tableRequestsColumns({ currentTab, page: pageName }).length - 1
                )
              : tableRequestsColumns({ currentTab, page: pageName })
          }
          tableBody={
            <HolidaysRequestsBodyTable
              currentTab={currentTab}
              requests={requests}
              selectedRequests={selectedRequests}
              setSelectedRequests={setSelectedRequests}
              handleRequestUpdate={handleRequestUpdate}
              handleRequestApprobationUpdate={handleRequestApprobationUpdate}
              selectAll={false}
              setNeedReload={setNeedReload}
              needReload={needReload}
            />
          }
          nbItems={requests.length}
          showHeaderAndPagination={showHeaderAndPagination}
        />
      )}
    </React.Fragment>
  )
}

export default HolidaysRequestsTab
