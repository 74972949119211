import { CircularProgress, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material'
import CustomNumberTextField from '../../inputs/customNumberTextField/CustomNumberTextField'
import { IUsersHolidayQuota } from 'interfaces/holidays/userHolidayQuota'
import PrimaryButton from '../../inputs/button/PrimaryButton'
import React, { ReactElement, useState } from 'react'
import { Close } from '@mui/icons-material'

interface IQuotasPopUp {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleSave: () => Promise<void>
  userHolidaysQuota: IUsersHolidayQuota
  setUserHolidaysQuota: React.Dispatch<React.SetStateAction<IUsersHolidayQuota>>
  initializeVariables: () => void
}

const QuotasPopUp = ({
  open,
  setOpen,
  handleSave,
  userHolidaysQuota,
  setUserHolidaysQuota,
  initializeVariables
}: IQuotasPopUp): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleQuotaChange = (quota: number, quotaType: string): void => {
    setUserHolidaysQuota((prevQuota) => {
      return {
        ...prevQuota,
        [quotaType]: quota
      }
    })
  }
  return (
    <Dialog
      open={open}
      onClose={(): void => {
        setOpen(false)
      }}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="popUp"
    >
      <IconButton
        onClick={() => {
          setOpen(false)
        }}
        id="closeIcon"
      >
        <Close />
      </IconButton>
      <DialogTitle id="save-dialog-title">
        Modification des droits pour les utilisateurs sélectionnés
      </DialogTitle>
      <DialogActions>
        <div className="content-quotas">
          <div className="inputs-quotas">
            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              <>
                <CustomNumberTextField
                  onChange={(value) => {
                    handleQuotaChange(Number(value), 'paidVacationQuota')
                  }}
                  id="paidVacationQuota"
                  value={userHolidaysQuota?.paidVacationQuota.toString() ?? '0'}
                  required={false}
                  label="Congés payés"
                  text="ex: 4,5"
                />
                <CustomNumberTextField
                  onChange={(value) => {
                    handleQuotaChange(Number(value), 'RTTQuota')
                  }}
                  id="RTTQuota"
                  value={userHolidaysQuota?.RTTQuota.toString() ?? '0'}
                  required={false}
                  label="RTT"
                  text="ex: 4,5"
                />
              </>
            )}
          </div>

          <div className="buttons-quotas">
            <PrimaryButton
              handleClick={(): void => {
                setOpen(false)
                initializeVariables()
              }}
              title="Annuler"
              background
              reverse
              disabled={loading}
            />
            <PrimaryButton
              handleClick={() => {
                setLoading(true)
                void (async () => {
                  await handleSave()
                  setLoading(false)
                })()
                initializeVariables()
              }}
              title="Appliquer à la sélection"
              background
              reverse={false}
              disabled={loading}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default QuotasPopUp
