import { getInterviews, getReviewerInterviews } from '../../services/CareerService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import TabNavigation from 'components/tabNavigation/TabNavigation'
import TimelineInterview from '../profil/career/TimelineInterview'
import useScreenSize from 'customHooks/useScreenSize'
import { IInterview } from '../../interfaces/career'
import { CurrentUserContext } from '../../App'
import { screenSizes } from 'constantes'
import { toast } from 'react-toastify'
import MyReviews from './MyReviews'

const careerTabList = [
  {
    id: 0,
    label: 'Ma carrière'
  },
  {
    id: 1,
    label: 'Evaluations'
  }
]

const CareerPersonal = (): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser
  const isSmallScreen = useScreenSize(screenSizes.TABLET_SIZE)
  const queryParameters = new URLSearchParams(window.location.search)
  const feedbackParam = queryParameters.get('feedback')
  const interviewId = queryParameters.get('interviewId')

  const [currentTab, setCurrentTab] = useState<number>(feedbackParam === '360' ? 1 : 0)
  const [targetInterviews, setTargetInterviews] = useState<IInterview[]>([])
  const [reviewerInterviews, setReviewerInterviews] = useState<IInterview[]>([])
  const [shouldOpenPopup, setShouldOpenPopup] = useState<boolean>(
    feedbackParam === '360' && !!interviewId
  )

  useEffect(() => {
    if (currentUser?.id) {
      // Récupération des entretiens de l'utilisateur connecté
      const fetchInterviews = async (): Promise<void> => {
        try {
          // On récupère les entretiens de l'utilisateur
          if (currentTab === 0) {
            const data = await getInterviews(currentUser.id)
            setTargetInterviews(data)
            // On récupère les entretiens de l'utilisateur en tant que reviewer
          } else {
            const data = await getReviewerInterviews(currentUser.id)
            setReviewerInterviews(data)
          }
        } catch (error) {
          toast.error('Erreur lors de la récupération des entretiens')
        }
      }

      void fetchInterviews()
    }
  }, [currentUser?.id, currentTab])

  return (
    <>
      <div>
        <h3>
          Ma <span className="accentuated-title">carrière</span>
        </h3>
      </div>

      <div className="flex justify-center">
        <TabNavigation
          currentTab={currentTab}
          handleTab={(e: any, tab: number) => {
            setCurrentTab(tab)
          }}
          navItems={careerTabList}
        />
      </div>

      {currentUser !== undefined && currentUser !== null && (
        <div className={isSmallScreen ? 'px-9 gap-2' : 'timeline-container'}>
          {currentTab === 0 ? (
            <TimelineInterview user={currentUser} isAdmin={false} interviews={targetInterviews} />
          ) : (
            <MyReviews
              interviews={reviewerInterviews}
              initialInterviewId={shouldOpenPopup ? Number(interviewId) : undefined}
              shouldOpenPopup={shouldOpenPopup}
              onPopupOpen={() => {
                setShouldOpenPopup(false)
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

export default CareerPersonal
