import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { getReportingSummaryOfMonth } from 'services/ReportingService'
import { IMergedSummaries, ISummary } from 'interfaces/reporting'
import { contractTypeConstant, projectStatus } from 'constantes'
import { CircularProgress } from '@mui/material'
import { formatNumber } from 'utils/otherUtils'
import { IProject } from 'interfaces/projects'

export interface AdminReportingByTypeProps {
  currentProject: IProject | undefined
  isBillable: boolean
  projectType: string
  year: number
  month: number
  place: number
  handleProjectClick: (project: IProject) => void
}

const AdminReportingByType = ({
  isBillable,
  currentProject,
  year,
  month,
  place,
  handleProjectClick,
  projectType
}: AdminReportingByTypeProps): ReactElement => {
  const [reportingSummary, setReportingSummary] = useState<ISummary[]>([])
  const [loader, setLoader] = useState<boolean>(false)
  // Filtre les productions par projets si le filtre existe
  const filteredSummaries = currentProject
    ? reportingSummary.filter((summary) => summary.project.id === currentProject.id)
    : reportingSummary

  useEffect(() => {
    setLoader(true)
    void getReportingSummaryOfMonth(year, month, place).then((response) => {
      if (response) {
        setReportingSummary(response)
      }
      setLoader(false)
    })
  }, [month, year, place])

  // Fusionne les Summary des différents contrats
  const aggregateData = (summary: ISummary[]): IMergedSummaries[] => {
    const aggregated: Record<string, IMergedSummaries> = {}

    summary?.forEach((item: ISummary) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const id = `${Number(item?.project?.id)}-${item?.billable}`
      if (!aggregated[id]) {
        aggregated[id] = {
          project: item?.project,
          direct: 0,
          prestataire: 0,
          billable: item.billable
        }
      }

      if (item.contractType === contractTypeConstant.PRESTATAIRE) {
        aggregated[id].prestataire += item.amountWorked / 100
      } else {
        aggregated[id].direct += item.amountWorked / 100
      }
    })

    return Object.values(aggregated)
  }

  const aggregatedData = useMemo(() => aggregateData(filteredSummaries), [filteredSummaries])

  // Calcule le total pour tous les contrats internes
  const totalDirect = useMemo(() => {
    return aggregatedData
      .filter((item) => item.billable === isBillable)
      .filter((item) => item.project.name !== projectStatus.HOLIDAY)
      .filter(
        (item) => !projectType || projectType === '' || item.project.type?.name === projectType
      )
      .reduce((total, item) => total + item.direct, 0)
  }, [aggregatedData, isBillable, projectType])

  // Calcule le total pour les prestataires
  const totalPresta = useMemo(() => {
    return aggregatedData
      .filter((item) => item.billable === isBillable)
      .filter(
        (item) => !projectType || projectType === '' || item.project.type?.name === projectType
      )
      .reduce((total, item) => total + item.prestataire, 0)
  }, [aggregatedData, isBillable, projectType])

  const totalAmount = useMemo(() => totalDirect + totalPresta, [totalDirect, totalPresta])

  return (
    <>
      {loader ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          <span className="bold small-accentuated-title">{`Production ${
            isBillable ? 'facturable' : 'non facturable'
          }`}</span>
          <div className="bg-white rounded-xl overflow-hidden mt-1">
            {reportingSummary.length ? (
              <table className="w-full shadow-md text-sm">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    <th className="bold p-1 text-left">Client</th>
                    <th className="bold text-left">Projet</th>
                    <th className="bold p-1">T.B</th>
                    <th className="bold p-1">Direct</th>
                    <th className="bold p-1">Sous-traitance</th>
                    <th className="bold p-1">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {aggregatedData
                    .sort(
                      (a: IMergedSummaries, b: IMergedSummaries) =>
                        a.project.client?.name?.localeCompare(b.project.client?.name ?? '') ?? 1
                    )
                    .map(
                      (summary, index) =>
                        isBillable === summary.billable &&
                        (projectType === null ||
                          projectType === '' ||
                          projectType === summary.project.type?.name) &&
                        summary.project.name !== projectStatus.HOLIDAY && (
                          <tr key={index} className="border-b-2 border-gray-300 text-gray-500 p-1">
                            <td className="p-1">{summary.project.client?.name}</td>
                            <td
                              className="underline text-violet-500 cursor-pointer"
                              onClick={() => {
                                handleProjectClick(summary.project)
                              }}
                            >
                              {summary.project.name}
                            </td>
                            <td className="text-center">{summary.project.identifier}</td>
                            <td className="text-center">{summary.direct}</td>
                            <td className="text-center">{summary.prestataire}</td>
                            <td className="bold text-black p-1 text-center">
                              {formatNumber(Number(summary.direct) + Number(summary.prestataire))}
                            </td>
                          </tr>
                        )
                    )}
                  <tr className="border-b-2 border-gray-300 bg-violet-100">
                    <td className="bold p-1">Total</td>
                    <td></td>
                    <td></td>
                    <td className="text-center">{formatNumber(totalDirect)}</td>
                    <td className="text-center">{formatNumber(totalPresta)}</td>
                    <td className="bold text-center">{formatNumber(totalAmount)}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>Pas de données pour ce mois.</div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default AdminReportingByType
