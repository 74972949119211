import {
  IDetailHolidayRequest,
  IHolidayRequest,
  ITotalHolidayQuotaRequest
} from 'interfaces/holidays/holidayRequest'
import { INewHolidayRequestResponse } from 'interfaces/holidays/NewHolidayRequestResponse'
import { IHolidayRequestsWithPagination } from 'interfaces/dataWithPagination'
import { IUsersHolidayQuota } from 'interfaces/holidays/userHolidayQuota'
import { IHolidayQuota } from 'interfaces/holidays/holidayQuota'
import { convertDateTimeToDateString } from 'utils/dateUtils'
import { IFileResponse } from 'interfaces/fileResponse'
import { customAxios } from './axiosUtils'
import { ROWS_PER_PAGE } from 'constantes'
import { toast } from 'react-toastify'
import { isArray } from 'lodash'

/**
 * Récupérer tous les types de congés
 */
export const getHolidays = async (): Promise<IHolidayQuota[]> => {
  try {
    return await customAxios.get('holidays')
  } catch (error) {
    return []
  }
}

/**
 * Mettre à jour le quota de congés d'un ou plusieurs collaborateurs (le crée si pas encore créé en BD)
 */
export const createOrUpdateUsersHolidayQuota = async (
  usersHolidayQuota: IUsersHolidayQuota
): Promise<IUsersHolidayQuota | null> => {
  try {
    const result = await customAxios.post('/users/holidays-quota', usersHolidayQuota)
    toast.success('La modification des droits a bien été prise en compte')
    return result as unknown as IUsersHolidayQuota
  } catch (error) {
    return null
  }
}

/**
 * Mettre à jour le quota de plusieurs collaborateurs selon les critères de recherche (les créent si pas encore créé en BD)
 * Contient un ensemble de critères de recherches et d'ordre de récupération des données en paramètres
 */
export const createOrUpdateAllUsersHolidayQuota = async (
  usersHolidayQuota: IUsersHolidayQuota,
  currentPage: number,
  limit: number,
  placeId: number | string | null,
  contractTypeId: number[] | string | number | null,
  search: string | null,
  orderBy: string | null,
  sortDirection: string | null,
  selectedServices: string[],
  status: number | string | null
): Promise<IUsersHolidayQuota | null> => {
  try {
    const placeParam = placeId !== null ? `&placeId=${placeId}` : ''
    let contractTypeParam = ''
    if (contractTypeId !== null) {
      if (isArray(contractTypeId)) {
        contractTypeId.forEach((contract) => {
          contractTypeParam += `&contractTypeIdList[]=${contract}`
        })
      } else {
        contractTypeParam = contractTypeId !== null ? `&contractTypeIdList[]=${contractTypeId}` : ''
      }
    }
    const searchParam = search !== null && search !== '' ? `&search=${search}` : ''
    let selectedServicesParam = ''
    selectedServices.forEach((service) => {
      selectedServicesParam += `&services[]=${service}`
    })
    const statusParam = status !== null && status !== 'aucun' ? `&status=${status}` : ''
    const orderByParam = orderBy !== null && orderBy !== 'active' ? `&orderBy=${orderBy}` : ''
    const sortDirectionParam = sortDirection !== null ? `&sortDirection=${sortDirection}` : ''

    const url = `holidays-quota?limit=${limit}&currentPage=${currentPage}${placeParam}${contractTypeParam}${searchParam}${orderByParam}${sortDirectionParam}${selectedServicesParam}${statusParam}`
    const result = await customAxios.post(url, usersHolidayQuota)
    toast.success('La modification des droits a bien été prise en compte')
    return result as unknown as IUsersHolidayQuota
  } catch (error) {
    return null
  }
}

/**
 * Permet de faire une demande de congés
 * @param holidayRequest les infos de la demande
 * @param userId l'id du collaborateur qui fait la demande
 */
export const createHolidayRequest = async (
  holidayRequest: IHolidayRequest,
  userId: number
): Promise<INewHolidayRequestResponse | null> => {
  try {
    const formatedFirstDay = convertDateTimeToDateString(holidayRequest.firstDay)
    const formatedLastDay = convertDateTimeToDateString(holidayRequest.lastDay)
    const data = {
      firstDay: formatedFirstDay,
      lastDay: formatedLastDay,
      holidayType: holidayRequest.holidayType,
      duration: holidayRequest.duration,
      year: holidayRequest.year,
      user: holidayRequest.user
    }
    const result = await customAxios.post(`users/${userId}/holiday-requests`, data)
    toast.success('Votre demande de congés a bien été prise en compte')
    return result as unknown as INewHolidayRequestResponse
  } catch (error) {
    return null
  }
}

/**
 * Permet de modifier une demande de congé
 */
export const updateHolidayRequests = async (
  holidayRequest: IDetailHolidayRequest
): Promise<IDetailHolidayRequest | null> => {
  try {
    const response = await customAxios.put(`holiday-requests/${holidayRequest.id}`, holidayRequest)
    toast.success(`Le congé a bien été ${holidayRequest.status.toLowerCase()}`)
    return response as unknown as IDetailHolidayRequest
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir les demandes de congés en fonction d'une recherche sur le collaborateur
 */
export const getHolidayRequests = async (
  currentPage: number = 1,
  limit: number = ROWS_PER_PAGE,
  orderBy: string = 'firstDay',
  sortDirection: string = 'asc',
  search: string | null = '',
  placeId: number | string | null,
  statusList: string[],
  year: number,
  holidayType: string | null
): Promise<IHolidayRequestsWithPagination | null> => {
  try {
    const placeParam = placeId !== null ? `&placeId=${placeId}` : ''
    const orderByParam = orderBy !== null ? `&orderBy=${orderBy}` : ''
    const sortDirectionParam = sortDirection !== null ? `&sortDirection=${sortDirection}` : ''
    const searchParam = search !== null && search !== '' ? `&search=${search}` : ''
    const holidayTypeParam =
      holidayType !== null && holidayType !== '' ? `&holidayType=${holidayType}` : ''
    const yearParam = `&year=${year}`
    let statusParam = ''
    statusList.forEach((status) => {
      statusParam += `&statusList[]=${status}`
    })

    const url = `holiday-requests?limit=${limit}&currentPage=${currentPage}${placeParam}${orderByParam}${sortDirectionParam}${yearParam}${searchParam}${statusParam}${holidayTypeParam}`
    return await customAxios.get(url)
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir le total des demandes de congés non validées
 */
export const getUnvalidatedHolidayRequests = async (
  status: string,
  place: number | string | null
): Promise<{
  data: []
} | null> => {
  try {
    const placeParam = place !== null ? `&placeId=${place}` : ''
    const url = `holiday-requests?statusList[]=${status}${placeParam}`
    return await customAxios.get(url)
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir le total des congées de tout les collaborateurs
 */
export const getTotalHolidayQuotaRequests = async (
  month: number
): Promise<ITotalHolidayQuotaRequest | null> => {
  try {
    const monthParam = `?month=${month}`
    return await customAxios.get(`holidays-total${monthParam}`)
  } catch (error) {
    return null
  }
}

/**
 * Permet d'obtenir les demandes de congés d'un collaborateur
 */
export const getMyHolidayRequest = async (
  currentPage: number,
  limit: number,
  orderBy: string | null,
  sortDirection: string | null,
  year: number,
  statusList: string[],
  userId: number
): Promise<IHolidayRequestsWithPagination | null> => {
  try {
    let statusParam = ''
    statusList.forEach((status) => {
      statusParam += `&statusList[]=${status}`
    })
    const yearParam = `&year=${year}`
    const limitParam = `&limit=${limit}`
    const pageParam = `?currentPage=${currentPage}`
    const orderByParam = orderBy !== null ? `&orderBy=${orderBy}` : ''
    const sortDirectionParam = sortDirection !== null ? `&sortDirection=${sortDirection}` : ''

    return await customAxios.get(
      `/users/${userId}/holiday-requests${pageParam}${limitParam}${orderByParam}${sortDirectionParam}${yearParam}${statusParam}`
    )
  } catch (error) {
    return null
  }
}

/**
 * Exporte la synthèse des congés en CSV.
 * @return string | undefined
 */
export const exportHolidaySynthesis = async (
  place: number | null,
  year: number,
  month: number
): Promise<IFileResponse | null> => {
  try {
    const placeParam = place !== null ? `&placeId=${place}` : ''
    return await customAxios.get(
      `holiday-synthesis-export?year=${year}&month=${month}${placeParam}`,
      {
        responseType: 'arraybuffer'
      }
    )
  } catch (error) {
    return null
  }
}
