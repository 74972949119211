import React, { ReactElement, useContext, useEffect, useState } from 'react'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { getPlaces } from 'services/SelectorsValuesService'
import { IListSelectorValue } from 'interfaces/selectors'
import { useSearchParams } from 'react-router-dom'
import { PlaceSelectedContext } from 'App'

interface IPlaceFilter {
  navOpen: boolean
}

const PlaceFilter = ({ navOpen }: IPlaceFilter): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const [searchParams] = useSearchParams()
  const [places, setPlaces] = useState<IListSelectorValue[]>([])
  // Alimentation du selecteur de lieux
  useEffect(() => {
    void (async () => {
      await getPlaces().then((response) => {
        setPlaces(response.concat({ id: -1, name: 'Tous' }))
        const placeQuery = searchParams.get('place')
        if (placeQuery && response.find((place) => place.id === Number(placeQuery))) {
          placeSelectedContext?.setPlaceSelected(Number(placeQuery))
        }
      })
    })()
  }, [])

  const handleChangePlace = (newPlace: number | null): void => {
    placeSelectedContext?.setPlaceSelected(newPlace === -1 ? null : newPlace)
  }

  if (!navOpen) {
    return <div></div>
  }

  return (
    <div className="place-filter">
      <CustomSelect
        value={place ?? 'Tous'}
        options={places}
        handleSelect={(newValue) => {
          handleChangePlace(newValue as number)
        }}
        id="place"
        width={190}
        inputLabel="Lieu"
        required={true}
      />
    </div>
  )
}

export default PlaceFilter
