import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { customAxios } from './axiosUtils'

/**
 Récupère les nouveaux employés
*/
export const getNewArrival = async (): Promise<IMinimalCollaborateurData[]> => {
  try {
    return await customAxios.get(`newArrivals`)
  } catch (error) {
    return []
  }
}
