import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { getSettings, updateSettings } from 'services/SettingService'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import CircularProgress from '@mui/material/CircularProgress'
import { IColorSettings } from 'interfaces/colorSettings'
import SettingsForm from './settingsForm/SettingsForm'
import { changeThemeColor } from 'utils/otherUtils'
import { useNavigate } from 'react-router-dom'
import { ISetting } from 'interfaces/setting'
import { PlaceSelectedContext } from 'App'
import { colorVars } from 'constantes'

const Setting = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true)
  const [settings, setSettings] = useState<ISetting[]>([])
  const [initialeThemeColors, setInitialeThemeColors] = useState<IColorSettings | null>(null)
  const initialeThemeColorsRef = useRef(initialeThemeColors)
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const navigate = useNavigate()
  // Récupérer le token de chaque service
  useEffect(() => {
    setLoading(true)
    void (async () => {
      if (place !== null) {
        const settingTheme = await getSettings(place)
        setSettings([...settingTheme])
        setLoading(false)
        // set les couleurs initiales pour gérer le cas où on annule ou change de page sans sauvegarder
        const colors = getDBThemeColors([...settingTheme])
        if (colors != null) {
          initialeThemeColorsRef.current = colors
          setInitialeThemeColors(colors)
        }
      }
    })()
  }, [place])

  // On stocke une référence de initialThemeCOlor pour être sûr d'avoir la dernier valeur et l'utiliser lors du démontage du composant
  useEffect(() => {
    initialeThemeColorsRef.current = initialeThemeColors
  }, [initialeThemeColors])

  // Lorsqu'on quitte setting, on re applique les couleurs enregistrées en BD (gère l'annulation, ou changement de page sans sauvegarde + sauvegarde)
  useEffect(() => {
    return (): void => {
      if (initialeThemeColorsRef.current !== null) {
        changeThemeColor(colorVars.PRIMARY_COLOR, initialeThemeColorsRef.current.primaryColor)
        changeThemeColor(colorVars.SECONDARY_COLOR, initialeThemeColorsRef.current.secondaryColor)
        changeThemeColor(colorVars.TERTIARY_COLOR, initialeThemeColorsRef.current.tertiaryColor)
      }
    }
  }, [])

  // Récupère les couleurs de thème parmi les settings à partir des clés
  const getDBThemeColors = (settings: ISetting[]): IColorSettings | null => {
    const primaryColor = settings.find((setting) => setting.key === 'primary_color')?.value
    const secondaryColor = settings.find((setting) => setting.key === 'secondary_color')?.value
    const tertiaryColor = settings.find((setting) => setting.key === 'tertiary_color')?.value

    if (primaryColor == null || secondaryColor == null || tertiaryColor == null) {
      return null
    }

    return { primaryColor, secondaryColor, tertiaryColor }
  }

  const isDataURI = (str: string | null | undefined): boolean => {
    const dataURIPattern = /^data:image\/(jpeg|png);base64,/
    if (str !== undefined && str !== null) {
      return dataURIPattern.test(str)
    }
    return true
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true)
    const stock = [...settings].find((element) => element.key === 'logo')
    if (!isDataURI(stock?.value)) {
      const result = [...settings]
      result[result.findIndex((element) => element.key === 'logo')].value = null
      setSettings(result)
    }
    const newSettings = await updateSettings(settings, place)
    // Met à jour le thème initiale pour conserver ce thème lors du changement de page
    const colors = getDBThemeColors(newSettings)
    if (colors != null) {
      setInitialeThemeColors(colors)
    }

    setLoading(false)
  }

  return (
    <div className="settings">
      <h3>
        Page de<span className="accentuated-title"> paramétrage</span>{' '}
      </h3>

      {place === null ? (
        <div className="loader">
          <h3>Veuillez sélectionner un lieu</h3>
        </div>
      ) : (
        <div>
          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            <>
              <SettingsForm
                settings={settings}
                setSettings={setSettings}
                initalColors={initialeThemeColorsRef.current}
              />

              <div className="btn-box">
                <PrimaryButton
                  handleClick={() => {
                    void (async () => {
                      await handleSubmit()
                    })()
                  }}
                  title="Sauvegarder"
                  background
                  reverse={false}
                  disabled={false}
                />
                <PrimaryButton
                  handleClick={(): void => {
                    navigate(-1)
                  }}
                  title="Annuler"
                  background
                  reverse
                  disabled={false}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default Setting
