import {
  Box,
  Pagination,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { IheadCells } from 'interfaces/headCells'
import React, { ReactElement } from 'react'
import { Order } from 'types/order'

interface IFilteredListItems {
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  nbPages: number
  order?: Order
  setOrder?: React.Dispatch<React.SetStateAction<Order>>
  orderBy?: string
  setOrderBy?: React.Dispatch<React.SetStateAction<string>>
  headCells: IheadCells[]
  tableBody: ReactElement
  nbItems: number
  setOnlyShowFilter?: React.Dispatch<React.SetStateAction<boolean>>
  onlyShowMobileFilters?: boolean
  selectAll?: boolean
  setSelectAll?: React.Dispatch<React.SetStateAction<boolean>>
  showHeaderAndPagination?: boolean
}

const FilteredListItems = ({
  page,
  setPage,
  nbPages,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  headCells,
  tableBody,
  nbItems,
  setOnlyShowFilter,
  onlyShowMobileFilters,
  selectAll,
  setSelectAll,
  showHeaderAndPagination
}: IFilteredListItems): ReactElement => {
  const changeOrderBy = (id: string): void => {
    if (id !== 'googlePicture' && id !== 'checkbox') {
      const isAsc = orderBy === id && order === 'asc'
      setOrder?.(isAsc ? 'desc' : 'asc')
      setOrderBy?.(id)
    }
  }

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  return (
    <>
      {onlyShowMobileFilters === undefined || !onlyShowMobileFilters ? (
        <Box sx={{ width: '100%' }} className="table">
          {nbItems === 0 ? (
            <p className="no-data">Aucune donnée trouvée</p>
          ) : (
            <>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                  <Table aria-labelledby="tableTitle">
                    {headCells?.length > 0 && (
                      <TableHead>
                        <TableRow>
                          {setSelectAll !== undefined && selectAll !== undefined ? (
                            <TableCell className="sx-delete">
                              <div className="check-icon-headcell">
                                <input
                                  className="checkbox"
                                  type="checkbox"
                                  disabled={false}
                                  onChange={() => {
                                    setSelectAll(!selectAll)
                                  }}
                                  id="headcell-checkbox"
                                  checked={selectAll}
                                />
                              </div>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {headCells.map((headCell, index) => (
                            <TableCell
                              key={headCell.id}
                              sortDirection={orderBy === headCell.id ? order : false}
                              className={`${
                                headCell.priority === 4
                                  ? 'md-delete'
                                  : headCell.priority === 3
                                  ? 'sx-delete'
                                  : headCell.priority === 2
                                  ? 'sx-mini-delete'
                                  : ''
                              }`}
                            >
                              {headCell.isSortable !== false ? (
                                <TableSortLabel
                                  active={orderBy === headCell.id}
                                  direction={orderBy === headCell.id ? order : 'asc'}
                                  onClick={(): void => {
                                    changeOrderBy(headCell.id)
                                  }}
                                  IconComponent={ArrowDropUpIcon}
                                >
                                  {headCell.label}
                                </TableSortLabel>
                              ) : (
                                <div>{headCell.label}</div>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                    )}
                    {tableBody}
                  </Table>
                </TableContainer>
              </Paper>
              {showHeaderAndPagination && (
                <div className="pagination">
                  <Pagination
                    count={nbPages}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              )}
            </>
          )}
          {onlyShowMobileFilters !== undefined && (
            <div className="mobile-filter">
              <PrimaryButton
                handleClick={function (): void {
                  if (setOnlyShowFilter !== undefined) {
                    setOnlyShowFilter(true)
                  }
                }}
                title="Filtrer"
                background
                reverse={false}
                disabled={false}
                className="cta"
              />
            </div>
          )}
        </Box>
      ) : (
        <PrimaryButton
          handleClick={() => {
            if (setOnlyShowFilter !== undefined) {
              setOnlyShowFilter(false)
            }
          }}
          title="Rechercher"
          background
          reverse={false}
          disabled={false}
          className="cta"
        />
      )}
    </>
  )
}

export default FilteredListItems
