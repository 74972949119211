import React, { ReactElement } from 'react'

interface IPrimaryButton {
  handleClick: (event?: any) => void
  title: string
  icon?: ReactElement
  background: boolean
  reverse: boolean
  disabled: boolean
  isLoading?: boolean
  className?: string
  isActive?: boolean
  classNameTitle?: string
  image?: string
  fontSize?: string
}

const PrimaryButton = ({
  handleClick,
  title,
  icon,
  background,
  reverse,
  disabled,
  isLoading,
  className = '',
  classNameTitle = '',
  image,
  fontSize
}: IPrimaryButton): ReactElement => {
  if (isLoading === true) {
    return <img src="/spinner.gif" style={{ height: 50 }} alt="spinner" />
  }

  return (
    <div className="btn-container">
      <button
        className={`btn-newCollabs ${background ? 'background' : 'no-background'} ${
          reverse ? 'reverse' : ''
        } ${className} `}
        onClick={(e) => {
          e.stopPropagation()
          handleClick(e)
        }}
        disabled={disabled}
      >
        <span className={icon !== undefined ? 'btn-icon' : ''}>{icon}</span>{' '}
        <span className={`btn-title ${classNameTitle}`} style={{ fontSize }}>
          {title}
        </span>
        {image}
      </button>
    </div>
  )
}

export default PrimaryButton
