import HolidayRequestConfPopUp from 'components/inputs/confirmationPopUp/HolidayRequestConfPopUp'
import { IPlanningDate, IUserPlanningDate } from '../../interfaces/planning'
import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import { validatePlanningDates } from '../../services/PlanningService'
import { updateHolidayRequests } from 'services/HolidayService'
import PrimaryButton from '../inputs/button/PrimaryButton'
import useScreenSize from 'customHooks/useScreenSize'
import React, { ReactElement, useState } from 'react'
import { screenSizes, statusEnum } from 'constantes'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

interface IApprobationButtons {
  request: IDetailHolidayRequest | IUserPlanningDate
  year: number
  handleRequestApprobationUpdate: (response: IDetailHolidayRequest | IPlanningDate[]) => void
  setApprovedRowId: (id: number) => void
  isTT: boolean
}

const HolidayRequestApprobation = ({
  request,
  year,
  handleRequestApprobationUpdate,
  setApprovedRowId,
  isTT
}: IApprobationButtons): ReactElement => {
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const [action, setAction] = useState<string>('')
  const [requestId, setRequestId] = useState<number>()
  const [holidayRequest, setHolidayRequest] = useState<IDetailHolidayRequest | IUserPlanningDate>()
  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)
  const [refusalText, setRefusalText] = useState<string | undefined>(undefined)

  const handleValidationClick = (status: string): void => {
    setAction(status)
    setOpenPopUp(true)

    if (isTT) {
      const tt: IUserPlanningDate = { ...(request as IUserPlanningDate) }
      tt.planningDates[0].status = status

      setHolidayRequest(tt)
      setRequestId(tt.planningDates[0].id)
    } else {
      const holiday: IDetailHolidayRequest = {
        ...(request as IDetailHolidayRequest),
        status,
        holidayType: { id: (request as IDetailHolidayRequest).holidayType.id },
        year,
        user: (request as IDetailHolidayRequest).user,
        requestingUser: (request as IDetailHolidayRequest).requestingUser
      }
      setHolidayRequest(holiday)
      setRequestId(holiday.id)
    }
  }

  const handleValidationConfirmation = async (): Promise<void> => {
    if (holidayRequest !== undefined && requestId !== undefined) {
      let response: IPlanningDate[] | IDetailHolidayRequest | null
      if (holidayRequest.refusalText !== '') {
        holidayRequest.refusalText = refusalText
      }
      if (isTT) {
        response = await validatePlanningDates(holidayRequest as IUserPlanningDate)
      } else {
        response = await updateHolidayRequests(holidayRequest as IDetailHolidayRequest)
      }
      if (response !== null) {
        const id = isTT
          ? (holidayRequest as IUserPlanningDate).planningDates[0].id
          : (holidayRequest as IDetailHolidayRequest).id
        if (id !== undefined) {
          setApprovedRowId(id)
        }
        setOpenPopUp(false)
        // Attend que l'animation se fasse avant de supprimer la demande de la liste
        setTimeout(() => {
          handleRequestApprobationUpdate(response as IDetailHolidayRequest | IPlanningDate[])
        }, 800) // ajustez le délai selon vos besoins
      }
    }
  }

  return (
    <>
      <div className="holidays-requests-status">
        {isSmallScreen ? (
          <CheckIcon
            id="icon-request-accept"
            onClick={() => {
              handleValidationClick(statusEnum.ACCEPTED)
            }}
          />
        ) : (
          <PrimaryButton
            handleClick={() => {
              handleValidationClick(statusEnum.ACCEPTED)
            }}
            title="Valider"
            background
            reverse
            disabled={false}
            className="btn-request-accept"
          />
        )}
        {isSmallScreen ? (
          <CloseIcon
            id="icon-request-refuse"
            onClick={() => {
              handleValidationClick(statusEnum.REFUSED)
            }}
          />
        ) : (
          <PrimaryButton
            handleClick={() => {
              handleValidationClick(statusEnum.REFUSED)
            }}
            title="Refuser"
            background
            reverse
            disabled={false}
            className="btn-request-refuse"
          />
        )}
      </div>
      <HolidayRequestConfPopUp
        action={action}
        open={openPopUp}
        setOpen={setOpenPopUp}
        handleAction={handleValidationConfirmation}
        isTT={isTT}
        refusalText={refusalText}
        setRefusalText={setRefusalText}
      />
    </>
  )
}

export default HolidayRequestApprobation
