import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import { StatusStyles, dateFormat, isValidStatus } from 'constantes'
import React, { ReactElement, useContext, useState } from 'react'
import Permission from '../../components/permission/Permission'
import { TableBody, TableCell, TableRow } from '@mui/material'
import ProfilImage from 'components/profilImage/ProfilImage'
import { featuresList } from '../../constantes'
import { useNavigate } from 'react-router-dom'
import { YearSelectedContext } from 'App'
import dayjs from 'dayjs'

interface IListCollabsBodyTable {
  collabs: IDetailCollaborateurData[]
  selectedCollabs: IDetailCollaborateurData[]
  setSelectedCollabs: React.Dispatch<React.SetStateAction<IDetailCollaborateurData[]>>
  selectAll: boolean
}

const ListCollabsBodyTable = ({
  collabs,
  selectedCollabs,
  setSelectedCollabs,
  selectAll
}: IListCollabsBodyTable): ReactElement => {
  const navigate = useNavigate()

  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected

  const [checked, setChecked] = useState<boolean>(false)

  const getStatusClassName = (status: string): string => {
    const normalizedStatus = status.toLowerCase()
    // S'assure que le statut est valide
    if (isValidStatus(normalizedStatus)) {
      return StatusStyles[normalizedStatus]
    }
    return StatusStyles.active // Retourne le statut actif par défaut
  }

  const handleChange = (collab: IDetailCollaborateurData): void => {
    setChecked(!checked)
    if (selectedCollabs.find((item) => item.id === collab.id) === undefined) {
      setSelectedCollabs(selectedCollabs.concat(collab))
    } else {
      setSelectedCollabs(selectedCollabs.filter((item) => item.id !== collab.id))
    }
  }

  /*
   * Retourne la date d'entrée du collaborateur
   * @param collab - Le collaborateur
   * @returns La date d'entrée du collaborateur
   */
  const getEntryDate = (collab: IDetailCollaborateurData): string => {
    if (collab.permanentContractStartDate) {
      return dayjs(collab.permanentContractStartDate).format(dateFormat.DATE)
    }
    if (collab.arrivalDate && !collab.internshipStartDate) {
      return dayjs(collab.arrivalDate).format(dateFormat.DATE)
    }
    return '-'
  }

  /*
   * Retourne la date de début de stage du collaborateur
   * @param collab - Le collaborateur
   * @returns La date de début de stage du collaborateur
   */
  const getStartInternshipDate = (collab: IDetailCollaborateurData): string => {
    if (collab.internshipStartDate) {
      return dayjs(collab.internshipStartDate).format(dateFormat.DATE)
    }
    return '-'
  }

  /*
   * Retourne la date de départ du collaborateur
   * @param collab - Le collaborateur
   * @returns La date de départ du collaborateur
   */
  const getLeavingDate = (collab: IDetailCollaborateurData): string => {
    if (collab.endInternshipDate) {
      return dayjs(collab.endInternshipDate).format(dateFormat.DATE)
    }
    if (collab.leavingDate) {
      return dayjs(collab.leavingDate).format(dateFormat.DATE)
    }
    return '-'
  }

  return (
    <TableBody>
      {collabs.map((collab: IDetailCollaborateurData) => {
        const currentHolidaysQuota =
          collab.holidaysQuota !== undefined
            ? collab.holidaysQuota?.find((quota) => quota.year === Number(year))
            : undefined
        const annualQuota =
          !Number.isNaN(currentHolidaysQuota) && currentHolidaysQuota !== undefined
            ? Number(currentHolidaysQuota.paidVacationQuota) + Number(currentHolidaysQuota.RTTQuota)
            : NaN
        const remainingQuota =
          collab.remainingQuotas !== undefined ? collab.remainingQuotas.remainingQuota : NaN
        return (
          <TableRow
            hover
            onClick={() => {
              navigate(`/profil/${collab.id}`)
            }}
            tabIndex={-1}
            key={collab.id}
            className={getStatusClassName(collab.status)}
          >
            <Permission name={featuresList.HOLIDAY}>
              <TableCell className="sx-delete">
                <div className="check-icon-collab">
                  <input
                    className="checkbox"
                    type="checkbox"
                    disabled={selectAll}
                    onChange={() => {
                      handleChange(collab)
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    id={collab.id.toString()}
                    checked={selectAll || selectedCollabs.some((item) => item.id === collab.id)}
                  />
                </div>
              </TableCell>
            </Permission>
            <TableCell className="md-delete">
              {' '}
              <ProfilImage user={collab} />
            </TableCell>
            <TableCell>{collab.displayLastname !== '' ? collab.displayLastname : '-'}</TableCell>
            <TableCell>{collab.displayFirstname !== '' ? collab.displayFirstname : '-'}</TableCell>
            <TableCell className="md-delete">{collab.place?.name}</TableCell>
            <TableCell className="md-delete">{collab.contractType?.name}</TableCell>
            <TableCell className="sx-delete">{getEntryDate(collab)}</TableCell>
            <TableCell className="sx-delete">{getStartInternshipDate(collab)}</TableCell>
            <TableCell className="sx-delete">{getLeavingDate(collab)}</TableCell>
            <Permission name={featuresList.HOLIDAY}>
              <>
                <TableCell className="md-delete">
                  {!Number.isNaN(annualQuota) ? annualQuota : '-'}
                </TableCell>
                <TableCell className="md-delete">
                  {remainingQuota !== undefined && !Number.isNaN(remainingQuota)
                    ? remainingQuota
                    : '-'}
                </TableCell>
              </>
            </Permission>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default ListCollabsBodyTable
