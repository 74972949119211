import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import SearchBox from 'components/inputs/searchBox/SearchBox'
import { getTrombinoscopeUsers } from 'services/UserService'
import TrombinoscopeTab from './TrombinoscopeTab'
import { CircularProgress } from '@mui/material'
import { PlaceSelectedContext } from 'App'

const Trombinoscope = (): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null

  const [collabs, setCollabs] = useState<IMinimalCollaborateurData[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const users = await getTrombinoscopeUsers(searchTerm, place)
      setCollabs(users)
      setLoading(false)
    })()
  }, [searchTerm, place])

  return (
    <div className="trombi">
      <div className="trombi-title">
        <h3>
          <span className="accentuated-title">Trombinoscope</span> de Steamulo
        </h3>
        <SearchBox setSearchTerm={setSearchTerm} placeholder="Rechercher un collaborateur" />
      </div>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : collabs.length > 0 ? (
        <TrombinoscopeTab collabs={collabs} searchTerm={searchTerm} />
      ) : (
        <h4 className="no-collabs-found-trombi">Collaborateur(s) non trouvé(s)</h4>
      )}
    </div>
  )
}

export default Trombinoscope
