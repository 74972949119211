import { IListSelectorValue } from 'interfaces/selectors'
import { IProfilTab } from './interfaces/headCells'

export const rolePermissionsConstant = {
  ROLE_ADMIN: { value: 'ROLE_SUPER_ADMIN', label: 'Super admin' } as unknown as IListSelectorValue,
  ROLE_USER: {
    value: 'ROLE_USER',
    label: 'Utilisateur front-office'
  } as unknown as IListSelectorValue,
  ROLE_ADMIN_COMPTABLE: {
    value: 'ROLE_ADMIN_COMPTABLE',
    label: 'Admin comptable'
  } as unknown as IListSelectorValue,
  ROLE_ADMIN_RH: { value: 'ROLE_ADMIN_RH', label: 'Admin RH' } as unknown as IListSelectorValue,
  ROLE_MANAGER: { value: 'ROLE_MANAGER', label: 'Directeur' } as unknown as IListSelectorValue,
  ROLE_PROJECT_MANAGER: {
    value: 'ROLE_PROJECT_MANAGER',
    label: 'Chef de Projet'
  } as unknown as IListSelectorValue
}

export const roleConstant = {
  ROLE_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_USER: 'ROLE_USER',
  ROLE_ADMIN_COMPTABLE: 'ROLE_ADMIN_COMPTABLE',
  ROLE_ADMIN_RH: 'ROLE_ADMIN_RH',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_PROJECT_MANAGER: 'ROLE_PROJECT_MANAGER'
}

export const jobConstant = {
  DEVELOPPEUR: 'Développeur',
  DEVOPS: 'DevOps',
  PO: 'Product Owner',
  COMMERCIAL: 'Commercial',
  RH: 'RH',
  OPERATION: 'Opération',
  DIRECTION: 'Direction',
  OTHER: 'Autre'
}

export const mealPlanConstant = {
  RESTAU_TICKETS: 'Tickets restau',
  IFR: 'IFR'
}

export const contractTypeConstant = {
  STAGIAIRE: 'Stagiaire',
  PRESTATAIRE: 'Prestataire',
  CDI: 'CDI',
  ALTERNANT: 'Alternant',
  CLIENT: 'Client',
  COMPTE_SERVICE: 'Compte de service',
  CDD: 'CDD'
}

export const placeConstant = {
  PARIS: 'Paris',
  LYON: 'Lyon'
}

export const OSList = ['MacOs', 'Ubuntu', 'Windows', 'Ubuntu/Windows']

export const YesOrNotConstant = { yes: 'Oui', no: 'Non' }
export const YesOrNotList = [YesOrNotConstant.yes, YesOrNotConstant.no]

export const ActiveOrNotConstant = { active: 'Actif', inactive: 'Inactif' }
export const ActiveList = [ActiveOrNotConstant.active, ActiveOrNotConstant.inactive]

export const ROWS_PER_PAGE: number = 20
export const ROWS_PER_PAGE_MAX: number = 200

export const materialTypeConstant = {
  FIX: 'Fix',
  PORTABLE: 'Portable'
}
export const materialTypeList = [materialTypeConstant.FIX, materialTypeConstant.PORTABLE]

export const themeSetting = {
  PRIMARY_COLOR: 'primary_color',
  LOGO: 'logo',
  SECONDARY_COLOR: 'secondary_color',
  TERTIARY_COLOR: 'tertiary_color'
}

export const menuItemConstant = {
  PROFIL: 'Profil',
  TABLEAU_BORD: 'Tableau de bord',
  LISTE_COLLABS: 'Liste collaborateurs',
  LISTE_MATERIELS: 'Liste matériels',
  PARAMETRAGE: 'Paramétrage',
  CARRIERE: 'Carrière',
  MES_CONGES: 'Mes congés',
  SUIVI_ABSENCES: 'Suivi des absences',
  NOTES_FRAIS: 'Notes de frais',
  MES_NOTES_FRAIS: 'Mes notes de frais',
  PLANNING: 'Planning',
  REPORTING: 'Reporting',
  ADMIN_REPORTING: 'Reporting Admin',
  TROMBINOSCOPE: 'Trombinoscope',
  STEAMDEJ: 'SteamDej',
  MES_DOCUMENTS: 'Mes documents',
  DECONNEXION: 'Déconnexion',
  DIVIDER: 'Divider',
  ADMIN: 'Administration',
  USER: 'Mes informations',
  GROUP: 'Groupes',
  LISTE_CLIENTS: 'Liste clients et projets',
  COMPANY: 'Mon entreprise et moi'
}

export const holidayRequestConstant = {
  TT: 'TT',
  MALADIE: 'Arrêt maladie',
  A_VALIDER: 'A valider',
  HISTORIQUE: 'Historique',
  SYNTHESE: 'Synthèse'
}

export const expensesTypeConstant = {
  TELEPHONE: 'Téléphone',
  INTERNET: 'Internet',
  TEL_INTERNET: 'Téléphone / Internet',
  AUTRES: 'Autres'
}

export const expensesSubtypeConstant = {
  MATINALE: 'Matinale / Déjeuner client',
  COURSES: 'Courses',
  FOURNITURES: 'Fournitures administratives',
  EQUIPEMENTS: 'Achats de petits équipements',
  FRAIS_REFACTURES: 'Frais refacturés',
  PORT_CAS_EXCEPTIONNEL: 'Port cas exceptionnel',
  DIVERS: 'Divers',
  TRANSPORTS: 'Transports'
}
export const expensesSubtypeList = [
  expensesSubtypeConstant.MATINALE,
  expensesSubtypeConstant.COURSES,
  expensesSubtypeConstant.FOURNITURES,
  expensesSubtypeConstant.EQUIPEMENTS,
  expensesSubtypeConstant.FRAIS_REFACTURES,
  expensesSubtypeConstant.PORT_CAS_EXCEPTIONNEL,
  expensesSubtypeConstant.DIVERS,
  expensesSubtypeConstant.TRANSPORTS
]

export const expenseStatusContant = {
  A_SAISIR: 'A saisir',
  A_SIGNER: 'A signer',
  A_VERIFIER: 'A vérifier',
  A_VALIDER: 'A valider',
  A_REMBOURSER: 'A rembourser',
  REMBOURSE: 'Remboursé',
  REFUSE: 'Refusé',
  NON_RENSEIGNE: 'Non renseigné',
  ERREUR: 'Erreur banque'
}

export const holidayRequestList = [
  holidayRequestConstant.A_VALIDER,
  holidayRequestConstant.TT,
  holidayRequestConstant.MALADIE,
  holidayRequestConstant.HISTORIQUE,
  holidayRequestConstant.SYNTHESE
]

export const headerConstant = {
  PAGINATION_COUNT: 'pagination-count',
  PAGINATION_PAGE: 'pagination-page',
  PAGINATION_LIMIT: 'pagination-limit',
  PAGINATION_MAX_PAGE: 'pagination-max-page'
}

export const dayDurationConstant = {
  JOURNEE: 'Journée',
  MATIN: 'Matin',
  APRES_MIDI: 'Après-midi'
}

export const dayDurationList = [
  dayDurationConstant.JOURNEE,
  dayDurationConstant.MATIN,
  dayDurationConstant.APRES_MIDI
]

export const colorVars = {
  PRIMARY_COLOR: '--main-color',
  SECONDARY_COLOR: '--secondary-color',
  TERTIARY_COLOR: '--tertiary-color'
}

export const colorHexValues = {
  PRIMARY_COLOR: '#1653F0',
  SECONDARY_COLOR: '#0700E0',
  TERTIARY_COLOR: '#7B01D4'
}

export const screenSizes = {
  MOBILE_SIZE: 650,
  TABLET_SIZE: 1100,
  HYBRID_SIZE: 1200,
  DESKTOP_SIZE: 1400
}

export const DayTypes = {
  TT: 'Télétravail',
  PRESENTIEL: 'Présentiel',
  CLIENTS: 'Clients',
  ASTREINTE: 'Astreinte',
  CONGE_PAYE: 'Congé payé',
  AUTRE_CONGE: 'Autre congé',
  ECOLE: 'École',
  VIDE: 'Vide'
}

export const settingCategoryConstant = {
  SERVICES: 'services',
  EMAIL: 'email',
  THEME: 'thème',
  DRIVE: 'drive',
  ON_BOARDING: 'onboarding'
}

export enum DayEnum {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export const statusEnum = {
  TO_VALIDATE: 'A valider',
  ACCEPTED: 'Accepté',
  REFUSED: 'Refusé',
  CANCELED: 'Annulé'
}

export const statusList = [
  statusEnum.TO_VALIDATE,
  statusEnum.ACCEPTED,
  statusEnum.REFUSED,
  statusEnum.CANCELED
]

export const holidayTypesEnum = {
  CONGE_PAYE: 'Payé',
  CONGE_MALADIE: 'Maladie',
  CONGE_SANS_SOLDE: 'Sans solde'
}

export const ONE_DAY_DURATION_IN_MINUTES = 24 * 60
// On refresh le token toutes les 50 minutes, pour être sûr qu'il soit toujours valide
// Car le token google expire au bout de 1h
export const GOOGLE_TOKEN_EXPIRATION = 50 * 60 * 1000

export const steamdejStatusEnum = {
  TODO: 'A faire',
  DONE: 'Fait'
}

export const planningViewEnum = {
  MONTH: 'Mois',
  YEAR: 'Année',
  GROUP: 'Groupe'
}

export const planningViewList = [planningViewEnum.MONTH, planningViewEnum.YEAR]

export const monthsList = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
]

export const shortMonthsList = [
  'JAN',
  'FEV',
  'MAR',
  'AVR',
  'MAI',
  'JUIN',
  'JUIL',
  'AOUT',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
]

export const daysList = ['L', 'M', 'M', 'J', 'V', 'S', 'D']

export const myDocumentRequestConstant = [
  { title: 'Mes fiches de paies', type: 'FICHESDEPAIES' },
  { title: 'Mes documents personnels', type: 'DOCUMENTSPERSONNELS' },
  { title: 'Documents internes', type: 'DOCUMENTSINTERNES' }
]

export const featuresList = {
  COLLABS: 'COLLABS',
  MATERIAL: 'MATERIAL',
  CLIENTS: 'CLIENTS',
  CAREER: 'CAREER',
  NDF: 'NDF',
  DEJ: 'DEJ',
  TROMBI: 'TROMBI',
  HOLIDAY: 'HOLIDAY',
  PLANNING: 'PLANNING',
  DOCUMENTS: 'DOCUMENTS',
  REPORTING: 'REPORTING'
}

export const profilTabList: IProfilTab[] = [
  {
    label: 'Informations du compte',
    confidential: false
  },
  {
    label: 'Avantages',
    confidential: false
  },
  {
    label: 'Carrière',
    confidential: true,
    contractTypes: [
      contractTypeConstant.CDI,
      contractTypeConstant.CDD,
      contractTypeConstant.STAGIAIRE,
      contractTypeConstant.ALTERNANT
    ],
    needPermissions: [
      roleConstant.ROLE_MANAGER,
      roleConstant.ROLE_ADMIN,
      roleConstant.ROLE_ADMIN_RH
    ],
    feature: featuresList.CAREER
  },
  {
    label: 'Documents Personnels',
    confidential: false,
    contractTypes: [
      contractTypeConstant.CDI,
      contractTypeConstant.CDD,
      contractTypeConstant.STAGIAIRE,
      contractTypeConstant.ALTERNANT
    ],
    needPermissions: [roleConstant.ROLE_ADMIN, roleConstant.ROLE_ADMIN_RH],
    feature: featuresList.DOCUMENTS
  },
  {
    label: 'Costs',
    confidential: false,
    contractTypes: [
      contractTypeConstant.CDI,
      contractTypeConstant.CDD,
      contractTypeConstant.STAGIAIRE,
      contractTypeConstant.ALTERNANT,
      contractTypeConstant.PRESTATAIRE
    ],
    needPermissions: [roleConstant.ROLE_ADMIN, roleConstant.ROLE_ADMIN_COMPTABLE],
    feature: featuresList.REPORTING
  }
]

export const adminReportingTabList: IProfilTab[] = [
  {
    label: 'Pas pointé',
    confidential: false
  },
  {
    label: 'Synthèse',
    confidential: false
  },
  {
    label: 'Par type',
    confidential: false
  },
  {
    label: 'Par collab',
    confidential: false
  },
  {
    label: 'Par projet',
    confidential: false
  },
  {
    label: 'Costs',
    confidential: false
  },
  {
    label: 'Clients / Projets',
    confidential: false
  }
]

export const CPReportingTabList: IProfilTab[] = [
  {
    label: 'Mon reporting',
    confidential: false
  },
  {
    label: 'Mes projets',
    confidential: false
  }
]

export const incomeTypeList = {
  BONUS: 'Prime',
  SALARY: 'Salaire',
  INCREASE: 'Augmentation'
}

export const dateFormat = {
  DATE: 'DD/MM/YYYY',
  DATEHOUR: 'DD/MM/YYYY HH:mm'
}

export const chartOptionsValueShown = {
  radialBar: {
    hollow: {
      margin: 0,
      background: '#fff',
      image: undefined,
      imageOffsetX: 0,
      imageOffsetY: 0,
      position: 'front'
    },
    track: {
      background: '#F2F1FF',
      margin: 0
    },
    dataLabels: {
      show: true,
      name: {
        show: false
      },
      value: {
        offsetY: 10,
        color: '#1009D8',
        fontSize: '28px',
        fontWeight: '700',
        show: true
      }
    }
  }
}

export const chartOptionsNameShown = {
  radialBar: {
    hollow: {
      margin: 0,
      background: '#fff',
      image: undefined,
      imageOffsetX: 0,
      imageOffsetY: 0,
      position: 'front'
    },
    track: {
      background: '#F2F1FF',
      margin: 0
    },
    dataLabels: {
      value: {
        show: false
      },
      name: {
        offsetY: 10,
        color: '#fb923c',
        fontSize: '32px',
        fontWeight: '700',
        show: true
      }
    }
  }
}

export const chartFillColorBlue = {
  type: 'gradient',
  gradient: {
    shade: 'dark',
    type: 'horizontal',
    shadeIntensity: 1,
    opacityFrom: 1,
    opacityTo: 1,
    colorStops: [
      {
        offset: 0,
        color: '#1009D8',
        opacity: 1
      },
      {
        offset: 100,
        color: '#8986D3',
        opacity: 1
      }
    ]
  }
}

export const chartFillColorOrange = {
  type: 'gradient',
  gradient: {
    shade: 'dark',
    type: 'horizontal',
    shadeIntensity: 1,
    opacityFrom: 1,
    opacityTo: 1,
    colorStops: [
      {
        offset: 0,
        color: '#fb923c',
        opacity: 1
      },
      {
        offset: 100,
        color: '#fcad79',
        opacity: 1
      }
    ]
  }
}

export const projectStatus = {
  CLOSED: 'Clôturé',
  HOLIDAY: '-Congés',
  INPROGRESS: 'En cours'
}

export type Status = 'active' | 'inactive'

export const StatusEnum = {
  ACTIVE: 'active' as const,
  INACTIVE: 'inactive' as const
} as const

// Pour s'assurer que l'on reste dans le type Status
export const StatusMap: Record<Status, string> = {
  active: 'Actif',
  inactive: 'Inactif'
}

// Utilise le type Status pour définir les classes CSS
export const StatusStyles: Record<Status, string> = {
  active: '',
  inactive: 'inactive-row'
}

// Vérifie si une valeur est un type Status
export const isValidStatus = (status: string): status is Status => {
  return Object.values(StatusEnum).includes(status as Status)
}
