import { getContractTypes, getJobs, getProjectTypes } from 'services/SelectorsValuesService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { useRetrieveUsers } from 'customHooks/retrieveMatchingUsers'
import { getUserProductionOfYear } from 'services/ReportingService'
import TabNavigation from 'components/tabNavigation/TabNavigation'
import AdminReportingByContract from './AdminReportingByContract'
import AdminReportingByProject from './AdminReportingByProject'
import { PlaceSelectedContext, YearSelectedContext } from 'App'
import { adminReportingTabList, monthsList } from 'constantes'
import AdminReportingFollowUp from './AdminReportingFollowUp'
import AdminReportingFilters from './AdminReportingFilters'
import AdminReportingAnnual from './AdminReportingAnnual'
import AdminReportingByType from './AdminReportingByType'
import { IListSelectorValue } from 'interfaces/selectors'
import { getProjects } from 'services/ProjectsService'
import { IUserProduction } from 'interfaces/reporting'
import ImportCosts from '../profil/costs/ImportCosts'
import ListClients from '../listClients/ListClients'
import { getClients } from 'services/ClientsService'
import { CircularProgress } from '@material-ui/core'
import { IProject } from 'interfaces/projects'
import { IClient } from 'interfaces/clients'
import moment from 'moment'

const AdminReporting = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true)
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [month, setMonth] = useState<number>(moment().month() + 1)
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()
  const [years, setYears] = useState<string[]>([])
  const [clients, setClients] = useState<IClient[]>([])
  const [clientOptions, setClientOptions] = useState<string[]>([])
  const [selectedClient, setSelectedClient] = useState<IClient>()
  const [projects, setProjects] = useState<IProject[]>([])
  const [projectOptions, setProjectOptions] = useState<string[]>([])
  const [selectedProject, setSelectedProject] = useState<IProject>()
  const [isBillable, setIsBillable] = useState<boolean>(true)
  const [users, retrieveUsers] = useRetrieveUsers()
  const [currentUser, setCurrentUser] = useState<IMinimalCollaborateurData | null>(null)
  const [contracts, setContracts] = useState<string[]>([])
  const [jobs, setJobs] = useState<string[]>([])
  const [currentJob, setCurrentJob] = useState<string>('')
  const [currentContract, setCurrentContract] = useState<string>('')
  const [userProduction, setUserProduction] = useState<IUserProduction[]>([])
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const [projectTypes, setProjectTypes] = useState<string[]>([])
  const [currentProjectType, setCurrentProjectType] = useState<string>('')

  useEffect(() => {
    setLoading(true)
    const currentYear = new Date().getFullYear()
    setYears([
      (currentYear + 1).toString(),
      ...Array.from({ length: currentYear - 2018 }, (_, index) => (currentYear - index).toString())
    ])
    void (async () => {
      getClientsData()
    })()
    void getContractTypes().then((response: IListSelectorValue[]) => {
      if (response) {
        setContracts(response.map((contract) => contract?.name ?? ''))
      }
    })
    void getJobs().then((response: IListSelectorValue[]) => {
      if (response) {
        const filteredJobs = response
          .map((job) => job?.name ?? '')
          .filter((name) => name !== 'Autre')
        setJobs(['Tous sauf direction', ...filteredJobs])
      }
    })
    void getProjectTypes().then((response: IListSelectorValue[]) => {
      if (response) {
        setProjectTypes(response.map((type) => type?.name ?? ''))
      }
    })
    setLoading(false)
  }, [])

  useEffect(() => {
    void getUserProductionOfYear(year, place ?? 1).then((response) => {
      if (response) {
        setUserProduction(response)
      }
    })
  }, [year, place])

  useEffect(() => {
    setLoading(true)
    void (async () => {
      getClientsData()
      void getProjectsData(selectedClient?.id ?? '')
      setSelectedClient(undefined)
      setSelectedProject(undefined)
    })()
    setLoading(false)
  }, [isBillable, place])

  const getClientsData = (): void => {
    void getClients(undefined, place ?? 1).then((clients) => {
      setClients(clients)
      setClientActions(clients)
    })
  }

  const getProjectsData = async (id: string): Promise<void> => {
    const projects = await getProjects(id, place ?? 1)
    setProjectActions(projects)
  }

  const setClientActions = (clients: IClient[]): void => {
    const filteredClients = clients.filter((client) =>
      client.projects?.some((project) => project.invoice === isBillable)
    )
    const clientOptions = filteredClients.map((client) => client.name ?? '')
    setClientOptions(clientOptions)
  }

  const setProjectActions = (projects: IProject[]): void => {
    setProjects(projects)
    const projectOptions = projects
      .filter((project) => project.invoice === isBillable)
      .map((project) => {
        return project.name ?? ''
      })
    setProjectOptions(projectOptions)
  }

  const handleChangeClient = async (value: string | null): Promise<void> => {
    if (!value) {
      setSelectedClient(undefined)
      setSelectedProject(undefined)
      return
    }
    const selected = clients.find((client) => client.name === value)
    await getProjectsData(selected?.id ?? '')
    setSelectedClient(selected)
  }

  const handleChangeProject = (value: string): void => {
    const selected = projects.find((project) => project.name === value)
    setSelectedProject(selected)
  }

  const handleChangeYear = (newYear: number): void => {
    yearSelectedContext?.setYearSelected(newYear)
  }

  const handleChangeMonth = (newMonth: string): void => {
    const currentMonth =
      Number(monthsList.indexOf(typeof newMonth === 'string' ? newMonth : '')) + 1
    setMonth(currentMonth)
  }

  const handleProjectClick = (project: IProject): void => {
    setSelectedClient(project.client)
    setSelectedProject(project)
    setCurrentTab(4)
  }

  const goToReporting = (name: string): void => {
    const url = `/reporting?name=${name}&month=${month}&year=${year}${
      place != null && place !== 0 ? `&place=${place}` : ''
    }`
    window.open(url, '_blank')
  }

  return (
    <>
      {currentTab === 0 ? (
        <h3>
          Suivi du <span className="accentuated-title">reporting</span>
        </h3>
      ) : currentTab === 5 ? (
        <h3>
          Suivi des <span className="accentuated-title">costs</span>
        </h3>
      ) : currentTab === 6 ? (
        <h3>
          Liste des <span className="accentuated-title">clients et projets</span>
        </h3>
      ) : (
        <h3>
          Synthèse de <span className="accentuated-title">production</span>
        </h3>
      )}

      <TabNavigation
        currentTab={currentTab}
        handleTab={(e: any, tab: number) => {
          setCurrentTab(tab)
        }}
        navItems={adminReportingTabList}
      />

      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        currentTab !== 6 && (
          <AdminReportingFilters
            isBillable={isBillable}
            setIsBillable={setIsBillable}
            selectedClient={selectedClient}
            selectedProject={selectedProject}
            clientOptions={clientOptions}
            projectOptions={projectOptions}
            handleChangeClient={handleChangeClient}
            handleChangeProject={handleChangeProject}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            currentTab={currentTab}
            monthsList={monthsList}
            month={month}
            years={years}
            year={year}
            users={users}
            retrieveUsers={retrieveUsers}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            contracts={contracts}
            currentContract={currentContract}
            setCurrentContract={setCurrentContract}
            jobs={jobs}
            currentJob={currentJob}
            setCurrentJob={setCurrentJob}
            projectTypes={projectTypes}
            currentProjectType={currentProjectType}
            setCurrentProjectType={setCurrentProjectType}
          />
        )
      )}

      <>
        {currentTab === 0 && (
          <AdminReportingFollowUp
            year={year}
            month={month}
            place={place ?? 1}
            goToReporting={goToReporting}
          />
        )}
      </>
      <>
        {currentTab === 1 && (
          <AdminReportingAnnual year={year} userProduction={userProduction} place={place ?? 1} />
        )}
      </>
      <>
        {currentTab === 2 && (
          <AdminReportingByType
            currentProject={selectedProject}
            isBillable={isBillable}
            year={year}
            month={month}
            place={place ?? 1}
            handleProjectClick={handleProjectClick}
            projectType={currentProjectType}
          />
        )}
      </>
      <>
        {currentTab === 3 && (
          <AdminReportingByContract
            month={month}
            year={year}
            selectedContract={currentContract}
            selectedJob={currentJob}
            selectedUser={currentUser?.id ?? 0}
            place={place ?? 1}
            goToReporting={goToReporting}
          />
        )}
      </>
      <>
        {currentTab === 4 && (
          <AdminReportingByProject
            month={month}
            year={year}
            selectedProject={selectedProject}
            place={place ?? 1}
            goToReporting={goToReporting}
          />
        )}
      </>
      <>{currentTab === 5 && <ImportCosts month={month} year={year} place={place ?? 1} />}</>
      <>{currentTab === 6 && <ListClients />}</>
    </>
  )
}

export default AdminReporting
