import {
  IInterview,
  IInterviewAnswer,
  IInterviewAnswerUpdateDTO,
  IInterviewQuestion
} from '../../interfaces/career'
import {
  getInterviewAnswers,
  getInterviewQuestions,
  updateInterviewAnswers
} from 'services/CareerService'
import ReviewReaderPopUp from 'components/popup/interviewPopup/ReviewReaderPopUp'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import ReviewPopUp from '../../components/popup/interviewPopup/ReviewPopUp'
import { hasRHRole, hasSuperAdminRole } from '../../utils/otherUtils'
import ProfilImage from '../../components/profilImage/ProfilImage'
import { formatDateToDay } from '../../utils/dateUtils'
import { Edit, Visibility } from '@mui/icons-material'
import { CurrentUserContext } from '../../App'
import { toast } from 'react-toastify'

interface IMyReviewsProps {
  interviews: IInterview[]
  onAnswersUpdate?: (answers: IInterviewAnswer[]) => void
  initialInterviewId?: number
  shouldOpenPopup?: boolean
  onPopupOpen?: () => void
}

const MyReviews = ({
  interviews,
  onAnswersUpdate,
  initialInterviewId,
  shouldOpenPopup,
  onPopupOpen
}: IMyReviewsProps): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [openReader, setOpenReader] = useState<boolean>(false)
  const [reviewToEdit, setReviewToEdit] = useState<IInterview | undefined>()
  const [answers, setAnswers] = useState<IInterviewAnswer[]>([])
  const [questions, setQuestions] = useState<IInterviewQuestion[]>([])

  // Gère l'ouverture automatique du popup
  useEffect(() => {
    if (shouldOpenPopup && initialInterviewId && interviews.length > 0) {
      const review = interviews.find((r) => Number(r.id) === initialInterviewId)
      if (review && currentUser && canEditInterview(review, currentUser.id)) {
        handleReviewClick(review, false)
        onPopupOpen?.()
      }
    }
  }, [shouldOpenPopup, initialInterviewId, interviews, currentUser])

  /**
   * Permet de savoir si l'utilisateur est admin ou manager de cet entretien
   * Auquel cas il peut voir toutes les réponses
   *
   * @returns boolean
   */
  const isUserAdmin = (): boolean => {
    if (currentUser?.rolePermissions === undefined) {
      return false
    }

    const isSuperAdmin = hasSuperAdminRole(currentUser.rolePermissions)
    const isAdminRH = hasRHRole(currentUser.rolePermissions)
    const isManager =
      reviewToEdit?.managers?.some((manager) => manager.id === currentUser?.id) ?? false
    return isSuperAdmin || isAdminRH || isManager
  }

  /**
   * Fonction qui retourne si l'utilisateur peut éditer un entretien,
   * il doit être évaluateur
   *
   * @param review  L'entretien
   * @param userId L'id de l'utilisateur
   *
   * @returns boolean
   */
  const canEditInterview = (review: IInterview, userId: number): boolean => {
    const isReviewer = review.reviewers?.some((reviewer) => reviewer.id === userId) ?? false
    return isReviewer
  }

  /**
   * Gère les clics sur les boutons pour voir et éditer un entretien
   *
   * @param review L'entretien à voir ou éditer
   * @param readOnly true si l'utilisateur veut seulement voir l'entretien, false si il veut l'éditer
   */
  const handleReviewClick = (review: IInterview, readOnly: boolean): void => {
    setReviewToEdit(review)
    setQuestions([])
    setAnswers([])

    const isReview = review.type?.name !== "Rapport d'étonnement" ? 1 : 0

    void getInterviewQuestions(isReview, currentUser?.id ?? 0).then((questions) => {
      setQuestions(questions)
    })
    void getInterviewAnswers(Number(review.id), currentUser?.id ?? 0)
      .then((answers) => {
        setAnswers(answers)
      })
      .finally(() => {
        review.hasAnswers = true
        if (readOnly) {
          setOpenReader(true)
        } else {
          setOpenPopup(true)
        }
      })
  }

  /**
   * Permet de sauvegarder les réponses d'un entretien
   *
   * @param answers Les réponses à enregistrer
   */
  const handleSaveAnswers = (answers: IInterviewAnswer[]): void => {
    const dto: IInterviewAnswerUpdateDTO[] = answers.map((answer) => ({
      id: answer.id,
      questionId: answer.question.id ?? 0,
      interviewId: Number(answer.interview?.id) ?? 0,
      answer: answer.answer,
      user: answer.user
    }))

    void (async () => {
      try {
        const updatedAnswers = await updateInterviewAnswers(dto, currentUser?.id ?? 0)
        onAnswersUpdate?.(updatedAnswers)
        toast.success('Retour enregistré')
        setAnswers([])
      } catch (error) {
        toast.error("Erreur lors de l'enregistrement")
      }
    })()
  }

  return (
    <>
      {interviews.length > 0 && (
        <div className="interview-reviews">
          {interviews.map((review, index) => (
            <div key={index} className="review">
              <div className="review-content">
                <div className="flex gap-2 mt-2">
                  <ProfilImage user={review.user} />
                  <div className="bold">{`${review.user?.displayFirstname ?? ''} ${
                    review.user?.displayLastname ?? ''
                  }`}</div>
                </div>
                <div className="review-comment">
                  <span>
                    <p>
                      {`En attente d'un retour avant le ${formatDateToDay(
                        review?.date?.toString() ?? ''
                      )}`}
                    </p>
                    <Visibility
                      fontSize="small"
                      color="info"
                      className="cursor-pointer"
                      onClick={() => {
                        handleReviewClick(review, true)
                      }}
                    />
                    {currentUser && canEditInterview(review, currentUser.id) && (
                      <Edit
                        fontSize="small"
                        color="disabled"
                        className="cursor-pointer"
                        onClick={() => {
                          handleReviewClick(review, false)
                        }}
                      />
                    )}
                  </span>
                </div>
                {review.date !== undefined && (
                  <div className="review-date">{formatDateToDay(review.date.toString())}</div>
                )}
              </div>
              <hr />
            </div>
          ))}
        </div>
      )}
      <ReviewPopUp
        open={openPopup}
        close={() => {
          setOpenPopup(false)
          setReviewToEdit(undefined)
          setAnswers([])
        }}
        interviewToEdit={reviewToEdit}
        questions={questions}
        answers={answers.filter((answer) => answer.user?.id === currentUser?.id)}
        saveAnswers={handleSaveAnswers}
      />
      <ReviewReaderPopUp
        open={openReader}
        close={() => {
          setOpenReader(false)
        }}
        questions={questions}
        answers={answers}
        isAdmin={isUserAdmin()}
      />
    </>
  )
}

export default MyReviews
