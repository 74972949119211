import { IClient } from 'interfaces/clients'
import { customAxios } from './axiosUtils'

export const saveClientPicture = async (clientId: number, picture: string): Promise<IClient[]> => {
  try {
    const client = await customAxios.post(`clients/${clientId}/profile-pictures`, { picture })
    return client as unknown as IClient[]
  } catch (error) {
    return []
  }
}
