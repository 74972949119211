import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from '../utils/otherUtils'
import { GOOGLE_TOKEN_EXPIRATION, ONE_DAY_DURATION_IN_MINUTES } from '../constantes'
import { connexionGoogle, refreshToken } from '../services/GoogleConnexionService'
import { getSelfUserData, getUserByAutoLoginToken } from '../services/UserService'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { IMinimalCollaborateurData } from '../interfaces/collaborateurData'
import { getBirthdaysOfTheDay } from '../services/BirthdayService'
import { getNewArrival } from '../services/NewArrivalService'
import { getMessages } from '../services/MessageService'
import { IMessage } from '../interfaces/message'
import { useEffect, useState } from 'react'

export const useGoogleAuth = ({
  setCurrentUser,
  setBirthdayPopUp,
  setNewArrivalPopUp,
  setMessages
}: {
  setCurrentUser: (user: IMinimalCollaborateurData | null) => void
  setBirthdayPopUp: (birthdayPopUp: IMinimalCollaborateurData[] | null) => void
  setNewArrivalPopUp: (birthdayPopUp: IMinimalCollaborateurData[] | null) => void
  setMessages: (messages: IMessage[] | null) => void
}): boolean => {
  const [isCheckEnded, setIsCheckEnded] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const refreshTokenIfNeeded = async (): Promise<void> => {
      const user = getLocalStorageWithExpiration('user')
      if (user?.refresh_token) {
        const response = await refreshToken(user.refresh_token)
        if (response) {
          // Met à jour le token d'accès dans le cookie de session
          setLocalStorageWithExpiration(
            'user',
            JSON.stringify({
              ...user,
              access_token: response.access_token
            }),
            ONE_DAY_DURATION_IN_MINUTES
          )
          setCurrentUser(user)
        } else {
          // Si le token de rafraichissement n'est plus valide, déconnecte l'utilisateur
          window.open(`${process.env.REACT_APP_PORTAIL_URL ?? ''}deconnexion`, '_self')
        }
      }
    }

    // Met en place un intervalle pour rafraichir le token d'accès
    const refreshInterval = setInterval(() => {
      void refreshTokenIfNeeded()
    }, GOOGLE_TOKEN_EXPIRATION)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [])

  useEffect(() => {
    const logGoogleUser = async (): Promise<void> => {
      const credential = searchParams.get('credential')
      const autologinToken = searchParams.get('autologin')

      if (credential !== null || autologinToken !== null) {
        let response = null
        if (credential !== null) {
          response = await connexionGoogle(credential)
        }
        if (autologinToken !== null) {
          response = await getUserByAutoLoginToken(autologinToken)
          response = await getSelfUserData(2024)
        }
        // Vérifie s'il y a eu une erreur lors de l'appel de l'api
        if (response === null) {
          window.open(`${process.env.REACT_APP_PORTAIL_URL ?? ''}deconnexion`, '_self')
          return
        }

        const user = response
        // Conserve le token et l'user dans un cookie de session
        if (user !== undefined) {
          setLocalStorageWithExpiration('user', JSON.stringify(user), ONE_DAY_DURATION_IN_MINUTES)
          setCurrentUser(user)
        }
        const birthdayResponse = await getBirthdaysOfTheDay()
        if (Object.values(birthdayResponse).length !== 0) {
          setBirthdayPopUp(birthdayResponse)
        }
        const newArrivalResponse = await getNewArrival()
        if (Object.values(newArrivalResponse).length !== 0) {
          setNewArrivalPopUp(newArrivalResponse)
        }

        const messages = await getMessages(true, user?.place?.id ?? 1)
        setMessages(messages)

        const currentPath = location.pathname
        const isIndexPage = currentPath === '/'

        if (isIndexPage) {
          navigate('/planning')
        } else {
          searchParams.delete('credential')
          searchParams.delete('autologin')
          setSearchParams(searchParams)
        }
      }
      setIsCheckEnded(true)
    }

    void logGoogleUser()
  }, [])

  return isCheckEnded
}
