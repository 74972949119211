import { createMessage, updateMessage } from '../../../services/MessageService'
import { Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material'
import CustomDatePicker from '../../inputs/customDatePicker/CustomDatePicker'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import PrimaryButton from '../../inputs/button/PrimaryButton'
import { IMessage } from '../../../interfaces/message'
import { PlaceSelectedContext } from '../../../App'
import { Editor } from 'react-draft-wysiwyg'
import { Close } from '@mui/icons-material'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

interface IMessagePopUp {
  open: boolean
  setOpen: (open: boolean) => void
  messageToEdit: IMessage | undefined
  setMessages: (messages: IMessage[]) => void
}

const MessagePopUp = ({
  open,
  setOpen,
  messageToEdit,
  setMessages
}: IMessagePopUp): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const [loading, setLoading] = useState<boolean>(false)
  const [state, setState] = useState<EditorState | undefined>()
  const [errors, setErrors] = useState<string[]>([])
  const [message, setMessage] = useState<IMessage>({
    text: '',
    startDate: new Date(),
    endDate: new Date(),
    placeId: place ?? 1
  })

  useEffect(() => {
    setMessage(
      messageToEdit ?? {
        text: '',
        startDate: new Date(),
        endDate: new Date(),
        placeId: place ?? 1
      }
    )
    setState(
      messageToEdit !== undefined
        ? EditorState.createWithContent(
            // @ts-expect-error
            ContentState.createFromBlockArray(htmlToDraft(messageToEdit.text))
          )
        : EditorState.createEmpty()
    )
  }, [messageToEdit])

  const handleSave = async (): Promise<void> => {
    const errors = []
    if (message.startDate > message.endDate) {
      errors.push('La date de début doit être inférieure à la date de fin.')
    }
    setErrors(errors)

    if (errors.length > 0) {
      return
    }

    const text = state !== undefined ? draftToHtml(convertToRaw(state.getCurrentContent())) : ''
    let messages
    if (message.id !== undefined) {
      messages = await updateMessage({ ...message, text })
    } else {
      messages = await createMessage({ ...message, text, placeId: place ?? 1 })
    }
    setMessages(messages)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={(): void => {
        setOpen(false)
      }}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="popup interview-popup"
    >
      <IconButton
        onClick={() => {
          setOpen(false)
        }}
        id="closeIcon"
      >
        <Close />
      </IconButton>
      {message.id !== undefined ? (
        <DialogTitle id="save-dialog-title">Modification d&apos;un message</DialogTitle>
      ) : (
        <DialogTitle id="save-dialog-title">Ajout d&apos;un message</DialogTitle>
      )}
      <DialogActions>
        <div>
          <div className="d-flex mb-5">
            <CustomDatePicker
              handleChangeDate={(e) => {
                e !== null && setMessage({ ...message, startDate: e.toDate() })
              }}
              id="startDate"
              value={message.startDate}
              required
              label="Date de début"
              includeTime={true}
            />
            <div className="ml-5">
              <CustomDatePicker
                handleChangeDate={(e) => {
                  e !== null && setMessage({ ...message, endDate: e.toDate() })
                }}
                id="endDate"
                value={message.endDate}
                required
                label="Date de fin"
                includeTime={true}
              />
            </div>
          </div>
          <div className="mb-5">
            <Editor
              editorState={state}
              onEditorStateChange={setState}
              toolbar={{
                inline: { inDropdown: true },
                blockType: { inDropdown: true },
                fontSize: { inDropdown: true },
                options: ['inline', 'blockType', 'fontSize', 'colorPicker', 'emoji']
              }}
            />
          </div>

          {errors.length > 0 &&
            errors.map((error: string, index: number) => (
              <p key={index} className="form-error">
                {error}
              </p>
            ))}

          <div className="buttons-quotas mt-10">
            <PrimaryButton
              handleClick={(): void => {
                setOpen(false)
              }}
              title="Annuler"
              background
              reverse
              disabled={loading}
            />
            <PrimaryButton
              handleClick={() => {
                setLoading(true)
                void (async () => {
                  await handleSave()
                  setLoading(false)
                })()
              }}
              title="Enregistrer"
              background
              reverse={false}
              disabled={loading}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default MessagePopUp
