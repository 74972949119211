import {
  deleteInterview,
  getInterviewAnswers,
  getInterviewQuestions
} from '../../../services/CareerService'
import {
  IDetailCollaborateurData,
  IMinimalCollaborateurData
} from '../../../interfaces/collaborateurData'
import ConfirmationPopUp from '../../../components/inputs/confirmationPopUp/ConfirmationPopUp'
import { IInterview, IInterviewAnswer, IInterviewQuestion } from '../../../interfaces/career'
import InterviewPopUp from '../../../components/popup/interviewPopup/InterviewPopUp'
import ReviewReaderPopUp from 'components/popup/interviewPopup/ReviewReaderPopUp'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../components/inputs/button/PrimaryButton'
import { hasRHRole, hasSuperAdminRole } from '../../../utils/otherUtils'
import { Delete, Edit, Visibility } from '@mui/icons-material'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import { formatDateToDay } from '../../../utils/dateUtils'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineDot from '@mui/lab/TimelineDot'
import Timeline from '@mui/lab/Timeline'
import { CurrentUserContext } from 'App'
import { Rating } from '@mui/material'
import './TimelineInterview.scss'

interface ITimeline {
  user: IDetailCollaborateurData | IMinimalCollaborateurData
  isAdmin: boolean
  interviews: IInterview[]
  onInterviewsChange?: (interviews: IInterview[]) => void
}

const TimelineInterview = ({
  user,
  isAdmin,
  interviews: initialInterviews,
  onInterviewsChange
}: ITimeline): ReactElement => {
  const [interviews, setInterviews] = useState<IInterview[]>(initialInterviews)
  const [interviewToEdit, setInterviewToEdit] = useState<IInterview | undefined>()
  const [openInterview, setOpenInterview] = useState<boolean>(false)
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false)
  const [answers, setAnswers] = useState<IInterviewAnswer[]>([])
  const [questions, setQuestions] = useState<IInterviewQuestion[]>([])
  const [openReader, setOpenReader] = useState<boolean>(false)
  const userContext = useContext(CurrentUserContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedReviewer, setSelectedReviewer] = useState<IMinimalCollaborateurData | null>(null)
  const currentUser = userContext?.currentUser

  useEffect(() => {
    setInterviews(initialInterviews)
  }, [initialInterviews])

  const addUserEventToTimeline = (
    tmpInterviews: IInterview[],
    title: string,
    date?: string | null
  ): IInterview[] => {
    if (date !== undefined && date !== null) {
      tmpInterviews.push({
        date: new Date(date),
        type: {
          name: title
        }
      })
    }

    return tmpInterviews
  }

  /**
   * Fonction qui retourne les entretiens de l'utilisateur avec les événements de timeline
   *
   * @param baseInterviews Les entretiens de l'utilisateur en BDD
   * @returns
   */
  const getProcessedInterviews = (baseInterviews: IInterview[]): IInterview[] => {
    let tmpInterviews = [...baseInterviews]

    // Ajoute les événements de timeline correspondant aux données de l'utilisateur
    tmpInterviews = addUserEventToTimeline(
      tmpInterviews,
      user.internshipStartDate ? 'Début de stage' : 'Début de CDI',
      user.arrivalDate
    )

    tmpInterviews = user.internshipStartDate
      ? addUserEventToTimeline(tmpInterviews, 'Fin de stage', user.endInternshipDate)
      : tmpInterviews

    tmpInterviews = user.internshipStartDate
      ? addUserEventToTimeline(tmpInterviews, 'Début de CDI', user.permanentContractStartDate)
      : tmpInterviews

    tmpInterviews = addUserEventToTimeline(tmpInterviews, 'Départ', user.leavingDate)
    tmpInterviews = addUserEventToTimeline(
      tmpInterviews,
      "Fin de période d'essai",
      user.endTrialPeriodDate
    )

    return tmpInterviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  }

  const processedInterviews = getProcessedInterviews(interviews)

  /**
   * Permet de savoir si l'utilisateur est admin ou manager de cet entretien
   * Auquel cas il peut voir toutes les réponses
   *
   * @returns boolean
   */
  const isUserAdmin = (): boolean => {
    if (currentUser?.rolePermissions === undefined) {
      return false
    }

    const isSuperAdmin = hasSuperAdminRole(currentUser.rolePermissions)
    const isAdminRH = hasRHRole(currentUser.rolePermissions)
    const isManager =
      interviewToEdit?.managers?.some((manager) => manager.id === currentUser?.id) ?? false
    return isSuperAdmin || isAdminRH || isManager
  }

  /**
   * Fonction qui retourne si l'utilisateur peut éditer un entretien,
   * en fonction de son rôle et de l'entretien
   *
   * @param review L'interview
   * @param userId L'utilisateur
   * @returns
   */
  const canEditInterview = (review: IInterview, userId: number): boolean => {
    if (currentUser?.rolePermissions === undefined) {
      return false
    }

    const isManager = review.managers?.some((manager) => manager.id === userId) ?? false
    const isSuperAdmin = hasSuperAdminRole(currentUser?.rolePermissions)
    return isManager || isSuperAdmin
  }

  /**
   * Gère la mise à jour des entretiens
   *
   * @param updatedInterviews
   */
  const handleInterviewsUpdate = (updatedInterviews: IInterview[]): void => {
    setInterviews(updatedInterviews)
    onInterviewsChange?.(updatedInterviews)
  }

  /**
   * Gère la suppression d'un entretien
   */
  const handleDelete = async (): Promise<void> => {
    if (interviewToEdit?.id !== undefined) {
      const updatedInterviews = await deleteInterview(user.id, interviewToEdit.id)
      handleInterviewsUpdate(updatedInterviews)
      setInterviewToEdit(undefined)
      setOpenDeletePopup(false)
    }
  }

  /**
   * Gère le clic sur un reviewer
   *
   * @param reviewer
   * @param review
   */
  const handleReviewerClick = (reviewer: IMinimalCollaborateurData, review: IInterview): void => {
    setSelectedReviewer(reviewer)
    const isReview = review.type?.name !== "Rapport d'étonnement" ? 1 : 0

    void getInterviewQuestions(isReview, currentUser?.id ?? 0).then((questions) => {
      setQuestions(questions)
    })
    void getInterviewAnswers(Number(review.id), currentUser?.id ?? 0)
      .then((answers) => {
        const reviewerAnswers = answers.filter((answer) => answer.user.id === reviewer.id)
        setAnswers(reviewerAnswers)
      })
      .finally(() => {
        setOpenReader(true)
      })
  }

  return (
    <>
      {isAdmin && (
        <div className="button-interview">
          <PrimaryButton
            handleClick={(): void => {
              setInterviewToEdit(undefined)
              setOpenInterview(true)
            }}
            title="Ajouter un entretien"
            background
            reverse={false}
            disabled={false}
          />
        </div>
      )}
      <div className="timeline">
        <Timeline position="alternate">
          {processedInterviews.map((interview: IInterview, index: number) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="timeline-content">
                <div className="interview-details">
                  <div className="date">{formatDateToDay(interview.date.toString())}</div>
                  <div>
                    {interview.type?.name}
                    {interview.managers && interview.managers.length > 0 && (
                      <>
                        {' par '}
                        {interview.managers.map((manager, index) => (
                          <span key={manager.id}>
                            {index > 0 && ', '}
                            {manager.displayFirstname ?? ''} {manager.displayLastname?.[0] ?? ''}
                          </span>
                        ))}
                      </>
                    )}
                  </div>
                  {interview.score !== undefined && (
                    <Rating name="read-only" value={interview.score} precision={0.5} readOnly />
                  )}
                  {interview.income !== undefined && (
                    <span className="income-badge">{`${interview.income.type} : ${interview.income.amount}€`}</span>
                  )}
                  {isAdmin && interview.reviewers && interview.reviewers.length > 0 && (
                    <div className="reviewers">
                      <div className="reviewers-list">
                        {interview.reviewers.map((reviewer) => (
                          <div
                            key={reviewer.id}
                            className={`reviewer-item ${
                              interview.respondedUserIds?.includes(reviewer.id)
                                ? 'has-answered'
                                : ''
                            }`}
                            onClick={() => {
                              handleReviewerClick(reviewer, interview)
                            }}
                          >
                            <span className="reviewer-name">
                              {reviewer.displayFirstname} {reviewer.displayLastname}
                            </span>
                            <Visibility fontSize="small" className="reviewer-action" />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {isAdmin && interview.type?.id !== undefined && (
                    <div className="actions">
                      {currentUser && canEditInterview(interview, currentUser.id) && (
                        <>
                          <Edit
                            fontSize="small"
                            color="disabled"
                            onClick={() => {
                              setInterviewToEdit(interview)
                              setOpenInterview(true)
                            }}
                          />
                          <Delete
                            fontSize="small"
                            color="error"
                            onClick={() => {
                              setInterviewToEdit(interview)
                              setOpenDeletePopup(true)
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
      <InterviewPopUp
        user={user}
        open={openInterview}
        setOpen={setOpenInterview}
        interviewToEdit={interviewToEdit}
        onInterviewsChange={handleInterviewsUpdate}
        isEdit={interviewToEdit !== undefined}
      />
      <ConfirmationPopUp
        dialogTitle="Voulez-vous supprimer cet entretien ?"
        dialogContentText="Cet entretien sera définitivement annulé."
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        handleSave={handleDelete}
        loading={false}
      />
      <ReviewReaderPopUp
        open={openReader}
        close={() => {
          setOpenReader(false)
          setSelectedReviewer(null)
        }}
        questions={questions}
        answers={answers}
        isAdmin={isUserAdmin()}
      />
    </>
  )
}

export default TimelineInterview
