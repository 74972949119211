import {
  getLocalStorageWithExpiration,
  hasOnlyUserRole,
  hasRole,
  isAllowedForSteamDej
} from 'utils/otherUtils'
import React, { ReactElement, useContext } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import PageLayout from './pageLayout/PageLayout'

import Error404Page from '../pages/error404Page/Error404Page'
import { IFeature } from '../interfaces/setting'
import { FeaturesContext } from '../App'
import { toast } from 'react-toastify'

interface IProtectedRoute {
  element: ReactElement
  needRole: string[]
  feature?: string
  onlySteamDej?: boolean
  fullWidth?: boolean
}

const ProtectedRoute = ({
  element,
  needRole = [],
  feature,
  onlySteamDej = false,
  fullWidth
}: IProtectedRoute): ReactElement => {
  const user = getLocalStorageWithExpiration('user')
  const isAuthorized =
    user !== null && needRole.length > 0
      ? hasRole(JSON.parse(user).rolePermissions, needRole)
      : false
  const { userId } = useParams()
  const steamDejAllowed = user !== null ? isAllowedForSteamDej(JSON.parse(user)) : false
  const featuresContext = useContext(FeaturesContext)

  // Vérifie si utilisateur toujours connecté avec le bon token (si pas de user = pas de token associé) + si possède le bon role et s'il ne modifie pas l'id du profil dans l'url
  if (user === null) {
    window.open(
      `${process.env.REACT_APP_PORTAIL_URL ?? ''}?callback=${window.location.href.replace(
        '&',
        '%26'
      )}`,
      '_self'
    )
    return <></>
  }
  if (
    feature !== undefined &&
    featuresContext?.features !== null &&
    featuresContext?.features.find((item: IFeature) => item.name === feature) === undefined
  ) {
    return <Error404Page></Error404Page>
  }
  if (
    (needRole.length > 0 && !isAuthorized) ||
    // eslint-disable-next-line
    (onlySteamDej && !steamDejAllowed) ||
    (userId !== undefined &&
      hasOnlyUserRole(JSON.parse(user).rolePermissions) &&
      Number(userId) !== (typeof user === 'string' ? JSON.parse(user).id : null))
  ) {
    toast.error('Vous avez été déconnecté car accès interdit')
    return <Navigate to="/deconnexion" replace />
  }
  const currentPath = location.pathname
  const currentUser = JSON.parse(user)

  if (currentUser.needOnBoarding === true && currentPath !== '/onboarding') {
    return <Navigate to="/onboarding" replace />
  }

  return (
    <PageLayout fullWidth={fullWidth} hideMenu={currentUser.needOnBoarding === true}>
      {element}
    </PageLayout>
  )
}

export default ProtectedRoute
