import { isHolidayOrWeekendDay, isToday } from 'utils/dateUtils'
import React, { ReactElement, useEffect, useState } from 'react'
import { IReporting, IWorkEntry } from 'interfaces/reporting'
import moment from 'moment'

export interface AdminReportingPersonalTableProps {
  listDates: string[]
  holidayDates: string[]
  reporting: IReporting[]
  month: number
}

const AdminReportingPersonalTable = ({
  listDates,
  holidayDates,
  reporting,
  month
}: AdminReportingPersonalTableProps): ReactElement => {
  const [userGroupedReportings, setUserGroupedReportings] = useState<Record<number, IReporting[]>>(
    []
  )
  const findAmountWorked = (workEntries: IWorkEntry[], date: string): number | string => {
    const entry = workEntries.find((entry) => moment(entry.date).format('YYYY-MM-DD') === date)
    return entry?.amountWorked ? entry.amountWorked / 100 : '-'
  }

  const calculateTotalAmountWorked = (workEntries: IWorkEntry[]): number => {
    return (
      workEntries.reduce((total, entry) => {
        const entryDate = moment(entry.date)
        if (entryDate.month() + 1 === month) {
          return total + entry.amountWorked
        }
        return total
      }, 0) / 100
    )
  }

  const calculateUserTotal = (userReports: IReporting[], isBillable: boolean): number => {
    return userReports
      .filter((report) => report.isBillable === isBillable)
      .reduce((total, report) => {
        return total + calculateTotalAmountWorked(report.workEntries)
      }, 0)
  }

  const groupByUser = (reporting: IReporting[]): Record<number, IReporting[]> => {
    return reporting.reduce<Record<number, IReporting[]>>((acc, report) => {
      const userId = report.user?.id ?? 0
      if (!acc[userId]) {
        acc[userId] = []
      }
      acc[userId].push(report)
      return acc
    }, {})
  }

  useEffect(() => {
    setUserGroupedReportings(groupByUser(reporting))
  }, [reporting])

  return (
    <>
      {Object.entries(userGroupedReportings).map(([userId, userReports]) => (
        <div key={userId} className="mt-1">
          <>
            <span className="small-accentuated-title mt-1">
              {'Détail Reporting : ' +
                String(userReports[0]?.user?.displayLastname ?? '') +
                ' ' +
                String(userReports[0]?.user?.displayFirstname ?? '')}
            </span>

            <div className="bg-white rounded-xl overflow-hidden p-1 mt-2">
              <table className="w-full shadow-md text-center text-sm">
                <thead>
                  <tr className="border-b-2 border-gray-300">
                    <th className="bold text-left pl-1">Description</th>
                    <th className="bold">Type</th>
                    {listDates.map((day, index) => (
                      <th
                        key={index}
                        className={`bold text-sm ${
                          isHolidayOrWeekendDay(day, holidayDates) ? 'light-greyText' : ''
                        } ${isToday(day) ? 'today-date' : ''}`}
                      >
                        <div className="flex flex-col day">
                          <div>{parseInt(moment(day).format('DD'), 10)}</div>
                        </div>
                      </th>
                    ))}
                    <th className="bold">Mois</th>
                  </tr>
                </thead>
                <tbody>
                  {userReports.filter((report) => report.isBillable).length > 0 && (
                    <>
                      <tr className="bg-orange-100 border-b-2 border-gray-300 bold">
                        <td colSpan={36}>ACTIVITES FACTUREES</td>
                      </tr>
                      {userReports
                        .filter((report) => report.isBillable)
                        .map((report) => (
                          <tr key={report.id} className="border-b-2 border-gray-400">
                            <td className="text-left pl-1">{report.description}</td>
                            <td>{report.project.type?.name}</td>
                            {listDates.map((day, index) => (
                              <td
                                key={index}
                                className={`bold w-[27px] text-xs ${
                                  isHolidayOrWeekendDay(day, holidayDates) ? 'bg-gray-100' : ''
                                }`}
                              >
                                {findAmountWorked(report.workEntries, day)}
                              </td>
                            ))}
                            <td className="bold bg-gray-300 border-r-2 border-gray-100">
                              {calculateTotalAmountWorked(report.workEntries)}
                            </td>
                          </tr>
                        ))}
                      <tr className="bg-gray-200 bold border-b-2 border-gray-300">
                        <td className="text-left pl-1">Sous-total</td>
                        <td colSpan={1 + listDates.length}></td>
                        <td className="bg-violet-200 border-r-2 border-gray-100">
                          {calculateUserTotal(userReports, true)}
                        </td>
                      </tr>
                    </>
                  )}
                  {userReports.filter((report) => !report.isBillable).length > 0 && (
                    <>
                      <tr className="bg-violet-200 border-b-2 border-gray-300 bold">
                        <td colSpan={36}>ACTIVITES NON FACTUREES</td>
                      </tr>
                      {userReports
                        .filter((report) => !report.isBillable)
                        .map((report) => (
                          <tr key={report.id} className="border-b-2 border-gray-400">
                            <td className="text-left pl-1">{report.description}</td>
                            <td>{report.project.type?.name}</td>
                            {listDates.map((day, index) => (
                              <td
                                key={index}
                                className={`bold w-[27px] text-xs ${
                                  isHolidayOrWeekendDay(day, holidayDates) ? 'bg-gray-100' : ''
                                }`}
                              >
                                {findAmountWorked(report.workEntries, day)}
                              </td>
                            ))}
                            <td className="bold bg-gray-300 border-r-2 border-gray-100">
                              {calculateTotalAmountWorked(report.workEntries)}
                            </td>
                          </tr>
                        ))}
                      <tr className="bg-gray-200 bold border-b-2 border-gray-300">
                        <td className="text-left pl-1">Sous-total</td>
                        <td colSpan={1 + listDates.length}></td>
                        <td className="bg-violet-200 border-r-2 border-gray-100">
                          {calculateUserTotal(userReports, false)}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr className="bold border-b-2 border-gray-300">
                    <td className="text-left pl-1">TOTAL</td>
                    <td colSpan={1 + listDates.length}></td>
                    <td className=" border-r-2 border-gray-100">
                      {calculateUserTotal(userReports, true) +
                        calculateUserTotal(userReports, false)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </div>
      ))}
    </>
  )
}

export default AdminReportingPersonalTable
