import { Dialog, DialogContent, DialogContentText, IconButton } from '@mui/material'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { BirthdayPopUpContext, NewArrivalPopUpContext } from 'App'
import ProfilImage from 'components/profilImage/ProfilImage'
import React, { ReactElement, useContext } from 'react'
import { Close } from '@mui/icons-material'

const NewPopUp = (): ReactElement => {
  const newArrivalPopUpContext = useContext(NewArrivalPopUpContext)
  const birthdayPopUpContext = useContext(BirthdayPopUpContext)

  return (
    <Dialog
      open={true}
      onClose={(): void => {
        newArrivalPopUpContext?.setNewArrivalPopUp(null)
        birthdayPopUpContext?.setBirthdayPopUp(null)
      }}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="popup"
    >
      <IconButton
        onClick={() => {
          newArrivalPopUpContext?.setNewArrivalPopUp(null)
          birthdayPopUpContext?.setBirthdayPopUp(null)
        }}
        id="closeIcon"
      >
        <Close />
      </IconButton>
      {newArrivalPopUpContext?.newArrivalPopUp !== undefined &&
        newArrivalPopUpContext?.newArrivalPopUp !== null && (
          <>
            {newArrivalPopUpContext.newArrivalPopUp.length > 1 ? (
              <span className="accentuated-title">Nouvelles arrivées</span>
            ) : (
              <span className="accentuated-title">Nouvelle arrivée</span>
            )}
            <div className="popup-content">
              {newArrivalPopUpContext?.newArrivalPopUp?.map(
                (newArrival: IMinimalCollaborateurData) => (
                  <DialogContent key={newArrival.id}>
                    {newArrival.profilePictureFilename != null ? (
                      <ProfilImage user={newArrival} />
                    ) : (
                      <></>
                    )}
                    <DialogContentText id="firstname-dialog">
                      {newArrival.displayFirstname}
                    </DialogContentText>
                    <DialogContentText id="lastname-dialog">
                      {newArrival.displayLastname}
                    </DialogContentText>
                  </DialogContent>
                )
              )}
            </div>
          </>
        )}
      {birthdayPopUpContext?.birthdayPopUp !== null && (
        <>
          <span className="accentuated-title">Joyeux Anniversaire</span>
          <div className="popup-content">
            {birthdayPopUpContext?.birthdayPopUp?.map((birthday: IMinimalCollaborateurData) => (
              <DialogContent key={birthday.id}>
                {birthday.profilePictureFilename != null ? <ProfilImage user={birthday} /> : <></>}
                <DialogContentText id="lastname-dialog">
                  {birthday.displayFirstname}
                </DialogContentText>
                <DialogContentText id="lastname-dialog">
                  {birthday.displayLastname}
                </DialogContentText>
              </DialogContent>
            ))}
          </div>
        </>
      )}
    </Dialog>
  )
}

export default NewPopUp
