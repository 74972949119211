import {
  calculateTotalForDay,
  calculateTotalForMonth,
  getHolidayDatesWithoutPentecote,
  isHolidayOrWeekendDay,
  isToday
} from 'utils/dateUtils'
import React, { ReactElement, useMemo, useState } from 'react'

import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { IReporting, IReportingRequest } from 'interfaces/reporting'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import ReportingTableContent from './ReportingTableContent'
import { addReporting } from 'services/ReportingService'
import useScreenSize from 'customHooks/useScreenSize'
import { daysList, screenSizes } from 'constantes'
import { toast } from 'react-toastify'
import Holidays from 'date-holidays'
import moment from 'moment'

export interface ReportingTableProps {
  listDates: string[]
  currentUser: IMinimalCollaborateurData | null
  year: number
  month: number
  setMonth: React.Dispatch<React.SetStateAction<number>>
  reporting: IReporting[]
  modifiedReportings: IReporting[]
  setModifiedReportings: React.Dispatch<React.SetStateAction<IReporting[]>>
  handleDelete: (reportingId: number) => void
  setReporting: React.Dispatch<React.SetStateAction<IReporting[]>>
  isSmallScreen: boolean
  isLocked: boolean
}

const ReportingTable = ({
  currentUser,
  year,
  month,
  setMonth,
  listDates,
  reporting,
  setModifiedReportings,
  modifiedReportings,
  handleDelete,
  setReporting,
  isSmallScreen,
  isLocked
}: ReportingTableProps): ReactElement => {
  const hd = new Holidays('FR')
  const holidayDates = useMemo(() => {
    return getHolidayDatesWithoutPentecote(hd, year)
  }, [year])
  const isMediumScreen = !useScreenSize(screenSizes.DESKTOP_SIZE)
  const [hoveredDay, setHoveredDay] = useState<string | null>(null)

  const initialWeek = useMemo(() => {
    const firstDayOfMonth = moment(`${year}-${month}`, 'YYYY-MM').startOf('month')
    const startOfWeek = firstDayOfMonth.clone().startOf('isoWeek')
    const endOfWeek = firstDayOfMonth.clone().endOf('isoWeek')
    const days = []

    let day = startOfWeek
    while (day <= endOfWeek) {
      days.push(day.format('YYYY-MM-DD'))
      day = day.clone().add(1, 'day')
    }

    return days
  }, [year, month])
  const [currentWeek, setCurrentWeek] = useState<string[]>(initialWeek)

  // Navigation en version mobile
  const navigateToPreviousWeek = (): void => {
    const previousWeek = currentWeek.map((day) =>
      moment(day).subtract(7, 'days').format('YYYY-MM-DD')
    )
    const lastDayOfWeek = moment(previousWeek[previousWeek.length - 1])
    const newMonth = lastDayOfWeek.month() + 1
    setCurrentWeek(previousWeek)
    setMonth(newMonth)
  }

  // Navigation en version mobile
  const navigateToNextWeek = (): void => {
    const nextWeek = currentWeek.map((day) => moment(day).add(7, 'days').format('YYYY-MM-DD'))

    const firstDayOfWeek = moment(nextWeek[0])
    const newMonth = firstDayOfWeek.month() + 1
    setCurrentWeek(nextWeek)
    setMonth(newMonth)
  }

  // Sauvegarde toutes les lignes du Reporting modifiées et les ajoutent en BDD
  const handleSaveAll = (): void => {
    const request: IReportingRequest[] = []

    modifiedReportings.forEach((item) =>
      request.push({
        id: item.id,
        userId: currentUser?.id ?? 1,
        projectId: item?.project.id ?? 1,
        description: item?.description,
        workEntries: item?.workEntries,
        year,
        month,
        isBillable: item?.isBillable
      })
    )

    void addReporting(request).then((updatedReporting: IReporting[]) => {
      setReporting(updatedReporting)
      setModifiedReportings([])
    })

    toast.success('Enregistrement réussi')
  }

  // Sauvegarde une ligne du Reporting en BDD
  const handleSaveLine = (item: IReporting): void => {
    if (!item) {
      return
    }

    const request: IReportingRequest[] = [
      {
        id: item.id,
        userId: currentUser?.id ?? 1,
        projectId: item?.project.id ?? 1,
        description: item?.description,
        workEntries: item?.workEntries,
        year,
        month,
        isBillable: item?.isBillable
      }
    ]

    void addReporting(request).then((updatedReporting: IReporting[]) => {
      const modifiedWithoutSaved = modifiedReportings.filter((report) => report.id !== item.id)

      setModifiedReportings(modifiedWithoutSaved)

      setReporting((prevReporting: IReporting[]) => {
        const mergedReporting: IReporting[] = [...updatedReporting]
        modifiedWithoutSaved.forEach((modifiedItem: IReporting) => {
          const index = mergedReporting.findIndex((report) => report.id === modifiedItem.id)
          if (index !== -1) {
            mergedReporting[index] = modifiedItem
          } else {
            mergedReporting.push(modifiedItem)
          }
        })
        return mergedReporting
      })
    })

    toast.success('Enregistrement réussi')
  }

  // Calcule le total d'entrées pointées sur chaque jour du mois
  const totalForDay = calculateTotalForDay(reporting, isSmallScreen ? currentWeek : listDates)
  const totalForDayNonBillable = calculateTotalForDay(
    reporting,
    isSmallScreen ? currentWeek : listDates,
    false
  )
  const totalForDayBillable = calculateTotalForDay(
    reporting,
    isSmallScreen ? currentWeek : listDates,
    true
  )
  const totalForMonthBillable = calculateTotalForMonth(reporting, listDates, true)
  const totalForMonth = calculateTotalForMonth(reporting, listDates)

  return (
    <>
      <table className="w-full">
        <thead>
          {/* Header du tableau en mobile */}
          {isSmallScreen ? (
            <tr>
              <th
                className={modifiedReportings.length === 0 ? 'pointer-events-none opacity-50' : ''}
              >
                <PrimaryButton
                  title="Enregistrer"
                  handleClick={handleSaveAll}
                  background
                  reverse={false}
                  disabled={false}
                />
              </th>
              <th>
                <ArrowBackIos
                  className="cursor-pointer"
                  fontSize="small"
                  onClick={navigateToPreviousWeek}
                />
              </th>
              {daysList.map((day, index) => (
                <th
                  key={index}
                  className={`bold w-[34px] text-center text-sm ${
                    index >= 5 ? 'text-gray-400' : ''
                  }`}
                >
                  {day}
                </th>
              ))}
              <th>
                <ArrowForwardIos
                  className="cursor-pointer"
                  fontSize="small"
                  onClick={navigateToNextWeek}
                />
              </th>
            </tr>
          ) : (
            // Header du tableau en desktop
            <tr>
              <th colSpan={3}></th>
              {listDates.map((day, index) => (
                <th
                  key={index}
                  className={`bold text-sm ${
                    isHolidayOrWeekendDay(day, holidayDates) ? 'light-greyText' : ''
                  } ${isToday(day) ? 'today-date' : ''} ${
                    hoveredDay === day ? 'bg-slate-100 transition-colors duration-150' : ''
                  }`}
                >
                  <div className="flex flex-col day">
                    <div>{parseInt(moment(day).format('DD'), 10)}</div>
                  </div>
                </th>
              ))}
              <th>Σ</th>
            </tr>
          )}
        </thead>

        <tbody>
          {isSmallScreen ? (
            // Tableau reporting mobile
            <>
              <tr>
                <td colSpan={2}></td>
                {currentWeek.map((day, index) => (
                  <td
                    key={index}
                    className={`bold text-center text-sm ${
                      isHolidayOrWeekendDay(day, holidayDates) ? 'light-greyText' : ''
                    } ${isToday(day) ? 'today-date' : ''}`}
                  >
                    {moment(day).format('D')}
                  </td>
                ))}
                <td></td>
              </tr>
              <tr>
                <td className="font-black text-end pr-2" style={{ fontSize: '13px' }} colSpan={2}>
                  Totaux jours pointés:
                </td>
                {currentWeek.map((day, index) => (
                  <td
                    key={index}
                    className={`border border-gray-200 text-center text-sm ${
                      isHolidayOrWeekendDay(day, holidayDates)
                        ? 'bg-gray-100 text-gray-100'
                        : totalForDay[index] !== 1
                        ? 'text-red-600'
                        : 'text-green-600'
                    } `}
                  >
                    {totalForDay[index]}
                  </td>
                ))}
                <td></td>
              </tr>
            </>
          ) : (
            // Tableau reporting desktop
            <tr>
              <td
                colSpan={1}
                className={modifiedReportings.length === 0 ? 'pointer-events-none opacity-50' : ''}
              >
                {!isLocked && (
                  <PrimaryButton
                    title="Tout enregistrer"
                    handleClick={handleSaveAll}
                    background
                    reverse={false}
                    disabled={false}
                    className="w-[170px]"
                  />
                )}
              </td>
              <td colSpan={1}></td>
              <td className="font-black text-end pr-2" style={{ fontSize: '12px' }}>
                Totaux jours pointés:
              </td>

              {listDates.map((day, index) => (
                <td
                  style={{ fontSize: '9px' }}
                  key={index}
                  className={`cursor-pointer border border-gray-200 text-xs text-center min-w-[16px] ${
                    isMediumScreen ? 'w-[25px]' : 'w-[16px]'
                  } ${
                    isHolidayOrWeekendDay(day, holidayDates)
                      ? 'bg-gray-100 text-gray-100'
                      : totalForDay[index] !== 1
                      ? 'text-red-600'
                      : 'text-green-600'
                  }`}
                >
                  {totalForDay[index]}
                </td>
              ))}

              <td className="border border-gray-200 bg-white text-xs text-center w-[26px]">
                {totalForMonth}
              </td>
            </tr>
          )}

          {/* ACTIVITES FACTURABLES */}
          <ReportingTableContent
            isBillable={true}
            listDates={isSmallScreen ? currentWeek : listDates}
            reporting={reporting}
            year={year}
            month={month}
            modifiedReportings={modifiedReportings}
            handleDelete={handleDelete}
            setReporting={setReporting}
            setModifiedReportings={setModifiedReportings}
            handleSaveLine={handleSaveLine}
            isSmallScreen={isSmallScreen}
            holidayDates={holidayDates}
            isLocked={isLocked}
            hoveredDay={hoveredDay}
            setHoveredDay={setHoveredDay}
            currentUser={currentUser}
          />
          <tr className="h-[30px] hidden xl:contents">
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td className="font-black text-end pr-2" style={{ fontSize: '12px' }}>
              Totaux jours facturés pointés:
            </td>

            {listDates.map((day, index) => (
              <td
                style={{ fontSize: '9px' }}
                key={index}
                className={`cursor-pointer border border-gray-200 text-xs text-center min-w-[16px] ${
                  isMediumScreen ? 'w-[25px]' : 'w-[16px]'
                } bg-gray-100 text-black`}
              >
                {totalForDayBillable[index]}
              </td>
            ))}

            <td className="border border-gray-200 bg-white text-xs text-center w-[26px]">
              {totalForMonthBillable}
            </td>
          </tr>
          {/* ACTIVITES NON FACTURABLES */}
          <ReportingTableContent
            isBillable={false}
            listDates={isSmallScreen ? currentWeek : listDates}
            reporting={reporting}
            year={year}
            month={month}
            modifiedReportings={modifiedReportings}
            handleDelete={handleDelete}
            setReporting={setReporting}
            setModifiedReportings={setModifiedReportings}
            handleSaveLine={handleSaveLine}
            isSmallScreen={isSmallScreen}
            holidayDates={holidayDates}
            isLocked={isLocked}
            hoveredDay={hoveredDay}
            setHoveredDay={setHoveredDay}
            currentUser={currentUser}
          />
          <tr className="h-[30px] hidden xl:contents">
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td className="font-black text-end pr-2" style={{ fontSize: '12px' }}>
              Totaux jours non-facturés pointés:
            </td>

            {listDates.map((day, index) => (
              <td
                style={{ fontSize: '9px' }}
                key={index}
                className={`cursor-pointer border border-gray-200 text-xs text-center min-w-[16px] ${
                  isMediumScreen ? 'w-[25px]' : 'w-[16px]'
                } bg-gray-100 text-black`}
              >
                {totalForDayNonBillable[index]}
              </td>
            ))}

            <td className="border border-gray-200 bg-white text-xs text-center w-[26px]">
              {calculateTotalForMonth(reporting, listDates, false)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default ReportingTable
