import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { InputLabel, TextField } from '@mui/material'
import React, { ReactElement } from 'react'

interface IInterviewPopupSelfInput {
  user: IMinimalCollaborateurData
  title: string
}

const InterviewPopupSelfInput = ({ user, title }: IInterviewPopupSelfInput): ReactElement => {
  return (
    <div>
      <InputLabel className="mb-1" id={title}>
        {title}
      </InputLabel>
      <TextField value={user.displayFirstname + ' ' + user.displayLastname} disabled fullWidth />
    </div>
  )
}

export default InterviewPopupSelfInput
