import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import ProfilImage from '../../components/profilImage/ProfilImage'
import React, { ReactElement, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { deleteGroup } from 'services/GroupService'
import EditIcon from '@mui/icons-material/Edit'
import { IGroup } from 'interfaces/group'

interface IGroupCard {
  groups: IGroup[]
  handleEdit: (group: IGroup) => void
  setGroups: React.Dispatch<React.SetStateAction<IGroup[]>>
}

const GroupCards = ({ groups, handleEdit, setGroups }: IGroupCard): ReactElement => {
  const [openDeleteGroupPopUp, setOpenDeleteGroupPopUp] = useState(false)
  const [groupToDelete, setGroupToDelete] = useState<IGroup>()
  const [loading, setLoading] = useState<boolean>(false)

  const handleDelete = async (): Promise<void> => {
    setLoading(true)
    if (groupToDelete !== undefined) {
      const updatedGroups = await deleteGroup(groupToDelete)
      setGroups(updatedGroups)
      setOpenDeleteGroupPopUp(false)
    }
    setLoading(false)
  }

  return (
    <div>
      {groups.map((group) => (
        <div className="box-groups-container" key={group.id}>
          <div className="group-content">
            <div className="bold">{group.title}</div>
            <div className="members">
              {group.usersAssignments?.map((user) => (
                <div className="group-collab-name" key={user?.id}>
                  <ProfilImage user={user} />
                  <span className="collab-name">
                    {user?.displayFirstname} {user?.displayLastname}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="buttons-container">
            <DeleteIcon
              id="delete-icon"
              onClick={() => {
                void (async () => {
                  setGroupToDelete(group)
                  setOpenDeleteGroupPopUp(true)
                })()
              }}
            ></DeleteIcon>
            <EditIcon
              id="edit-icon"
              onClick={() => {
                handleEdit(group)
              }}
            ></EditIcon>
          </div>
        </div>
      ))}
      <ConfirmationPopUp
        dialogTitle="Voulez-vous supprimer ce groupe ?"
        dialogContentText="Les données de ce groupe seront supprimées"
        open={openDeleteGroupPopUp}
        setOpen={setOpenDeleteGroupPopUp}
        handleSave={handleDelete}
        loading={loading}
      />
    </div>
  )
}

export default GroupCards
