import {
  IDetailCollaborateurData,
  IMinimalCollaborateurData
} from '../interfaces/collaborateurData'
import { IFeature, ISetting, ITheme } from 'interfaces/setting'
import { IFeatureContext } from '../interfaces/context'
import { IProfilTab } from '../interfaces/headCells'
import { hasRole } from '../utils/otherUtils'
import { roleConstant } from '../constantes'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

/**
 * Récupérer les couleurs du site
 */
export const getThemeColors = async (place: number | null): Promise<ITheme> => {
  try {
    const placeParam = place !== null ? `placeId=${place}` : ''
    const response: ITheme = await customAxios.get(`settings/colors?${placeParam}`)
    return response
  } catch (e) {
    toast.error('Erreur lors du chargement du thème')
    return {
      primaryColor: '#1653F0',
      secondaryColor: '#0700E0',
      tertiaryColor: '#7B01D4',
      logoLink: ''
    }
  }
}

/**
Récupérer le token de chaque service
**/
export const getSettings = async (place: number): Promise<ISetting[]> => {
  try {
    const placeParam = place !== null ? `placeId=${place}` : ''
    return await customAxios.get(`settings?${placeParam}`)
  } catch (error) {
    return []
  }
}

/**
Mettre à jour le token de chaque service
**/
export const updateSettings = async (
  settings: ISetting[],
  place: number | null
): Promise<ISetting[]> => {
  try {
    const placeParam = place !== null ? `placeId=${place}` : ''
    const response = await customAxios.put(`settings?${placeParam}`, settings)
    if (response !== null) {
      toast.success('Vos modifications ont bien été sauvegardées')
    }
    return response as unknown as ISetting[]
  } catch (error) {
    return []
  }
}

/**
 * Récupérer les modules du site
 */
export const getFeatures = async (): Promise<IFeature[]> => {
  try {
    return await customAxios.get(`settings/features`)
  } catch (e) {
    toast.error('Erreur lors du chargement des modules')
    return []
  }
}

export const isFeatureEnabled = (context: IFeatureContext | null, name: string): boolean => {
  return context?.features?.find((item: IFeature) => item.name === name) !== undefined
}

export const isTabEnabled = (
  tab: IProfilTab,
  featureContext: IFeatureContext | null,
  user: IDetailCollaborateurData | null,
  currentUser: IMinimalCollaborateurData | null | undefined
): boolean => {
  if (
    tab.contractTypes !== undefined &&
    (user?.contractType?.name === undefined || !tab?.contractTypes.includes(user.contractType.name))
  ) {
    return false
  }

  if (
    tab.confidential &&
    user?.isConfidential === true &&
    (currentUser === null ||
      currentUser === undefined ||
      !hasRole(currentUser.rolePermissions, [roleConstant.ROLE_ADMIN]))
  ) {
    return false
  }

  if (
    tab.needPermissions !== undefined &&
    (currentUser === null ||
      currentUser === undefined ||
      !hasRole(currentUser.rolePermissions, tab?.needPermissions))
  ) {
    return false
  }

  return tab.feature === undefined || isFeatureEnabled(featureContext, tab.feature)
}
