import React, { ReactElement, useContext, useEffect } from 'react'
import { logout } from '../services/GoogleConnexionService'
import { CurrentUserContext } from '../App'

const LogoutCallback = (): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  useEffect(() => {
    const logoutUser = async (): Promise<void> => {
      logout().finally(() => {
        localStorage.clear()
        userContext?.setCurrentUser(null)
        window.open(`${process.env.REACT_APP_PORTAIL_URL ?? ''}`, '_self')
      })
    }

    // eslint-disable-next-line
    logoutUser()
  }, [])

  return <></>
}

export default LogoutCallback
