import { Dialog, DialogContent, DialogContentText } from '@mui/material'
import { IconButton } from '@material-ui/core'
import Close from '@mui/icons-material/Close'
import React, { ReactElement } from 'react'

interface IDescriptionPopUp {
  descriptionContent: string | undefined
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DescriptionPopUp = ({
  descriptionContent,
  open,
  setOpen
}: IDescriptionPopUp): ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={(): void => {
        setOpen(false)
      }}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="popUp"
    >
      <div className="w-[400px] flex justify-center">
        <IconButton
          onClick={() => {
            setOpen(false)
          }}
          id="closeIcon"
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{descriptionContent}</DialogContentText>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default DescriptionPopUp
