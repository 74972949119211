import { IheadCells } from 'interfaces/headCells'

interface ITableRequestsColumns {
  currentTab: number
  page: string
}

export const tableRequestsColumns = ({ currentTab, page }: ITableRequestsColumns): IheadCells[] => [
  {
    id: 'googlePicture',
    label: '',
    priority: 4,
    isSortable: false
  },
  {
    id: 'requestingUser',
    label: 'Nom',
    priority: 1,
    isSortable: page !== 'Dashboard'
  },
  {
    id: 'firstDay',
    label: 'Date début',
    priority: 1,
    isSortable: page !== 'Dashboard'
  },
  {
    id: 'lastDay',
    label: 'Date fin',
    priority: 2,
    isSortable: page !== 'Dashboard'
  },
  {
    id: 'duration',
    label: 'Durée',
    priority: 3,
    isSortable: page !== 'Dashboard'
  },
  {
    id: 'holidayType',
    label: 'Type',
    priority: 3,
    isSortable: page !== 'Dashboard'
  },
  {
    id: 'justificationFilename',
    label: 'Document',
    priority: 4,
    isSortable: false
  },
  {
    id: 'status',
    label: 'Statut',
    priority: 1,
    isSortable: currentTab !== 1
  }
]

export const offsiteRequestsColumns = (): IheadCells[] => [
  {
    id: 'googlePicture',
    label: '',
    priority: 4,
    isSortable: false
  },
  {
    id: 'requestingUser',
    label: 'Nom',
    priority: 1,
    isSortable: false
  },
  {
    id: 'firstDay',
    label: 'Date',
    priority: 1,
    isSortable: false
  },
  {
    id: 'askThe',
    label: 'Demandé le ',
    priority: 1,
    isSortable: false
  },
  {
    id: 'duration',
    label: 'Durée',
    priority: 3,
    isSortable: false
  },
  {
    id: 'justification',
    label: 'Commentaire',
    priority: 4,
    isSortable: false
  },
  {
    id: 'status',
    label: 'Statut',
    priority: 1,
    isSortable: false
  }
]
