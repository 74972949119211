import { IFollowUp, IReportingFollowUp } from 'interfaces/reporting'
import { getReportingFollowUp } from 'services/ReportingService'
import React, { ReactElement, useEffect, useState } from 'react'
import { formatDateWithoutYear } from 'utils/dateUtils'
import { CircularProgress } from '@mui/material'

export interface AdminReportingFollowUpProps {
  year: number
  month: number
  place: number
  goToReporting: (name: string) => void
}

const AdminReportingFollowUp = ({
  year,
  month,
  place,
  goToReporting
}: AdminReportingFollowUpProps): ReactElement => {
  const [followUpData, setFollowUpData] = useState<IReportingFollowUp | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    setLoading(true)
    void getReportingFollowUp(month, year, place).then((response) => {
      setFollowUpData(response)
      setLoading(false)
    })
  }, [year, month, place])

  return (
    <>
      {loading && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}

      {!loading && followUpData?.followUps && (
        <div className="bg-white p-2 rounded-lg shadow-md">
          <table className="w-full">
            <thead className="border-b-2 border-gray-300">
              <tr>
                <th className="bold text-left">Nom</th>
                <th className="bold text-left">Prénom</th>
                <th className="bold text-left">Contrat</th>

                {followUpData?.weeks.map((week, index) => (
                  <th key={index}>
                    {formatDateWithoutYear(week.startDate)} au {formatDateWithoutYear(week.endDate)}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {followUpData?.followUps
                .sort((a: IFollowUp, b: IFollowUp) => a.familyName.localeCompare(b.familyName))
                .map((data, index) => (
                  <tr key={index} className="border-b-2 border-gray-300 text-gray-500">
                    <td
                      className="cursor-pointer"
                      onClick={() => {
                        goToReporting(data.familyName)
                      }}
                    >
                      {data.familyName}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() => {
                        goToReporting(data.familyName)
                      }}
                    >
                      {data.name}
                    </td>
                    <td>{data.contractType}</td>
                    {data.workedDays.map((days, index) => (
                      <td
                        key={index}
                        className={
                          days !== followUpData?.weeks[index]?.workableDays
                            ? 'bg-red-600 text-white border border-gray-400 text-center'
                            : 'bg-gray-200 border border-gray-400 text-center'
                        }
                      >
                        {days}/{followUpData?.weeks[index]?.workableDays}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default AdminReportingFollowUp
