import { IClient, IUpdateClient } from 'interfaces/clients'
import userDefaultPicture from '../../assets/user.png'
import React, { ReactElement, useState } from 'react'

interface IClientImage {
  client: IClient | IUpdateClient | null | undefined
  zoomInAllowed?: boolean
}

const ClientImage = ({ client, zoomInAllowed = false }: IClientImage): ReactElement => {
  let urlImage = userDefaultPicture
  if (client?.clientPictureFilename !== undefined) {
    urlImage = '/api/clients/' + String(client?.id) + '/profile-pictures'
  }

  const [zoomIn, setZoomIn] = useState<boolean>(false)

  const handleZoomIn = (): void => {
    if (zoomInAllowed) {
      setZoomIn(!zoomIn)
    }
  }

  return (
    <div className="client-img" onClick={handleZoomIn}>
      <img
        src={urlImage}
        alt="Photo client"
        className={zoomInAllowed ? `zoom-in-cursor ${zoomIn ? 'zoom-in' : ''}` : ''}
      />
    </div>
  )
}

export default ClientImage
