import React, { ReactElement, useContext, useEffect, useState } from 'react'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { getGroups } from 'services/GroupService'
import { PlaceSelectedContext } from '../../App'
import { IGroup } from 'interfaces/group'
import GroupPopUp from './GroupPopUp'
import GroupCards from './GroupCards'

const Group = (): ReactElement => {
  const [groupToEdit, setGroupToEdit] = useState<IGroup | undefined>()
  const [openGroup, setOpenGroup] = useState<boolean>(false)
  const [groups, setGroups] = useState<IGroup[]>([])
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null

  useEffect(() => {
    void getGroups(place).then(setGroups)
  }, [place])

  const handleEdit = (group: IGroup): void => {
    setGroupToEdit(group)
    setOpenGroup(true)
  }

  return (
    <div className="groups">
      <div className="groupes-title">
        <h3>
          <span className="accentuated-title">Groupes</span>
        </h3>
        <PrimaryButton
          handleClick={(): void => {
            setOpenGroup(true)
            setGroupToEdit(undefined)
          }}
          title="Ajouter un groupe"
          background
          reverse={false}
          disabled={false}
        />
        <GroupCards groups={groups} handleEdit={handleEdit} setGroups={setGroups} />
      </div>
      <GroupPopUp
        open={openGroup}
        setOpen={setOpenGroup}
        groupToEdit={groupToEdit}
        setGroups={setGroups}
      />
    </div>
  )
}

export default Group
