import { FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material'
import React, { ReactElement } from 'react'

interface ICustomGroupRadios {
  onChange: (value: string) => void
  id: string
  gpRadiovalue: string | null
  errorMessage?: string
  required: boolean
  radiosLabelValue: string[]
  label?: string
}

const CustomGroupRadios = ({
  onChange,
  id,
  gpRadiovalue,
  errorMessage = '',
  required,
  label = '',
  radiosLabelValue
}: ICustomGroupRadios): ReactElement => {
  return (
    <div className="GroupRadio">
      <InputLabel id={id} required={required}>
        {label}
      </InputLabel>
      <RadioGroup
        id="type"
        value={gpRadiovalue !== null ? gpRadiovalue : 'Aucun'}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      >
        {radiosLabelValue.map((radioLabelValue) => (
          <FormControlLabel
            key={radioLabelValue}
            value={radioLabelValue}
            control={<Radio />}
            label={radioLabelValue}
          />
        ))}
      </RadioGroup>
      <p className="form-error">{errorMessage}</p>
    </div>
  )
}

export default CustomGroupRadios
