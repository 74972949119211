import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CustomTextField from '../../inputs/customTextFIeld/CustomTextField'
import PrimaryButton from '../../inputs/button/PrimaryButton'
import React, { ReactElement } from 'react'

interface ITextPopUp {
  dialogTitle: string
  dialogContentText: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleSave: () => Promise<void>
  handleChange: (value: string) => void
  value: string
  loading: boolean
}

const TextPopUp = ({
  dialogTitle,
  dialogContentText,
  open,
  setOpen,
  handleSave,
  handleChange,
  value,
  loading
}: ITextPopUp): ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={(): void => {
        setOpen(false)
      }}
      aria-labelledby="save-dialog"
      aria-describedby="save-dialog-description"
      className="popUp"
    >
      <DialogTitle id="save-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent className="text-popup">
        <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
        <CustomTextField
          onChange={handleChange}
          id="expense-refusal-reason"
          value={value}
          errorMessage={undefined}
          required={false}
          label="Justification"
          multiline={true}
        />
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          handleClick={(): void => {
            setOpen(false)
          }}
          title="Annuler"
          background
          reverse
          disabled={loading}
        />
        <PrimaryButton
          handleClick={() => {
            void (async () => {
              await handleSave()
            })()
          }}
          title="Valider"
          background
          reverse={false}
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  )
}

export default TextPopUp
