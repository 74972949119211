import { getUserProductionOfMonth } from 'services/ReportingService'
import React, { ReactElement, useEffect, useState } from 'react'
import { calculateWorkableDaysInMonth } from 'utils/dateUtils'
import { IUserProduction } from 'interfaces/reporting'
import { CircularProgress } from '@mui/material'
import { formatNumber } from 'utils/otherUtils'
import { round } from 'lodash'

export interface AdminReportingByContractProps {
  month: number
  year: number
  selectedContract: string
  selectedJob: string
  selectedUser: number
  place: number
  goToReporting: (name: string) => void
}

const AdminReportingByContract = ({
  month,
  year,
  selectedContract,
  selectedJob,
  selectedUser,
  place,
  goToReporting
}: AdminReportingByContractProps): ReactElement => {
  const [userProductions, setUserProduction] = useState<IUserProduction[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [potentialWorkedDays, setPotentialWorkedDays] = useState<number>(
    calculateWorkableDaysInMonth(month, year)
  )
  // Filtre les productions par type de contract si le filtre existe
  const filteredProductionsByContract = selectedContract
    ? userProductions.filter((prod) => prod.contractType === selectedContract)
    : userProductions

  // Filtre les productions par job si le filtre existe
  const filteredProductions = selectedJob
    ? selectedJob === 'Tous sauf direction'
      ? filteredProductionsByContract.filter((prod) => prod.user?.job?.name !== 'Direction')
      : filteredProductionsByContract.filter((prod) => prod.user?.job?.name === selectedJob)
    : filteredProductionsByContract

  // Calcule les totaux et moyennes
  const totalBillable = filteredProductions.reduce((sum, prod) => sum + prod.amountBillable, 0)
  const totalNonBillable = filteredProductions.reduce(
    (sum, prod) => sum + prod.amountNonBillable,
    0
  )
  const totalHolidays = filteredProductions.reduce((sum, prod) => sum + prod.amountHoliday, 0)
  const totalCumul = totalBillable + totalNonBillable
  const totalSA =
    filteredProductions.reduce((sum, prod) => sum + round(prod.sa, 2), 0) /
    filteredProductions.length
  const totalTFHA =
    filteredProductions.reduce(
      (sum, prod) =>
        sum + round(((prod.amountBillable + prod.amountHoliday) / potentialWorkedDays) * 100, 2),
      0
    ) / filteredProductions.length

  useEffect(() => {
    setLoading(true)
    void getUserProductionOfMonth(year, month, place).then((response) => {
      if (response) {
        setUserProduction(
          response.sort((a: IUserProduction, b: IUserProduction) =>
            a.lastName.localeCompare(b.lastName)
          )
        )
        setLoading(false)
      }
    })
    const newPotential = calculateWorkableDaysInMonth(month, year)
    setPotentialWorkedDays(newPotential)
  }, [month, year, place])

  return (
    <>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          <span className="small-accentuated-title">
            {selectedContract ? `Production ${selectedContract}` : 'Production Tout Contrat'}
          </span>
          <div className="bg-white rounded-xl overflow-hidden mt-1 text-sm">
            {userProductions.length ? (
              <table className="w-full shadow-md">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    <th className="bold p-1 text-left">Nom</th>
                    <th className="bold text-left">Prénom</th>
                    <th className="bold">Facturé</th>
                    <th className="bold">Non facturé</th>
                    <th className="bold">Congé</th>
                    <th className="bold">Cumul</th>
                    <th className="bold">Potentiel</th>
                    <th className="bold">TFHA</th>
                    <th className="bold">SA</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProductions.map(
                    (production, index) =>
                      (!selectedUser || selectedUser === production.user.id) && (
                        <tr key={index} className="border-b-2 border-gray-300 text-gray-500">
                          <td
                            className="p-1 cursor-pointer"
                            onClick={() => {
                              goToReporting(production.lastName)
                            }}
                          >
                            {production?.lastName}
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() => {
                              goToReporting(production.lastName)
                            }}
                          >
                            {production?.name}
                          </td>
                          <td className="text-center">{production?.amountBillable / 100}</td>
                          <td className="text-center">{production?.amountNonBillable / 100}</td>
                          <td className="text-center">{production?.amountHoliday / 100}</td>
                          <td className="text-center">
                            {(production?.amountBillable +
                              production.amountNonBillable +
                              production?.amountHoliday) /
                              100}
                          </td>
                          <td className="text-center">{potentialWorkedDays}</td>
                          <td className="text-center">
                            {round(
                              (production?.amountBillable + production?.amountHoliday) /
                                potentialWorkedDays,
                              2
                            ) ?? 0}
                            %
                          </td>
                          <td className="text-center">
                            {formatNumber(round(production.sa / 100, 2) ?? 0)}
                          </td>
                        </tr>
                      )
                  )}
                  <tr className="border-b-2 border-gray-300 bg-violet-100 bold">
                    <td className="p-1">Total</td>
                    <td></td>
                    <td className="text-center">{totalBillable / 100}</td>
                    <td className="text-center">{totalNonBillable / 100}</td>
                    <td className="text-center">{totalHolidays / 100}</td>
                    <td className="text-center">{totalCumul / 100}</td>
                    <td className="text-center">{potentialWorkedDays * userProductions.length}</td>
                    <td className="text-center">{totalTFHA ? round(totalTFHA / 100, 2) : 0}%</td>
                    <td className="text-center">
                      {totalSA ? formatNumber(round(totalSA / 100, 2)) : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>Pas de données pour ce mois</div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default AdminReportingByContract
