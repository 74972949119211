import IdentityCard from '../../../assets/Carte_identite.svg'
import VitaleCard from '../../../assets/Carte_vitale.svg'
import React, { ReactElement, useContext } from 'react'
import Transport from '../../../assets/Transport.svg'
import { CurrentUserContext } from '../../../App'
import Upload from '../../../assets/upload.png'
import Check from '../../../assets/check.png'
import RIB from '../../../assets/RIB.svg'
import '../OnBoarding.scss'

const DocumentStep = ({
  selectedIdentityCard,
  setSelectedIdentityCard,
  selectedRIB,
  setSelectedRIB,
  selectedVitalCard,
  setSelectedVitalCard,
  selectedTransport,
  setSelectedTransport
}: {
  selectedIdentityCard: [string | Blob, string] | undefined
  setSelectedIdentityCard: (value: [string | Blob, string]) => void
  selectedRIB: [string | Blob, string] | undefined
  setSelectedRIB: (value: [string | Blob, string]) => void
  selectedVitalCard: [string | Blob, string] | undefined
  setSelectedVitalCard: (value: [string | Blob, string]) => void
  selectedTransport: [string | Blob, string] | undefined
  setSelectedTransport: (value: [string | Blob, string]) => void
}): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  const STEP_1 = [
    currentUser?.onBoardingFiles?.find((file) => file.key === 'IDENTITY_CARD')?.provided !== true
      ? {
          key: 'identityCard',
          text: "Importer votre carte d'identité",
          image: IdentityCard,
          action: setSelectedIdentityCard,
          value: selectedIdentityCard
        }
      : null,
    currentUser?.onBoardingFiles?.find((file) => file.key === 'RIB')?.provided !== true
      ? {
          key: 'RIB',
          text: 'Importer votre RIB',
          image: RIB,
          action: setSelectedRIB,
          value: selectedRIB
        }
      : null,
    currentUser?.onBoardingFiles?.find((file) => file.key === 'VITALE_CARD')?.provided !== true
      ? {
          key: 'VitaleCard',
          text: 'Importer votre carte vitale',
          image: VitaleCard,
          action: setSelectedVitalCard,
          value: selectedVitalCard
        }
      : null,
    currentUser?.onBoardingFiles?.find((file) => file.key === 'ATTESTATION_TRANSPORT')?.provided !==
    true
      ? {
          key: 'Transport',
          text: 'Importer votre attestation de transport',
          image: Transport,
          action: setSelectedTransport,
          value: selectedTransport
        }
      : null
  ]

  return (
    <div className="step-1">
      {STEP_1.filter((step) => step !== null).map((step, index) => (
        <div
          className={`card ${step?.value == null ? 'card-full' : 'card-empty'}`}
          key={`step1-${index}`}
        >
          {step?.value != null && <img className="checked" src={Check} alt="validated" />}
          <label htmlFor={step?.key} className="pointer card-container">
            <input
              className="d-none"
              id={step?.key}
              type="file"
              accept=".pdf"
              onChange={({ target }) => {
                if (target.files != null) {
                  const file = target.files[0]
                  if (file !== undefined) {
                    step?.action([new Blob([file], { type: file.type }), file.name])
                  }
                }
              }}
            />
            <img src={step?.image} className="h-half card-image" alt={step?.text} />
            <div className="h-half uploader">
              <img src={Upload} width={32} alt="upload" />
              <p className="step-1-text">{step?.value != null ? step.value[1] : step?.text}</p>
            </div>
          </label>
        </div>
      ))}
    </div>
  )
}
export default DocumentStep
