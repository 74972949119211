import FilteredListItems from '../../../components/filteredListItems/FilteredListItems'
import DocumentUploader from '../../../components/inputs/uploader/DocumentUploader'
import PrimaryButton from '../../../components/inputs/button/PrimaryButton'
import { getCosts, importCosts } from '../../../services/ReportingService'
import { formatDateToMonthAndYear } from '../../../utils/dateUtils'
import React, { ReactElement, useEffect, useState } from 'react'
import { TableBody, TableCell, TableRow } from '@mui/material'
import { ICost } from '../../../interfaces/reporting'
import { PdfFile } from '../../../interfaces/pfdFile'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ImportCostsProps {
  month: number
  year: number
  place: number
}

const ImportCosts = ({ month, year, place }: ImportCostsProps): ReactElement => {
  const navigate = useNavigate()
  const [costs, setCosts] = useState<ICost[]>([])
  const [importedCosts, setImportedCosts] = useState<ICost[]>([])
  const [uploadedFile, setUploadedFile] = useState<PdfFile | null>(null)

  useEffect(() => {
    void getCosts(year, month, place).then(setCosts)
  }, [month, year])

  const importFile = (csvFile: Blob, submit: boolean): void => {
    void importCosts(csvFile, submit).then((result: ICost[]) => {
      if (!submit) {
        setImportedCosts(result)
      } else {
        toast.success('Les costs ont bien été enregistrés')
        setImportedCosts([])
        setUploadedFile(null)
        void getCosts(year, month, place).then(setCosts)
      }
    })
  }

  return (
    <div className="flex justify-between">
      <div className="w-[50%]">
        {costs.length > 0 && (
          <FilteredListItems
            nbPages={1}
            page={1}
            headCells={[
              {
                id: 'user',
                label: 'Salarié',
                priority: 1,
                isSortable: false
              },
              {
                id: 'cost',
                label: 'Cost',
                priority: 2,
                isSortable: false
              },
              {
                id: 'efficiency',
                label: 'Efficacité',
                priority: 2,
                isSortable: false
              }
            ]}
            tableBody={
              <TableBody>
                {costs.map((cost: ICost, index: number) => (
                  <TableRow
                    key={cost.id}
                    className={index === 0 ? '!border-t-0' : ''}
                    hover
                    onClick={() => {
                      navigate(`/profil/${cost.userAssignment?.id}?tab=Costs`)
                    }}
                  >
                    <TableCell>
                      {cost.userAssignment.displayFirstname} {cost.userAssignment.displayLastname}
                    </TableCell>
                    <TableCell>{cost.cost ? `${cost.cost}€` : '-'}</TableCell>
                    <TableCell>{cost.userAssignment.efficientPourcent}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
            nbItems={costs.length}
            onlyShowMobileFilters={undefined}
            setPage={() => {}}
          />
        )}
      </div>
      <div className="w-[40%]">
        <DocumentUploader
          accept={{
            '.csv': []
          }}
          selectedFile={uploadedFile}
          uploadFile={(file) => {
            void (async () => {
              if (file) {
                setUploadedFile(file)
                importFile(file.content, false)
              }
            })()
          }}
          minimalDesign={false}
          title="Importer le fichier des costs"
          edit={false}
        />
        <div className="my-4 flex justify-center">
          {importedCosts?.length > 0 && uploadedFile && (
            <PrimaryButton
              handleClick={() => {
                importFile(uploadedFile.content, true)
              }}
              title="Valider l'ensemble des costs"
              background
              reverse={false}
              disabled={false}
            />
          )}
        </div>
        <FilteredListItems
          nbPages={1}
          page={1}
          headCells={[]}
          tableBody={
            <TableBody>
              {importedCosts?.map((cost: ICost, index: number) => (
                <TableRow
                  key={cost.id}
                  className={index === 0 ? '!border-t-0' : ''}
                  hover
                  onClick={() => {
                    navigate(`/profil/${cost.userAssignment?.id}?tab=Costs`)
                  }}
                >
                  <TableCell>
                    {cost.userAssignment.displayFirstname} {cost.userAssignment.displayLastname}
                  </TableCell>
                  <TableCell>{formatDateToMonthAndYear(cost.date)}</TableCell>
                  <TableCell>{cost.cost}€</TableCell>
                  <TableCell>{cost.userAssignment.efficientPourcent}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          nbItems={10}
          onlyShowMobileFilters={undefined}
          setPage={() => {}}
        />
      </div>
    </div>
  )
}

export default ImportCosts
