import React, { ReactElement, useContext, useEffect, useState } from 'react'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { CurrentUserContext, YearSelectedContext } from 'App'
import { ContactSupport } from '@mui/icons-material'
import moment from 'moment'

interface IYearFilter {
  navOpen: boolean
  showSupport?: boolean
}

const YearFilter = ({ navOpen, showSupport }: IYearFilter): ReactElement => {
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()
  const [years, setYears] = useState<string[]>([])
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  // Alimentation du sélecteur d'année
  useEffect(() => {
    const arrivalYear = new Date(currentUser?.arrivalDate ?? '').getFullYear()
    const currentYear = new Date().getFullYear()
    let yearRange = [
      (currentYear + 1).toString(),
      ...Array.from({ length: currentYear - 2018 }, (_, index) => (currentYear - index).toString())
    ]

    if (arrivalYear) {
      yearRange = Array.from({ length: currentYear + 1 - arrivalYear + 1 }, (_, index) =>
        (currentYear + 1 - index).toString()
      )
    }

    setYears(yearRange)
  }, [])

  const handleChangeYear = (newYear: number): void => {
    yearSelectedContext?.setYearSelected(newYear)
  }
  return (
    <div>
      {showSupport && (
        <a
          href="https://steamulo-support.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noreferrer"
          className="bug"
        >
          {navOpen && (
            <span className="bug-text mr-3 text-white text-sm">Contacter le support</span>
          )}
          <ContactSupport />
        </a>
      )}
      <div className="year-filter">
        {navOpen && (
          <CustomSelect
            value={year}
            options={years}
            handleSelect={(newValue) => {
              handleChangeYear(Number(newValue))
            }}
            id="year"
            width={190}
            inputLabel="Année"
            required
          />
        )}
      </div>
    </div>
  )
}

export default YearFilter
