import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import { IInterviewAnswer, IInterviewQuestion } from 'interfaces/career'
import React, { ReactElement, useState } from 'react'

interface IReviewQuestionAndAnswer {
  question: IInterviewQuestion
  answer: IInterviewAnswer | null
  setAnswer: (answer: string, question: IInterviewQuestion) => void
}

const ReviewQuestionAndAnswer = ({
  question,
  answer,
  setAnswer
}: IReviewQuestionAndAnswer): ReactElement | null => {
  const [localAnswer, setLocalAnswer] = useState<string>(answer?.answer ?? '')

  const handleBlur = (): void => {
    setAnswer(localAnswer, question)
  }

  const handleChange = (value: string): void => {
    setLocalAnswer(value)
  }

  return (
    <div className="px-4 mt-8">
      <div className="bold mb-1">{question.title}</div>
      <div className="mb-1 italic">{question.question}</div>
      <CustomTextField
        onChange={(value: any) => {
          handleChange(value)
        }}
        onBlur={handleBlur}
        id={question?.id?.toString() ?? ''}
        value={localAnswer}
        errorMessage=""
        label=""
        required={false}
        multiline={true}
      />
    </div>
  )
}

export default ReviewQuestionAndAnswer
