import { PdfFile } from 'interfaces/pfdFile'
import { IBill } from 'interfaces/expenses'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

export const openJustificationFile = async (billId: number): Promise<PdfFile | null> => {
  try {
    return await customAxios.get(`expenses-bill/${billId}/justification-files`, {
      responseType: 'arraybuffer'
    })
  } catch (error) {
    return null
  }
}

export const saveJustificationFile = async (
  billId: number,
  file: PdfFile
): Promise<IBill | null> => {
  try {
    const formData = new FormData()
    formData.append('file', file.content, file.filename)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const response = await customAxios.post(
      `expenses-bill/${billId}/justification-files`,
      formData,
      config
    )
    return response as unknown as IBill
  } catch (error) {
    return null
  }
}

export const deleteJustificationFile = async (billId: number): Promise<IBill | null> => {
  try {
    const response = await customAxios.delete(`expenses-bill/${billId}/justification-files`)
    toast.success('Votre justificatif a bien été supprimé')
    return response as unknown as IBill
  } catch (error) {
    return null
  }
}
