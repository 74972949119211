import HolidayRequestApprobation from 'components/holidayRequestApprobation/HolidayRequestApprobation'
import OldHolidayRequestCancellation from './OldHolidayRequestCancellation'
import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import PrimaryButton from '../../components/inputs/button/PrimaryButton'
import { updateHolidayRequests } from '../../services/HolidayService'
import React, { ReactElement, useContext, useState } from 'react'
import { TableBody, TableCell, TableRow } from '@mui/material'
import ProfilImage from 'components/profilImage/ProfilImage'
import { IPlanningDate } from '../../interfaces/planning'
import useScreenSize from 'customHooks/useScreenSize'
import HolidaysRequestPdf from './HolidaysRequestPdf'
import { YearSelectedContext } from 'App'
import { screenSizes } from 'constantes'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'animate.css'

interface IHolidaysRequestsBodyTable {
  currentTab: number
  requests: IDetailHolidayRequest[]
  selectedRequests: IDetailHolidayRequest[]
  setSelectedRequests: React.Dispatch<React.SetStateAction<IDetailHolidayRequest[]>>
  handleRequestUpdate: (response: IDetailHolidayRequest) => void
  handleRequestApprobationUpdate: (response: IDetailHolidayRequest | IPlanningDate[]) => void
  selectAll: boolean
  setNeedReload: React.Dispatch<React.SetStateAction<boolean>>
  needReload: boolean
}

const HolidaysRequestsBodyTable = ({
  currentTab,
  requests,
  selectedRequests,
  setSelectedRequests,
  handleRequestUpdate,
  handleRequestApprobationUpdate,
  selectAll,
  setNeedReload,
  needReload
}: IHolidaysRequestsBodyTable): ReactElement => {
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = yearSelectedContext?.yearSelected ?? moment().year()
  const isSmallScreen = useScreenSize(screenSizes.MOBILE_SIZE)

  const [approvedRowId, setApprovedRowId] = useState<number | null>(null)
  const [checked, setChecked] = useState<boolean>(false)

  const handleChange = (request: IDetailHolidayRequest): void => {
    setChecked(!checked)
    if (selectedRequests.find((item) => item.id === request.id) === undefined) {
      setSelectedRequests(selectedRequests.concat(request))
    } else {
      setSelectedRequests(selectedRequests.filter((item) => item.id !== request.id))
    }
  }

  const updateHolidayRequest = async (request: IDetailHolidayRequest): Promise<void> => {
    await updateHolidayRequests({
      ...request,
      status: 'Régularisé',
      user: request.user,
      requestingUser: request.requestingUser
    })
    setNeedReload(!needReload)
  }

  // Fonction qui retourne l'url de la page de planning en fonction de l'id de l'utilisateur
  const createPlanningUrl = (userId: string | number | undefined): string => {
    const safeUserId = String(userId ?? '')
    return `/planning?view=annual&userId=${safeUserId}`
  }

  return (
    <>
      <TableBody>
        {requests.map((request) => {
          const userId = request.user?.id
          const planningUrl = createPlanningUrl(userId)
          return (
            <TableRow
              hover={false}
              key={`${request.id}-${currentTab}`}
              className={
                request.id === approvedRowId ? 'animate__animated animate__bounceOutRight' : ''
              }
            >
              {currentTab === 1 ? (
                <TableCell className="sx-delete">
                  <div className="check-icon-collab">
                    <input
                      className="checkbox"
                      type="checkbox"
                      disabled={selectAll}
                      onChange={() => {
                        handleChange(request)
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      checked={selectAll || selectedRequests.some((item) => item.id === request.id)}
                    />
                  </div>
                </TableCell>
              ) : (
                <></>
              )}
              <TableCell className="md-delete">
                <Link to={planningUrl}>
                  <ProfilImage user={request.user} />
                </Link>
              </TableCell>
              <TableCell>
                {request.user !== undefined && request.user !== null
                  ? String(request.user.displayFirstname) +
                    ' ' +
                    String(request.user.displayLastname)
                  : ''}
              </TableCell>
              <TableCell>
                {moment(request.firstDay).format('DD/MM/yyyy')}
                {isSmallScreen ? ` - ${moment(request.lastDay).format('DD/MM/yyyy')}` : ''}
              </TableCell>
              <TableCell className="sx-mini-delete">
                {moment(request.lastDay).format('DD/MM/yyyy')}
              </TableCell>
              <TableCell className="sx-delete">{request.duration}</TableCell>
              <TableCell className="sx-delete">{request.holidayType.holidayType}</TableCell>
              <TableCell className="md-delete">
                <HolidaysRequestPdf request={request} handlePdfUpdate={handleRequestUpdate} />
              </TableCell>
              {currentTab !== 2 ? (
                <TableCell>
                  <div className={`holidays-requests-status ${request.status}`}>
                    {currentTab === 2 || currentTab === 3 ? (
                      <OldHolidayRequestCancellation
                        request={request}
                        handleOldRequestCancellation={handleRequestUpdate}
                      />
                    ) : (
                      <HolidayRequestApprobation
                        request={request}
                        year={year}
                        handleRequestApprobationUpdate={handleRequestApprobationUpdate}
                        setApprovedRowId={setApprovedRowId}
                        isTT={false}
                      />
                    )}
                  </div>
                </TableCell>
              ) : (
                request.justificationFilename === undefined && (
                  <TableCell>
                    <PrimaryButton
                      handleClick={(): void => {
                        void updateHolidayRequest(request)
                      }}
                      title="Régulariser"
                      background
                      reverse={true}
                      disabled={false}
                    />
                  </TableCell>
                )
              )}
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}

export default HolidaysRequestsBodyTable
