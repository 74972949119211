import CustomAutoComplete from 'components/inputs/autoCompleteInput/AutoCompleteClientInput'
import TwoChoicesButton from 'components/inputs/button/TwoChoicesButton'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ChangeEvent, ReactElement } from 'react'
import { IProject } from 'interfaces/projects'
import { IClient } from 'interfaces/clients'
import { TextField } from '@mui/material'

export interface ReportingAddOptionsProps {
  isBillable: boolean
  setIsBillable: React.Dispatch<React.SetStateAction<boolean>>
  selectedClient: IClient | undefined
  selectedProject: IProject | undefined
  clientOptions: string[]
  projectOptions: string[]
  handleAddClick: () => void
  description: string | undefined
  handleChangeClient: (value: string | null) => Promise<void>
  handleChangeProject: (value: string) => void
  handleChangeDescription: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  isSmallScreen: boolean
}

const ReportingAddOptions = ({
  isBillable,
  setIsBillable,
  selectedClient,
  selectedProject,
  clientOptions,
  projectOptions,
  handleAddClick,
  description,
  handleChangeClient,
  handleChangeDescription,
  handleChangeProject,
  isSmallScreen
}: ReportingAddOptionsProps): ReactElement => {
  return (
    <>
      <div className="lg:mr-3 pl-2 py-3">
        <TwoChoicesButton
          title1="Facturable"
          title2="Non facturable"
          onChange={setIsBillable}
          defaultValue={isBillable}
        />
      </div>
      <div className={` ${isSmallScreen ? 'flex-none space-y-3' : 'flex space-x-2 pl-2 pb-1'}`}>
        <CustomAutoComplete
          value={selectedClient?.name ?? null}
          onChange={(e: any, newValue: string | null): void => {
            void handleChangeClient(newValue)
          }}
          id="client"
          options={clientOptions}
          setOptions={(newValue: string | null): void => {
            void handleChangeClient(newValue)
          }}
          width={!isSmallScreen ? 260 : undefined}
          placeholder="Client"
        />
        <CustomAutoComplete
          value={selectedProject?.name ?? null}
          onChange={(e: any, newValue: string | null): void => {
            handleChangeProject(newValue ?? '')
          }}
          id="projet"
          options={projectOptions}
          setOptions={(newValue: string | null): void => {
            handleChangeProject(newValue ?? '')
          }}
          width={!isSmallScreen ? 260 : undefined}
          placeholder="Projet"
        />
        <TextField
          onChange={(value) => {
            if (value !== undefined) {
              handleChangeDescription(value)
            }
          }}
          value={description ?? ''}
          label={isSmallScreen ? 'Ajouter une description...' : 'Description'}
          className="pb-1"
          multiline={isSmallScreen}
          sx={{ width: 260 }}
        />
        <div className="pt-1 md:pt-0">
          <PrimaryButton
            title="Ajouter"
            handleClick={handleAddClick}
            background
            reverse={false}
            disabled={false}
          />
        </div>
      </div>
    </>
  )
}

export default ReportingAddOptions
