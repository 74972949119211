import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { updateSteamDejStatus } from 'services/SteamDejService'
import { ISteamDejGroup } from 'interfaces/steamDejGroup'
import { steamdejStatusEnum } from 'constantes'
import React, { ReactElement } from 'react'

interface ISteamDejCard {
  steamdejGroups: ISteamDejGroup[]
  statusModified: boolean
  setStatusModified: React.Dispatch<React.SetStateAction<boolean>>
  handleChangeGroupStatus: (updatedGroup: ISteamDejGroup) => void
}

const SteamDejCard = ({
  steamdejGroups,
  statusModified,
  setStatusModified,
  handleChangeGroupStatus
}: ISteamDejCard): ReactElement => {
  const handleClick = async (group: ISteamDejGroup, status: string): Promise<void> => {
    const response = await updateSteamDejStatus(group.id, { status })
    setStatusModified(!statusModified)
    if (response !== null) {
      handleChangeGroupStatus(group)
    }
  }

  return (
    <div className="steamdej-body">
      {steamdejGroups.map((group, index) => {
        const gradient = group.status === steamdejStatusEnum.DONE
        return (
          <div className={`box-steamdej-container ${gradient ? 'gradient' : ''}`} key={group.id}>
            <p className="box-steamdej-title">
              La table de <span className="firstName">{group.user1?.displayFirstname}</span>
              <span className="lastName"> {group.user1?.displayLastname}</span>
            </p>
            <div className="card-group-body">
              {[group.user2, group.user3, group.user4, group.user5, group.user6]
                .filter((user) => user !== undefined)
                .map((user) => {
                  return (
                    <p className="group-collab-name" key={user?.id}>
                      {user?.displayFirstname}{' '}
                      <span className="lastName">{user?.displayLastname}</span>
                    </p>
                  )
                })}
            </div>
            <PrimaryButton
              handleClick={() => {
                if (index === 0) {
                  void (async () => {
                    await handleClick(
                      group,
                      gradient ? steamdejStatusEnum.TODO : steamdejStatusEnum.DONE
                    )
                  })()
                }
              }}
              title="MIAM !"
              background
              reverse={!gradient}
              disabled={false}
              classNameTitle={gradient ? '' : 'gradient'}
              image="&nbsp;🍔"
            />
          </div>
        )
      })}
    </div>
  )
}

export default SteamDejCard
