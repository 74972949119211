import { IheadCells } from 'interfaces/headCells'

interface ITableSynthesisColumns {
  currentTab: number
  page: string
}

export const tableSynthesisColumns = ({
  currentTab,
  page
}: ITableSynthesisColumns): IheadCells[] => [
  {
    id: 'googlePicture',
    label: '',
    priority: 4,
    isSortable: false
  },
  {
    id: 'requestingUser',
    label: 'Nom',
    priority: 1,
    isSortable: false
  },
  {
    id: 'rights',
    label: 'Droits',
    priority: 4,
    isSortable: false
  },
  {
    id: 'taken',
    label: 'Pris',
    priority: 1,
    isSortable: false
  },
  {
    id: 'rest',
    label: 'Reste',
    priority: 1,
    isSortable: false
  },
  {
    id: 'restYTD',
    label: 'Reste YTD',
    priority: 1,
    isSortable: false
  },
  {
    id: 'actual_months',
    label: 'Mois en cours',
    priority: 2,
    isSortable: false
  }
]
