import CustomNumberTextField from 'components/inputs/customNumberTextField/CustomNumberTextField'
import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import CustomGroupRadios from 'components/inputs/customGroupRadios/CustomGroupRadios'
import CustomDatePicker from 'components/inputs/customDatePicker/CustomDatePicker'
import { IExpensesSubtype, IExpensesType, IMyExpense } from 'interfaces/expenses'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import { IUsersHolidayQuota } from 'interfaces/holidays/userHolidayQuota'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import ImageUploader from 'components/inputs/uploader/ImageUploader'
import { useRetrieveUsers } from 'customHooks/retrieveMatchingUsers'
import SubFormHeader from 'components/subFormHeader/SubFormHeader'
import React, { ReactElement, useEffect, useState } from 'react'
import Expenses from '../../profil/dataInfos/expenses/Expenses'
import { IUpdateUserData } from 'interfaces/collaborateurData'
import { featuresList, mealPlanConstant } from 'constantes'
import Permission from 'components/permission/Permission'
import { IListSelectorValue } from 'interfaces/selectors'
import { removeAccents } from 'utils/otherUtils'
import { IErrors } from 'interfaces/formFields'
import { setValidDate } from 'utils/dateUtils'
import { InputLabel } from '@mui/material'
import { Dayjs } from 'dayjs'

interface ICreationFormPartOne {
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  jobs: IListSelectorValue[]
  contractTypes: IListSelectorValue[]
  places: IListSelectorValue[]
  roles: IListSelectorValue[]
  expenseTypes: IExpensesType[]
  expenseSubtypes: IExpensesSubtype[]
  errors: IErrors
  selectedImg: string | null
  setSelectedImg: React.Dispatch<React.SetStateAction<string | null>>
  userHolidaysQuota: IUsersHolidayQuota | null
  setUserHolidaysQuota: React.Dispatch<React.SetStateAction<IUsersHolidayQuota | null>>
  userExpenseSettings: IMyExpense[]
  setUserExpenseSettings: React.Dispatch<React.SetStateAction<IMyExpense[]>>
}

/**
 * Gestion de la validation de l'IBAN
 *
 * @param iban
 * @returns
 */
const validateIBAN = (iban: string): boolean => {
  const ibanRegex =
    /^(?:FR[0-9]{2}(?:[ -]?[0-9A-Z]{4}){5}[ -]?[0-9A-Z]{3}|(?!FR)([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?)$/
  return iban === '' || ibanRegex.test(iban)
}

const CreationFormPartOne = ({
  userDataToSend,
  setUserDataToSend,
  errors,
  jobs,
  contractTypes,
  places,
  roles,
  expenseTypes,
  expenseSubtypes,
  selectedImg,
  setSelectedImg,
  userHolidaysQuota,
  setUserHolidaysQuota,
  userExpenseSettings,
  setUserExpenseSettings
}: ICreationFormPartOne): ReactElement => {
  const [tutors, retrieveTutors] = useRetrieveUsers()
  const [ibanError, setIbanError] = useState<string>('')

  useEffect(() => {
    const defaultSettings: IMyExpense[] = []
    expenseTypes.forEach((data: IExpensesType) => {
      defaultSettings.push({
        id: null,
        isActiveExpense: false,
        expenseType: data,
        expenseSubtype: null,
        amount: 0
      })
    })
    setUserExpenseSettings(defaultSettings)
  }, [])

  const handleChangeDate = (newValue: Dayjs | null, propertyToUpdate: string): void => {
    setUserDataToSend({
      ...userDataToSend,
      [propertyToUpdate]: newValue !== null ? setValidDate(newValue) : null
    })
  }

  const handleQuotaChange = (quota: number, quotaType: string): void => {
    setUserHolidaysQuota((prevQuota) => {
      if (prevQuota !== null) {
        return {
          ...prevQuota,
          [quotaType]: quota
        }
      }
      return prevQuota
    })
  }

  /**
   * Gestion de l'IBAN
   *
   * @param value
   */
  const handleIBANChange = (value: string): void => {
    setUserDataToSend({ ...userDataToSend, IBAN: value })
    setIbanError(validateIBAN(value) ? '' : 'Format IBAN invalide')
  }

  return (
    <div className="form">
      <div className="left-content">
        <SubFormHeader title="Informations personnelles" />
        <div className="sub-form">
          <CustomTextField
            onChange={(value) => {
              setUserDataToSend({ ...userDataToSend, firstname: value })
            }}
            id="firstname"
            value={userDataToSend.firstname}
            errorMessage={errors.firstname?.message}
            required
            label="Prénom"
          />

          <CustomTextField
            onChange={(value) => {
              setUserDataToSend({ ...userDataToSend, lastname: value })
            }}
            id="lastname"
            value={userDataToSend.lastname}
            errorMessage={errors.lastname?.message}
            required
            label="Nom"
          />

          <CustomDatePicker
            handleChangeDate={(e, property) => {
              handleChangeDate(e, property)
            }}
            className="w-60"
            id="birthdayDate"
            value={userDataToSend.birthdayDate}
            errorMessage=""
            required={false}
            label="Date de naissance"
          />

          <CustomTextField
            onChange={(value) => {
              setUserDataToSend({ ...userDataToSend, phoneNumber: value })
            }}
            id="phoneNumber"
            value={userDataToSend.phoneNumber}
            errorMessage={errors.phoneNumber?.message}
            required={false}
            label="Numéro de téléphone"
          />

          <Permission name={featuresList.NDF}>
            <>
              <CustomTextField
                onChange={(value) => {
                  setUserDataToSend({ ...userDataToSend, initials: value })
                }}
                id="initials"
                value={userDataToSend.initials}
                errorMessage=""
                required={false}
                label="Initiales"
              />
              <CustomTextField
                onChange={(value) => {
                  setUserDataToSend({ ...userDataToSend, BIC: value })
                }}
                id="BIC"
                value={userDataToSend.BIC}
                errorMessage={errors.BIC?.message}
                required={false}
                label="BIC"
              />

              <CustomTextField
                onChange={handleIBANChange}
                id="IBAN"
                value={userDataToSend.IBAN}
                errorMessage={ibanError}
                required={false}
                label="IBAN"
              />
            </>
          </Permission>

          <InputLabel className="porfilImg-label" id="porfilImg">
            Image de profil
          </InputLabel>
          <ImageUploader selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
        </div>
        <div className="margin-top"></div>
        <SubFormHeader title="Informations sur le poste" />
        <div className="sub-form">
          <CustomSelect
            value={userDataToSend.job}
            options={jobs}
            handleSelect={(newValue) => {
              setUserDataToSend({ ...userDataToSend, job: newValue as number })
            }}
            id="job"
            inputLabel="Poste"
            required
          />
          <p className="form-error"></p>

          <CustomSelect
            value={userDataToSend.contractType}
            options={contractTypes}
            handleSelect={(newValue) => {
              setUserDataToSend({ ...userDataToSend, contractType: newValue as number })
            }}
            id="contractType"
            inputLabel="Type de contrat"
            required
          />
          <p className="form-error"></p>

          <InputLabel id="tutor">Tuteur</InputLabel>
          <AutoCompleteUserInput
            value={userDataToSend.tutor}
            onChange={(e, newValue): void => {
              setUserDataToSend({
                ...userDataToSend,
                tutor: newValue
              })
            }}
            id="tutor"
            options={tutors}
            setOptions={(inputValue) => {
              void (async () => {
                await retrieveTutors(inputValue)
              })()
            }}
          />
        </div>

        <Permission name={featuresList.HOLIDAY}>
          <>
            <div className="margin-top"></div>
            <SubFormHeader title="Information congés" />
            <CustomNumberTextField
              onChange={(value) => {
                handleQuotaChange(Number(value), 'paidVacationQuota')
              }}
              id="paidVacationQuota"
              value={userHolidaysQuota?.paidVacationQuota.toString() ?? '0'}
              errorMessage=""
              required={false}
              label="Nombre congés payés"
              text="ex: 4,5"
            />
            <CustomNumberTextField
              onChange={(value) => {
                handleQuotaChange(Number(value), 'RTTQuota')
              }}
              id="RTTQuota"
              value={userHolidaysQuota?.RTTQuota.toString() ?? '0'}
              errorMessage=""
              required={false}
              label="Nombre RTT"
              text="ex: 4,5"
            />
          </>
        </Permission>
      </div>
      <div className="right-content">
        <SubFormHeader title="Informations pratiques" />

        <CustomSelect
          value={userDataToSend.rolePermissions}
          options={roles}
          handleSelect={(newValue) => {
            setUserDataToSend({ ...userDataToSend, rolePermissions: newValue })
          }}
          id="role"
          inputLabel="Role"
          required
          multiple={true}
        />
        <p className="form-error"></p>

        <CustomSelect
          value={userDataToSend.place}
          options={places}
          handleSelect={(newValue) => {
            setUserDataToSend({ ...userDataToSend, place: newValue as number })
          }}
          id="place"
          inputLabel="Lieu"
          required
        />
        <p className="form-error"></p>

        <CustomDatePicker
          handleChangeDate={(e, property) => {
            handleChangeDate(e, property)
          }}
          id="arrivalDate"
          value={userDataToSend.arrivalDate}
          errorMessage={errors.arrivalDate?.message}
          required={true}
          label="Date d'entrée"
        />

        <CustomDatePicker
          handleChangeDate={(e, property) => {
            handleChangeDate(e, property)
          }}
          id="internshipStartDate"
          value={userDataToSend.internshipStartDate}
          errorMessage={errors.internshipStartDate?.message}
          required={true}
          label="Date de début stage"
        />

        <CustomDatePicker
          handleChangeDate={(e, property) => {
            handleChangeDate(e, property)
          }}
          id="leavingDate"
          value={userDataToSend.leavingDate}
          errorMessage=""
          required={false}
          label="Date de sortie"
        />

        {userDataToSend.contractType === 1 ? (
          <CustomDatePicker
            handleChangeDate={(e, property) => {
              handleChangeDate(e, property)
            }}
            id="endTrialPeriodDate"
            value={userDataToSend.endTrialPeriodDate}
            errorMessage=""
            required={false}
            label="Date de fin de stage"
          />
        ) : userDataToSend.contractType === 3 ? (
          <CustomDatePicker
            handleChangeDate={(e, property) => {
              handleChangeDate(e, property)
            }}
            id="endInternshipPeriodDate"
            value={userDataToSend.endInternshipPeriodDate}
            errorMessage=""
            required={false}
            label="Date de fin de la période d'essai"
          />
        ) : (
          <></>
        )}

        <CustomNumberTextField
          onChange={(value) => {
            setUserDataToSend({ ...userDataToSend, badgeId: value })
          }}
          id="badgeId"
          value={userDataToSend.badgeId}
          errorMessage=""
          required={false}
          label="Badge ID"
        />

        <CustomTextField
          onChange={(value) => {
            const result = removeAccents(value)
            setUserDataToSend({ ...userDataToSend, personalMail: result })
          }}
          id="personalMail"
          value={userDataToSend.personalMail}
          errorMessage={errors.personalMail?.message}
          required
          label="Mail personnel"
        />
        <CustomGroupRadios
          onChange={(value) => {
            if (value === 'Aucun') {
              setUserDataToSend({ ...userDataToSend, mealPlan: null })
            } else {
              setUserDataToSend({ ...userDataToSend, mealPlan: value })
            }
          }}
          id="mealPlan"
          gpRadiovalue={userDataToSend.mealPlan}
          required={false}
          radiosLabelValue={[mealPlanConstant.RESTAU_TICKETS, mealPlanConstant.IFR, 'Aucun']}
        />

        <Permission name={featuresList.NDF}>
          <>
            <div className="margin-top"></div>
            <SubFormHeader title="Notes de frais" />
            <Expenses
              editMode
              user={undefined}
              displayTitle={false}
              userExpenses={userExpenseSettings}
              setUserExpenses={setUserExpenseSettings}
            />
            <div className="margin-top"></div>
          </>
        </Permission>
      </div>
    </div>
  )
}

export default CreationFormPartOne
