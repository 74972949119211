import {
  getExpensesSubtypes,
  getMyBills,
  getMyExpenses,
  getStatusExpenses
} from 'services/ExpensesService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { expenseStatusContant, monthsList, screenSizes } from 'constantes'
import { IBill, IExpense, IExpensesSubtype } from 'interfaces/expenses'
import { CircularProgress, Dialog, TableBody } from '@mui/material'
import { CurrentUserContext, YearSelectedContext } from 'App'
import useScreenSize from '../../customHooks/useScreenSize'
import MyExpensesTicket from './MyExpensesTicket'
import ExpenseRow from '../expenses/ExpenseRow'
import moment from 'moment'

const MyExpenses = (): ReactElement => {
  const isSmallScreen = useScreenSize(screenSizes.TABLET_SIZE)
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = Number(yearSelectedContext?.yearSelected) ?? moment().year()
  const userContext = useContext(CurrentUserContext)
  const userId = userContext?.currentUser?.id
  const [myExpenses, setMyExpenses] = useState<IExpense[]>([])
  const [myBills, setMyBills] = useState<IBill[]>([])
  const [myExpense, setMyExpense] = useState<IExpense | null>(null)
  const [expensesSubtypes, setExpensesSubtypes] = useState<IExpensesSubtype[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [showTicketList, setShowTicketList] = useState<boolean[]>(new Array(13).fill(false))
  const [expensesOfTheYear, setExpensesOfTheYear] = useState<IExpense[]>([])

  useEffect(() => {
    setLoading(true)
    setShowTicketList(new Array(13).fill(false))
    void (async () => {
      if (userId !== undefined) {
        const response = await getMyExpenses(year, userId)
        const bills = await getMyBills(userId, year)
        if (response !== null) {
          setMyExpenses(response)
        }
        if (bills !== null) {
          setMyBills(bills)
        }

        const expensesYear: IExpense[] = []
        for (const month of monthsList) {
          const expenseMonth = await getMyExpenseByMonth(response ?? [], month)
          expensesYear.push(expenseMonth)
        }

        setExpensesOfTheYear(expensesYear)
      }
      setLoading(false)
    })()
  }, [year])

  useEffect(() => {
    void (async () => {
      setExpensesSubtypes(await getExpensesSubtypes())
    })()
  }, [])

  const sumAmounts = myExpenses
    .reduce((accumulator, myExpense) => accumulator + myExpense.totalAmount, 0)
    .toFixed(2)

  const handleChangeAmount = (expense: IExpense): void => {
    const updatedExpenses = [...myExpenses]
    const indexToUpdate = updatedExpenses.findIndex((item: IExpense) => item.id === expense.id)

    if (indexToUpdate !== -1) {
      updatedExpenses[indexToUpdate] = expense
    } else {
      updatedExpenses.push(expense)
    }
    setMyExpenses((expenses) => updatedExpenses)
    setMyExpense(expense)

    const updatedExpensesOfYear = [...expensesOfTheYear]
    const indexToUpdateYear = updatedExpensesOfYear.findIndex(
      (item: IExpense) => item.month === expense.month
    )

    if (indexToUpdateYear !== -1) {
      updatedExpensesOfYear[indexToUpdateYear] = expense
      setExpensesOfTheYear((expenses) => updatedExpensesOfYear)
    }
  }

  const getMyExpenseByMonth = async (
    userExpenses: IExpense[],
    month: string
  ): Promise<IExpense> => {
    const indexMonth = Number(monthsList.indexOf(month)) + 1
    const expensesOfMonth = [...userExpenses].filter((myExpense) => myExpense.month === indexMonth)

    if (expensesOfMonth?.length > 0) {
      return expensesOfMonth[0]
    }

    let status = expenseStatusContant.NON_RENSEIGNE
    if (year === moment().year() && indexMonth >= moment().month()) {
      status = expenseStatusContant.A_SAISIR
    } else {
      const currentMonthStatus = await getStatusExpenses(year, indexMonth)
      status =
        currentMonthStatus === expenseStatusContant.A_SAISIR
          ? expenseStatusContant.A_SAISIR
          : expenseStatusContant.NON_RENSEIGNE
    }

    return {
      user: userContext?.currentUser ?? null,
      totalAmount: 0,
      status,
      year,
      month: indexMonth
    }
  }

  return (
    <div className="ml-3">
      <div className="myexpenses-title">
        <h3>
          Mes <span className="accentuated-title">notes de frais</span>
        </h3>
        {loading ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          <div className="myexpenses-body">
            <div className="myexpenses-table">
              <TableBody>
                {expensesOfTheYear.length > 0 &&
                  monthsList.map((month, index) => {
                    const expenseOfMonth: IExpense | undefined = expensesOfTheYear.find(
                      (item: IExpense) => item.month === index + 1
                    )
                    return (
                      expenseOfMonth !== undefined && (
                        <ExpenseRow
                          key={index}
                          showTicket={showTicketList[expenseOfMonth.month]}
                          expense={expenseOfMonth}
                          isAdmin={false}
                          month={month}
                          handleRowClick={() => {
                            if (expenseOfMonth !== undefined) {
                              setMyExpense(expenseOfMonth)
                              if (expenseOfMonth.status !== expenseStatusContant.NON_RENSEIGNE) {
                                setShowTicketList(
                                  showTicketList.map((ticket, index) => {
                                    return index === expenseOfMonth.month ? !ticket : false
                                  })
                                )
                              }
                            }
                          }}
                        />
                      )
                    )
                  })}
              </TableBody>
              <div className="myexpenses-total">
                Total annuel : <span>{sumAmounts}€</span>
              </div>
            </div>
            {!isSmallScreen && myExpense !== null && showTicketList[myExpense?.month] ? (
              <MyExpensesTicket
                myBills={myBills}
                setMyBills={setMyBills}
                myExpense={myExpense}
                setMyExpense={(expense: IExpense) => {
                  handleChangeAmount(expense)
                }}
                expensesSubtypes={expensesSubtypes}
                userId={userId}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={isSmallScreen && myExpense !== null && showTicketList[myExpense?.month]}
        onClose={(): void => {
          if (myExpense != null) {
            setShowTicketList(
              showTicketList.map((ticket, index) => {
                return index === myExpense?.month ? !ticket : false
              })
            )
          }
        }}
        aria-labelledby="save-dialog"
        aria-describedby="save-dialog-description"
        className="popUp popUp-expenses myexpenses-body"
      >
        <MyExpensesTicket
          myBills={myBills}
          setMyBills={setMyBills}
          myExpense={myExpense}
          setMyExpense={(expense: IExpense) => {
            handleChangeAmount(expense)
          }}
          expensesSubtypes={expensesSubtypes}
          userId={userId}
          handleClose={(): void => {
            if (myExpense != null) {
              setShowTicketList(
                showTicketList.map((ticket, index) => {
                  return index === myExpense?.month ? !ticket : false
                })
              )
            }
          }}
        />
      </Dialog>
    </div>
  )
}

export default MyExpenses
