import CustomNumberTextField from '../../../components/inputs/customNumberTextField/CustomNumberTextField'
import { IDetailCollaborateurData, IUpdateUserData } from '../../../interfaces/collaborateurData'
import { getIncomes, getInterviews, getLevels } from '../../../services/CareerService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IIncome, IInterview, ILevel } from '../../../interfaces/career'
import { createOrUpdateCollab } from '../../../services/UserService'
import { incomeTypeList, screenSizes } from '../../../constantes'
import Stepper from '../../../components/stepper/Stepper'
import useScreenSize from 'customHooks/useScreenSize'
import TimelineInterview from './TimelineInterview'
import { YearSelectedContext } from '../../../App'
import ChartIncome from './ChartIncome'
import { Switch } from '@mui/material'

interface ICareerInfos {
  editMode: boolean
  user: IDetailCollaborateurData
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
}

const CareerInfos = ({
  editMode,
  user,
  userDataToSend,
  setUserDataToSend
}: ICareerInfos): ReactElement => {
  const yearSelectedContext = useContext(YearSelectedContext)
  const [levels, setLevels] = useState<ILevel[]>([])
  const [incomes, setIncomes] = useState<IIncome[] | undefined>()
  const [salary, setSalary] = useState<number>(0)
  const [interviews, setInterviews] = useState<IInterview[]>([])
  const isSmallScreen = useScreenSize(screenSizes.TABLET_SIZE)

  useEffect(() => {
    void getLevels().then(setLevels)
  }, [])

  useEffect(() => {
    void getIncomes(user.id).then((data) => {
      setIncomes(data)
      setSalary(data.find((income) => income.type === incomeTypeList.SALARY)?.amount ?? 0)
    })
  }, [user.id])

  useEffect(() => {
    void getInterviews(user.id).then(setInterviews)
  }, [])

  const updateUser = async (userToUpdate: IUpdateUserData): Promise<void> => {
    setUserDataToSend(userToUpdate)
    await createOrUpdateCollab(
      userToUpdate,
      Number(yearSelectedContext?.yearSelected),
      false,
      Number(user.id)
    )
  }

  return (
    <>
      <div className="box-data">
        <p className="category-title">Profil confidentiel</p>
        <div className="box-inline-elts">
          <div className="box-column">
            <Switch
              checked={userDataToSend?.isConfidential}
              onChange={(event) => {
                void updateUser({ ...userDataToSend, isConfidential: event.target.checked })
              }}
            />
          </div>
        </div>
      </div>
      <div className="box-data">
        <p className="category-title">Salaire</p>
        <div className="box-inline-elts">
          <div className="box-column">
            {editMode ? (
              <CustomNumberTextField
                onChange={(value) => {
                  setSalary(Number(value))
                  setUserDataToSend({ ...userDataToSend, salary: Number(value) })
                }}
                id="salary"
                value={salary?.toString() ?? '0'}
                label="Arrivée"
                errorMessage=""
                required={false}
              />
            ) : (
              <div>
                <p className="data-title">Arrivée</p>
                <p className="data-value">{salary}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Stepper
        currentStep={
          userDataToSend?.level !== null
            ? levels.findIndex((level) => level.id === userDataToSend?.level?.id)
            : 0
        }
        steps={levels.map((level) => level.label)}
        onStepChange={(index) => {
          void updateUser({ ...userDataToSend, level: levels[index] })
        }}
        isReadonly={false}
      />

      <div className={isSmallScreen ? 'mb-11' : 'timeline-container'}>
        <TimelineInterview user={user} isAdmin={true} interviews={interviews} />
      </div>

      {incomes !== undefined && <ChartIncome incomes={incomes} />}
    </>
  )
}

export default CareerInfos
