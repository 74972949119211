import React, { ReactElement, useContext, useState } from 'react'
import Publication from '../popup/messagePopUp/Publication'
import { MessagesContext } from '../../App'
import Navbar from '../navBar/NavBar'

interface PageLayoutProps {
  fullWidth?: boolean
  hideMenu?: boolean
  children: React.ReactNode
}

// Contenu minimum que doit contenir une page avec la gestion des marges selon l'ouverture et fermeture du menu
const PageLayout = (props: PageLayoutProps): ReactElement => {
  const fullWidth = props.fullWidth ?? false
  const [open, setOpen] = useState(true)
  const messagesContext = useContext(MessagesContext)

  return (
    <div>
      {props.hideMenu !== true && (
        <Navbar
          open={open}
          setOpen={setOpen}
          hasPublication={
            messagesContext?.messages?.length !== undefined && messagesContext?.messages?.length > 0
          }
        />
      )}
      <div
        className={`${
          props.hideMenu !== true
            ? `${open ? 'margin-left-open' : 'margin-left-close'} minPage-container`
            : ''
        }`}
      >
        {messagesContext?.messages?.length !== undefined &&
          messagesContext?.messages?.length > 0 && (
            <Publication
              className={`${!open ? 'close-menu' : ''}`}
              text={messagesContext?.messages?.[0].text ?? ''}
            />
          )}
        {!fullWidth ? (
          <div className="maxWidth-page">{props.children}</div>
        ) : (
          <div className="w-100">{props.children}</div>
        )}
      </div>
    </div>
  )
}

export default PageLayout
