import { IButtonConfig } from 'interfaces/buttonConfig'
import Popover from '@mui/material/Popover'
import React from 'react'

interface IMorePopOver {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  anchorEl: HTMLElement | SVGSVGElement | null
  buttonConfigs: IButtonConfig[]
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | SVGSVGElement | null>>
}

const MorePopOver: React.FC<IMorePopOver> = ({
  open,
  setOpen,
  anchorEl,
  buttonConfigs,
  setAnchorEl
}) => {
  const handlePopoverClose = (): void => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <ul className="text-sm">
        {buttonConfigs.map((config, index) => (
          <li
            key={index}
            className={config.show ? 'hover:bg-gray-200 border-b-2 px-5 py-1' : 'hidden'}
          >
            <button onClick={config.onClick}>{config.label}</button>
          </li>
        ))}
      </ul>
    </Popover>
  )
}

export default MorePopOver
