import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import userDefaultPicture from '../../assets/user.png'
import React, { ReactElement, useState } from 'react'

interface IProfilImage {
  user: IMinimalCollaborateurData | null | undefined
  zoomInAllowed?: boolean
}

const ProfilImage = ({ user, zoomInAllowed = false }: IProfilImage): ReactElement => {
  let urlImage = userDefaultPicture
  if (user?.profilePictureFilename !== undefined) {
    urlImage = '/api/users/' + String(user?.id) + '/profile-pictures'
  }

  const [zoomIn, setZoomIn] = useState<boolean>(false)

  const handleZoomIn = (): void => {
    if (zoomInAllowed) {
      setZoomIn(!zoomIn)
    }
  }

  return (
    <div className="profil-img" onClick={handleZoomIn}>
      <img
        src={urlImage}
        alt="Photo profil"
        className={zoomInAllowed ? `zoom-in-cursor ${zoomIn ? 'zoom-in' : ''}` : ''}
      />
    </div>
  )
}

export default ProfilImage
