import { generateSignedUrlDocument, submitOnBoarding } from '../../services/MyDocumentsService'
import { CurrentUserContext, PlaceSelectedContext, YearSelectedContext } from '../../App'
import { ONE_DAY_DURATION_IN_MINUTES, settingCategoryConstant } from '../../constantes'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { setLocalStorageWithExpiration } from '../../utils/otherUtils'
import { getSelfUserData } from '../../services/UserService'
import { getSettings } from '../../services/SettingService'
import DocumentStep from './steps/DocumentStep'
import { useNavigate } from 'react-router-dom'
import IndexStep from './steps/IndexStep'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import { cloneDeep } from 'lodash'
import './OnBoarding.scss'

const OnBoarding = (): ReactElement => {
  const navigate = useNavigate()
  const [isSubmitting, setSubmitting] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [selectedIdentityCard, setSelectedIdentityCard] = useState<[string | Blob, string]>()
  const [selectedRIB, setSelectedRIB] = useState<[string | Blob, string]>()
  const [selectedVitalCard, setSelectedVitalCard] = useState<[string | Blob, string]>()
  const [selectedTransport, setSelectedTransport] = useState<[string | Blob, string]>()
  const [loadingIframe, setLoadingIframe] = useState<boolean>(false)
  const [errorIframe, setErrorIframe] = useState<boolean>(false)
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser
  const [documents, setDocuments] = useState<
    Array<{ fileId: string; url?: string; envelopId?: string; title?: string }>
  >([])

  useEffect(() => {
    if (currentUser?.needOnBoarding === false) {
      return
    }
    void (async () => {
      if (place !== null) {
        const settings = await getSettings(place)
        const onBoardingFiles = settings.filter(
          (setting) => setting.category === settingCategoryConstant.ON_BOARDING
        )
        setDocuments(
          onBoardingFiles.map((setting) => {
            return {
              fileId: setting.value ?? '',
              title: setting.label ?? ''
            }
          })
        )
      }
    })()
  }, [place])

  const yearSelectedContext = useContext(YearSelectedContext)
  const year = Number(yearSelectedContext?.yearSelected) ?? moment().year()

  useEffect(() => {
    if (activeStep === 0 || activeStep >= documents.length + 1) {
      return
    }
    setLoadingIframe(true)

    generateSignedUrlDocument(documents[activeStep - 1].fileId)
      .then((result: { url: string; envelopId: string }) => {
        const documentCopy = cloneDeep(documents)
        documentCopy[activeStep - 1].url = result.url
        documentCopy[activeStep - 1].envelopId = result.envelopId
        setDocuments(documentCopy)
        setLoadingIframe(false)
      })
      .catch(() => {
        setLoadingIframe(false)
      })
  }, [activeStep])

  const validateForm = (): void => {
    setSubmitting(true)
    submitOnBoarding({
      identityCard: selectedIdentityCard,
      RIB: selectedRIB,
      vitalCard: selectedVitalCard,
      transport: selectedTransport,
      documents
    })
      .then(async () => {
        const user = await getSelfUserData(year)
        userContext?.setCurrentUser(user)
        setLocalStorageWithExpiration('user', JSON.stringify(user), ONE_DAY_DURATION_IN_MINUTES)
        navigate('/planning')
      })
      .catch(() => {
        toast.error('Une erreur est survenue lors de la soumission des documents')
      })
  }

  return (
    <div className="onboarding">
      <div className="header">
        <h3>
          <span className="accentuated-title big-title">Collabs</span> de Steamulo
        </h3>
        <p>Merci de suivre les étapes suivantes pour compléter votre profil.</p>
      </div>
      <IndexStep
        validate={() => {
          validateForm()
        }}
        fileTitle={documents.map((document) => document.title)}
        showNextButton={activeStep === 0 || activeStep === documents.length + 1}
        maxSteps={documents.length + 1}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isSubmitting={isSubmitting}
      >
        {activeStep === 0 ? (
          <DocumentStep
            selectedIdentityCard={selectedIdentityCard}
            setSelectedIdentityCard={setSelectedIdentityCard}
            selectedRIB={selectedRIB}
            setSelectedRIB={setSelectedRIB}
            selectedVitalCard={selectedVitalCard}
            setSelectedVitalCard={setSelectedVitalCard}
            selectedTransport={selectedTransport}
            setSelectedTransport={setSelectedTransport}
          />
        ) : (
          <div className="m-5">
            {errorIframe && (
              <p className="text-center">
                La signature des documents est obligatoire. Veuillez recharger la page.
              </p>
            )}
            {loadingIframe && <p className="text-center">Génération du document en cours...</p>}
            {!loadingIframe && !errorIframe && documents[activeStep - 1]?.url !== null && (
              <iframe
                width="100%"
                height={900}
                src={documents[activeStep - 1]?.url}
                onLoad={(e) => {
                  const queryParameters = new URLSearchParams(
                    // @ts-expect-error
                    e.target.contentWindow.location.search
                  )

                  if (queryParameters.get('event') === 'signing_complete') {
                    setActiveStep(activeStep + 1)
                  } else {
                    setErrorIframe(true)
                  }
                }}
              ></iframe>
            )}
          </div>
        )}
      </IndexStep>
    </div>
  )
}
export default OnBoarding
