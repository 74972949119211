import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import DocumentUploader from 'components/inputs/uploader/DocumentUploader'
import { IDetailHolidayRequest } from 'interfaces/holidays/holidayRequest'
import { saveJustificationFile } from 'services/JustificationPdfService'
import { updateHolidayRequests } from 'services/HolidayService'
import FilePopover from 'pages/myHolidays/pdfModal/PdfModal'
import { UploadedFile } from 'interfaces/uploadedFile'
import { InsertDriveFile } from '@mui/icons-material'
import React, { ReactElement, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { statusEnum } from 'constantes'
import moment from 'moment'

interface IMyRequest {
  holidayRequest: IDetailHolidayRequest
  setMyHolidayRequests: React.Dispatch<React.SetStateAction<IDetailHolidayRequest[]>>
  statusIcons: Array<{
    label: string
    icon: JSX.Element
  }>
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>
}

const MyRequest = ({
  holidayRequest,
  setMyHolidayRequests,
  statusIcons,
  setReloadData
}: IMyRequest): ReactElement => {
  const [openCancelRequestPopUp, setOpenCancelRequestPopUp] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const openFileModal = Boolean(anchorEl)

  const [loading, setLoading] = useState<boolean>(false)
  const [selectedFile, setselectedFile] = useState<UploadedFile | null>(null)

  const getStatusIcon = (statusLabel: string): any => {
    const statusIcon = statusIcons.find((status) => status.label === statusLabel)?.icon
    if (statusIcon !== undefined) {
      return statusIcon
    }
    return <></>
  }

  const handleCancelRequest = async (): Promise<void> => {
    setLoading(true)
    holidayRequest.status = statusEnum.CANCELED
    const response = await updateHolidayRequests(holidayRequest)
    if (response !== null) {
      setMyHolidayRequests((prev) =>
        prev.map((request) => {
          if (request.id === response.id) {
            return response // Remplace l'élément par la nouvelle requête modifiée
          }
          return request // Garde les autres éléments inchangés
        })
      )
    }
    setLoading(false)
    setOpenCancelRequestPopUp(false)
    setReloadData(true)
  }

  const showTrashToCancelRequest = (): boolean => {
    return (
      moment(holidayRequest.firstDay).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
      (holidayRequest.status === statusEnum.ACCEPTED ||
        holidayRequest.status === statusEnum.TO_VALIDATE)
    )
  }

  const handleRequestClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const saveFile = async (file: UploadedFile | null): Promise<void> => {
    if (file !== null) {
      const response = await saveJustificationFile(holidayRequest?.id, file)
      if (response !== null) {
        await handleFileUpdate(response, file)
      }
    }
  }

  const handleFileUpdate = async (
    fileSaveResponse: IDetailHolidayRequest,
    file: UploadedFile | null
  ): Promise<void> => {
    setselectedFile(file)
    setMyHolidayRequests((prev) =>
      prev.map((request) => {
        if (request.id === fileSaveResponse.id) {
          return fileSaveResponse // Remplace l'élément par la nouvelle requête modifiée
        }
        return request // Garde les autres éléments inchangés
      })
    )
  }

  return (
    <div className="my-request">
      <div className="holiday-type">{holidayRequest.holidayType.holidayType} </div>
      {holidayRequest.workingDays === 0.5 ? (
        <>
          <div className="date-box">
            Le
            <div className="box-border">
              {moment(holidayRequest.firstDay).format('DD/MM/YYYY')}{' '}
            </div>
          </div>
          <div className="date-box">{holidayRequest.duration}</div>
        </>
      ) : (
        <>
          <div className="date-box">
            Du
            <div className="box-border">
              {moment(holidayRequest.firstDay).format('DD/MM/YYYY')}{' '}
            </div>
          </div>
          <div className="date-box">
            Au
            <div className="box-border">{moment(holidayRequest.lastDay).format('DD/MM/YYYY')} </div>
          </div>
        </>
      )}
      <div className="justificatif">
        {holidayRequest.justificationFilename !== undefined ? (
          <IconButton
            onClick={(e) => {
              handleRequestClick(e)
            }}
          >
            <InsertDriveFile id="file-icon"></InsertDriveFile>
          </IconButton>
        ) : (
          <>
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <DocumentUploader
                selectedFile={selectedFile}
                uploadFile={(file) => {
                  void saveFile(file)
                }}
                minimalDesign
                edit={false}
              />
            </div>
          </>
        )}
      </div>
      <div className="d-flex-align-center">
        <p className="days_count">Nb de jours </p>
        <div className="box-border">{holidayRequest.workingDays} J</div>
        <div className="icons-with-trash">
          {getStatusIcon(holidayRequest.status)}
          {showTrashToCancelRequest() && (
            <DeleteIcon
              id="delete-icon"
              onClick={() => {
                setOpenCancelRequestPopUp(true)
              }}
            ></DeleteIcon>
          )}
        </div>
      </div>
      <FilePopover
        open={openFileModal}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        holidayRequest={holidayRequest}
        handleFileUpdate={handleFileUpdate}
      />
      <ConfirmationPopUp
        dialogTitle="Voulez-vous annuler cette demande de congés ?"
        dialogContentText="Votre demande sera annulée et votre planning sera automatiquement mis à jour"
        open={openCancelRequestPopUp}
        setOpen={setOpenCancelRequestPopUp}
        handleSave={handleCancelRequest}
        loading={loading}
      />
    </div>
  )
}

export default MyRequest
