import {
  deleteJustificationFile,
  openJustificationFile,
  saveJustificationFile
} from 'services/JustificationPdfExpenseService'
import {
  Close,
  FileDownload,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LockOpen
} from '@mui/icons-material'
import {
  expenseStatusContant,
  expensesSubtypeList,
  expensesTypeConstant,
  screenSizes
} from 'constantes'
import {
  createorUpdateExpenseBill,
  deleteBill,
  updateExpenseStatus
} from 'services/ExpensesService'
import { IBill, IExpense, IExpenseBill, IExpensesSubtype, IExpensesType } from 'interfaces/expenses'
import CustomNumberTextField from 'components/inputs/customNumberTextField/CustomNumberTextField'
import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import DocumentUploader from 'components/inputs/uploader/DocumentUploader'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import useScreenSize from '../../customHooks/useScreenSize'
import IconButton from '@mui/material/IconButton'
import { PdfFile } from 'interfaces/pfdFile'
import fileDownload from 'js-file-download'
import { YearSelectedContext } from 'App'
import { Collapse } from '@mui/material'
import moment from 'moment'

interface INewExpenseTicket {
  myBills: IBill[]
  setMyBills: (data: IBill[]) => void
  myExpense: IExpense | null
  setMyExpense: (data: IExpense) => void
  expensesSubtypes: IExpensesSubtype[]
  userId?: number | undefined
  isAdmin: boolean
  handleClose?: (() => void) | undefined
}

interface ErrorFormTicket {
  selectedPdf?: string | null
  amount?: string | null
  expenseType?: string | null
  expenseSubtype?: string | null
  description?: string | null
}

const NewExpenseTicket = ({
  myBills,
  setMyBills,
  myExpense,
  setMyExpense,
  expensesSubtypes,
  userId,
  isAdmin,
  handleClose
}: INewExpenseTicket): ReactElement => {
  const isSmallScreen = useScreenSize(screenSizes.TABLET_SIZE)
  const yearSelectedContext = useContext(YearSelectedContext)
  const year = Number(yearSelectedContext?.yearSelected) ?? moment().year()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedPdf, setSelectedPdf] = useState<PdfFile | null>(null)
  const [errorMessage, setErrorMessage] = useState<ErrorFormTicket | null>(null)
  const [bill, setBill] = useState<IExpenseBill>({
    // eslint-disable-next-line
    expenseType: { id: 1 } as IExpensesType,
    year: myExpense?.year ?? 0,
    month: myExpense?.month ?? 0,
    expenseSubtype: null,
    description: '',
    amount: null
  })
  const [openStatusExpensePopUp, setOpenStatusExpensePopUp] = useState<boolean>(false)
  const [billsToDisplay, setBillsToDisplay] = useState<IBill[]>([])
  const [showDetails, setShowDetails] = useState<boolean>(!isSmallScreen)

  let increment = 0

  useEffect(() => {
    setBill({
      // eslint-disable-next-line
      expenseType: { id: -1 } as IExpensesType,
      year: myExpense?.year ?? 0,
      month: myExpense?.month ?? 0,
      expenseSubtype: null,
      description: '',
      amount: null
    })
    setSelectedPdf(null)
  }, [myExpense])

  useEffect(() => {
    setErrorMessage(null)
    setSelectedPdf(null)
  }, [myExpense?.id])

  useEffect(() => {
    if (myExpense !== null) {
      setBillsToDisplay(
        myBills.filter((myBill) => {
          if (myExpense.id !== undefined) {
            return myBill.expense.id === myExpense?.id
          }

          return myBill.expense.month === myExpense?.month
        })
      )
    }
  }, [myBills, myExpense])

  const controlData = (): boolean => {
    let hasError = false
    let errorMessage: ErrorFormTicket | null = null

    if (selectedPdf === null) {
      errorMessage = {
        selectedPdf: 'Le justificatif est obligatoire'
      }
      hasError = true
    }

    if (bill.amount === null) {
      errorMessage = {
        ...errorMessage,
        amount: 'Le montant est obligatoire'
      }
      hasError = true
    }

    const regex = /^\d+(\.\d{1,2})?$/

    if (bill.amount && (isNaN(parseFloat(bill.amount)) || !regex.test(bill.amount))) {
      errorMessage = {
        ...errorMessage,
        amount: 'Le montant est incorrect'
      }
      hasError = true
    }

    if (bill.expenseType?.id === null || bill.expenseType?.id === -1) {
      errorMessage = {
        ...errorMessage,
        expenseType: 'La nature de la note de frais est obligatoire'
      }
      hasError = true
    }

    if (bill.expenseType?.label === 'Autres') {
      if (bill.expenseSubtype === null) {
        errorMessage = {
          ...errorMessage,
          expenseSubtype: 'La catégorie est obligatoire'
        }
        hasError = true
      }
      if (bill.description === '') {
        errorMessage = {
          ...errorMessage,
          description: 'La description est obligatoire'
        }
        hasError = true
      }
    }

    setErrorMessage(errorMessage)
    return hasError
  }

  const reloadData = (idBill: number | undefined, updatedBill: IBill): void => {
    let newBills = [...myBills]
    if (idBill === undefined) {
      newBills.push({ ...updatedBill, document: 'true' })
    } else {
      newBills = newBills.map((item: IBill) => {
        if (item.id === idBill) {
          return { ...updatedBill, document: 'true' }
        } else {
          return item
        }
      })
    }
    setMyBills(newBills)
    setSelectedPdf(null)

    if (myExpense !== null) {
      const totalAmount = newBills
        .filter((myBill) => {
          if (myExpense?.id !== undefined) {
            return myBill.expense.id === myExpense?.id
          }

          return myBill.expense.month === myExpense?.month
        })
        .reduce((accumulator, bill) => accumulator + (bill.amount ?? 0), 0)
        .toFixed(2)
      setMyExpense({
        ...myExpense,
        id: updatedBill.expense.id,
        totalAmount: parseFloat(totalAmount),
        status: expenseStatusContant.A_SIGNER
      })
    }
  }

  const handleSubmit = async (): Promise<void> => {
    if (userId !== undefined) {
      if (controlData()) {
        return
      }

      const response = await createorUpdateExpenseBill(
        {
          ...bill,
          // @ts-expect-error
          amount: parseFloat(bill.amount)
        },
        userId
      )
      if (response?.id !== undefined && selectedPdf !== null) {
        // enregistre le justificatif sur le serveur
        await saveJustificationFile(response.id, selectedPdf)
      }
      if (response !== null) {
        reloadData(bill.id, response)
      }
    }
  }

  const handleDelete = async (billId: number): Promise<void> => {
    await deleteJustificationFile(billId)
    await deleteBill(billId)

    const newBills = myBills.filter((item: IBill) => item.id !== billId)
    setMyBills(newBills)
    if (myExpense !== null) {
      const total = newBills
        .filter((myBill) => myBill.expense.id === myExpense?.id)
        .reduce((accumulator, bill) => accumulator + (bill.amount ?? 0), 0)
      setMyExpense({
        ...myExpense,
        totalAmount: total,
        status: total !== 0 ? expenseStatusContant.A_SIGNER : expenseStatusContant.A_SAISIR
      })
    }
  }

  const handleOpen = async (billId: number): Promise<void> => {
    const response = await openJustificationFile(billId)
    if (response !== null) {
      fileDownload(response.content, response.filename)
    }
  }

  const handleModifyStatus = async (
    status: string,
    refusalReason: string | null
  ): Promise<void> => {
    setLoading(true)
    let response
    if (userId !== undefined && myExpense !== null && myExpense.id !== undefined) {
      response = await updateExpenseStatus(myExpense.id, {
        status,
        refusalReason
      })
      if (response !== null && response !== undefined) {
        setMyExpense({ ...myExpense, status: response.status })
      }
    }
    setLoading(false)
    setOpenStatusExpensePopUp(false)
  }

  return (
    <>
      <div className="box-myexpense-header">
        <div className="myexpense-new-title">
          <div className="left"></div>
          <div className="myexpense-title">Nouvelle note de frais</div>
          <div className="right">
            {handleClose !== undefined && <Close onClick={handleClose} />}
          </div>
        </div>

        {billsToDisplay.length !== 0 && (
          <>
            {isSmallScreen && (
              <div className="myexpense-detail-accordeon">
                <div>Afficher le détail</div>
                <div>
                  {showDetails ? (
                    <KeyboardArrowDown
                      onClick={() => {
                        setShowDetails(!showDetails)
                      }}
                    />
                  ) : (
                    <KeyboardArrowUp
                      onClick={() => {
                        setShowDetails(!showDetails)
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            <Collapse in={showDetails} className="myexpenses-display">
              {billsToDisplay.map((myBill) => {
                increment += 1
                return (
                  <div key={increment} className="expense-summary">
                    <div className="display-expense">
                      <div className="detail">Note de frais {increment}</div>
                      <div className="mybill-buttons">
                        <PrimaryButton
                          handleClick={() => {
                            setBill({
                              id: myBill.id,
                              year,
                              month: myExpense?.month ?? 0,
                              expenseType: myBill.expenseType,
                              expenseSubtype: myBill.expenseSubtype ?? null,
                              description: myBill.description,
                              amount: myBill.amount.toString()
                            })
                          }}
                          title="Modifier"
                          background={false}
                          reverse={false}
                          disabled={false}
                          className="modify-btn"
                        />
                        <PrimaryButton
                          handleClick={() => {
                            void (async () => {
                              if (myBill.id !== undefined) {
                                await handleDelete(myBill.id)
                              }
                            })()
                          }}
                          title="Supprimer"
                          background={false}
                          reverse={false}
                          disabled={false}
                          className="modify-btn delete-btn"
                        />
                      </div>
                    </div>
                    <div className="display-expense">
                      <div>
                        {myBill.expenseType.label === expensesTypeConstant.AUTRES
                          ? myBill.expenseSubtype.label
                          : myBill.expenseType.label}
                        {myBill.document !== undefined && (
                          <IconButton
                            onClick={() => {
                              void (async () => {
                                if (myBill.id !== undefined) {
                                  await handleOpen(myBill.id)
                                }
                              })()
                            }}
                          >
                            <FileDownload id="download-file-icon" />
                          </IconButton>
                        )}
                      </div>
                      <div>{myBill.amount.toFixed(2)} €</div>
                    </div>
                  </div>
                )
              })}
            </Collapse>
            <hr className="divider" />
          </>
        )}
      </div>
      {myExpense?.status === expenseStatusContant.A_SAISIR ||
      myExpense?.status === expenseStatusContant.A_SIGNER ? (
        <>
          <div className="myexpense-buttons">
            <PrimaryButton
              handleClick={() => {
                setBill({
                  ...bill,
                  expenseType: { id: 1, label: expensesTypeConstant.INTERNET },
                  expenseSubtype: null
                })
              }}
              title="Internet"
              background
              reverse
              disabled={false}
              className={bill.expenseType.label === expensesTypeConstant.INTERNET ? 'isActive' : ''}
            />
            <PrimaryButton
              handleClick={() => {
                setBill({
                  ...bill,
                  expenseType: { id: 2, label: expensesTypeConstant.TELEPHONE },
                  expenseSubtype: null
                })
              }}
              title="Téléphone"
              background
              reverse
              disabled={false}
              className={
                bill.expenseType.label === expensesTypeConstant.TELEPHONE ? 'isActive' : ''
              }
            />
            <PrimaryButton
              handleClick={() => {
                setBill({
                  ...bill,
                  expenseType: { id: 3, label: expensesTypeConstant.TEL_INTERNET },
                  expenseSubtype: null
                })
              }}
              title="Téléphone/Internet"
              background
              reverse
              disabled={false}
              className={
                bill.expenseType.label === expensesTypeConstant.TEL_INTERNET ? 'isActive' : ''
              }
            />
            <CustomSelect
              value={'Autres'}
              options={expensesSubtypes}
              handleSelect={(value) => {
                setBill({
                  ...bill,
                  expenseType: { id: 4, label: expensesTypeConstant.AUTRES },
                  expenseSubtype: {
                    id: Number(value),
                    label: expensesSubtypeList[Number(value) - 1]
                  }
                })
              }}
              id="subtypes"
              width={190}
              inputLabel={false}
              required
            />
            {bill.expenseSubtype?.label !== undefined && (
              <PrimaryButton
                handleClick={() => {}}
                title={bill.expenseSubtype?.label ?? ''}
                background
                reverse
                disabled={true}
                className="isActive"
              />
            )}
          </div>
          <p className="form-error">{errorMessage?.expenseType}</p>
          <p className="form-error">{errorMessage?.expenseSubtype}</p>
          <hr className="divider" />
          {bill.expenseType.label === expensesTypeConstant.AUTRES && (
            <div className="mybill-description">
              <CustomTextField
                onChange={(value) => {
                  setBill({
                    ...bill,
                    description: value
                  })
                }}
                id="mybill-description"
                value={bill.description}
                required={false}
                multiline={true}
                errorMessage={errorMessage?.description ?? ''}
                label="Description"
              />
            </div>
          )}
          <div className="mybill-amount">
            Montant TTC
            <CustomNumberTextField
              onChange={(value) => {
                setBill({
                  ...bill,
                  amount: value
                })
              }}
              id="mybill-amount"
              value={bill.amount ?? ''}
              required={false}
            />
            €
          </div>
          <p className="form-error">{errorMessage?.amount ?? ''}</p>

          <hr className="divider" />

          <div className="px-12">
            <DocumentUploader
              accept={{
                'application/pdf': [],
                'image/jpeg': [],
                'image/png': []
              }}
              selectedFile={selectedPdf}
              uploadFile={(pdfBase64) => {
                setSelectedPdf(pdfBase64)
              }}
              minimalDesign={false}
              edit={false}
            />
            <p className="form-error">{errorMessage?.selectedPdf}</p>
          </div>

          <hr className="divider" />

          <div className="align-btn">
            <PrimaryButton
              handleClick={() => {
                setBill({
                  // eslint-disable-next-line
                  expenseType: { id: 1 } as IExpensesType,
                  year: myExpense?.year ?? 0,
                  month: myExpense?.month ?? 0,
                  expenseSubtype: null,
                  description: '',
                  amount: null
                })
                setSelectedPdf(null)
              }}
              title="Annuler"
              background
              reverse
              disabled={false}
            />
            <PrimaryButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              handleClick={handleSubmit}
              title="Enregistrer"
              background
              reverse={false}
              disabled={false}
            />
            {!isAdmin &&
              myExpense?.status === expenseStatusContant.A_SIGNER &&
              myExpense?.totalAmount !== 0 && (
                <PrimaryButton
                  handleClick={() => {
                    setOpenStatusExpensePopUp(true)
                  }}
                  icon={<LockOpen />}
                  title="Signer"
                  background
                  reverse={false}
                  disabled={false}
                  className="btn-sign"
                />
              )}
          </div>
        </>
      ) : (
        <></>
      )}

      <ConfirmationPopUp
        dialogTitle="Voulez-vous vraiment signer les notes de frais ?"
        dialogContentText="Vous ne pourrez plus les modifier."
        open={openStatusExpensePopUp}
        setOpen={setOpenStatusExpensePopUp}
        handleSave={async () => {
          void (async () => {
            await handleModifyStatus(expenseStatusContant.A_VERIFIER, null)
          })()
        }}
        loading={loading}
      />
    </>
  )
}

export default NewExpenseTicket
