import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import TabNavigation from 'components/tabNavigation/TabNavigation'
import React, { ReactElement, useEffect, useState } from 'react'
import TrombinoscopeCollab from './TrombinoscopeCollab'
import { jobConstant } from 'constantes'

interface ITrombinoscopeTab {
  collabs: IMinimalCollaborateurData[]
  searchTerm: string
}
const TrombinoscopeTab = ({ collabs, searchTerm }: ITrombinoscopeTab): ReactElement => {
  const [currentTab, setCurrentTab] = useState(0)
  const jobsList = Object.values(jobConstant).filter((job) => job !== 'Autre')
  jobsList.push('TOUS')

  const handleTab = (e: any, tab: number): void => {
    setCurrentTab(tab)
  }

  // préSelectionner le bon onglet
  useEffect(() => {
    if (searchTerm !== '' && collabs.length !== 0 && collabs[0].job?.id !== undefined) {
      setCurrentTab(jobsList.findIndex((item) => item === collabs[0].job?.name))
    }
  }, [collabs])

  return (
    <React.Fragment>
      <div className="trombi-header">
        <TabNavigation
          currentTab={currentTab}
          handleTab={handleTab}
          navItems={jobsList.map((item) => ({ label: item }))}
        ></TabNavigation>
      </div>
      <TrombinoscopeCollab users={collabs} job={jobsList[currentTab]} />
    </React.Fragment>
  )
}

export default TrombinoscopeTab
