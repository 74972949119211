import {
  ICost,
  ILock,
  IReporting,
  IReportingFollowUp,
  IReportingRequest,
  ISummary,
  IUserProduction,
  reportingExport
} from 'interfaces/reporting'
import { IProject } from '../interfaces/projects'
import { PdfFile } from 'interfaces/pfdFile'
import fileDownload from 'js-file-download'
import { customAxios } from './axiosUtils'
import { projectStatus } from 'constantes'
import { toast } from 'react-toastify'

/**
 * Récupération des reporting d'un user pour un mois donné.
 */
export const getReportingByMonth = async (
  month: number,
  year: number,
  user: number
): Promise<IReporting[]> => {
  try {
    const reporting = await customAxios.get(`reporting?month=${month}&year=${year}&user=${user}`)
    return reporting as unknown as IReporting[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du reporting')
    return []
  }
}

/**
 * Récupération des reporting d'un user pour un mois donné.
 */
export const getReportingByProject = async (
  month: number,
  year: number,
  project: number,
  place: number
): Promise<IReporting[]> => {
  try {
    const reporting = await customAxios.get(
      `reporting?month=${month}&year=${year}&project=${project}&placeId=${place}`
    )
    return reporting as unknown as IReporting[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du reporting')
    return []
  }
}

/**
 Permet d'ajouter un reporting
 @param data le body contenant les données du reporting
 **/
export const addReporting = async (data: IReportingRequest[]): Promise<IReporting[]> => {
  try {
    if (data.length) {
      const reporting = await customAxios.post(`reporting`, data)
      return reporting as unknown as IReporting[]
    }
  } catch (error) {
    toast.error('Erreur lors de la modification')
    return []
  }
  return []
}

/**
 Permet de supprimer un reporting
 @param data le body contenant les données du reporting
 **/
export const deleteReporting = async (
  id: number,
  year: number,
  month: number
): Promise<IReporting[]> => {
  try {
    if (id !== undefined) {
      const reporting = await customAxios.delete(`reporting/${id}?year=${year}&month=${month}`)
      return reporting as unknown as IReporting[]
    }
    return []
  } catch (error) {
    toast.error('Erreur lors de la suppression')
    return []
  }
}

/**
 * Export des reporting d'un user pour un mois donné.
 */
export const exportReportingByMonth = async (
  month: number,
  year: number,
  user: number,
  data: reportingExport
): Promise<void> => {
  try {
    const response: PdfFile | null = await customAxios.post(
      `reporting/export?month=${month}&year=${year}&user=${user}`,
      data,
      { responseType: 'blob' }
    )
    if (response !== null) {
      fileDownload(response.content, response.filename, 'pdf')
    }
  } catch (error) {
    toast.error('Erreur lors du téléchargement du PDF')
  }
}

/**
 * Récupération des suivi d'un user pour un mois donné.
 */
export const getReportingFollowUp = async (
  month: number,
  year: number,
  place: number
): Promise<undefined | IReportingFollowUp> => {
  try {
    const followUp = await customAxios.get(
      `reporting/follow-up?month=${month}&year=${year}&placeId=${place}`
    )
    return followUp as unknown as IReportingFollowUp
  } catch (error) {
    toast.error('Erreur lors de la récupération du suivi')
  }
}

/**
 * Récupération des synthèses annuelles d'un user pour un mois donné.
 */
export const getReportingSummary = async (
  month: number | null,
  year: number | null,
  place: number,
  userId: number | null,
  projectId: number | null,
  contractType: string | null
): Promise<undefined | ISummary[]> => {
  try {
    const params = new URLSearchParams()

    if (month !== null) {
      params.append('month', month.toString())
    }
    if (year !== null) {
      params.append('year', year.toString())
    }
    if (userId !== null) {
      params.append('userId', userId.toString())
    }
    if (projectId !== null) {
      params.append('projectId', projectId.toString())
    }
    if (contractType !== null && contractType !== '') {
      params.append('contractType', contractType)
    }

    const queryString = params.toString()

    const summary = await customAxios.get(`reporting/summary?${queryString}&placeId=${place}`)
    return summary as unknown as ISummary[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du suivi')
  }
}

/**
 * Récupération des synthèses annuelles pour une année donné.
 */
export const getReportingSummaryOfYear = async (
  year: number,
  place: number
): Promise<undefined | ISummary[]> => {
  try {
    const summary = await customAxios.get(`reporting/summary?year=${year}&placeId=${place}`)
    return summary as unknown as ISummary[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du suivi')
  }
}

/**
 * Récupération des synthèses annuelles pour un mois donné.
 */
export const getReportingSummaryOfMonth = async (
  year: number,
  month: number,
  place: number
): Promise<undefined | ISummary[]> => {
  try {
    const summary = await customAxios.get(
      `reporting/summary?year=${year}&month=${month}&placeId=${place}`
    )
    return summary as unknown as ISummary[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du suivi')
  }
}

/**
 * Récupération des synthèses annuelles pour un projet donné.
 */
export const getReportingSummaryOfProject = async (
  year: number,
  month: number,
  id: number,
  place: number
): Promise<undefined | ISummary[]> => {
  try {
    const summary = await customAxios.get(
      `reporting/summary?year=${year}&month=${month}&projectId=${id}&placeId=${place}`
    )
    return summary as unknown as ISummary[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du suivi')
  }
}

/**
 * Récupération des synthèses annuelles pour un projet donné.
 */
export const getAnnualSummaryOfProject = async (
  year: number,
  id: number,
  place: number
): Promise<undefined | ISummary[]> => {
  try {
    const summary = await customAxios.get(
      `reporting/summary?year=${year}&projectId=${id}&placeId=${place}`
    )
    return summary as unknown as ISummary[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du suivi')
  }
}

/**
 * Récupération du total de montants travaillés de toutes les années pour un projet donné.
 */
export const getSummaryTotalOfProject = async (
  id: number | string,
  billable: string,
  place: number
): Promise<undefined | number> => {
  try {
    const summary = await customAxios.get(
      `reporting/summary-sum?projectId=${id}&billable=${billable}&placeId=${place}`
    )
    return summary as unknown as number
  } catch (error) {
    toast.error('Erreur lors de la récupération du total')
  }
}

/**
 * Récupération des synthèses de production des users pour un mois donné.
 */
export const getUserProductionOfYear = async (
  year: number,
  place: number
): Promise<undefined | IUserProduction[]> => {
  try {
    const production = await customAxios.get(
      `reporting/user-production?year=${year}&placeId=${place}`
    )
    return production as unknown as IUserProduction[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du suivi')
  }
}

/**
 * Récupération des synthèses de production des users pour un mois donné.
 */
export const getUserProductionOfMonth = async (
  year: number,
  month: number,
  place: number
): Promise<undefined | IUserProduction[]> => {
  try {
    const production = await customAxios.get(
      `reporting/user-production?year=${year}&month=${month}&placeId=${place}`
    )
    return production as unknown as IUserProduction[]
  } catch (error) {
    toast.error('Erreur lors de la récupération du suivi')
  }
}

export const getCostsForUser = async (user: number): Promise<ICost[]> => {
  try {
    const costs = await customAxios.get(`users/${user}/costs`)
    return costs as unknown as ICost[]
  } catch (error) {
    toast.error('Erreur lors de la récupération des costs')
    return []
  }
}

export const getCosts = async (
  year: number,
  month: number,
  place: number | null
): Promise<ICost[]> => {
  try {
    let url = `costs?year=${year}&month=${month}`
    if (place) {
      url += `&placeId=${place}`
    }
    const costs = await customAxios.get(url)
    return costs as unknown as ICost[]
  } catch (error) {
    toast.error('Erreur lors de la récupération des costs')
    return []
  }
}

export const importCosts = async (file: string | Blob, submit: boolean): Promise<ICost[]> => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('submit', String(submit))

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return await customAxios.post(`costs`, formData, config)
  } catch (error) {
    toast.error('Veuillez vérifier le format de votre fichier')
    return []
  }
}

export const getLocks = async (year: number, place: number): Promise<ILock[]> => {
  try {
    const locks = await customAxios.get(`reporting/locks?year=${year}&placeId=${place}`)
    return locks as unknown as ILock[]
  } catch (error) {
    toast.error('Erreur lors de la récupération des blocages')
    return []
  }
}

export const createLock = async (year: number, place: number, month: number): Promise<ILock[]> => {
  try {
    const locks = await customAxios.post(
      `reporting/locks?year=${year}&placeId=${place}&month=${month}`
    )
    return locks as unknown as ILock[]
  } catch (error) {
    toast.error('Erreur lors du blocage du reporting')
    return []
  }
}

export const deleteLock = async (id: string): Promise<ILock[]> => {
  try {
    const locks = await customAxios.delete(`reporting/locks/${id}`)
    return locks as unknown as ILock[]
  } catch (error) {
    toast.error("Erreur lors de la suppression d'un blocage")
    return []
  }
}

export const isHoliday = (project: IProject): boolean => {
  return project.name === projectStatus.HOLIDAY
}
