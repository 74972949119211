import PrimaryButton from '../../../components/inputs/button/PrimaryButton'
import { IColorSettings } from 'interfaces/colorSettings'
import { InputLabel, TextField } from '@mui/material'
import { settingCategoryConstant } from 'constantes'
import DeleteIcon from '@mui/icons-material/Delete'
import { ISetting } from 'interfaces/setting'
import React, { ReactElement } from 'react'
import { cloneDeep } from 'lodash'

interface IOnBoardingForm {
  settings: ISetting[]
  setSettings: React.Dispatch<React.SetStateAction<ISetting[]>>
  initalColors: IColorSettings | null
}

const OnBoardingForm = ({ settings, setSettings }: IOnBoardingForm): ReactElement => {
  const handleChange = (index: number, value: string, key: 'value' | 'label'): void => {
    const tmpSettings = cloneDeep(settings)
    tmpSettings[index][key] = value
    setSettings(tmpSettings)
  }

  return (
    <>
      <div className="d-flex between mb-5">
        <InputLabel className={'data-title w-50 text-center bold'}>Nom du fichier</InputLabel>
        <InputLabel className={'data-title w-50 text-center bold'}>
          Id du fichier sur le drive
        </InputLabel>
      </div>
      {settings.map((setting, index) => {
        if (setting.category !== settingCategoryConstant.ON_BOARDING || setting.toDelete === true) {
          return null
        }
        return (
          <div className="setting-input" key={setting.key}>
            <div>
              <div className="setting-field-copy">
                <TextField
                  onChange={(e) => {
                    handleChange(index, e.target.value, 'label')
                  }}
                  placeholder="Nom du fichier"
                  value={setting.label}
                  type="text"
                />
                <TextField
                  id={setting.key}
                  onChange={(e) => {
                    handleChange(index, e.target.value, 'value')
                  }}
                  placeholder={'Id drive du fichier'}
                  value={setting.value}
                  type="text"
                />
                {settings.filter(
                  (setting) => setting.category === settingCategoryConstant.ON_BOARDING
                ).length > 1 && (
                  <DeleteIcon
                    id="copy-icon"
                    onClick={() => {
                      const tmp = cloneDeep(settings)
                      tmp[index].toDelete = true
                      setSettings(tmp)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )
      })}
      <div className="d-flex">
        <PrimaryButton
          handleClick={(): void => {
            setSettings([
              ...settings,
              {
                category: settingCategoryConstant.ON_BOARDING,
                key: `ON_BOARDING_FILE_${Math.random()}`,
                label: '',
                value: ''
              }
            ])
          }}
          title="+ Ajouter un document"
          background
          reverse
          disabled={false}
        />
      </div>
    </>
  )
}

export default OnBoardingForm
