import {
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  InputLabel,
  ListItem
} from '@mui/material'
import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { useRetrieveUsers } from 'customHooks/retrieveMatchingUsers'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { addGroup, updateGroup } from 'services/GroupService'
import { PlaceSelectedContext } from '../../App'
import { Close } from '@mui/icons-material'
import { IGroup } from 'interfaces/group'

interface IGroupPopUp {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  groupToEdit: IGroup | undefined
  setGroups: React.Dispatch<React.SetStateAction<IGroup[]>>
}

const GroupPopUp = ({ open, setOpen, groupToEdit, setGroups }: IGroupPopUp): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [users, retrieveUsers] = useRetrieveUsers()
  const [requestedUser] = useState<IMinimalCollaborateurData | null>(null)
  const [group, setGroup] = useState<IGroup>(groupToEdit ?? {})
  const [filteredUsers, setFilteredUsers] = useState<IMinimalCollaborateurData[]>([])
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null

  useEffect(() => {
    if (groupToEdit !== undefined) {
      setGroup(groupToEdit)
    } else {
      setGroup({})
    }
  }, [groupToEdit, open])

  // Filtre les utilisateurs qui ne sont pas déjà dans le groupe
  useEffect(() => {
    const currentUserIds = new Set(group.usersAssignments?.map((user) => user.id) ?? [])
    setFilteredUsers(users.filter((user) => !currentUserIds.has(user.id)))
  }, [users, group.usersAssignments])

  const handleSave = async (): Promise<void> => {
    if (!group.usersAssignments) {
      return
    }

    setLoading(true)
    group.placeId = place
    if (group.id !== undefined) {
      const groups = await updateGroup(group)
      setGroups(groups)
    } else {
      const groups = await addGroup(group)
      setGroups(groups)
    }
    setOpen(false)
    setLoading(false)
  }

  const handleDeleteUser = (chipToDelete: IMinimalCollaborateurData) => () => {
    setGroup({
      ...group,
      usersAssignments: group.usersAssignments?.filter((chip) => chip.id !== chipToDelete.id) ?? []
    })
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={(): void => {
          setOpen(false)
        }}
        aria-labelledby="save-dialog"
        aria-describedby="save-dialog-description"
        className="popup-group"
      >
        <IconButton
          onClick={() => {
            setOpen(false)
          }}
          id="closeIcon"
        >
          <Close />
        </IconButton>
        {groupToEdit !== undefined ? (
          <DialogTitle id="save-dialog-title">Modification d&apos;un groupe</DialogTitle>
        ) : (
          <DialogTitle id="save-dialog-title">Ajout d&apos;un groupe</DialogTitle>
        )}
        <DialogActions>
          <div>
            <div className="mb-5">
              <CustomTextField
                onChange={(value) => {
                  setGroup({ ...group, title: value })
                }}
                id="firstname"
                value={group?.title ?? ''}
                errorMessage={''}
                required={false}
                label="Nom du groupe"
              />
            </div>
            <div className="mb-5">
              <InputLabel id="userAssignmentId">Personnes à ajouter dans le groupe</InputLabel>
              <AutoCompleteUserInput
                value={requestedUser}
                onChange={(e: any, newValue: IMinimalCollaborateurData | null): void => {
                  if (newValue !== null) {
                    const users = group.usersAssignments ?? []
                    setGroup({
                      ...group,
                      usersAssignments: [...users, newValue]
                    })
                  }
                }}
                id="userAssignmentId"
                options={filteredUsers}
                setOptions={(inputValue: string) => {
                  void (async () => {
                    await retrieveUsers(inputValue)
                  })()
                }}
                clearInputAfterSelect
              />
              <div className="chips">
                {group.usersAssignments?.map((data) => {
                  return (
                    <ListItem key={data.id}>
                      <Chip
                        label={`${data.displayFirstname} ${data.displayLastname}`}
                        onDelete={handleDeleteUser(data)}
                      />
                    </ListItem>
                  )
                })}
              </div>
            </div>
            <div className="buttons-quotas mt-10">
              <PrimaryButton
                handleClick={(): void => {
                  setOpen(false)
                }}
                title="Annuler"
                background
                reverse
                disabled={loading}
              />
              <PrimaryButton
                handleClick={() => {
                  void (async () => {
                    await handleSave()
                  })()
                }}
                title="Enregistrer"
                background
                reverse={false}
                disabled={loading}
              />
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default GroupPopUp
