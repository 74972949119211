import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { getMatchingUsers } from 'services/SelectorsValuesService'
import { PlaceSelectedContext } from '../App'
import { useContext, useState } from 'react'

export const useRetrieveUsers = (): [
  IMinimalCollaborateurData[],
  (inputValue: string) => Promise<IMinimalCollaborateurData[]>
] => {
  const [users, setUsers] = useState<IMinimalCollaborateurData[]>([])
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null

  /**
   * return la liste des utilisateurs correspondant à la recherche
   * @param inputValue le texte écrit dans le champ de recherche
   */
  const retrieveUsers = async (inputValue: string): Promise<IMinimalCollaborateurData[]> => {
    if (inputValue.length === 0) {
      setUsers([])
      return []
    }
    const users = (await getMatchingUsers(inputValue, place))?.data
    if (users === undefined) return []
    const usersFiltered = users.map((user: IMinimalCollaborateurData) => {
      return user
    })
    setUsers(usersFiltered)
    return usersFiltered
  }

  return [users, retrieveUsers]
}
