import { ISteamDejGroup, IUpdateSteamDejStatus } from 'interfaces/steamDejGroup'
import { customAxios } from './axiosUtils'
import { toast } from 'react-toastify'

/**
 * Récupérer les groupes de SteamDej du mois en cours
 */
export const getSteamDejGroups = async (
  search: string | null,
  place: number | null
): Promise<ISteamDejGroup[]> => {
  try {
    const placeParam = place !== null ? `&placeId=${place}` : ''
    const searchParam = search !== null && search !== '' ? `search=${search}` : ''
    return await customAxios.get(`steamdej?${searchParam}${placeParam}`)
  } catch (error) {
    return []
  }
}

/**
 * Mettre à jour le statut d'un groupe de SteamDej' donné
 */
export const updateSteamDejStatus = async (
  groupId: number,
  steamDejStatus: IUpdateSteamDejStatus
): Promise<ISteamDejGroup | null> => {
  try {
    const result = await customAxios.put(`steamdej/${groupId}`, steamDejStatus)
    toast.success("Le SteamDej' a bien été modifié")
    return result as unknown as ISteamDejGroup
  } catch (error) {
    return null
  }
}
