import { IBill, IExpense, IExpensesSubtype } from 'interfaces/expenses'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement, useContext, useState } from 'react'
import { updateExpenseStatus } from 'services/ExpensesService'
import TextPopUp from 'components/popup/textPopUp/TextPopUp'
import { hasSuperAdminRole } from '../../utils/otherUtils'
import NewExpenseTicket from './NewExpenseTicket'
import { expenseStatusContant } from 'constantes'
import { CurrentUserContext } from '../../App'
import ExpenseReceipt from './ExpenseReceipt'

interface IMyExpensesTicket {
  myBills: IBill[]
  setMyBills: (data: IBill[]) => void
  myExpense: IExpense | null
  setMyExpense: (data: IExpense) => void
  expensesSubtypes: IExpensesSubtype[]
  userId?: number
  isAdmin?: boolean
  handleClose?: () => void
}

const MyExpensesTicket = ({
  myBills,
  setMyBills,
  myExpense,
  setMyExpense,
  expensesSubtypes,
  userId,
  isAdmin = false,
  handleClose
}: IMyExpensesTicket): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [openTextPopUp, setOpenTextPopUp] = useState<boolean>(false)
  const [refusalText, setRefusalText] = useState<string | null>(null)
  const userContext = useContext(CurrentUserContext)
  const user = userContext?.currentUser

  const handleModifyStatus = async (
    status: string,
    refusalReason: string | null
  ): Promise<void> => {
    setLoading(true)
    let response
    if (userId !== undefined && myExpense !== null && myExpense.id !== undefined) {
      response = await updateExpenseStatus(myExpense.id, {
        status,
        refusalReason
      })
    }
    setLoading(false)
    if (response !== null && response !== undefined && myExpense !== null) {
      setMyExpense({ ...myExpense, status: response.status, refusalReason: response.refusalReason })
    }
  }

  return (
    <div className="box-container-myexpense">
      {myExpense !== null &&
        (myExpense?.status === expenseStatusContant.A_VERIFIER ||
        myExpense?.status === expenseStatusContant.A_VALIDER ||
        myExpense?.status === expenseStatusContant.ERREUR ||
        myExpense?.status === expenseStatusContant.REFUSE ||
        myExpense?.status === expenseStatusContant.A_REMBOURSER ||
        myExpense?.status === expenseStatusContant.REMBOURSE ||
        isAdmin ? (
          <ExpenseReceipt
            myBills={myBills.filter((myBill) => myBill.expense.id === myExpense?.id)}
            myExpense={myExpense}
            handleClose={handleClose}
          />
        ) : (
          <NewExpenseTicket
            expensesSubtypes={expensesSubtypes}
            myBills={myBills}
            setMyBills={setMyBills}
            myExpense={myExpense}
            setMyExpense={setMyExpense}
            userId={userId}
            isAdmin={isAdmin}
            handleClose={handleClose}
          />
        ))}

      {isAdmin &&
      (myExpense?.status === expenseStatusContant.A_VALIDER ||
        myExpense?.status === expenseStatusContant.A_VERIFIER) ? (
        <div className="align-btn">
          <PrimaryButton
            handleClick={() => {
              setOpenTextPopUp(true)
            }}
            title="Refuser"
            background
            reverse
            disabled={
              myExpense?.status === expenseStatusContant.A_VALIDER &&
              (user?.rolePermissions === undefined || !hasSuperAdminRole(user.rolePermissions))
            }
            className={'btn-refuse'}
          />
          <PrimaryButton
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            handleClick={() => {
              let status = ''
              if (myExpense?.status === expenseStatusContant.A_VALIDER) {
                status = expenseStatusContant.A_REMBOURSER
              } else {
                status = expenseStatusContant.A_VALIDER
              }
              void (async () => {
                await handleModifyStatus(status, null)
              })()
            }}
            title={myExpense?.status === expenseStatusContant.A_VERIFIER ? 'Vérifier' : 'Valider'}
            background
            reverse={false}
            disabled={
              myExpense?.status === expenseStatusContant.A_VALIDER &&
              (user?.rolePermissions === undefined || !hasSuperAdminRole(user.rolePermissions))
            }
            className={'btn-accept'}
          />
        </div>
      ) : (
        <></>
      )}

      {isAdmin &&
      (myExpense?.status === expenseStatusContant.A_REMBOURSER ||
        myExpense?.status === expenseStatusContant.REMBOURSE) ? (
        <div className="align-btn">
          <PrimaryButton
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            handleClick={() => {
              void (async () => {
                await handleModifyStatus(expenseStatusContant.ERREUR, null)
              })()
            }}
            title={'Erreur banque'}
            background
            reverse={false}
            disabled={false}
            className={'red'}
          />
        </div>
      ) : (
        <></>
      )}

      <TextPopUp
        dialogTitle="Merci de justifier pourquoi cette note de frais a été refusée"
        dialogContentText={''}
        open={openTextPopUp}
        setOpen={setOpenTextPopUp}
        handleSave={async () => {
          void (async () => {
            if (myExpense?.status !== undefined) {
              await handleModifyStatus(expenseStatusContant.A_SAISIR, refusalText)
              setOpenTextPopUp(false)
              setRefusalText(null)
            }
          })()
        }}
        loading={loading}
        handleChange={(value) => {
          setRefusalText(value)
        }}
        value={refusalText ?? ''}
      />
    </div>
  )
}

export default MyExpensesTicket
