import { IDetailCollaborateurData, IUpdateUserData } from 'interfaces/collaborateurData'
import CustomDatePicker from 'components/inputs/customDatePicker/CustomDatePicker'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import { setValidDate } from 'utils/dateUtils'
import React, { ReactElement } from 'react'
import { Dayjs } from 'dayjs'
import moment from 'moment'

interface IPersonalInfos {
  user: IDetailCollaborateurData | null
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  personalMailError: string
  phoneNumberError: string
}

const PersonalInfos = ({
  user,
  editMode,
  userDataToSend,
  setUserDataToSend,
  personalMailError,
  phoneNumberError
}: IPersonalInfos): ReactElement => {
  const handleChangeDate = (newValue: Dayjs | null, propertyToUpdate: string): void => {
    setUserDataToSend({
      ...userDataToSend,
      [propertyToUpdate]: newValue !== null ? setValidDate(newValue) : null
    })
  }

  return (
    <div className="box-data">
      <div className="category-title">Informations personnelles</div>
      <div className="box-inline-elts">
        <div className="box-column">
          {editMode ? (
            <CustomDatePicker
              handleChangeDate={(e, property) => {
                handleChangeDate(e, property)
              }}
              id="birthdayDate"
              value={userDataToSend.birthdayDate}
              errorMessage=""
              required={false}
              label="Date de naissance"
            />
          ) : (
            <div>
              <p className="data-title">Date de naissance</p>
              <p className="data-value">
                {user?.birthdayDate !== undefined && user.birthdayDate !== null
                  ? moment(user?.birthdayDate).format('DD/MM/yyyy')
                  : ''}
              </p>
            </div>
          )}
        </div>
        <div className="box-column">
          <div>
            {editMode ? (
              <CustomTextField
                onChange={(value) => {
                  setUserDataToSend({ ...userDataToSend, personalMail: value })
                }}
                id="personalMail"
                value={userDataToSend.personalMail}
                errorMessage={personalMailError}
                required
                label="Mail personnel"
              />
            ) : (
              <>
                <p className="data-title">Mail personnel</p>
                <p className="data-value">{user?.personalEmail}</p>
              </>
            )}
          </div>
        </div>
        <div className="box-column">
          <div>
            {editMode ? (
              <CustomTextField
                onChange={(value) => {
                  setUserDataToSend({ ...userDataToSend, phoneNumber: value })
                }}
                id="phoneNumber"
                value={userDataToSend.phoneNumber}
                errorMessage={phoneNumberError}
                required={false}
                label="Numéro de téléphone"
              />
            ) : (
              <>
                <p className="data-title">N° de téléphone</p>
                <p className="data-value">
                  {user?.phoneNumber !== undefined ? user?.phoneNumber : ''}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalInfos
