import CustomGroupRadios from 'components/inputs/customGroupRadios/CustomGroupRadios'
import CustomDatePicker from 'components/inputs/customDatePicker/CustomDatePicker'
import { IDetailMaterialData, IUpdateMaterialData } from 'interfaces/materialData'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { IListSelectorValue } from 'interfaces/selectors'
import { materialTypeConstant } from 'constantes'
import { IErrors } from 'interfaces/formFields'
import { setValidDate } from 'utils/dateUtils'
import React, { ReactElement } from 'react'
import { Dayjs } from 'dayjs'
import moment from 'moment'

interface IPracticleInfos {
  material: IDetailMaterialData | null
  editMode: boolean
  materialDataToSend: IUpdateMaterialData
  setMaterialDataToSend: React.Dispatch<React.SetStateAction<IUpdateMaterialData>>
  places: IListSelectorValue[]
  errors: IErrors
}

const PracticleInfos = ({
  material,
  editMode,
  materialDataToSend,
  setMaterialDataToSend,
  places,
  errors
}: IPracticleInfos): ReactElement => {
  const handleChangeDate = (newValue: Dayjs | null, propertyToUpdate: string): void => {
    setMaterialDataToSend({
      ...materialDataToSend,
      [propertyToUpdate]: newValue !== null ? setValidDate(newValue) : null
    })
  }

  const handleMultiTextLineChange = (value: string, propertyToUpdate: string): void => {
    if (value.length <= 255) {
      setMaterialDataToSend({ ...materialDataToSend, [propertyToUpdate]: value })
    } else {
      alert('Vous avez dépassé la limite de 255 caractères maximum')
    }
  }

  return (
    <div className="box-data">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <p className="category-title">Informations pratiques</p>
        <div className="box-inline-elts">
          <div className="box-column">
            <div>
              {editMode ? (
                <CustomDatePicker
                  handleChangeDate={(e, property) => {
                    handleChangeDate(e, property)
                  }}
                  id="purchaseDate"
                  value={materialDataToSend.purchaseDate}
                  errorMessage={errors.purchaseDate?.message}
                  required
                  label="Date d'achat"
                />
              ) : (
                <>
                  <p className="data-title">Date d&apos;achat</p>
                  <p className="data-value">
                    {materialDataToSend.purchaseDate !== null &&
                    materialDataToSend.purchaseDate !== undefined
                      ? moment(materialDataToSend?.purchaseDate).format('DD/MM/yyyy')
                      : ''}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="box-column">
            <div>
              {editMode ? (
                <CustomDatePicker
                  handleChangeDate={(e, property) => {
                    handleChangeDate(e, property)
                  }}
                  id="leavingDate"
                  value={materialDataToSend.leavingDate}
                  errorMessage=""
                  required={false}
                  label="Date de sortie"
                />
              ) : (
                <>
                  <p className="data-title">Date de sortie</p>
                  <p className="data-value">
                    {materialDataToSend.leavingDate !== null &&
                    materialDataToSend.leavingDate !== undefined
                      ? moment(materialDataToSend?.leavingDate).format('DD/MM/yyyy')
                      : ''}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="box-column">
            <div>
              {editMode ? (
                <CustomGroupRadios
                  onChange={(value) => {
                    setMaterialDataToSend({ ...materialDataToSend, type: value })
                  }}
                  id="type"
                  gpRadiovalue={materialDataToSend.type}
                  required={false}
                  radiosLabelValue={[materialTypeConstant.FIX, materialTypeConstant.PORTABLE]}
                />
              ) : (
                <>
                  <p className="data-title">Type</p>
                  <p className="data-value">{material?.type}</p>
                </>
              )}
            </div>
          </div>
          <div className="box-inline-elts">
            <div>
              {editMode ? (
                <CustomTextField
                  onChange={(value) => {
                    handleMultiTextLineChange(value, 'description')
                  }}
                  id="description"
                  value={materialDataToSend.description}
                  errorMessage=""
                  required={false}
                  label="Description"
                  multiline
                />
              ) : (
                <>
                  <p className="data-title">Description</p>
                  <p className="data-value">{material?.description}</p>
                </>
              )}
            </div>
            <div>
              {editMode ? (
                <CustomTextField
                  onChange={(value) => {
                    handleMultiTextLineChange(value, 'comment')
                  }}
                  id="comment"
                  value={materialDataToSend.comment}
                  errorMessage=""
                  required={false}
                  label="Commentaire"
                  multiline
                />
              ) : (
                <>
                  <p className="data-title">Commentaire</p>
                  <p className="data-value">{material?.comment}</p>
                </>
              )}
            </div>
            <div>
              {editMode ? (
                <div>
                  <CustomSelect
                    handleSelect={(value) => {
                      setMaterialDataToSend({ ...materialDataToSend, place: value as number })
                    }}
                    options={places}
                    id="placeId"
                    value={materialDataToSend.place}
                    inputLabel="Lieu"
                    required
                  />
                  <p className="form-error"></p>
                </div>
              ) : (
                <>
                  <p className="data-title">Lieu</p>
                  <p className="data-value">{material?.place?.name}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </div>
  )
}

export default PracticleInfos
