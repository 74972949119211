import PrimaryButton from '../../../components/inputs/button/PrimaryButton'
import React, { ReactElement } from 'react'
import '../OnBoarding.scss'

const IndexStep = ({
  validate,
  activeStep,
  maxSteps,
  showNextButton,
  isSubmitting,
  fileTitle,
  setActiveStep,
  children
}: {
  fileTitle: Array<string | undefined>
  activeStep: number
  showNextButton: boolean
  isSubmitting: boolean
  maxSteps: number
  validate: () => void
  setActiveStep: (value: number) => void
  children: ReactElement
}): ReactElement => {
  const STEPS = ['Documents personnels', ...fileTitle]

  return (
    <div>
      <div className="d-flex flex-column">
        <div className="d-flex">
          {STEPS.map((_, index) => (
            <div key={`step-${index}`} className="d-flex">
              <div className={`step ${activeStep === index ? 'step-active' : ''}`}>{index + 1}</div>
              {index !== STEPS.length - 1 && <div className="step-line" />}
            </div>
          ))}
        </div>
        <div className="d-flex">
          {STEPS.map((step, index) => (
            <div key={`text-${index}`} className="d-flex text-container">
              <span
                className="text"
                dangerouslySetInnerHTML={{
                  __html: step ?? ''
                }}
              ></span>
            </div>
          ))}
        </div>
      </div>

      {activeStep < maxSteps && children}

      {showNextButton && (
        <div className="btn-step-container">
          <PrimaryButton
            isLoading={isSubmitting}
            handleClick={() => {
              if (activeStep < maxSteps - 1) {
                setActiveStep(activeStep + 1)
                return
              }
              validate()
            }}
            title={activeStep < maxSteps - 1 ? 'Etape suivante' : 'Envoyer'}
            background
            reverse={false}
            disabled={false}
          />
        </div>
      )}
    </div>
  )
}
export default IndexStep
