import { IDetailCollaborateurData, IUpdateUserData } from 'interfaces/collaborateurData'
import { getLocalStorageWithExpiration, hasManagerRole } from 'utils/otherUtils'
import { getMaterialsWithPagination } from 'services/DataWithPaginationService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IGetMinimalMaterialData } from 'interfaces/materialData'
import InfiniteScroll from 'react-infinite-scroll-component'
import OutlinedInput from '@mui/material/OutlinedInput'
import ListItemText from '@mui/material/ListItemText'
import DeleteIcon from '@mui/icons-material/Delete'
import InputLabel from '@mui/material/InputLabel'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import { ROWS_PER_PAGE } from 'constantes'
import { PlaceSelectedContext } from 'App'
import Select from '@mui/material/Select'
import moment from 'moment'

interface IMaterial {
  user: IDetailCollaborateurData | null
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  myMaterials: IGetMinimalMaterialData[]
  setMyMaterials: React.Dispatch<React.SetStateAction<IGetMinimalMaterialData[]>>
}

const MaterialUpdateUser = ({
  user,
  editMode,
  userDataToSend,
  setUserDataToSend,
  myMaterials,
  setMyMaterials
}: IMaterial): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const [materials, setMaterials] = useState<IGetMinimalMaterialData[]>([])
  const [more, setMore] = useState<boolean>(true)
  const [pagination, setPagination] = useState<number>(1)
  const [selectedMaterials, setSelectedMaterials] = useState<IGetMinimalMaterialData[]>([])
  const navigate = useNavigate()
  const userSelf = getLocalStorageWithExpiration('user')
  const isAdmin = userSelf !== null ? hasManagerRole(JSON.parse(userSelf).rolePermissions) : false

  // récupération des matériels actifs et non encore attribués
  useEffect(() => {
    void (async () => {
      if (editMode) {
        const materials = await loadMaterials(1)

        if (materials !== undefined) {
          setMaterials(materials)
        }

        setSelectedMaterials([])
      }
    })()
  }, [editMode, user])

  const loadMaterials = async (
    pagination: number
  ): Promise<IGetMinimalMaterialData[] | undefined> => {
    const materialsResponse = await getMaterialsWithPagination(
      pagination,
      ROWS_PER_PAGE,
      'purchaseDate',
      'desc',
      '',
      '',
      place,
      null,
      1,
      0
    )
    return materialsResponse?.data
  }

  const updateMyMaterials = (material: IGetMinimalMaterialData, isDeleted: boolean): void => {
    let myNewMaterials = []
    if (isDeleted) {
      const deletedMaterial = material
      myNewMaterials = myMaterials.filter((myMaterial) => {
        return deletedMaterial !== myMaterial
      })
    } else {
      const selectedMaterial = material
      myNewMaterials = [...myMaterials, selectedMaterial]
    }
    setMyMaterials(myNewMaterials)
    const myMaterialsIds = myNewMaterials?.map(({ id }) => ({ id }))

    // Met à jour les matériels associés à l'utilisateur
    if (myMaterialsIds !== undefined)
      setUserDataToSend({
        ...userDataToSend,
        updateMaterials: myMaterialsIds
      })
  }

  const handleDeleteMaterial = (materialToDelete: IGetMinimalMaterialData): void => {
    // déselectionne les éléments qu'on supprime via la poubelle et qui était sélectionnés dans la liste
    const updatedSelectedMaterials = selectedMaterials.filter(
      (element) => element.serialNumber !== materialToDelete?.serialNumber
    )
    setSelectedMaterials(updatedSelectedMaterials)
    updateMyMaterials(materialToDelete, true)
  }

  const loadNextMaterials = async (): Promise<void> => {
    const newpagination = pagination + 1
    const nextMaterials = await loadMaterials(newpagination)

    if (nextMaterials !== undefined && nextMaterials.length === ROWS_PER_PAGE) {
      setPagination(newpagination)
      const allMaterials = [...materials].concat(nextMaterials)
      setMaterials(allMaterials)
    } else {
      setMore(false)
    }
  }

  const handleAddMaterial = (newMaterial: string | IGetMinimalMaterialData[]): void => {
    const selectedSerialNumber = newMaterial[newMaterial.length - 1]
    // vérifie si le matériel sélectionné existe déjà dans la liste selectedMaterials
    const existingMaterial = selectedMaterials.find(
      (element) => element.serialNumber === selectedSerialNumber
    )
    let updatedSelectedMaterials = []
    if (existingMaterial != null) {
      // le matériel doit être retiré de la liste
      updatedSelectedMaterials = selectedMaterials.filter(
        (element) => element.serialNumber !== selectedSerialNumber
      )
      updateMyMaterials(existingMaterial, true)
    } else {
      const materialToAdd = materials.find(
        (element) => element.serialNumber === selectedSerialNumber
      )
      if (materialToAdd !== undefined) {
        // le matériel doit être ajouté à la liste des matériels sélectionnés
        updatedSelectedMaterials = [...selectedMaterials, materialToAdd]
        updateMyMaterials(materialToAdd, false)
      } else {
        updatedSelectedMaterials = [...selectedMaterials]
      }
    }

    setSelectedMaterials(updatedSelectedMaterials)
  }

  return (
    <div className="box-data">
      <p className="category-title">Matériels</p>
      <div className="box-inline-elts">
        {editMode ? (
          <div className="box-column">
            <div className="no-custom-select">
              <InputLabel id="material-free-label">Attribuer nouveau(x) matériel(s)</InputLabel>
              <Select
                disabled={!editMode}
                labelId="material-label-id"
                id="material-id"
                multiple
                value={selectedMaterials}
                MenuProps={{ PaperProps: { id: 'menu-id' } }}
                className="w-100 p-2"
                onChange={(e) => {
                  handleAddMaterial(e.target.value)
                }}
                renderValue={(selected) => {
                  const result = selected.map((item) => item.model + '(' + item.type + ')' + ', ')
                  return result
                }}
                input={<OutlinedInput label="Matériel" />}
              >
                <InfiniteScroll
                  style={{ overflow: 'hidden' }}
                  dataLength={materials.length}
                  next={loadNextMaterials}
                  hasMore={more}
                  scrollableTarget="menu-id"
                  loader=""
                >
                  <></>
                </InfiniteScroll>
                {materials.map((item) => (
                  <MenuItem key={item.id} value={item.serialNumber}>
                    <Checkbox checked={selectedMaterials.includes(item)} />
                    <ListItemText
                      primary={
                        <p>
                          <span className="bold"></span>
                          {item.inventoryNumber}
                          <span className="bold"> | </span>
                          {item.brand}
                          <span className="bold"> | </span>
                          {item.model}
                          <span className="bold"> | </span>
                          {item.type}
                          <span className="bold"> | </span>
                          {moment(item.purchaseDate).format('L')}
                        </p>
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        ) : null}
        <div className="box-column">
          <div>
            {editMode ? (
              <InputLabel>Matériel(s) Attribué(s)</InputLabel>
            ) : (
              <p className="data-title">Matériel(s) Attribué(s)</p>
            )}
            <div className="delete-material-box">
              {myMaterials?.map((material, index) => (
                <div key={material.id} className="delete-material-item">
                  <p
                    className="data-value"
                    style={isAdmin ? { cursor: 'pointer' } : { cursor: 'selection' }}
                    onClick={
                      isAdmin
                        ? () => {
                            navigate(`/material/${Number(material.id)}`)
                          }
                        : () => {}
                    }
                  >
                    {material.inventoryNumber} - {material.model} ({material.type})
                  </p>
                  {editMode && (
                    <DeleteIcon
                      id="delete-icon"
                      onClick={() => {
                        handleDeleteMaterial(material)
                      }}
                    ></DeleteIcon>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialUpdateUser
