import { Status, StatusEnum } from 'constantes'

export type ApiStatus = 'active' | 'inactive'

/**
 * Convertit le Status en ApiStatus
 *
 * @param status
 * @returns
 */
export const toApiStatus = (status: Status): ApiStatus => {
  return status.toLowerCase() as ApiStatus
}

/**
 * Convertit l'ApiStatus en Status
 *
 * @param apiStatus
 * @returns
 */
export const fromApiStatus = (apiStatus: string | null): Status | null => {
  if (!apiStatus) return null
  const lowercaseStatus = apiStatus.toLowerCase()
  return Object.values(StatusEnum).includes(lowercaseStatus as Status)
    ? (lowercaseStatus as Status)
    : null
}
