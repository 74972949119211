import { useMaterialDataValidator } from 'customHooks/useMaterialDataValidator'
import { getMaterialsWithPagination } from 'services/DataWithPaginationService'
import { IMaterialWithPagination } from 'interfaces/dataWithPagination'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import React, { ReactElement, useEffect, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { IUpdateMaterialData } from 'interfaces/materialData'
import { getPlaces } from 'services/SelectorsValuesService'
import { IListSelectorValue } from 'interfaces/selectors'
import { addMaterial } from 'services/MaterialService'
import { materialTypeConstant } from 'constantes'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MaterialForm from './MaterialForm'

const AddMaterial = (): ReactElement => {
  const defaultMaterialValues: IUpdateMaterialData = {
    brand: '',
    model: '',
    serialNumber: '',
    type: materialTypeConstant.PORTABLE,
    inventoryNumber: 0,
    purchaseDate: new Date(),
    leavingDate: null,
    os: '',
    description: '',
    comment: '',
    descriptiveLink: '',
    userAssignment: null,
    isActive: true,
    place: 1
  }

  const [response, setResponse] = useState<IMaterialWithPagination | null>()
  const [places, setPlaces] = useState<IListSelectorValue[]>([])

  // pré remplir le numéro d'inventaire
  useEffect(() => {
    void (async () => {
      setResponse(await getMaterialsWithPagination(1, 1, 'inventoryNumber'))
    })()
    if (response?.data[0].inventoryNumber !== undefined) {
      setNewMaterial({
        ...newMaterial,
        inventoryNumber: Number(response.data[0].inventoryNumber) + 1
      })
    }
  }, [response?.data[0].inventoryNumber])

  useEffect(() => {
    void (async () => {
      setPlaces(await getPlaces())
    })()
  }, [])

  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)
  const [newMaterial, setNewMaterial] = useState<IUpdateMaterialData>(defaultMaterialValues)
  const { errors, validateData } = useMaterialDataValidator(newMaterial)

  const handleSubmit = async (): Promise<void> => {
    if (!validateData()) return
    setLoading(true)
    const material = await addMaterial(newMaterial)
    if (material !== null) {
      navigate('/liste_materiels?tri=updatedAt')
    }
    setLoading(false)
  }

  return (
    <div>
      <PrimaryButton
        handleClick={(): void => {
          navigate(-1)
        }}
        title="Retour"
        icon={<ChevronLeftIcon />}
        background={false}
        reverse={false}
        disabled={false}
      />

      <h3>
        Ajout d&apos; un nouvel <span className="accentuated-title">ordinateur</span>{' '}
      </h3>
      {loading ? (
        <div className="loader">
          <CircularProgress />{' '}
        </div>
      ) : (
        <>
          <MaterialForm
            newMaterial={newMaterial}
            setNewMaterial={setNewMaterial}
            errors={errors}
            places={places}
          />
          <div className="btn-box">
            <PrimaryButton
              handleClick={() => {
                void (async () => {
                  await handleSubmit()
                })()
              }}
              title="Enregistrer"
              background
              reverse={false}
              disabled={false}
            />
            <PrimaryButton
              handleClick={(): void => {
                navigate(-1)
              }}
              title="Annuler"
              background
              reverse
              disabled={false}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default AddMaterial
