import CustomNumberTextField from 'components/inputs/customNumberTextField/CustomNumberTextField'
import { IDetailCollaborateurData, IUpdateUserData } from 'interfaces/collaborateurData'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { IListSelectorValue } from 'interfaces/selectors'
import React, { ReactElement } from 'react'

interface IPracticleInfos {
  user: IDetailCollaborateurData | null
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  places: IListSelectorValue[]
  roles: IListSelectorValue[]
}

const PracticleInfos = ({
  user,
  editMode,
  userDataToSend,
  setUserDataToSend,
  places,
  roles
}: IPracticleInfos): ReactElement => {
  const handleSelect = (
    newValue: number | string | null | number[],
    propertyToUpdate: string
  ): void => {
    setUserDataToSend({
      ...userDataToSend,
      [propertyToUpdate]: newValue
    })
  }

  // Permet d'obtenir l'objet role avec le plus de privilèges
  const getRoles = (
    roles: IListSelectorValue[],
    user: IDetailCollaborateurData | undefined
  ): string => {
    const rolesList = roles.filter((role) => {
      return role.name !== undefined && user?.roles.includes(role.name)
    })
    return rolesList.map((role) => role.label).join(', ')
  }

  return (
    <div className="box-data">
      <p className="category-title">Informations pratiques</p>
      <div className="box-inline-elts">
        <div className="box-column">
          {editMode ? (
            <CustomSelect
              value={userDataToSend.rolePermissions}
              options={roles}
              handleSelect={(newValue) => {
                handleSelect(newValue, 'rolePermissions')
              }}
              id="rolePermissions"
              width={300}
              inputLabel="Role"
              required
              multiple={true}
            />
          ) : (
            <div>
              <p className="data-title">Role</p>
              <p className="data-value">{user !== null ? getRoles(roles, user) : ''}</p>
            </div>
          )}
        </div>
        <div className="box-column">
          {editMode ? (
            <CustomSelect
              value={userDataToSend.place}
              options={places}
              handleSelect={(newValue) => {
                handleSelect(newValue, 'place')
              }}
              id="place"
              width={300}
              inputLabel="Lieu"
              required
            />
          ) : (
            <div>
              <p className="data-title">Lieu</p>
              <p className="data-value">{user?.place?.name}</p>
            </div>
          )}
        </div>
        <div className="box-column">
          {editMode ? (
            <div className="number-field">
              <CustomNumberTextField
                onChange={(value) => {
                  setUserDataToSend({ ...userDataToSend, badgeId: value })
                }}
                id="badgeId"
                value={userDataToSend.badgeId}
                errorMessage=""
                required={false}
                label="Badge ID"
              />
            </div>
          ) : (
            <div>
              <p className="data-title">Badge ID</p>
              <p className="data-value">{user?.badgeId}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PracticleInfos
