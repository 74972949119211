import {
  ChartsAxisHighlight,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer
} from '@mui/x-charts'
import React, { ReactElement, useEffect, useState } from 'react'
import { formatDateToDay } from '../../../utils/dateUtils'
import { IIncome } from '../../../interfaces/career'
import { incomeTypeList } from '../../../constantes'

interface IChartIncome {
  incomes: IIncome[]
}

const ChartIncome = ({ incomes }: IChartIncome): ReactElement => {
  const [data, setData] = useState<Array<number | null>>([])

  useEffect(() => {
    let sum = 0
    const points: Array<number | null> = []

    incomes.forEach((income) => {
      if (income.type === incomeTypeList.INCREASE || income.type === incomeTypeList.SALARY) {
        sum += income.amount
        points.push(sum)
      } else {
        points.push(sum)
      }
    })

    setData(points)
  }, [incomes])

  return (
    <div className="chart-container">
      <div className="bold">Evolution du salaire depuis l&apos;arrivée</div>
      <ResponsiveChartContainer
        colors={['#0700E0']}
        xAxis={[
          {
            data: incomes.map((income) => formatDateToDay(income.date.toString())),
            scaleType: 'point'
          }
        ]}
        series={[
          {
            data,
            type: 'line',
            showMark: true,
            disableHighlight: false
          }
        ]}
        height={300}
      >
        {incomes
          .filter((income) => income.type === incomeTypeList.BONUS)
          .map((item) => (
            <ChartsReferenceLine
              key={item.id}
              x={formatDateToDay(item.date.toString())}
              label={`${item.amount}€`}
              labelAlign="start"
              labelStyle={{ stroke: '#7B01D4' }}
              lineStyle={{ stroke: '#7B01D4' }}
            />
          ))}
        <LinePlot />
        <MarkPlot />
        <ChartsXAxis />
        <ChartsYAxis />
        <ChartsTooltip />
        <ChartsAxisHighlight x={'line'} y={'none'} />
      </ResponsiveChartContainer>
    </div>
  )
}

export default ChartIncome
