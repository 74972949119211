import { IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import React, { ReactElement, useState } from 'react'

interface CustomSelectArrowsProps {
  options: string[]
  value: string
  width?: number
  required?: boolean
  className?: string
  handleChange: (value: string) => void
}

const CustomSelectArrows = ({
  options,
  value,
  width = 200,
  required = false,
  handleChange
}: CustomSelectArrowsProps): ReactElement => {
  const [selectedIndex, setSelectedIndex] = useState<number>(options.indexOf(value))

  const handleSelectChange = (event: SelectChangeEvent<number>): void => {
    const selectedIndex = event.target.value as number
    setSelectedIndex(selectedIndex)
    handleChange(options[selectedIndex])
  }

  const handleArrowClick = (position: string): void => {
    let newIndex
    if (position === 'left') {
      newIndex = selectedIndex > 0 ? selectedIndex - 1 : options.length - 1
    } else {
      newIndex = selectedIndex < options.length - 1 ? selectedIndex + 1 : 0
    }

    setSelectedIndex(newIndex)
    handleChange(options[newIndex])
  }

  return (
    <div className="bg-white rounded-lg flex justify-center border border-purple-500">
      <IconButton
        size="medium"
        className="ml-1"
        onClick={() => {
          handleArrowClick('left')
        }}
      >
        <ArrowBackIos className="text-purple-500" />
      </IconButton>
      <Select
        value={selectedIndex}
        onChange={handleSelectChange}
        style={{ width }}
        variant="standard"
        disableUnderline
        required={required}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={index}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <IconButton
        size="medium"
        onClick={() => {
          handleArrowClick('right')
        }}
      >
        <ArrowForwardIos className="text-purple-500" />
      </IconButton>
    </div>
  )
}

export default CustomSelectArrows
