import AutoCompleteUserInput from 'components/inputs/autoCompleteInput/AutoCompleteUserInput'
import CustomAutoComplete from 'components/inputs/autoCompleteInput/AutoCompleteClientInput'
import CustomSelectArrows from 'components/inputs/customSelect/CustomSelectArrows'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../components/inputs/button/PrimaryButton'
import TwoChoicesButton from 'components/inputs/button/TwoChoicesButton'
import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import CustomSelect from 'components/inputs/customSelect/CustomSelect'
import { getAllUsers } from 'services/SelectorsValuesService'
import { enumerateDaysBetweenDates } from 'utils/dateUtils'
import { hasAccountantRole } from '../../utils/otherUtils'
import { IYearSelectedContext } from 'interfaces/context'
import { CircularProgress } from '@material-ui/core'
import { IosShare } from '@mui/icons-material'
import { IProject } from 'interfaces/projects'
import { CurrentUserContext } from '../../App'
import { IClient } from 'interfaces/clients'
import { IconButton } from '@mui/material'
import { monthsList } from 'constantes'
import { toast } from 'react-toastify'
import moment from 'moment'

export interface ReportingHeaderProps {
  users: IMinimalCollaborateurData[]
  retrieveUsers: (inputValue: string) => Promise<IMinimalCollaborateurData[]>
  currentUser: IMinimalCollaborateurData | null
  setCurrentUser: (user: IMinimalCollaborateurData | null) => void
  year: number
  yearSelectedContext: IYearSelectedContext | null
  month: number
  setMonth: (month: number) => void
  setListDates: React.Dispatch<React.SetStateAction<string[]>>
  isSmallScreen: boolean
  handleExport: () => void
  handleLock: (toLock: boolean) => void
  loading: boolean
  isLocked: boolean
  currentTab: number
  setClient: (client: string | null) => Promise<void>
  setProject: (project: string) => void
  clientOptions: string[]
  projectOptions: string[]
  selectedClient: IClient | undefined
  selectedProject: IProject | undefined
  isBillable: boolean
  setIsBillable: React.Dispatch<React.SetStateAction<boolean>>
  place: number
}

const ReportingHeader = ({
  users,
  retrieveUsers,
  currentUser,
  setCurrentUser,
  year,
  yearSelectedContext,
  month,
  setMonth,
  setListDates,
  isSmallScreen,
  handleExport,
  loading,
  isLocked,
  handleLock,
  currentTab,
  setClient,
  setProject,
  clientOptions,
  projectOptions,
  selectedClient,
  selectedProject,
  isBillable,
  setIsBillable,
  place
}: ReportingHeaderProps): ReactElement => {
  const [years, setYears] = useState<string[]>([])
  const [allUsers, setAllUsers] = useState<any>([])
  const isAdmin =
    hasAccountantRole(useContext(CurrentUserContext)?.currentUser?.rolePermissions ?? []) ?? false

  // Alimentation du sélecteur d'année
  useEffect(() => {
    const currentYear = new Date().getFullYear()
    setYears([
      (currentYear + 1).toString(),
      ...Array.from({ length: currentYear - 2018 }, (_, index) => (currentYear - index).toString())
    ])
    setDayListOnCurrentMonthOrWeek(new Date(year, month - 1))
    void getFiltersFromURL()
  }, [place])

  useEffect(() => {
    const fetchAllUsers = async (): Promise<void> => {
      try {
        const response = await getAllUsers(true, place)
        setAllUsers(response?.data)
      } catch (error) {
        toast.error('Aucun utilisateur trouvé')
      }
    }
    void fetchAllUsers()
  }, [place])

  const getFiltersFromURL = async (): Promise<void> => {
    const params = new URLSearchParams(location.search)

    const lastName: string = params.get('name') ?? ''
    const monthParam: number = Number(params.get('month'))
    const yearParam: number = Number(params.get('year'))
    const foundUsers: IMinimalCollaborateurData[] = await retrieveUsers(lastName)
    const userParam = foundUsers[0]

    if (userParam) {
      setCurrentUser(userParam)
    }
    if (monthParam) {
      setMonth(monthParam)
      setDayListOnCurrentMonthOrWeek(new Date(year, monthParam - 1))
    }
    if (yearParam) {
      yearSelectedContext?.setYearSelected(yearParam)
    }
  }

  const handleChangeYear = (newYear: number): void => {
    yearSelectedContext?.setYearSelected(newYear)
    setDayListOnCurrentMonthOrWeek(new Date(newYear, month - 1))
  }

  const handleChangeMonth = (newMonth: string): void => {
    const currentMonth =
      Number(monthsList.indexOf(typeof newMonth === 'string' ? newMonth : '')) + 1
    setMonth(currentMonth)
    setDayListOnCurrentMonthOrWeek(new Date(year, currentMonth - 1))
  }

  const setDayListOnCurrentMonthOrWeek = (date: Date): void => {
    const validDateToGetInterval = moment(date).add(1, 'day')
    let dates = ['']

    dates = enumerateDaysBetweenDates(
      validDateToGetInterval.startOf('month').toDate(),
      validDateToGetInterval.endOf('month').toDate()
    )
    setListDates(dates)
  }

  return (
    <>
      <div className="flex flex-row gap-2 mx-1 flex-wrap">
        {currentTab === 0 && (
          <div className="pt-1.5">
            <AutoCompleteUserInput
              value={currentUser ?? null}
              onChange={(e: any, newValue: IMinimalCollaborateurData | null): void => {
                if (newValue !== null) {
                  setCurrentUser(newValue)
                }
              }}
              id="userAssignmentId"
              options={users.length > 0 ? users : allUsers}
              setOptions={(inputValue: string) => {
                void (async () => {
                  await retrieveUsers(inputValue)
                })()
              }}
            />
          </div>
        )}
        <div className="pt-1.5">
          <CustomSelectArrows
            key={month}
            value={monthsList[month - 1]}
            options={monthsList}
            handleChange={(newValue: string) => {
              handleChangeMonth(newValue)
            }}
            required={false}
            width={150}
          />
        </div>
        <div className="pt-1.5">
          <CustomSelect
            value={year}
            options={years}
            handleSelect={(newValue) => {
              handleChangeYear(Number(newValue))
            }}
            id="year"
            width={100}
            inputLabel=""
            showDelete={false}
          />
        </div>
        {currentTab === 1 && (
          <>
            <div className="pt-1.5">
              <CustomAutoComplete
                value={selectedClient?.name ?? ''}
                onChange={(e: any, newValue: string | null): void => {
                  void setClient(newValue)
                }}
                id="client"
                options={clientOptions}
                setOptions={(newValue: string | null): void => {
                  void setClient(newValue)
                }}
                width={200}
              />
            </div>
            <div className="pt-1.5">
              <CustomSelect
                value={selectedProject?.name ?? ''}
                options={projectOptions}
                handleSelect={(newValue) => {
                  setProject(newValue)
                }}
                id="project"
                width={260}
                inputLabel=""
                showDelete={false}
              />
            </div>
            <div className="pt-4">
              <TwoChoicesButton
                title1="Facturable"
                title2="Non facturable"
                onChange={setIsBillable}
                defaultValue={isBillable}
                color="violet"
              />
            </div>
          </>
        )}
        {!isSmallScreen && currentTab === 0 && (
          <div className="flex ml-auto text-blue-700 hover:text-blue-500">
            {isAdmin && (
              <PrimaryButton
                handleClick={handleLock}
                title={isLocked ? 'Débloquer le reporting' : 'Bloquer le reporting'}
                background
                reverse={isLocked}
                disabled={false}
                className={!isLocked ? 'red' : ''}
              />
            )}
            <button
              className="flex items-center space-x-2 pl-6"
              onClick={() => {
                !loading && handleExport()
              }}
            >
              <p className="font-black items-center">Exporter</p>
              {loading ? (
                <div className="loader-small">
                  <CircularProgress size="small" />
                </div>
              ) : (
                <IconButton style={{ backgroundColor: 'blue', color: 'white' }}>
                  <IosShare className="p-2"></IosShare>
                </IconButton>
              )}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default ReportingHeader
