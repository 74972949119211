import HolidayRequest from './components/holidayRequest/HolidayRequest'
import React, { ReactElement, useContext, useState } from 'react'
import { CurrentUserContext, YearSelectedContext } from 'App'
import MyRequests from './components/myRequests/MyRequests'
import MyBalance from './components/MyBalance'
import { useParams } from 'react-router-dom'
import moment from 'moment'

const MyHolidays = (): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser

  const yearSelectedContext = useContext(YearSelectedContext)
  const year = Number(yearSelectedContext?.yearSelected) ?? moment().year()

  const [reloadData, setReloadData] = useState<boolean>(false)
  const { userId } = useParams<{ userId: string }>()

  return (
    <div className="my-holidays">
      <div className="my-holidays-header">
        <h3>
          Mes <span className="accentuated-title">congés</span>
        </h3>
      </div>

      <div className="myholidays-content">
        <div className="myholidays-data">
          <MyBalance
            userId={Number(userId) ?? currentUser?.id}
            year={year}
            reloadData={reloadData}
            setReloadData={setReloadData}
          />
          <HolidayRequest userId={currentUser?.id} year={year} setReloadData={setReloadData} />
        </div>
        <MyRequests
          reloadData={reloadData}
          setReloadData={setReloadData}
          userId={Number(userId) ?? currentUser?.id}
        />
      </div>
    </div>
  )
}

export default MyHolidays
