import { DayInfo, IPlanningDate } from '../interfaces/planning'
import { isHolidayOrWeekendDay, isToday } from './dateUtils'
import { dayDurationConstant, statusEnum } from 'constantes'

/**
 * Renvoie la couleur de fond d'un jour en fonction des données de planification utilisateur.
 *
 * @param day - La date du jour.
 * @param planningDates
 * @return DayInfo La couleur de fond du jour.
 */
const getDayBackgroundAndDayType = (day: string, planningDates: IPlanningDate[]): DayInfo => {
  // Récupère les dates pour un même jour (gestion demi-journées)
  const userPlanningDateFound = planningDates.filter((planning) => planning.dayDate === day)
  let color = ''
  let dayType = '-'

  if (userPlanningDateFound.length > 0) {
    if (userPlanningDateFound[0].duration === dayDurationConstant.JOURNEE) {
      color = userPlanningDateFound[0]?.dayType?.colorCode ?? ''
      dayType = userPlanningDateFound[0]?.dayType?.abbreviation ?? '-'
    } else {
      const [midDay1, midDay2] = userPlanningDateFound
      let leftColor = ''
      let rightColor = ''

      if (userPlanningDateFound[0].duration === dayDurationConstant.MATIN) {
        leftColor = midDay1?.dayType?.colorCode ?? 'transparent'
        rightColor = midDay2?.dayType?.colorCode ?? 'transparent'
        dayType = `${String(midDay1?.dayType?.abbreviation ?? '-')}/${String(
          midDay2?.dayType?.abbreviation ?? '-'
        )}`
      } else {
        leftColor = midDay2?.dayType?.colorCode ?? 'transparent'
        rightColor = midDay1?.dayType?.colorCode ?? 'transparent'
        dayType = `${String(midDay2?.dayType?.abbreviation ?? '-')}/${String(
          midDay1?.dayType?.abbreviation ?? '-'
        )}`
      }

      color = `linear-gradient(to bottom right, ${leftColor} 50%, ${rightColor} 50%)`
    }
  }

  return {
    background: color,
    dayType
  }
}

/**
 * Savoir si le jour attend une validation
 * @param day
 * @param planningDates
 */
const isDayInValidation = (day: string, planningDates: IPlanningDate[]): boolean => {
  // Récupère les dates pour un même jour (gestion demi-journées)
  const userPlanningDateFound = planningDates.filter((planning) => planning.dayDate === day)

  if (userPlanningDateFound.length === 0) {
    return false
  }
  // Vérifie si au moins une demi-journée qui ne respecte pas les règles
  return userPlanningDateFound.some(
    (planningDate) => planningDate.status === statusEnum.TO_VALIDATE
  )
}

/**
 * Vérifie que les jours respectent les règles
 * @param day
 * @param planningDates
 */
const followDayRules = (day: string, planningDates: IPlanningDate[]): boolean => {
  // Récupère les dates pour un même jour (gestion demi journées)
  const userPlanningDateFound = planningDates.filter((planning) => planning.dayDate === day)

  if (userPlanningDateFound.length === 0) {
    return true
  }
  // Vérifie si au moins une demi journée qui ne respecte pas les règles
  return !userPlanningDateFound.some((planningDate) => planningDate.followDayRules === false)
}

/**
 * Récupères les classes pour afficher un jour sur le calendrier
 * @param day
 * @param holidayDates
 * @param isEditable
 * @param planningDates
 * @param defaultClass
 */
const getDayButtonClass = (
  day: string,
  holidayDates: string[],
  isEditable: boolean,
  planningDates: IPlanningDate[],
  defaultClass: string[]
): string => {
  const classNames = [...defaultClass]

  if (isEditable) {
    classNames.push('days-hover')
  }

  if (isHolidayOrWeekendDay(day, holidayDates)) {
    classNames.push('light-greyText')
  }

  if (isToday(day) && followDayRules(day, planningDates)) {
    classNames.push('today-date')
  }

  if (!followDayRules(day, planningDates)) {
    if (
      !isDayInValidation(day, planningDates) &&
      getDayBackgroundAndDayType(day, planningDates).dayType.includes('T')
    ) {
      classNames.push('TT-respected-special-rule-day')
    } else {
      classNames.push('no-respected-rule-day')
    }
  } else {
    classNames.push('day-respected day-border')
  }

  return classNames.join(' ')
}

export { getDayBackgroundAndDayType, isDayInValidation, followDayRules, getDayButtonClass }
