import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import React, { ReactElement, useState } from 'react'

interface ITwoChoiceButton {
  title1: string
  title2: string
  defaultValue: boolean
  onChange: (value: boolean) => void
  color?: string
}

const TwoChoicesButton = ({
  onChange,
  title1,
  title2,
  defaultValue = false,
  color = 'blue'
}: ITwoChoiceButton): ReactElement => {
  const [value, setValue] = useState<boolean>(defaultValue)

  const handleClick = (newValue: boolean): void => {
    if (newValue !== value) {
      setValue(newValue)
      onChange(newValue)
    }
  }

  return (
    <div className="flex items-center text-sm flex-wrap">
      <div
        className="cursor-pointer flex items-center mr-4"
        onClick={() => {
          handleClick(true)
        }}
      >
        {value ? (
          <RadioButtonCheckedIcon
            className={color === 'violet' ? 'text-violet-600 pr-1' : 'text-blue-600 pr-1'}
          />
        ) : (
          <RadioButtonUncheckedIcon
            className={color === 'violet' ? 'text-violet-600 pr-1' : 'text-blue-600 pr-1'}
          />
        )}
        {title1}
      </div>
      <div
        className={'cursor-pointer flex items-center'}
        onClick={() => {
          handleClick(false)
        }}
      >
        {!value ? (
          <RadioButtonCheckedIcon
            className={color === 'violet' ? 'text-violet-600 pr-1' : 'text-blue-600 pr-1'}
          />
        ) : (
          <RadioButtonUncheckedIcon
            className={color === 'violet' ? 'text-violet-600 pr-1' : 'text-blue-600 pr-1'}
          />
        )}
        {title2}
      </div>
    </div>
  )
}

export default TwoChoicesButton
