import ConfirmationPopUp from '../../../components/inputs/confirmationPopUp/ConfirmationPopUp'
import MessagePopUp from '../../../components/popup/messagePopUp/MessagePopUp'
import { deleteMessage, getMessages } from '../../../services/MessageService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { formatDateTime } from '../../../utils/dateUtils'
import { IMessage } from '../../../interfaces/message'
import MessageIcon from '@mui/icons-material/Message'
import DeleteIcon from '@mui/icons-material/Delete'
import { PlaceSelectedContext } from '../../../App'
import { CircularProgress } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

interface MessagesProps {
  createNewMessage: boolean
  setCreateNewMessage: (create: boolean) => void
}
const Messages = ({ createNewMessage, setCreateNewMessage }: MessagesProps): ReactElement => {
  const placeSelectedContext = useContext(PlaceSelectedContext)
  const place = placeSelectedContext?.placeSelected ?? null
  const [loading, setLoading] = useState<boolean>(true)
  const [messages, setMessages] = useState<IMessage[]>([])
  const [messageToEdit, setMessageToEdit] = useState<IMessage>()
  const [openMessagePopup, setOpenMessagePopup] = useState<boolean>(false)
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false)
  const [messageId, setMessageId] = useState<string | undefined>(undefined)

  useEffect(() => {
    setLoading(true)
    void (async () => {
      const messages = await getMessages(false, place ?? 1)
      setMessages(messages)
      setLoading(false)
    })()
  }, [place])

  useEffect(() => {
    setMessageToEdit(undefined)
    setOpenMessagePopup(createNewMessage)
  }, [createNewMessage])

  const handleDelete = async (): Promise<void> => {
    if (messageId !== undefined) {
      setLoading(true)
      const messages = await deleteMessage(messageId)
      setMessages(messages)
      setLoading(false)
      setOpenDeletePopup(false)
      setMessageId(undefined)
    }
  }

  return (
    <div className="metric-card">
      {loading ? (
        <div className="d-flex">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="metric-card__title last-activities">
            <MessageIcon /> <h5>Prochaines publications</h5>
          </div>
          <div>
            {messages.length === 0 && <p>Aucune publication à venir</p>}
            {messages.map((message, index) => {
              return (
                <div className="message-info" key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: message.text ?? ''
                    }}
                  ></div>
                  <div className="d-flex">
                    <div>
                      <p>{`${formatDateTime(message.startDate.toString())} - ${formatDateTime(
                        message.endDate.toString()
                      )}`}</p>
                    </div>
                    <div className="update-icons">
                      <EditIcon
                        id="edit-icon"
                        className="icon"
                        onClick={() => {
                          setMessageToEdit(message)
                          setOpenMessagePopup(true)
                        }}
                      />
                      <DeleteIcon
                        id="delete-icon"
                        onClick={() => {
                          setMessageId(message.id)
                          setOpenDeletePopup(true)
                        }}
                      ></DeleteIcon>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
      <ConfirmationPopUp
        dialogTitle="Voulez-vous supprimer ce message ?"
        dialogContentText="Les données de ce message seront supprimées"
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        handleSave={handleDelete}
        loading={loading}
      />
      <MessagePopUp
        open={openMessagePopup}
        setOpen={(open: boolean) => {
          setOpenMessagePopup(open)
          setCreateNewMessage(open)
          if (!open) {
            setMessageToEdit(undefined)
          }
        }}
        messageToEdit={messageToEdit}
        setMessages={(messages: IMessage[]) => {
          setCreateNewMessage(false)
          setMessageToEdit(undefined)
          setMessages(messages)
        }}
      />
    </div>
  )
}

export default Messages
