import React, { ReactElement } from 'react'
import Chart from 'react-apexcharts'

interface IStatChart {
  title: string
  subtitle: string
  options: ApexCharts.ApexOptions
  series: ApexAxisChartSeries | ApexNonAxisChartSeries
  width: number
}

const StatChart = ({ title, subtitle, options, series, width }: IStatChart): ReactElement => {
  return (
    <div className="bg-white rounded-md flex px-6 w-[90%] lg:w-[32%] items-center justify-center">
      <div className="w-[50%]">
        <div className="text-md font-black">{title}</div>
        <div className="text-sm font-thin">{subtitle}</div>
      </div>
      <div className="w-[52%]">
        <Chart options={options} series={series} type="radialBar" width={width} />
      </div>
    </div>
  )
}

export default StatChart
