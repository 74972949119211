import { IDetailCollaborateurData, IUpdateUserData } from 'interfaces/collaborateurData'
import CustomTextField from 'components/inputs/customTextFIeld/CustomTextField'
import React, { ReactElement } from 'react'

interface ICommentaryInfos {
  user: IDetailCollaborateurData | null
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
}

const CommentaryInfos = ({
  user,
  editMode,
  userDataToSend,
  setUserDataToSend
}: ICommentaryInfos): ReactElement => {
  return (
    <div className="box-data">
      <div className="category-title">Commentaire</div>
      <div className="box-inline-elts">
        <div className="box-column">
          <div>
            {editMode ? (
              <CustomTextField
                onChange={(value) => {
                  setUserDataToSend({ ...userDataToSend, commentary: value })
                }}
                id="commentary"
                value={userDataToSend?.commentary ?? ''}
                errorMessage=""
                required={false}
                label="Commentaire"
                multiline={true}
              />
            ) : (
              <>
                <p className="data-title">Commentaire</p>
                <p className="data-value">{user?.commentary}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentaryInfos
