import React, { ReactElement } from 'react'

interface IPublicationPopUp {
  text: string
  className: string
}

const Publication = ({ text, className }: IPublicationPopUp): ReactElement => {
  return (
    <div className={`publication-banner ${className}`}>
      <div
        className="text-animation"
        dangerouslySetInnerHTML={{
          __html: text ?? ''
        }}
      ></div>
    </div>
  )
}

export default Publication
