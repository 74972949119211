import React, { ReactElement } from 'react'

import { IMinimalCollaborateurData } from 'interfaces/collaborateurData'
import { calculateTotalForDay } from 'utils/dateUtils'
import ReportingTableRow from './ReportingTableRow'
import { IReporting } from 'interfaces/reporting'

export interface ReportingTableContentProps {
  listDates: string[]
  year: number
  month: number
  reporting: IReporting[]
  isBillable: boolean
  modifiedReportings: IReporting[]
  setModifiedReportings: React.Dispatch<React.SetStateAction<IReporting[]>>
  handleDelete: (reportingId: number) => void
  setReporting: React.Dispatch<React.SetStateAction<IReporting[]>>
  handleSaveLine: (item: IReporting) => void
  isSmallScreen: boolean
  holidayDates: string[]
  isLocked: boolean
  hoveredDay: string | null
  setHoveredDay: (day: string | null) => void
  currentUser: IMinimalCollaborateurData | null
}

const ReportingTableContent = ({
  year,
  month,
  listDates,
  reporting,
  isBillable,
  modifiedReportings,
  setModifiedReportings,
  handleDelete,
  setReporting,
  handleSaveLine,
  isSmallScreen,
  holidayDates,
  isLocked,
  hoveredDay,
  setHoveredDay,
  currentUser
}: ReportingTableContentProps): ReactElement => {
  const groupedReports: Record<string, IReporting[]> = {}
  reporting.forEach((report) => {
    const projectId = report.project?.id ?? 'unknown'
    if (!groupedReports[projectId]) {
      groupedReports[projectId] = []
    }
    groupedReports[projectId].push(report)
  })

  // Calcule le total pointé sur chaque journée
  const totalForDay = calculateTotalForDay(reporting, listDates)

  return (
    <>
      <tr className="text-white mb-4 text-center">
        <td colSpan={isSmallScreen ? listDates.length + 4 : listDates.length + 7}>
          {isBillable ? (
            <h5
              className={'font-black rounded-md py-1 mb-3 mt-8'}
              style={{ backgroundColor: '#fb923c', fontSize: '14px' }}
            >
              {'Activités facturables'}
            </h5>
          ) : (
            <h5
              className={'font-black rounded-md py-1 mb-3 mt-8'}
              style={{ backgroundColor: '#1653f0', fontSize: '14px' }}
            >
              {'Activités non facturables'}
            </h5>
          )}
        </td>
      </tr>

      {isSmallScreen ? (
        // Ligne tableau reporting mobile
        reporting.map(
          (item, index) =>
            item.isBillable === isBillable && (
              <ReportingTableRow
                key={index}
                isBillable={isBillable}
                listDates={listDates}
                year={year}
                month={month}
                handleDelete={handleDelete}
                setReporting={setReporting}
                modifiedReportings={modifiedReportings}
                setModifiedReportings={setModifiedReportings}
                handleSaveLine={handleSaveLine}
                item={item}
                index={index}
                totalForDay={totalForDay}
                isFirstReportingOfProject={true}
                isSmallScreen={isSmallScreen}
                holidayDates={holidayDates}
                isLocked={isLocked}
                hoveredDay={hoveredDay}
                setHoveredDay={setHoveredDay}
              />
            )
        )
      ) : (
        // Ligne tableau reporting desktop
        <>
          {reporting.length ? (
            <tr className={'border-l-4 ' + (isBillable ? 'border-orange-400' : 'border-blue-600')}>
              <td className="font-black pl-3" style={{ fontSize: '12px' }} colSpan={1}>
                Client
              </td>
              <td className="font-black" style={{ fontSize: '12px' }} colSpan={1}>
                Projet
              </td>
              <td className="font-black pl-3" style={{ fontSize: '12px' }} colSpan={1}>
                Description
              </td>
            </tr>
          ) : null}

          {/* Groupe les lignes du reporting par projets pour pouvoir les plier et déplier */}
          {groupedReports && reporting.length ? (
            Object.keys(groupedReports).map((projectId: string) => {
              const projectReports = groupedReports[projectId]
              return projectReports
                .filter((item: IReporting) => item.isBillable === isBillable)
                .map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <>
                        <ReportingTableRow
                          isBillable={isBillable}
                          listDates={listDates}
                          year={year}
                          month={month}
                          handleDelete={handleDelete}
                          setReporting={setReporting}
                          modifiedReportings={modifiedReportings}
                          setModifiedReportings={setModifiedReportings}
                          handleSaveLine={handleSaveLine}
                          item={item}
                          index={index}
                          totalForDay={[0]}
                          isFirstReportingOfProject={index === 0}
                          isSmallScreen={isSmallScreen}
                          holidayDates={holidayDates}
                          isLocked={isLocked}
                          hoveredDay={hoveredDay}
                          setHoveredDay={setHoveredDay}
                        />
                      </>
                    </React.Fragment>
                  )
                })
            })
          ) : (
            <tr className="text-center pt-2 italic">
              <td colSpan={6}>Pas d&apos;activité</td>
            </tr>
          )}
        </>
      )}
    </>
  )
}

export default ReportingTableContent
