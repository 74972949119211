import {
  StatusEnum,
  featuresList,
  menuItemConstant,
  myDocumentRequestConstant,
  profilTabList
} from 'constantes'
import {
  getContractTypes,
  getJobs,
  getPlaces,
  getRoles,
  getServices
} from 'services/SelectorsValuesService'
import { CurrentUserContext, FeaturesContext, SelectedItemContext, YearSelectedContext } from 'App'
import { createUserExpenseSettings, updateUserExpenseSetting } from 'services/ExpensesService'
import { createOrUpdateCollab, getSelfUserData, getUserById } from 'services/UserService'
import { IDetailCollaborateurData, IUpdateUserData } from 'interfaces/collaborateurData'
import ConfirmationPopUp from 'components/inputs/confirmationPopUp/ConfirmationPopUp'
import { isFeatureEnabled, isTabEnabled } from '../../services/SettingService'
import { IListSelectorValue, IServiceAccountData } from 'interfaces/selectors'
import { getPersonalDocumentsFiles } from '../../services/MyDocumentsService'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { IUsersHolidayQuota } from 'interfaces/holidays/userHolidayQuota'
import { createOrUpdateUsersHolidayQuota } from 'services/HolidayService'
import TabNavigation from '../../components/tabNavigation/TabNavigation'
import ListItemsFooter from 'components/listItemsFooter/ListItemsFooter'
import Notifications from './dataInfos/notifications/Notifications'
import ReportingSwitch from './dataInfos/reporting/ReportingSwitch'
import MaterialUpdateUser from './dataInfos/material/MaterialInfos'
import PrimaryButton from 'components/inputs/button/PrimaryButton'
import { IGetMinimalMaterialData } from 'interfaces/materialData'
import { saveUserPicture } from 'services/ProfilePictureService'
import { IMyDocumentsDrive } from '../../interfaces/myDocuments'
import Permission from '../../components/permission/Permission'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CircularProgress from '@mui/material/CircularProgress'
import MyDocumentsTab from '../myDocuments/MyDocumentsTab'
import CommentaryInfos from './dataInfos/CommentaryInfos'
import { ISelectedItemContext } from 'interfaces/context'
import { useNavigate, useParams } from 'react-router-dom'
import PracticleInfos from './dataInfos/PracticleInfos'
import { IProfilTab } from '../../interfaces/headCells'
import { hasManagerRole } from '../../utils/otherUtils'
import ProfilHeader from './profilHeader/ProfilHeader'
import MealPlanInfos from './dataInfos/MealPlanInfos'
import PersonalInfos from './dataInfos/PersonalInfos'
import Expenses from './dataInfos/expenses/Expenses'
import Services from './dataInfos/services/Services'
import BankingInfos from './dataInfos/BankingInfos'
import HolidayInfos from './dataInfos/HolidayInfos'
import ErrorIcon from '@mui/icons-material/Info'
import { IMyExpense } from 'interfaces/expenses'
import EntryLeave from './dataInfos/EntryLeave'
import CareerInfos from './career/CareerInfos'
import { isValidDate } from 'utils/dateUtils'
import ProInfos from './dataInfos/ProInfos'
import Costs from './costs/Costs'
import moment from 'moment'
import './Profil.scss'

const Profil = (): ReactElement => {
  const userContext = useContext(CurrentUserContext)
  const currentUser = userContext?.currentUser
  const selectedItemContext = useContext<ISelectedItemContext | null>(SelectedItemContext)
  const yearSelectedContext = useContext(YearSelectedContext)
  const featuresContext = useContext(FeaturesContext)
  const currentYearSelected = yearSelectedContext?.yearSelected ?? moment().year()
  const navigate = useNavigate()
  const { userId } = useParams()
  const queryParameters = new URLSearchParams(window.location.search)

  const [currentTab, setCurrentTab] = useState<number>(
    queryParameters.get('tab')
      ? profilTabList.findIndex((item: IProfilTab) => item.label === queryParameters.get('tab'))
      : 0
  )
  const [user, setUser] = useState<IDetailCollaborateurData | null>(null)
  const [jobs, setJobs] = useState<IListSelectorValue[]>([])
  const [contractTypes, setContractTypes] = useState<IListSelectorValue[]>([])
  const [places, setPlaces] = useState<IListSelectorValue[]>([])
  const [roles, setRoles] = useState<IListSelectorValue[]>([])
  const [editMode, setEditMode] = useState<boolean>(false)
  const [openUpdateCollabPopUp, setOpenUpdateCollabPopUp] = useState(false)
  const [activeAccounts, setActiveAccount] = useState<IServiceAccountData[]>([])
  const [inactiveAccounts, setInactiveAccount] = useState<IServiceAccountData[]>([])
  const [lastnameError, setLastnameError] = useState<string>('')
  const [firstnameError, setFirstnameError] = useState<string>('')
  const [arrivalDateError, setArrivalDateError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [personalMailError, setPersonalMailError] = useState<string>('')
  const [phoneNumberError, setPhoneNumberError] = useState<string>('')
  const [selectedImg, setSelectedImg] = useState<string | null>(null)
  const [croppedImg, setCroppedImg] = useState<string | null>(null)
  const [myMaterials, setMyMaterials] = useState<IGetMinimalMaterialData[]>([])
  const [documents, setDocuments] = useState<IMyDocumentsDrive[]>([])
  const [userHolidaysQuota, setUserHolidaysQuota] = useState<IUsersHolidayQuota>({
    year: currentYearSelected,
    RTTQuota: 0,
    paidVacationQuota: 0,
    users: []
  })
  const [userExpenses, setUserExpenses] = useState<IMyExpense[]>([])

  const [userDataToSend, setUserDataToSend] = useState<IUpdateUserData>({
    lastname: '',
    firstname: '',
    birthdayDate: null,
    phoneNumber: '',
    job: 2,
    contractType: 3,
    customPicture: '',
    tutor: null,
    rolePermissions: [2],
    place: 1,
    arrivalDate: null,
    leavingDate: null,
    internshipStartDate: null,
    permanentContractStartDate: null,
    badgeId: '',
    mealPlan: '',
    initials: '',
    BIC: '',
    IBAN: '',
    personalMail: '',
    googleAccount: false,
    mattermostAccount: false,
    merakiAccount: false,
    jetbrainsAccount: false,
    gitlabAccount: false,
    bitwardenAccount: false,
    jiraAccount: false,
    updateMaterials: [],
    endTrialPeriodDate: null,
    endInternshipDate: null,
    commentary: '',
    reportingEnabled: true
  })

  // Chargement des listes sauf roles
  useEffect(() => {
    void (async () => {
      setJobs(await getJobs())
      setContractTypes(await getContractTypes())
      setPlaces(await getPlaces())
    })()
  }, [])

  useEffect(() => {
    if (currentTab === 3 && Number(userId)) {
      void (async () => {
        setDocuments(await getPersonalDocumentsFiles(Number(userId)))
      })()
    }
  }, [userId, currentTab])

  // Mettre à jour le nouvel utilisateur
  useEffect(() => {
    setLoading(true)
    void (async () => {
      // On a besoin d'attendre que roles soit chargé pour récupérer le user
      const roles = await getRoles()
      setRoles(roles)
      // Si consultation de son propre profil alors appel à 'user/self' afin de pouvoir l'afficher qu'on soit admin ou non car le back l'autorise
      if (Number(userId) === currentUser?.id) {
        setUser(await getSelfUserData(Number(yearSelectedContext?.yearSelected)))
        selectedItemContext?.setSelectedItem(menuItemConstant.PROFIL)
      } else {
        setUser(await getUserById(Number(userId), Number(yearSelectedContext?.yearSelected)))
        selectedItemContext?.setSelectedItem('')
      }
    })()
  }, [userId])

  // Initialiser le role du user en etant sûr d'avoir eu le temps de charger les roles
  useEffect(() => {
    if (user === null || roles.length === 0) return
    setUserDataToSend({
      ...userDataToSend,
      rolePermissions: getRolesIds(roles, user) ?? [1]
    })
  }, [roles])

  // Initialiser userDataToSend + active,inactive users + allimenter les listes et récupérer le nouvel utilisateur
  useEffect(() => {
    user !== null && loadUserData(user)

    let services
    void (async () => {
      services = await getServices()
      // Récupération des services actifs et inactifs
      if (user !== null) {
        setActiveAccount(getActiveAndInactiveServices(user, services).active)
        setInactiveAccount(getActiveAndInactiveServices(user, services).inactive)
      }
      setLoading(false)
    })()
  }, [user])

  // load des quotas de congés et RTT du collaborateur pour l'année sélectionnée
  useEffect(() => {
    const userHolidaysQuota = user?.holidaysQuota?.find(
      (holiday) => holiday.year === currentYearSelected
    )
    // Si congés trouvé pour le user et l'année sélectionnée
    if (userHolidaysQuota !== undefined) {
      setUserHolidaysQuota(userHolidaysQuota)
    } else {
      setUserHolidaysQuota({
        RTTQuota: 0,
        paidVacationQuota: 0,
        year: currentYearSelected,
        users: []
      })
    }
  }, [user, currentYearSelected])

  // Permet de mettre à jour le reportingEnabled
  const handleReportingEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserDataToSend({
      ...userDataToSend,
      reportingEnabled: event.target.checked
    })
  }

  // Permet d'obtenir l'objet role avec le plus de privilèges
  const getRolesIds = (
    roles: IListSelectorValue[],
    user: IDetailCollaborateurData
  ): number[] | null => {
    if (roles.length === 0) return null

    return roles
      .filter((role) => {
        return role.name !== undefined && user.roles.includes(role.name)
      })
      .map((role) => role.id)
  }

  const loadUserData = (user: IDetailCollaborateurData): void => {
    // load les matériels
    let myMaterialsIds = null
    if (user?.materials !== undefined) {
      setMyMaterials(user.materials)
      myMaterialsIds = user?.materials?.map(({ id }) => ({ id }))
    }

    setUserDataToSend({
      lastname: user.displayLastname ?? '',
      firstname: user.displayFirstname ?? '',
      birthdayDate: user.birthdayDate !== undefined ? new Date(user.birthdayDate ?? '') : null,
      phoneNumber: user.phoneNumber !== undefined ? user.phoneNumber : '',
      job: user.job !== undefined ? user.job.id : 1,
      contractType: user.contractType !== undefined ? user.contractType.id : 1,
      customPicture: '',
      tutor: user.tutor ?? null,
      rolePermissions: getRolesIds(roles, user) ?? [2],
      place: user.place !== undefined ? user.place.id : 1,
      badgeId: user.badgeId ?? '',
      arrivalDate: user.arrivalDate !== undefined ? new Date(user.arrivalDate ?? '') : null,
      leavingDate: user.leavingDate !== undefined ? new Date(user.leavingDate ?? '') : null,
      internshipStartDate:
        user.internshipStartDate !== undefined ? new Date(user.internshipStartDate ?? '') : null,
      permanentContractStartDate:
        user.permanentContractStartDate !== undefined
          ? new Date(user.permanentContractStartDate ?? '')
          : null,
      personalMail: user.personalEmail ?? user?.email ?? '',
      mealPlan: user.mealPlan ?? null,
      initials: user.initials ?? '',
      BIC: user.BIC ?? '',
      IBAN: user.IBAN ?? '',
      googleAccount:
        user.googleAccount !== undefined
          ? (user.googleAccount.isActive || user.googleAccount.willBeActive) ?? false
          : false,
      mattermostAccount:
        user.mattermostAccount !== undefined
          ? (user.mattermostAccount.isActive || user.mattermostAccount.willBeActive) ?? false
          : false,
      merakiAccount:
        user.merakiAccount !== undefined
          ? (user.merakiAccount.isActive || user.merakiAccount.willBeActive) ?? false
          : false,
      jetbrainsAccount:
        user.jetbrainsAccount !== undefined
          ? user.jetbrainsAccount.isActive || user.jetbrainsAccount.willBeActive
          : false,
      gitlabAccount:
        user.gitlabAccount !== undefined
          ? (user.gitlabAccount.isActive || user.gitlabAccount.willBeActive) ?? false
          : false,
      bitwardenAccount:
        user.bitwardenAccount !== undefined
          ? (user.bitwardenAccount.isActive || user.bitwardenAccount.willBeActive) ?? false
          : false,
      jiraAccount:
        user.jiraAccount !== undefined
          ? (user.jiraAccount.isActive || user.jiraAccount.willBeActive) ?? false
          : false,
      updateMaterials: myMaterialsIds ?? [],
      isConfidential: user.isConfidential,
      level: user.level,
      endTrialPeriodDate:
        user.endTrialPeriodDate !== undefined ? new Date(user.endTrialPeriodDate ?? '') : null,
      endInternshipDate:
        user.endInternshipDate !== undefined ? new Date(user.endInternshipDate ?? '') : null,
      commentary: user.commentary ?? '',
      reportingEnabled: user.reportingEnabled ?? true
    })
  }

  const getActiveAndInactiveServices = (
    user: IDetailCollaborateurData,
    services: IServiceAccountData[]
  ): { active: IServiceAccountData[]; inactive: IServiceAccountData[] } => {
    const activeServices: IServiceAccountData[] = []
    let inactiveServices: IServiceAccountData[] = []

    Object.entries(user).forEach(([key, value]) => {
      if (value !== null) {
        const serviceName = key.replace('Account', '').toLowerCase()
        const service = services.find((s) => s.name.toLowerCase() === serviceName)
        if (service !== undefined) {
          if (value.isActive as boolean) {
            activeServices.push(service)
          }

          if (value.willBeActive as boolean) {
            service.name = `${service.name} (à venir)`
          }
        }
      }
    })
    // Trouver les services inactifs en comparant tous les noms de services avec les noms de services actifs
    inactiveServices = services.filter((service) => {
      const serviceName = service.name
      return !activeServices.some((activeService) => activeService.name === serviceName)
    })
    return { active: activeServices, inactive: inactiveServices }
  }

  const updateOrCreateUserHolidays = async (): Promise<void> => {
    if (userHolidaysQuota !== null && user !== null) {
      userHolidaysQuota.users = [user]

      let quotaResponse = null
      quotaResponse = await createOrUpdateUsersHolidayQuota(userHolidaysQuota)
      if (quotaResponse !== undefined && quotaResponse !== null) {
        setUserHolidaysQuota(quotaResponse)
      }
    }
  }

  const updateCollab = async (): Promise<void> => {
    setLoading(true)
    const userResponse = await createOrUpdateCollab(
      userDataToSend,
      Number(yearSelectedContext?.yearSelected),
      false,
      Number(userId)
    )
    if (userResponse !== null) {
      setUser(userResponse)
      setMyMaterials(user?.materials ?? [])
    }
  }

  const updateExpenseAmount = async (): Promise<void> => {
    userExpenses.forEach((userExpense) => {
      void (async () => {
        if (userExpense !== null && user !== null) {
          if (userExpense.id !== undefined) {
            await updateUserExpenseSetting(user.id, userExpense)
          } else {
            await createUserExpenseSettings(user.id, userExpense)
          }
        }
      })()
    })
  }

  const handleSave = async (): Promise<void> => {
    setLoading(true)
    // Maj quota congés collaborateur ou création si pas d'id
    isFeatureEnabled(featuresContext, featuresList.HOLIDAY) && (await updateOrCreateUserHolidays())

    // Maj montant note de frais
    isFeatureEnabled(featuresContext, featuresList.NDF) && (await updateExpenseAmount())

    await updateCollab()
    // Sauvegarde la nouvelle photo de profile si renseignée
    if (selectedImg !== null && user !== null) {
      await saveUserPicture(Number(user.id), selectedImg)
    }
    setOpenUpdateCollabPopUp(false)
    setLoading(false)
    setEditMode(false)
  }

  const controlUpdateValidity = (): void => {
    let error: boolean = false
    if (!isValidDate(userDataToSend.arrivalDate)) {
      setArrivalDateError("Veuillez renseigner la date d'arrivée ou une date valide")
      error = true
    } else {
      setArrivalDateError('')
    }
    if (userDataToSend.lastname === '') {
      setLastnameError('Veuillez renseigner un nom')
      error = true
    } else {
      setLastnameError('')
    }
    if (userDataToSend.firstname === '') {
      setFirstnameError('Veuillez renseigner un prénom')
      error = true
    } else {
      setFirstnameError('')
    }
    if (userDataToSend.personalMail === '') {
      setPersonalMailError("Veuillez renseigner l'adresse mail personnelle")
    } else if (!userDataToSend.personalMail.includes('@')) {
      setPersonalMailError('Veuillez renseigner une adresse mail valide')
      error = true
    } else {
      setPersonalMailError('')
    }
    if (
      (userDataToSend.phoneNumber.length > 0 &&
        userDataToSend.phoneNumber.match(/^(\\+33|0)[1-9][0-9]{8}$/) === null) ||
      userDataToSend.phoneNumber.match(/^(\\+33|0)[1-9][0-9]{8}$/)?.length === 0
    ) {
      setPhoneNumberError('Veuillez renseigner un numéro de téléphone valide')
      error = true
    } else {
      setPhoneNumberError('')
    }
    if (!error) {
      setOpenUpdateCollabPopUp(true)
    }
  }

  /**
   * Retourne true si l'utilisateur est inactif
   *
   * @returns boolean
   */
  const isUserInactive = (): boolean => {
    return user?.status === StatusEnum.INACTIVE
  }

  const resetChanges = (): void => {
    setEditMode(false)

    if (user !== null) {
      loadUserData(user)
    }
  }

  return (
    <div className="profil mx-1">
      {loading ? (
        <div className="loader">
          <CircularProgress />{' '}
        </div>
      ) : (
        <>
          <PrimaryButton
            handleClick={(): void => {
              navigate(-1)
            }}
            title="Retour"
            icon={<ChevronLeftIcon />}
            background={false}
            reverse={false}
            disabled={false}
          />
          {isUserInactive() && (
            <div className="inactive-user-banner">
              <ErrorIcon /> Cet utilisateur est désactivé depuis le{' '}
              {user?.leavingDate ? moment(user.leavingDate).format('DD/MM/YYYY') : 'inconnu'}
            </div>
          )}
          <ProfilHeader
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
            user={user}
            editMode={editMode}
            handleClick={(): void => {
              setEditMode(!editMode)
            }}
            lastnameError={lastnameError}
            firstnameError={firstnameError}
            userDataToSend={userDataToSend}
            setUserDataToSend={setUserDataToSend}
            croppedImg={croppedImg}
            setCroppedImg={setCroppedImg}
          />

          <TabNavigation
            currentTab={currentTab}
            handleTab={(e: any, tab: number) => {
              setCurrentTab(tab)
            }}
            navItems={profilTabList
              .filter((tab) => isTabEnabled(tab, featuresContext, user, currentUser))
              .map((tab) => ({ label: tab.label }))}
          ></TabNavigation>

          {currentTab === 0 ? (
            <>
              <EntryLeave
                editMode={editMode}
                user={user}
                userDataToSend={userDataToSend}
                setUserDataToSend={setUserDataToSend}
                arrivalDateError={arrivalDateError}
              />
              <Services
                editMode={editMode}
                categoryTitle="Etat des services"
                activeAccounts={activeAccounts}
                inactiveAccounts={inactiveAccounts}
                userDataToSend={userDataToSend}
                setUserDataToSend={setUserDataToSend}
              />
              <ProInfos
                editMode={editMode}
                user={user}
                userDataToSend={userDataToSend}
                setUserDataToSend={setUserDataToSend}
                contractTypes={contractTypes}
                jobs={jobs}
              />
              <PracticleInfos
                editMode={editMode}
                user={user}
                userDataToSend={userDataToSend}
                setUserDataToSend={setUserDataToSend}
                places={places}
                roles={roles}
              />
              <PersonalInfos
                editMode={editMode}
                user={user}
                userDataToSend={userDataToSend}
                setUserDataToSend={setUserDataToSend}
                personalMailError={personalMailError}
                phoneNumberError={phoneNumberError}
              />
              <Permission name={featuresList.NDF}>
                <BankingInfos
                  editMode={editMode}
                  userDataToSend={userDataToSend}
                  setUserDataToSend={setUserDataToSend}
                />
              </Permission>
              {hasManagerRole(currentUser?.rolePermissions ?? []) && (
                <Permission name={featuresList.MATERIAL}>
                  <MaterialUpdateUser
                    editMode={editMode}
                    user={user}
                    userDataToSend={userDataToSend}
                    setUserDataToSend={setUserDataToSend}
                    myMaterials={myMaterials}
                    setMyMaterials={setMyMaterials}
                  />
                </Permission>
              )}
              <CommentaryInfos
                user={user}
                editMode={editMode}
                userDataToSend={userDataToSend}
                setUserDataToSend={setUserDataToSend}
              />
              {Number(userId) === currentUser?.id && <Notifications user={user} />}
              <ReportingSwitch
                user={user}
                checked={userDataToSend.reportingEnabled}
                handleChange={handleReportingEnabledChange}
                disabled={!editMode}
              />
            </>
          ) : currentTab === 1 ? (
            <>
              <Permission name={featuresList.HOLIDAY}>
                <HolidayInfos
                  editMode={editMode}
                  userHolidaysQuota={userHolidaysQuota}
                  setUserHolidaysQuota={setUserHolidaysQuota}
                  userId={userId}
                />
              </Permission>
              <Permission name={featuresList.NDF}>
                <Expenses
                  editMode={editMode}
                  user={user}
                  userExpenses={userExpenses}
                  setUserExpenses={setUserExpenses}
                  displayTitle
                />
              </Permission>
              <MealPlanInfos
                editMode={editMode}
                userDataToSend={userDataToSend}
                setUserDataToSend={setUserDataToSend}
              />
            </>
          ) : currentTab === 2 ? (
            user !== null && (
              <CareerInfos
                editMode={editMode}
                userDataToSend={userDataToSend}
                setUserDataToSend={setUserDataToSend}
                user={user}
              />
            )
          ) : currentTab === 3 ? (
            <MyDocumentsTab
              documentDrive={documents}
              nbPages={1}
              currentTab={currentTab}
              selectedType={myDocumentRequestConstant[1].type}
              showHeaderAndPagination={true}
              needReload={false}
              order={'desc'}
              setOrder={() => {}}
              orderBy={''}
              setOrderBy={() => {}}
              page={0}
              setPage={() => {}}
              setNeedReload={() => {}}
              userId={Number(userId)}
              canEdit
            ></MyDocumentsTab>
          ) : (
            user && <Costs user={user} />
          )}

          {editMode ? (
            <ListItemsFooter
              handleCancel={(): void => {
                resetChanges()
              }}
              handleSave={controlUpdateValidity}
            />
          ) : null}
        </>
      )}
      <ConfirmationPopUp
        dialogTitle="Voulez-vous sauvegarder vos changements ?"
        dialogContentText="Les données de l'utilisateur seront mises à jour et les services aussi si le collaborateur est déjà arrivé !"
        open={openUpdateCollabPopUp}
        setOpen={setOpenUpdateCollabPopUp}
        handleSave={handleSave}
        loading={loading}
      />
    </div>
  )
}

export default Profil
