import { IListSelectorValue, IServiceAccountData } from 'interfaces/selectors'
import { ICollabsWithPagination } from 'interfaces/dataWithPagination'
import { customAxios } from './axiosUtils'

/**
Récupérer les jobs
@return la liste des jobs
**/
export const getJobs = async (): Promise<IListSelectorValue[]> => {
  try {
    return await customAxios.get('jobs')
  } catch (error) {
    return []
  }
}

/**
Récupérer les lieux
@return la liste des lieux
**/
export const getPlaces = async (): Promise<IListSelectorValue[]> => {
  try {
    return await customAxios.get('places')
  } catch (error) {
    return []
  }
}

/**
Récupérer les roles
@return la liste des roles
**/
export const getRoles = async (): Promise<IListSelectorValue[]> => {
  try {
    return await customAxios.get('roles')
  } catch (error) {
    return []
  }
}

/**
Récupérer les types de contrats
@return la liste des contractTypes
**/
export const getContractTypes = async (): Promise<IListSelectorValue[]> => {
  try {
    return await customAxios.get('contract-types')
  } catch (error) {
    return []
  }
}

/**
Récupérer la liste des utilisateurs correspondant à la recherche
@return la liste des collaborateurs trouvés
**/
export const getMatchingUsers = async (
  search: string,
  placeId: number | null
): Promise<ICollabsWithPagination | null> => {
  try {
    const placeParam = placeId !== null ? `&placeId=${placeId}` : ''
    return await customAxios.get(`users?search=${search}${placeParam}`)
  } catch (error) {
    return null
  }
}

/**
Récupérer la liste de tout les collabs
@return la liste des collaborateurs
**/
export const getAllUsers = async (
  isReportingEnabled: boolean,
  placeId: number | null
): Promise<ICollabsWithPagination | null> => {
  try {
    const placeParam = placeId !== null ? `&placeId=${placeId}` : ''
    const isReportingEnabledParam = isReportingEnabled ? `&isReportingEnabled=1` : ''
    return await customAxios.get(`users?${placeParam}${isReportingEnabledParam}&limit=1000`)
  } catch (error) {
    return null
  }
}

/**
Récupérer les services
@return la liste des services
**/
export const getServices = async (): Promise<IServiceAccountData[]> => {
  try {
    return await customAxios.get('services')
  } catch (error) {
    return []
  }
}

/**
  Récupérer les types d'entretiens
  @return la liste des interviewTypes
**/
export const getInterviewTypes = async (): Promise<IListSelectorValue[]> => {
  try {
    return await customAxios.get('interviews-types')
  } catch (error) {
    return []
  }
}

/**
  Récupérer les types de projets
  @return la liste des projectTypes
**/
export const getProjectTypes = async (): Promise<IListSelectorValue[]> => {
  try {
    return await customAxios.get('projects/projects-types')
  } catch (error) {
    return []
  }
}

/**
  Récupérer les statuts de projets
  @return la liste des projectStatus
**/
export const getProjectStatus = async (): Promise<IListSelectorValue[]> => {
  try {
    return await customAxios.get('projects/projects-status')
  } catch (error) {
    return []
  }
}
