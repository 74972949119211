import CustomNumberTextField from 'components/inputs/customNumberTextField/CustomNumberTextField'
import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import { getMyExpensesSettings } from 'services/ExpensesService'
import React, { ReactElement, useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { IMyExpense } from 'interfaces/expenses'
import { Switch } from '@mui/material'

interface IExpenses {
  editMode: boolean
  user: IDetailCollaborateurData | null | undefined
  userExpenses: IMyExpense[]
  setUserExpenses: React.Dispatch<React.SetStateAction<IMyExpense[]>>
  displayTitle: boolean
}

const Expenses = ({
  user,
  editMode,
  userExpenses,
  setUserExpenses,
  displayTitle
}: IExpenses): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    void (async () => {
      if (user?.id !== undefined) {
        const result = await getMyExpensesSettings(user.id)
        if (result !== undefined && result !== null) {
          setUserExpenses(result)
        }
      }
    })()
    setLoading(false)
  }, [user?.id])

  const handleAmountChange = (amount: number, index: number): void => {
    const userExpensesCopy = [...userExpenses]
    userExpensesCopy[index] = {
      ...userExpensesCopy[index],
      amount
    }
    setUserExpenses((prevExpenses) => userExpensesCopy)
  }

  const handleStatutChange = (isActiveExpense: boolean, index: number): void => {
    const userExpensesCopy = [...userExpenses]
    userExpensesCopy[index] = {
      ...userExpensesCopy[index],
      isActiveExpense
    }
    setUserExpenses((prevExpenses) => userExpensesCopy)
  }

  return (
    <div className={`box-data expenses ${displayTitle ? '' : 'no-padding-top'}`}>
      {displayTitle && <p className="category-title">Notes de frais</p>}
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="box-inline-elts">
          <div>
            {userExpenses.map((myExpensesSetting, index) => (
              <div className="expenses-toggle-box" key={index}>
                <div className="switch-label">
                  <Switch
                    checked={myExpensesSetting.isActiveExpense}
                    onChange={(): void => {
                      handleStatutChange(!myExpensesSetting.isActiveExpense, index)
                    }}
                    disabled={!editMode}
                  />
                  <p className="data-title">{myExpensesSetting.expenseType.label}</p>
                  {myExpensesSetting.isActiveExpense &&
                  myExpensesSetting.expenseType.label !== 'Autres' ? (
                    <>
                      {editMode ? (
                        <>
                          <CustomNumberTextField
                            onChange={(value) => {
                              handleAmountChange(Number(value), index)
                            }}
                            id="expenseAmount"
                            value={myExpensesSetting.amount.toString()}
                            required={false}
                          />
                          <>€</>
                        </>
                      ) : (
                        <>{myExpensesSetting.amount} €</>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Expenses
