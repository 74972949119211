import { IReporting } from 'interfaces/reporting'
import Holidays from 'date-holidays'
import { easter } from 'date-easter'
import { Dayjs } from 'dayjs'
import moment from 'moment'

/* A partir d'une date de départ et de fin, retourne un tableau des dates entres ces deux jours en string
  si une seule date sélectionné, on autorise les weekend, sinon on retire les weekend et jours fériés par défaut */
const getDatesBetween = (start: Date, end: Date, holidayDates: string[]): string[] => {
  const dates = []
  let currentDate = moment(start).startOf('day')
  const endDate = moment(end).startOf('day')

  if (start === end) {
    return [currentDate.format('YYYY-MM-DD')]
  }

  while (currentDate <= endDate) {
    // gestion weekEnd
    if (
      currentDate.day() !== 0 &&
      currentDate.day() !== 6 &&
      !isHolidayOrWeekendDay(currentDate.format('YYYY-MM-DD'), holidayDates)
    ) {
      dates.push(currentDate.format('YYYY-MM-DD'))
    }
    currentDate = currentDate.add(1, 'days')
  }

  return dates
}

const isSameDay = (date1: Date, date2: Date): boolean => {
  return moment(date1).isSame(moment(date2), 'day')
}

const isToday = (date: string): boolean => {
  return moment(date).isSame(moment(), 'day')
}

/**
 * Vérifie si la date donnée correspond à un week-end ou à un jour férié.
 * @param date - La date à vérifier au format 'YYYY-MM-DD'.
 * @return true si la date est un week-end ou un jour férié, sinon false.
 */
const isHolidayOrWeekendDay = (date: string, holidayDates: string[]): boolean => {
  const dayOfWeek = moment(date).day()
  const isHoliday = holidayDates.includes(date)

  return dayOfWeek === 0 || dayOfWeek === 6 || isHoliday
}

const isDateBeforeToday = (dateToCheck: string): boolean => {
  return moment(dateToCheck).startOf('day').isBefore(moment().startOf('day'))
}

const getHolidayDatesWithoutPentecote = (hd: any, year: number): string[] => {
  const startDate = moment(`${year}-01-01`)
  const endDate = moment(`${year}-12-31`)

  // Générer la liste des dates de l'année
  const listDates: string[] = []
  const currentDate = startDate.clone()
  while (currentDate.isSameOrBefore(endDate)) {
    listDates.push(currentDate.format('YYYY-MM-DD'))
    currentDate.add(1, 'day')
  }

  const esterDate = easter(year)
  const pentecoteDate = moment(
    new Date(esterDate.year, esterDate.month - 1, esterDate.day + 50)
  ).format('YYYY-MM-DD')

  // Obtenez les dates des jours fériés pour l'année choisie
  const holidayDates = listDates.filter(
    (date) => hd.isHoliday(date) !== false && date !== pentecoteDate
  )

  return holidayDates
}

/**
 *
 * @param startDate @param {Date} startDate - La date de début de la plage de dates à générer
 * @param {Date} endDate - La date de fin de la plage de dates à générer
 * @return {string[]} - Un tableau de chaînes de caractères représentant toutes les dates entre startDate et endDate, incluses.
 */
const enumerateDaysBetweenDates = (startDate: Date, endDate: Date): string[] => {
  const dates = []
  let currentDate = moment(startDate)
  const end = moment(endDate)
  while (currentDate <= end) {
    dates.push(currentDate.format('YYYY-MM-DD'))
    currentDate = currentDate.add(1, 'days')
  }
  return dates
}

// Permet de déterminer si la date récupérée est hier, auj ou demain ou autre
const getDayLabel = (date: Date): string => {
  const now = new Date()
  const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
  const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)

  if (date.toDateString() === now.toDateString()) {
    return "Aujourd'hui"
  } else if (date.toDateString() === yesterday.toDateString()) {
    return 'Hier'
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return 'Demain'
  } else {
    return ''
  }
}
/**
  Récupérer le nombre de jour entre deux dates
  @param d1 date 1 à comparer
  @param d2 date 2 à comparer
  @return nombre de jours entres deux dates
  **/
const numDaysBetween = (d1: Date, d2: Date): number => {
  const diff = Math.abs(d1.getTime() - d2.getTime())
  return diff / (1000 * 60 * 60 * 24)
}

/**
  Formatter date 'YYYY-MM-DD' en 'DD/MM/YYYY' ou nom du jour (jeudi...) si moins de 5j
  @param date date à formatter
  @return date formatée
  **/
const formatDate = (date: string): string => {
  const dateAsDate = new Date(date)
  const daysDiff = numDaysBetween(new Date(), dateAsDate)
  // Si moins de 5j, alors on retourne le nom du jour + précision sur la temporalité
  if (getDayLabel(new Date(date)) !== '') {
    return getDayLabel(new Date(date))
  }

  const dayString = dateAsDate.toLocaleString('fr-fr', { weekday: 'long' })
  if (daysDiff <= 5 && dateAsDate > new Date()) {
    return dayString.charAt(0).toUpperCase() + dayString.slice(1) + ' prochain'
  }
  if (daysDiff <= 5 && dateAsDate < new Date()) {
    return dayString.charAt(0).toUpperCase() + dayString.slice(1) + ' dernier'
  }
  return moment(date).format('DD/MM/YYYY')
}

// Formatter la date avec le temps en plus
const formatDateTime = (dateTime: string): string => {
  return moment(dateTime).format('DD/MM/YYYY [à] HH:mm')
}

// Formatter la date
const formatDateToDay = (dateTime: string): string => {
  return moment(dateTime).format('DD/MM/YYYY')
}

// Formatter la date sans l'année
const formatDateWithoutYear = (dateTime: string): string => {
  return moment(dateTime).format('DD/MM')
}

// Permet de vérifier qu'une date est renseignée et correcte
const isValidDate = (date: any): boolean => {
  if (date === undefined || date === null) return false
  if (typeof date === 'string' || !isNaN(date)) return true

  return false
}

// Permet de set la date selectionnée d'un datePicker à 8h pour ne pas avoir de problème lié au GMT + 1 ou 2 (avant 23h + 1)
const setValidDate = (date: Dayjs): Date => {
  return date.set('hour', 8).toDate()
}

const convertDateTimeToDateString = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD').split('T')[0]
}

const getMonthName = (numericMonth: string | undefined): string => {
  if (numericMonth !== undefined) {
    const monthIndex = parseInt(numericMonth, 10) - 1

    if (!isNaN(monthIndex) && monthIndex >= 0 && monthIndex <= 11) {
      const date = new Date(2000, monthIndex, 1)
      return date.toLocaleString('fr-FR', { month: 'long' })
    }
  }
  return 'Mois inconnu'
}

const formatDateToMonthAndYear = (dateTime: string): string => {
  const date = new Date(dateTime)
  return date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' })
}

const calculateTotalForDay = (
  reporting: IReporting[],
  listDates: string[],
  isBillable?: boolean
): number[] => {
  return listDates.map((day) => {
    const totalAmountWorkedForDay = reporting
      .filter((report) => (isBillable !== undefined ? report.isBillable === isBillable : true))
      .reduce((total, report) => {
        if (!report?.workEntries) {
          return 0
        }
        // On utilise filter pour récupérer les workEntries qui correspondent à la date
        const workEntries = report.workEntries.filter((entry) =>
          moment(entry.date).isSame(day, 'day')
        )
        // On somme les amountWorked de chaque workEntry
        const dayTotal = workEntries.reduce((sum, entry) => sum + entry.amountWorked, 0)
        return total + dayTotal
      }, 0)
    return totalAmountWorkedForDay / 100
  })
}

const calculateTotalForMonth = (
  reporting: IReporting[],
  listDates: string[],
  isBillable?: boolean
): number => {
  const totalForDay = calculateTotalForDay(reporting, listDates, isBillable)
  return totalForDay.reduce((total, amount) => total + amount, 0)
}

const calculateWorkableDaysInMonth = (month: number, year: number): number => {
  const hd = new Holidays('FR')
  const holidayDates = getHolidayDatesWithoutPentecote(hd, year)
  const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()
  let workableDays = 0

  for (let day = 1; day <= daysInMonth; day++) {
    const date = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD')
    const dayOfWeek = date.day()
    const dateString = date.format('YYYY-MM-DD')

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      if (!isHolidayOrWeekendDay(dateString, holidayDates)) {
        workableDays++
      }
    }
  }

  return workableDays
}

export {
  getDatesBetween,
  formatDate,
  formatDateToMonthAndYear,
  isValidDate,
  setValidDate,
  formatDateTime,
  formatDateToDay,
  formatDateWithoutYear,
  enumerateDaysBetweenDates,
  isToday,
  isHolidayOrWeekendDay,
  isDateBeforeToday,
  getHolidayDatesWithoutPentecote,
  isSameDay,
  convertDateTimeToDateString,
  getMonthName,
  calculateTotalForDay,
  calculateTotalForMonth,
  calculateWorkableDaysInMonth
}
