import { getMyNotifs, updateUserNotificationSettingFor } from 'services/NotificationService'
import { IDetailCollaborateurData } from 'interfaces/collaborateurData'
import React, { ReactElement, useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { IMyNotif } from 'interfaces/notifications'
import { Switch } from '@mui/material'

interface INotifications {
  user: IDetailCollaborateurData | null
}

const Notifications = ({ user }: INotifications): ReactElement => {
  const [myNotifications, setMyNotifications] = useState<IMyNotif[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    void (async () => {
      if (user?.id !== undefined) {
        setMyNotifications(await getMyNotifs(user?.id))
        setLoading(false)
      }
    })()
  }, [user?.id])

  const saveNotificationStateInDB = async (
    isActiveNotif: boolean,
    notifToUpdate: IMyNotif
  ): Promise<void> => {
    if (user?.id !== undefined) {
      const notifUpdated = await updateUserNotificationSettingFor(
        user.id,
        notifToUpdate.id,
        isActiveNotif
      )
      setMyNotifications((prev) => {
        return prev.map((previousNotif) => {
          if (previousNotif.id === notifUpdated?.id) {
            return notifUpdated
          }
          return previousNotif
        })
      })
    }
  }

  return (
    <div className="box-data notifs">
      <p className="category-title">Notifications</p>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="box-inline-elts">
          {myNotifications.map((myNotif) => (
            <div className="notif-toggle-box" key={myNotif.notifType.id}>
              <Switch
                checked={myNotif.isActiveNotif}
                onChange={(e): void => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  saveNotificationStateInDB(e.target.checked, myNotif)
                }}
              />
              <p className="data-title">{myNotif.notifType.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Notifications
