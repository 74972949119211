import { IheadCells } from 'interfaces/headCells'

export const tableMyDocumentsColumns = (): IheadCells[] => [
  {
    id: 'documentName',
    label: 'Nom',
    priority: 1,
    isSortable: false
  },
  {
    id: 'downloadAndOpen',
    label: '',
    priority: 2,
    isSortable: false
  }
]

export const tableMyDocumentsPaySlipColumns = (): IheadCells[] => [
  {
    id: 'documentName',
    label: 'Nom du Document',
    priority: 1,
    isSortable: false
  },
  {
    id: 'month',
    label: 'Mois',
    priority: 1,
    isSortable: false
  },
  {
    id: 'downloadAndOpen',
    label: '',
    priority: 2,
    isSortable: false
  }
]
