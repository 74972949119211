import CustomNumberTextField from 'components/inputs/customNumberTextField/CustomNumberTextField'
import { IUsersHolidayQuota } from 'interfaces/holidays/userHolidayQuota'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface IHolidayInfos {
  editMode: boolean
  userHolidaysQuota: IUsersHolidayQuota
  setUserHolidaysQuota: React.Dispatch<React.SetStateAction<IUsersHolidayQuota>>
  userId?: string | undefined
}

const HolidayInfos = ({
  editMode,
  userHolidaysQuota,
  setUserHolidaysQuota,
  userId
}: IHolidayInfos): ReactElement => {
  const handleQuotaChange = (quota: number, quotaType: string): void => {
    setUserHolidaysQuota((prevQuota) => {
      return {
        ...prevQuota,
        [quotaType]: quota
      }
    })
  }

  return (
    <div className="box-data">
      <p className="category-title">Congés</p>
      <div className="box-inline-elts">
        <div className="box-column">
          {editMode ? (
            <CustomNumberTextField
              onChange={(value) => {
                handleQuotaChange(Number(value), 'paidVacationQuota')
              }}
              id="paidVacationQuota"
              value={userHolidaysQuota?.paidVacationQuota.toString() ?? '0'}
              errorMessage=""
              required
              label="Nombre congés payés"
              text="ex: 4,5"
            />
          ) : (
            <div>
              <p className="data-title">Nombre congés payés</p>
              <p className="data-value">{userHolidaysQuota?.paidVacationQuota}</p>
            </div>
          )}
        </div>
        <div className="box-column">
          {editMode ? (
            <CustomNumberTextField
              onChange={(value) => {
                handleQuotaChange(Number(value), 'RTTQuota')
              }}
              id="RTTQuota"
              value={userHolidaysQuota?.RTTQuota.toString() ?? '0'}
              errorMessage=""
              required
              label="Nombre RTT"
              text="ex: 4,5"
            />
          ) : (
            <div>
              <p className="data-title">Nombre RTT</p>
              <p className="data-value">{userHolidaysQuota?.RTTQuota}</p>
            </div>
          )}
        </div>
        {userId !== undefined ? (
          <>
            <p className="data-title">
              <Link to={`/planning?view=annual&userId=${userId}`}>Voir le planning</Link>
            </p>
            <p className="data-title">
              <Link to={`/mes_conges/${userId}`}>Voir les congés</Link>
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default HolidayInfos
