import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { IconButton, InputBase, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import debounce from 'lodash/debounce'

interface ISearchBox {
  searchTerm?: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  placeholder: string
}

const SearchBox = ({ searchTerm = '', setSearchTerm, placeholder }: ISearchBox): ReactElement => {
  const [localSearchTerm, setLocalSearchTerm] = useState<string>(searchTerm)
  const debouncedSetSearchTerm = useRef(
    debounce((value: string) => {
      setSearchTerm(value)
    }, 500)
  ).current

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setLocalSearchTerm(value)
    debouncedSetSearchTerm(value)
  }

  useEffect(() => {
    setLocalSearchTerm(searchTerm)
  }, [searchTerm])

  return (
    <div className="search-box">
      <Paper component="form">
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ flex: 1 }}
          value={localSearchTerm}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearch}
          onKeyPress={(event) => {
            if (event.key === 'Enter') event.preventDefault()
          }}
        />
      </Paper>
    </div>
  )
}

export default SearchBox
