import React, { ReactElement } from 'react'

interface IStepper {
  currentStep: number
  steps: string[]
  onStepChange?: (index: number) => void
  isReadonly: boolean
}

const Stepper = ({ currentStep, steps, onStepChange, isReadonly }: IStepper): ReactElement => {
  return (
    <ul className="stepper">
      {steps.map((step: string, index: number) => (
        <li
          key={index}
          className={`stepper-item ${index <= currentStep ? 'completed' : ''} ${
            !isReadonly ? 'is-clickable' : ''
          }`}
          onClick={() => {
            onStepChange?.(index)
          }}
        >
          {step}
        </li>
      ))}
    </ul>
  )
}

export default Stepper
