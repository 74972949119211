import { IDetailCollaborateurData, IUpdateUserData } from 'interfaces/collaborateurData'
import CustomDatePicker from 'components/inputs/customDatePicker/CustomDatePicker'
import { contractTypeConstant } from 'constantes'
import { setValidDate } from 'utils/dateUtils'
import React, { ReactElement } from 'react'
import { Dayjs } from 'dayjs'
import moment from 'moment'

interface IEntryLeave {
  user: IDetailCollaborateurData | null
  editMode: boolean
  userDataToSend: IUpdateUserData
  setUserDataToSend: React.Dispatch<React.SetStateAction<IUpdateUserData>>
  arrivalDateError: string
}

const EntryLeave = ({
  user,
  editMode,
  userDataToSend,
  setUserDataToSend,
  arrivalDateError
}: IEntryLeave): ReactElement => {
  const handleChangeDate = (newValue: Dayjs | null, propertyToUpdate: string): void => {
    setUserDataToSend({
      ...userDataToSend,
      [propertyToUpdate]: newValue !== null ? setValidDate(newValue) : null
    })
  }

  const formatDate = (date: string | null | undefined): string => {
    return date !== undefined && date !== null ? moment(date).format('DD/MM/yyyy') : ''
  }

  return (
    <div className="box-data">
      <p className="category-title">Entrée/Sortie</p>
      <div className="box-inline-elts">
        <div className="box-column">
          {editMode ? (
            <CustomDatePicker
              handleChangeDate={(e, property) => {
                handleChangeDate(e, property)
              }}
              id="internshipStartDate"
              value={userDataToSend.internshipStartDate}
              errorMessage=""
              required={false}
              label="Date de début de stage"
            />
          ) : (
            user?.internshipStartDate && (
              <div>
                <p className="data-title">Date de début de stage</p>
                <p className="data-value">{formatDate(user.internshipStartDate)}</p>
              </div>
            )
          )}
        </div>

        <div className="box-column">
          {editMode ? (
            <CustomDatePicker
              handleChangeDate={(e, property) => {
                handleChangeDate(e, property)
              }}
              id="permanentContractStartDate"
              value={userDataToSend.permanentContractStartDate}
              errorMessage=""
              required={false}
              label="Date de début de CDI"
            />
          ) : (
            user?.permanentContractStartDate && (
              <div>
                <p className="data-title">Date de début de CDI</p>
                <p className="data-value">{formatDate(user.permanentContractStartDate)}</p>
              </div>
            )
          )}
        </div>

        <div className="box-column">
          {editMode ? (
            <CustomDatePicker
              handleChangeDate={(e, property) => {
                handleChangeDate(e, property)
              }}
              id="endInternshipDate"
              value={userDataToSend.endInternshipDate}
              errorMessage=""
              required={false}
              label="Date de fin de stage"
            />
          ) : (
            user?.endInternshipDate && (
              <div>
                <p className="data-title">Date de fin de stage</p>
                <p className="data-value">{formatDate(user.endInternshipDate)}</p>
              </div>
            )
          )}
        </div>

        <div className="box-column">
          {user?.contractType?.name !== contractTypeConstant.STAGIAIRE && (
            <>
              {editMode ? (
                <CustomDatePicker
                  handleChangeDate={(e, property) => {
                    handleChangeDate(e, property)
                  }}
                  id="endTrialPeriodDate"
                  value={userDataToSend.endTrialPeriodDate}
                  errorMessage=""
                  required={false}
                  label="Date de fin de période d'essai"
                />
              ) : (
                user?.endTrialPeriodDate && (
                  <div>
                    <p className="data-title">Date de fin de période d&apos;essai</p>
                    <p className="data-value">{formatDate(user.endTrialPeriodDate)}</p>
                  </div>
                )
              )}
            </>
          )}
        </div>

        <div className="box-column">
          {editMode ? (
            <CustomDatePicker
              handleChangeDate={(e, property) => {
                handleChangeDate(e, property)
              }}
              id="leavingDate"
              value={userDataToSend.leavingDate}
              errorMessage=""
              required={false}
              label="Date de sortie"
            />
          ) : (
            user?.leavingDate && (
              <div>
                <p className="data-title">Date de sortie</p>
                <p className="data-value">{formatDate(user.leavingDate)}</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default EntryLeave
